import { Component, Input, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/theme/theme.service';
import { ObtenerDatosService } from '../../../servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from '../../../servicios/globales.service';
import { Router } from '@angular/router';
import { RutasService } from '../../../servicios/rutas/rutas.service';

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

	selectedContienda: any = '';
	
	@Input() public subtitle: any = '';
	@Input() public subtitle_detalle: any = '';
	@Input() public estado: any = '';
	@Input() public distrito: any = '';
	@Input() public distrito_param: any = '';
	@Input() public seccion: any = '';
	@Input() public seccion_param: any = '';
	@Input() public casilla: any = '';
	@Input() public lastLabel: any = '';

	constructor(
		public _obtenerDatosService:ObtenerDatosService,
		public globalService: GlobalesService,
		public themeService: ThemeService,
		public router: Router,
		public rutasService: RutasService,
	) { }

	ngOnInit(): void {
		this.selectedContienda = this.globalService.getContienda();
	}

	onNavigate(lvl, url, navigate) {
		if (navigate) {
			switch (lvl) {
				case 1:
					delete this.rutasService.migas.distrito;
					delete this.rutasService.migas.distrito_clave;
					delete this.rutasService.migas.seccion;
					delete this.rutasService.migas.seccion_clave;
					delete this.rutasService.migas.casilla; 
					delete this.rutasService.migas.casilla_clave;
					break;
			
				case 2:
					delete this.rutasService.migas.seccion;
					delete this.rutasService.migas.seccion_clave;
					delete this.rutasService.migas.casilla; 
					delete this.rutasService.migas.casilla_clave;
					break;
	
				case 3:
					delete this.rutasService.migas.casilla; 
					delete this.rutasService.migas.casilla_clave;
					break;
	
				default:
					console.warn('Inconsistencia Navigate');
					break;
			}
	
			this.rutasService.checkMigas(this.rutasService.rutaActual.ruta);
			if (url) this.router.navigateByUrl(url);
		}
	}




}
