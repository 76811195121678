import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from "@angular/common";
import { ConsultaCiudadanaComponent } from './consulta-ciudadana.component';
import { ContiendasComponent } from '../contiendas.component';
import { AuthGuard } from '../../auth/auth.guard';

const routes: Routes = [
  {
    path: 'consulta-ciudadana',
    component: ContiendasComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'cuestionario',
        pathMatch: 'full'
      },
      {
        path: 'cuestionario',
        component: ConsultaCiudadanaComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule],
  exports: [RouterModule]
})
export class ConsultaCiudadanaRoutingModule { }
