import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsultaCiudadanaRoutingModule } from './consulta-ciudadana-routing.module';
import { ConsultaCiudadanaComponent } from './consulta-ciudadana.component';
import { ImportingModule } from '../../importing.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ConsultaCiudadanaComponent
  ],
  imports: [
    CommonModule,
    ImportingModule,
    RouterModule,
    ConsultaCiudadanaRoutingModule
  ]
})
export class ConsultaCiudadanaModule { }
