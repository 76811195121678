import { Injectable, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObtenerDatosService } from '../datos/obtener-datos/obtener-datos.service';


@Injectable({
  providedIn: 'root'
})
export class RutasService {

  nivelRutaEspecifica = false;
  rutaActual: any = {};
  nivel: string;

  detalles: any = {};
  rutas: any = {};

  migas: any = {};

  observ = new EventEmitter<any>();
  dominio: any;

  constructor(
    public activatedRoute: ActivatedRoute,
    // public datosService: ObtenerDatosService
  ) { }

  construirRutas() {
    if (this.detalles.circunscripcion) {
      this.rutas.circunscripcion = this.detalles.circunscripcion;
      if (this.detalles.entidad) {
        this.rutas.entidad = this.rutas.circunscripcion + '/entidad/' + this.detalles.entidad;
        if (this.detalles.distrito) {
          this.rutas.distrito = this.rutas.entidad + '/distrito/' + this.detalles.distrito;
          if (this.detalles.seccion) {
            this.rutas.seccion = this.rutas.distrito + '/seccion/' + this.detalles.seccion;
            if (this.detalles.casilla) {
              this.rutas.casilla = this.rutas.seccion + '/casilla/' + this.detalles.casilla;
            }
          }
        }
      }
    }
    this.observ.emit(this.rutas);
  }

  obtenerDominio() {
    let url:string = window.location.pathname;
    url = url.split('/diputaciones/nacional/')[0];
    this.dominio = url + '/diputaciones/nacional/';
  }

  obtenerId(entrada: string) {
    return entrada.split(' ')[1];
  }

  setearMigas() {
    const prom = new Promise((res, rej) => {
      localStorage.setItem('prep-migas', JSON.stringify(this.migas));
      res();
    });
    prom.then(() => {
      this.cargarMigas();
    })
  }

  cargarMigas() {
    if (localStorage.getItem('prep-migas')) {
      this.migas = JSON.parse(localStorage.getItem('prep-migas'));
      // this.checkMigas(this.rutaActual.ruta);
    }
  }

  establecerMigas(selectedContienda, url_params) {

    let idDistrito = url_params.distrito ? url_params.distrito : null;
      if (selectedContienda == 'ayuntamientos' || selectedContienda == 'juntas') {
        if (url_params.municipio) idDistrito = url_params.municipio;
      }

      if (idDistrito) this.migas.distrito_clave = idDistrito;
      else {
        this.migas.distrito = '';
        this.migas.distrito_clave = '';
      }
      
      let idSeccion = url_params.seccion ? url_params.seccion : null;
      if (idSeccion) this.migas.seccion_clave = idSeccion;
      else {
        this.migas.seccion = '';
        this.migas.seccion_clave = '';
      }
      
      let idCasilla = url_params.casilla ? url_params.casilla : null;
      if (idCasilla) this.migas.casilla_clave = idCasilla;
      else {
        this.migas.casilla = '';
        this.migas.casilla_clave = '';
      }

      // this.datosService.urlSubject.next({selectedContienda, url_params});
  }

  checkMigas(rutaCompleta) {
    let arr_url = rutaCompleta.split('/'),
        distrito = false,
        seccion = false,
        casilla = false;

    arr_url.forEach(el => {
      if (this.migas.distrito_clave == el) distrito = true;
      if (this.migas.seccion_clave == el) seccion = true;
      if (this.migas.casilla_clave == el) casilla = true;
    });

    if (distrito === false) {
      delete this.migas.distrito;
      delete this.migas.distrito_clave;
    }

    if (seccion === false) {
      delete this.migas.seccion;
      delete this.migas.seccion_clave;
    }

    if (casilla === false) {
      delete this.migas.casilla;
      delete this.migas.casilla_clave;
    }

    // localStorage.setItem('prep-migas', JSON.stringify(this.migas));
  }

  reset() {
    if (localStorage.getItem('prep-migas')) {
      localStorage.removeItem('prep-migas');
    }
    this.migas = {};
  }

  verificarNivel(pre?: string) {
    const prom = new Promise((res, rej) => {
      if (!pre) {
        let niveles = ['circunscripcion', 'entidad', 'distrito', 'seccion', 'casilla'];
        for (var nivel of niveles) {
          if (nivel in this.detalles) {
            this.nivel = nivel;
          }
        }
      res();
      } else {
        this.nivel = pre;
        res();
      }

    });

    prom.then(() => {
      if (!this.nivelRutaEspecifica) {
        this.migas = {};
      } else {
      }
      if(this.nivel === 'circunscripcion') {
        this.migas = {
          circunscripcion: this.migas.circunscripcion
        }
      }
      if(this.nivel === 'entidad') {
        this.migas = {
          circunscripcion: this.migas.circunscripcion,
          entidad: this.migas.entidad
        }
      }
      if(this.nivel === 'distrito') {
        this.migas = {
          circunscripcion: this.migas.circunscripcion,
          entidad: this.migas.entidad,
          distrito: this.migas.distrito
        }
      }
      if(this.nivel === 'seccion') {
        this.migas = {
          circunscripcion: this.migas.circunscripcion,
          entidad: this.migas.entidad,
          distrito: this.migas.distrito,
          seccion: this.migas.seccion
        }
      }
      if(this.nivel === 'casilla') {
        this.migas = {
          circunscripcion: this.migas.circunscripcion,
          entidad: this.migas.entidad,
          distrito: this.migas.distrito,
          seccion: this.migas.seccion,
          casilla: this.migas.casilla
        }
      }
      // if(this.nivel === 'entidad') {
      //   this.migas.distrito = null;
      //   this.migas.seccion = null;
      //   this.migas.casilla = null;
      // }
      // if(this.nivel === 'distrito') {
      //   this.migas.seccion = null;
      //   this.migas.casilla = null;
      // }
      // if(this.nivel === 'seccion') {
      //   this.migas.casilla = null;
      // }
    })

  }


  // L I M P I A R   C O M B O S
  limpiarSeccion() {
    if (this.migas.seccion) {
      this.migas.seccion = null;
    }
  }


}
