import { Component, HostListener, OnInit } from '@angular/core';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { ThemeService } from 'src/app/theme/theme.service';
import { GlobalesService } from '../../../servicios/globales.service';
declare var $:any;

@Component({
  selector: 'app-avance-nacional',
  templateUrl: './avance-nacional.component.html',
  styleUrls: ['./avance-nacional.component.scss']
})

export class AvanceNacionalComponent implements OnInit {

  datosAvance: any = [];
  main: any = {};
  selectedContienda: any = '';
  datosContienda: any = {};
  fechaContienda: any = '';
  horaContienda: any = '';
  utc: any = '';


  constructor(
    public readonly datosService: ObtenerDatosService,
    public themeService: ThemeService,
    public globalService: GlobalesService,
  ) { }

  ngOnInit(): void {
    this.globalService.momentjs.locale('es-mx');
    // this.datosService.llenadoAvanceNacional()
    //     .then((data) => {
    //       this.datosAvance = data;
    //     });
    // this.selectedContienda = this.getSelectedContienda();
    // this.main = this.getMainJSON();
    this.datosService.mainSubject.subscribe(data =>{
      if (data != null) {
        this.selectedContienda = this.globalService.getContienda();
        this.datosContienda = data[this.selectedContienda];
        let arrFechaVersion = this.datosContienda.fecha_version.split(' ');
        let strFechaVersion = arrFechaVersion[0] + ' ' + arrFechaVersion[1];
        this.fechaContienda = this.globalService.momentjs(strFechaVersion).format('D MMMM YYYY');
        this.horaContienda = this.globalService.momentjs(strFechaVersion).format('HH:mm');
        this.utc = arrFechaVersion[2].replace('(', '').replace(')', '')
      }
    });
  }

  actualizar() {
    location.reload();
  }

}
