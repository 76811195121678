import { Component, OnInit } from '@angular/core';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from "src/app/servicios/globales.service";

@Component({
  selector: 'app-participacion-ciudadana',
  templateUrl: './participacion-ciudadana.component.html',
  styleUrls: ['./participacion-ciudadana.component.scss']
})
export class ParticipacionCiudadanaComponent implements OnInit {

    contiendaActual: string;
    param: string;
    
    constructor(
        public _obtenerDatosService: ObtenerDatosService,
        public _globalService: GlobalesService
        ) { }

    ngOnInit(): void {
        this.contiendaActual = this._globalService.getContienda();
        this.param = this._globalService.param_distrito;
    }
    
}
