import { RouterModule } from "@angular/router";
import { NgModule } from '@angular/core';
import { NotFoundComponent } from './notfound.component';
import data from '../../../assets/json/main.json';

let auxContiendas = false,
    arrContiendas: any = [
      'gubernatura',
      'diputaciones',
      'ayuntamientos',
      'juntas',
    ],
    url = '';
for (const contienda in data) {
  if (arrContiendas.indexOf(contienda) >= 0) {
    if (auxContiendas == false) {
      auxContiendas = true;

      switch (contienda) {
        case 'gubernatura':
          url = 'gubernatura/votos-candidatura/grafica';
          break;

        case 'diputaciones':
          url = 'diputaciones/votos-distrito/mapa';
          break;

        case 'ayuntamientos':
          url = 'ayuntamientos/votos-municipio/mapa';
          break;

        case 'juntas':
          url = 'juntas/votos-municipio/mapa';
          break;
      
        default:
          break;
      }
    }
  }
  else {}
}

@NgModule({
    imports: [
        RouterModule.forChild([
			// {
			// 	path: 'notfound',
			// 	component: NotFoundComponent,
			// 	pathMatch: 'full'
			// },
            {
                path: '**',
                redirectTo: url
            }
        ])
    ],
    declarations: [
        NotFoundComponent
    ],
    exports: [
        RouterModule
    ]
})
export class WildcardRoutingModule { }