<div id="estadistica" class="anchor"></div>
<div class="container-fluid bgAzul txBlanco p-4 p-lg-5">
    <div class="row">
        <div class="col-12 col-lg-10 p-0 p-lg-5">
            <h2 class="font-medium pb-4">- Estadística -</h2>
            <div class="container">
                <div class="row">
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué datos se muestran en la sección Estadística Entidad?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'En esta sección encontrarás la gráfica del Porcentaje de Actas Esperadas, Capturadas y Contabilizadas, al momento, así como el total de las mismas. El número de Actas contabilizadas por Casillas Urbanas y No Urbanas. El porcentaje de la Participación
                        ciudadana conforme a las Actas Contabilizadas. El gráfico de los votos en Casillas Básicas, Contiguas y Extraordinarias. El gráfico
                        de la Lista Nominal de Actas Contabilizadas en comparación con la Lista Nominal de casillas aprobadas.' | highlightSearch: Search"></p>
                    </div>
                    <!-- <div class="pregunta" *ngIf="!config?.hide_especiales">
                        <h3 [innerHtml]="'¿Qué datos se muestran en la sección Estadística por Circunscripción?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Se mostrarán los datos específicos de la Circunscripción seleccionada: gráfico del porcentaje de Actas Esperadas, Capturadas y Contabilizadas, Porcentaje de Participación ciudadana conforme a las Actas Contabilizadas sin considerar la votación en las
                        Casillas Especiales. El gráfico de los votos en Casillas Básicas, Contiguas y Extraordinarias; votos en Casillas Especiales y el Total. El gráfico de la Lista Nominal de Actas Contabilizadas en comparación con la Lista Nominal
                        oficial.' | highlightSearch: Search"></p>
                    </div> -->
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué datos se muestran en la sección Estadística por Entidad?' | highlightSearch: Search"></h3>
                        <!-- <p [innerHtml]="'Se mostrarán los datos específicos de la Entidad seleccionada: gráfico del porcentaje de Actas Esperadas, Capturadas y Contabilizadas, Porcentaje de Participación ciudadana conforme a las Actas Contabilizadas sin considerar la votación en las Casillas
                        Especiales y el gráfico del Total de votos de la Lista Nominal.' | highlightSearch: Search"></p> -->
                        <p [innerHtml]="'Se mostrarán los datos específicos de la Entidad seleccionada: gráfico del porcentaje de Actas Esperadas, Capturadas y Contabilizadas, Porcentaje de Participación ciudadana conforme a las Actas Contabilizadas y el gráfico del Total de votos de la Lista Nominal.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta" *ngIf="!config?.hide_especiales">
                        <h3 [innerHtml]="'¿Qué datos se muestran en la sección Estadística por Municipio?' | highlightSearch: Search"></h3>
                        <!-- <p [innerHtml]="'Se mostrarán los datos específicos del Municipio seleccionado: gráfico del porcentaje de Actas Esperadas, Capturadas y Contabilizadas, Porcentaje de Participación ciudadana conforme a las Actas Contabilizadas sin considerar la votación en las Casillas
                        Especiales y el gráfico del Total de votos de la Lista Nominal.' | highlightSearch: Search"></p> -->
                        <p [innerHtml]="'Se mostrarán los datos específicos del Municipio seleccionado: gráfico del porcentaje de Actas Esperadas, Capturadas y Contabilizadas, Porcentaje de Participación ciudadana conforme a las Actas Contabilizadas y el gráfico del Total de votos de la Lista Nominal.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué datos se muestran en la sección Estadística por Sección?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Se mostrarán los datos específicos de la Sección seleccionada: gráfico de la Participación ciudadana conforme a las Actas Contabilizadas, indicando el porcentaje de la votación en las casillas de esa sección en contraste con el Total de la Lista Nominal.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué datos se muestran en la sección Estadística por Casilla?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Se mostrarán los datos específicos de la Casilla seleccionada: gráfico de la Participación ciudadana conforme a las Actas Contabilizadas, indicando el porcentaje de la votación en la casilla en contraste con el Total de la Lista Nominal.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué indica el reporte de las Actas Esperadas, Capturadas y Contabilizadas?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Indica el número de Actas de todas las casillas aprobadas por el Consejo Municipal respectivo, por cada por la elección. Total de Actas registradas en el sistema, que están dentro del catálogo de Actas Esperadas. Actas de las casillas aprobadas, capturadas
                        y sumadas.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué resultados se reportan en Actas Contabilizadas por Casillas Urbanas y No Urbanas?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Se refiere a las Actas que se han capturado en las casillas ubicadas en la ciudad y en las zonas rurales.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué indica el porcentaje de Participación Ciudadana conforme a las Actas Contabilizadas?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Indica el porcentaje de personas que han participado en la votación en relación a las Actas que se han contabilizado en una casilla.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué indica la gráfica de Votos en Casillas Básicas, Contiguas y Extraordinarias?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Indica los votos que se han capturado y contabilizado hasta el momento del corte en este tipo de casilla.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cuál es el resultado que se reporta en el gráfico de la Lista nominal?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'El resultado es el número de votantes que han ejercido su voto, en contraste con la Lista Nominal de casillas aprobadas esperada, es decir, todos las personas que están registradas y tienen derecho a votar.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cómo descargo la base de datos?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Al ingresar podrás consultar una guía para realizar la descarga e interpretación de la Base de datos.' | highlightSearch: Search"></p>
                    </div>
                    <!-- <div class="pregunta">
                        <h3 [innerHtml]="'¿En Participación ciudadana conforme a las Actas Contabilizadas, cuándo sí se muestra la gráfica de votos en las casillas?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Se muestra esta gráfica cuando te encuentras en los niveles: Nacional y Circunscripción. En Entidad, Sección y Casilla no se visualiza.' | highlightSearch: Search"></p>
                    </div> -->
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué implican las Actas con inconsistencias?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Implican una revisión detallada para determinar cuál es la inconsistencia que presentan, con base en esto determinar si son contabilizadas, no contabilizadas o fuera de catálogo.' | highlightSearch: Search"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>