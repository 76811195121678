import { Component, OnInit } from '@angular/core';
import { ComparativaService } from 'src/app/servicios/comparativa/comparativa.service';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
// declare var $;
import { GlobalesService } from 'src/app/servicios/globales.service'
import { ThemeService } from '../../../../../theme/theme.service';

@Component({
  selector: 'app-resumen-partido',
  templateUrl: './resumen-partido.component.html',
  styleUrls: ['./resumen-partido.component.scss']
})
export class ResumenPartidoComponent implements OnInit {
    grafica_table: any = [];  
    total_votos: string = '';
    pathImg = './assets/img/logos-partidos/';
    estadistica_extranjero: any = [];
    contienda_actual: any = '';
    resumen: any = {};
    fichas: any = [];

    /* contenido: any = [];
    votosActas: any = [];
    resumenVotacion: any = []; */

    constructor(
        public  _obtenerDatosService: ObtenerDatosService,
        public _globalService: GlobalesService,
        public comparativaService: ComparativaService,
        public themeService: ThemeService,
    ) { }

    ngOnInit(): void {
      /* this.obtenerDatos(); */
      // $('[data-toggle="tooltip"]').tooltip();
        this.comparativaService.stageStatus = false;
        this.comparativaService.pantalla = 'resumenPPyCI';
        this.comparativaService.resetear();

        this._globalService.bottomSheetContex = this;
        this._globalService.bottomSheetCallback = this.agregarElemento;    
    
        this._obtenerDatosService.mainSubject.subscribe(data =>{
        if(data == null) return;

        this.loadData();
      });
    }


    loadData() {
        let selectedContienda = this._globalService.getContienda();
        this.contienda_actual = selectedContienda;
        
        // if (this._obtenerDatosService.contiendas[selectedContienda] && this._obtenerDatosService.contiendas[selectedContienda]['detalle.distritos.json']){
        //   this.data = this._obtenerDatosService.contiendas[selectedContienda]['detalle.distritos.json'].diputaciones_table.rows;
        // }else {
        this._obtenerDatosService.loadJSON(selectedContienda, 'entidad.partido.json').then(data => {
            this._globalService.bottomSheetTpl = 'comparador-contienda';
            this.grafica_table = data['grafica'].columns;
            this.fichas = data['fichas'];
            this.resumen = data['resumen'];
            this.total_votos = '1000';
            this.estadistica_extranjero = data['extranjero'];
            
            this.comparativaService.votos_total = this.resumen['total'].total;
            this.comparativaService.votos_pctj = this.resumen['total'].prc;
//            this.comparativaService.votos_pctj = (this.resumen['total'].prc.toString().includes('%'))? this.resumen['total'].prc : this.resumen['total'].prc+'%' ;
        });
      // }
    }

    agregarElemento(el: any) {
      //Fix del contexto de la variable 
      let scope = (this instanceof GlobalesService)? this["bottomSheetContex"] : this;
//      console.log('arreglo elementos', scope.comparativaService.arregloElementos);
      if (scope.comparativaService.arregloElementos.length < 3) {
          scope.comparativaService.agregarElemento(el);
          el.seleccionado = true;
      }
      if(this instanceof GlobalesService && scope.comparativaService.arregloElementos.length >= 3){
          this.toggleBottomSheet(false);
      }
      if (!scope.comparativaService.stageStatus) {
          scope.comparativaService.stageStatus = true;
          scope.comparativaService.guardarStage(scope.fichas);
      }
  }

    eliminarElemento(el: any) {
    this.comparativaService.eliminarElemento(el);
    el.seleccionado = false;
    }

    selectedItemFun = (el: any): void => {
      this.agregarElemento(el);
    }
  
    diselectedItemFun = (el: any): void => {
      this.eliminarElemento(el);
    }

    /* obtenerDatos() {
    this.datosService.obtenerNacional()
        .subscribe((data: any) => {
            this.contenido = data.circunscripciones.votosDistrito;
            this.resumenVotacion = data.circunscripciones.resumenVotacion;
        });
    } */

}
