<div id="visualizacion-tarjetas" class="anchor"></div>
<div class="container-fluid bgAzul txBlanco p-4 p-lg-5">
    <div class="row">
        <div class="col-12 col-lg-10 p-0 p-lg-5">
            <h2 class="font-medium pb-4">- Visualización tarjetas -</h2>
            <div class="container">
                <div class="row">
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cuáles son los resultados que muestra el mapa electoral?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Te muestra el total de votos y porcentaje por Partido Político que aventaja hasta el momento en cada división geográfica. Por ejemplo, en Entidad te permite ver quién aventaja en cada una y dentro de ésta, quién
                        va adelante en cada Municipio.' | highlightSearch: Search">
                        </p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cómo puedo navegar en el mapa electoral?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Desde escritorio puedes dar clic sobre el Municipio que quieras revisar y en móvil puedes presionar sobre éste.' | highlightSearch: Search">
                        </p>
                    </div>
                    <!-- <div class="pregunta">
                        <h3 [innerHtml]="'¿Cómo puedo ver el detalle por Circunscripción?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'En el mapa electoral se encuentran las Circunscripciones, Entidades y Municipios Federales. Da clic sobre la Circunscripción que desees y se mostrará el Partido Político que aventaja, los votos, el número de Circunscripción, la Entidad y el Municipio.' | highlightSearch: Search">
                        </p>
                    </div> -->
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cómo puedo ver el detalle por Entidad?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Al seleccionar la Entidad, se mostrará el mapa correspondiente a ésta y se mostrará el resumen de la votación.' | highlightSearch: Search">
                        </p>
                    </div>
                    <!-- <div class="pregunta">
                        <h3 [innerHtml]="'¿Cuáles son los resultados que muestra el gráfico de la Cámara?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Se muestra el porcentaje de votos por Partido Político, '+( _obtenerDatosService.config?.estado == 'Yucatán' ? 'Candidatura Común' : 'Coalición' )+' y Candidatura Independiente.' | highlightSearch: Search">
                        </p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>