<div id="votos-actas-contabilizadas" class="anchor"></div>
<div class="container-fluid bgWhite p-4 p-lg-5" >
    <div class="row">
        <div class="col-12 col-lg-10 p-0 p-lg-5">
            <h2 class="font-medium pb-4">- Votos en Actas Contabilizadas -</h2>
            <div class="container">
                <div class="row">
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué muestra el Total de votos?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Muestra el número de votos que hasta el momento se han contabilizado de acuerdo al reporte y detalle solicitados.' | highlightSearch: Search">
                        </p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cómo puedo ver el detalle de votación de los Partidos Políticos?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'A través de los reportes Votos por Municipios y Votos por Partido Político, se muestra el resumen de votación para cada uno. Puedes detallar tu búsqueda al seleccionar Entidad, Sección y Casilla.' | highlightSearch: Search">
                        </p>
                    </div>
                    <!-- <div class="pregunta" *ngIf="!_obtenerDatosService?.config?.hide_independientes">
                        <h3 [innerHtml]="'¿Cómo consulto el detalle de votación por Candidatura Independiente?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'A través del reporte Votos por Municipios, selecciona el Municipio que quieres visualizar. Se muestra la sección Resumen de la votación indicando el Candidato, votos y porcentaje. Asimismo, en el reporte Votos por Partido Político
                        y Candidatura Independiente, selecciona el Municipio que quieres visualizar. Se mostrarán los votos de las Candidatura Independientes.' | highlightSearch: Search">
                        </p>
                    </div> -->
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cómo consulto el detalle de votación por '+( _obtenerDatosService.config?.estado == 'Oaxaca' ? 'Candidatura Común' : 'Coalición' )+'?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'A través del reporte Votos por Municipios, se visualiza el resumen del total de votos y porcentaje obtenidos hasta el momento.' | highlightSearch: Search">
                        </p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cómo consulto el Detalle de votos por Casilla?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'A través de los reportes Votos por Municipios y Votos por Partido Político, selecciona Entidad, Municipio, Sección y Casilla, se mostrará el resumen de la votación.' | highlightSearch: Search">
                        </p>
                    </div>
                    <!-- <div class="pregunta">
                        <h3 [innerHtml]="'¿Cómo consulto el Detalle de votos por Casillas Especiales?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'A través de los reportes Votos por Municipios y Votos por Partido Político, selecciona Circunscripción, Entidad, Municipio, Sección y el tipo de Casilla, se mostrará el resumen de la votación.' | highlightSearch: Search">
                        </p>
                    </div> -->
                    <div class="pregunta" *ngIf="!config?.hide_especiales">
                        <h3 [innerHtml]="'¿Cómo consulto la Distribución de votos por Candidatura a nivel Municipio?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'A través del reporte Votos por Municipios, selecciona la Entidad y el Municipio que quieres visualizar. Se mostrará la sección resumen de la votación y en seguida la sección Distribución de votos por candidatura a nivel Municipio. Aquí podrás
                        observar el nombre del candidato y el total de votos que lleva cada uno.' | highlightSearch: Search">
                        </p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cómo puedo agregar más opciones para conocer el detalle de votación?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'En versión escritorio, da clic en el botón Agregar, ubicado junto al resultado de cada actor político y visualiza el detalle. En versión móvil, selecciona el actor político, visualiza el detalle y desde ahí presiona el botón Agregar otro actor. En ambos
                        casos, puedes eliminar o sumar hasta 3 opciones.' | highlightSearch: Search">
                        </p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Dónde se indican los votos nulos?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Se indican en todos los resultados de búsqueda de las opciones para visualizar el detalle: Entidad, Municipio, Sección y Casilla.' | highlightSearch: Search">
                        </p>
                    </div>
                    <!-- <div class="pregunta">
                        <h3 [innerHtml]="'¿Cómo consulto las Actas RP?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Ingresa al reporte Votos por Partido Político y Candidatura Independiente, luego selecciona la Circunscripción y Entidad, se mostrará la sección Representación Proporcional, da clic en Ver Actas RP y podrás ver el resumen de la votación.' | highlightSearch: Search">
                        </p>
                    </div> -->
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué indica el estatus Actas en proceso?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Indica que el Acta aún no se ha digitalizado hasta el momento.' | highlightSearch: Search">
                        </p>
                    </div>
                    <!-- <div class="pregunta">
                        <h3 [innerHtml]="'¿Cómo puedo saber la procedencia de la imagen del Acta y qué indica su origen?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Desde el resumen de votación por Casilla, se indicará por cuál medio se digitalizó el Acta: escáner, dispositivo móvil o urna electrónica.' | highlightSearch: Search">
                        </p>
                    </div> -->
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cuáles son las dos vistas en que puedo visualizar el resumen de votación?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Desde la versión móvil, puedes visualizar el reporte en tabla o gráfica, dando clic en los íconos que están al inicio de la sección Resumen de la votación.' | highlightSearch: Search">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>