import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';

// declare var $;
// import * as $ from 'jquery';

@Component({
  selector: 'app-distrito-entidad-detalle-casilla',
  templateUrl: './distrito-entidad-detalle-casilla.component.html',
  styleUrls: ['./distrito-entidad-detalle-casilla.component.scss']
})
export class DistritoEntidadDetalleCasillaComponent implements OnInit {
    casilla_table: any = [];
    fichas: any = [];
    lista_nominal: string;
    contienda_actual: string;
    base: string;
    resumen: any = {};
    showCATD = false


  constructor(
    public _globalService: GlobalesService,
    private readonly activatedRouter: ActivatedRoute,
    public readonly rutas: RutasService,
    public _obtenerDatosService: ObtenerDatosService
  ) { }

  ngOnInit(): void {
      this.obtenerParametros();
      this.base = this.rutas.rutaActual.base;
      this.rutas.limpiarSeccion();
      this._obtenerDatosService.mainSubject.subscribe(data =>{
        if(data == null) return; 
        this.loadData();
        this.showCATD = data?.show_catd ? data?.show_catd : false
      });
  }
  
  scrollComponent(el: HTMLElement) {
      el.scrollIntoView();
  }

  loadData() {
      let selectedContienda = this._globalService.getContienda();
      this.contienda_actual = selectedContienda;
    // if (this._obtenerDatosService.contiendas[selectedContienda] && this._obtenerDatosService.contiendas[selectedContienda]['detalle.distritos.json']){
    //   this.data = this._obtenerDatosService.contiendas[selectedContienda]['detalle.distritos.json'].diputaciones_table.rows;
    // }else {
    this._obtenerDatosService.loadJSON(selectedContienda, 'detalle.' + this._globalService.param_distrito + '.casillas.json').then(data => {
      // this.casilla_table = data['casilla_table'].headers;
        this.fichas = data['fichas'];
        this._obtenerDatosService.desglose = data['casilla_table'];
        this.resumen = data['resumen'];
        this._obtenerDatosService.participacion_table = data['participacion_table'].rows;
        this.lista_nominal = data['participacion_table'].lista_nominal;
//        console.log(this.rutas);
        
        let listadoCasillas = [];
        data['casilla_table'].rows.forEach(casilla => {
            listadoCasillas.push({
                "id": casilla.casilla.text,
                "claveURL": casilla.casilla.seccion,
                "migas": casilla.casilla.text,
            });
        });
        
        this._obtenerDatosService.comboCasillas = listadoCasillas;
    });
    // }
  }

  obtenerParametros() {
    this.activatedRouter.params.subscribe((parametros) => {
      const params = { parametros };
      this._globalService.detalles = params.parametros;
    });
    this._globalService.setearParametros();
  }

  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

}
