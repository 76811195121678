import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ObtenerDatosService } from '../servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from '../servicios/globales.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router,
		public globalesService: GlobalesService,
		public _obtenerDatosService: ObtenerDatosService,
		// private authenticationService: AuthenticationService
	) {
		// this._obtenerDatosService.configSubject.subscribe(config =>{
		// 	if (config != null) {
		// 		if (config?.deshabilitar_btn_home === true)
		// 	}
		// });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let enableContienda = localStorage.getItem('enable-contienda');
		this._obtenerDatosService.mainSubject.subscribe(config =>{
			if (config != null) {
				if (config?.deshabilitar_btn_home === true) {
					localStorage.setItem('enable-contienda', null);
					this.router.navigate(['/inicio']);
        			return false;
				}
			}
		});

		this._obtenerDatosService.configSubject.subscribe(config =>{
			if (config != null) {
				if(config?.omitir_extranjero === true && route.url.join('') == 'voto-extranjero'){
					this.router.navigate(['/inicio']);
        			return false;
				}
			}
		});

        if (enableContienda === 'true') return true;

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/inicio'], /*{ queryParams: { returnUrl: state.url } }*/);
        return false;
    }
}