import { Component, OnInit, Inject } from '@angular/core';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { DOCUMENT } from '@angular/common';

import * as $ from 'jquery';

@Component({
  selector: 'app-pregunta-buscador',
  templateUrl: './pregunta-buscador.component.html',
  styleUrls: ['./pregunta-buscador.component.scss']
})
export class PreguntaBuscadorComponent implements OnInit {

  termino = 'Ej: casilla';
  Search: string = '';
  tmpConincidencias: any = []
  currentTxtSearch = ''
  currentIndex = -1;
  window: any

  constructor(private globalService: GlobalesService, @Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
   }

  ngOnInit(): void {
  }

  onSearch(){
    // console.log('SEARCH TEXT:\n', this.Search);

    // if(this.currentTxtSearch == this.Search && this.tmpConincidencias.length > 0){
    //   this.currentIndex++;
    //   if(this.currentIndex == this.tmpConincidencias.length){this.currentIndex = 0}
    //   //this.tmpConincidencias[this.currentIndex].scrollIntoView();  
    //   var elementPosition = this.tmpConincidencias[0].getBoundingClientRect().top;
    //   var offsetPosition = elementPosition - 180;
    //   console.log("next offset: ", offsetPosition);
    //   this.window.scrollTo({
    //        top: offsetPosition,
    //        behavior: "smooth"
    //   });
    //   return
    // }


    this.globalService.searchItem.next(this.Search)
    const query = "#preguntas-container > *:contains('"+this.Search+"')"
    this.tmpConincidencias = $(query)
    if(this.tmpConincidencias && this.tmpConincidencias.length > 0){
      this.currentTxtSearch = `${this.Search}`
      var elementPosition = this.tmpConincidencias[0].getBoundingClientRect().top;
      var offsetPosition = elementPosition - 180;

      // console.log("first offset: ", offsetPosition);
  
      // this.window.scrollTo({
      //      top: offsetPosition,
      //      behavior: "smooth"
      // });
      //this.window.scrollBy(0, offsetPosition)
      this.tmpConincidencias[0].scrollIntoView();
      // this.tmpConincidencias[0].animate({ scrollTop: offsetPosition }, 200);
      // this.tmpConincidencias[0].scrollTop(offsetPosition);
      this.currentIndex = 0;
    }

    // console.log('QUERY:\n', query)
    // console.log('SEARCH ITEM:\n',this.tmpConincidencias );
  }
}
