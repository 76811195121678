<div class="dis-flex flex-col" [ngClass]="{'movil': isOpen == 1, 'boton-compartir': !isSimulacro, 'boton-compartir-no-action': isSimulacro}">
    <div class="fondo dis-flex flex-col pt-1">
        <div class="redes">
            <div class="boton whatsapp dis-flex">
<!--                <a class="fas fa-phone-alt" aria-hidden="true" href="https://api.whatsapp.com/send?text={{this._url}}" target="_blank" data-action="share/whatsapp/share"></a>-->
                <a aria-hidden="true" href="https://api.whatsapp.com/send?text={{this._url}}" target="_blank" class="link-social" data-action="share/whatsapp/share">&nbsp;</a>                
            </div>
            <div class="boton facebook dis-flex">
                <a href="https://www.facebook.com/sharer/sharer.php?u={{this._url}}&quote={{this.title}}" class="link-social" title="Compartir en Facebook" target="_blank">&nbsp;</a>
            </div>
            <div class="boton twitter dis-flex">
                <a href="https://twitter.com/intent/tweet?source={{this._url}}&text={{this.title}}:%20{{this._url}}" class="link-social" target="_blank" title="Tweet">&nbsp;</a>
            </div>
        </div>
        <div class="principal dis-flex" (click)="openShare()">
            <i class="fas fa-share-alt" aria-hidden="true"></i>
        </div>
    </div>
    <p class="text-center" style="font-size: 1.2em;">
        Compartir
    </p>
</div>
<!-- 
<div class="boton whatssapp dis-flex">
    <a aria-hidden="true" href="https://api.whatsapp.com/send?text={{this._url}}" target="_blank" data-action="share/whatsapp/share"></a>
</div> -->