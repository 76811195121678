import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

// Componentes
import { ResumenVotacionComponent } from './componentes/plantilla/resumen-votacion/resumen-votacion.component';
import { ResumenVotacionMobileComponent } from './componentes/plantilla/resumen-votacion-mobile/resumen-votacion-mobile.component';
import { EstadisticaNacionalComponent } from './componentes/plantilla/estadistica-nacional/estadistica-nacional.component';
import { ListadoContentComponent } from './componentes/movil-listado/listado-content.component';
import { EstadisticaMovilComponent } from './componentes/estadistica-movil/estadistica-movil.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartModule } from 'angular-highcharts';
import { EncabezadoComponent } from './componentes/plantilla/encabezado/encabezado.component';

import { DistribucionVotosComponent } from './contiendas/diputacion/votos-distrito/componentes/compartidos/distribucion-votos/distribucion-votos.component';
import { DistribucionVotos2Component } from './contiendas/diputacion/votos-partido/componentes/compartidos/distribucion-votos2/distribucion-votos2.component';
import { DistritoDistritoComponent } from './contiendas/diputacion/votos-distrito/componentes/distrito-distrito/distrito-distrito.component';

import { FormatmilesPipe } from './pipes/formatmiles.pipe';
import { StringtointPipe } from './pipes/stringtoint.pipe';
import { RoundPipe } from './pipes/round.pipe';
// import { TituloPipePartido } from './pipes/titulo-partido/titulo.pipe';
// import { TituloPipePartido } from './pipes/titulo-partido/titulo.pipe';
import { TituloPipeDistrito } from './pipes/titulo-distrito/titulo.pipe';
import { TituloPipe } from './pipes/titulo/titulo.pipe';
import { BreadcrumbComponent } from './componentes/plantilla/breadcrumb/breadcrumb.component';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { TooltipModule } from 'ng2-tooltip-directive';
import { BottomSheetComponent } from './componentes/plantilla/bottom-sheet/bottom-sheet.component';
import { MenuResponsiveComponent } from './componentes/plantilla/menu-responsive/menu-responsive.component';

@NgModule({
  declarations: [
    DistribucionVotosComponent,
    DistribucionVotos2Component,
    DistritoDistritoComponent,
    ResumenVotacionComponent,
    ResumenVotacionMobileComponent,
    EstadisticaNacionalComponent,
    FormatmilesPipe,
    StringtointPipe,
    RoundPipe,
    // TituloPipePartido,
    TituloPipe,
    TituloPipeDistrito,

    BreadcrumbComponent,
    BottomSheetComponent,
    ListadoContentComponent,
    MenuResponsiveComponent,
    EstadisticaMovilComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    HighchartsChartModule,
    ChartModule,
    CommonModule,
    RouterModule,
    FormsModule,
    // NgxPageScrollModule
    NgxPageScrollCoreModule,
    TooltipModule
  ],
  providers: [],
  exports: [
    RoundPipe,
    TituloPipe,
    FormatmilesPipe,
    StringtointPipe,
    TituloPipeDistrito,
    DistribucionVotosComponent,
    DistribucionVotos2Component,
    DistritoDistritoComponent,
    ResumenVotacionComponent,
    ResumenVotacionMobileComponent,
    EstadisticaNacionalComponent,
    BreadcrumbComponent,
    TooltipModule,
    BottomSheetComponent,
    ListadoContentComponent,
    MenuResponsiveComponent,
    EstadisticaMovilComponent
  ],
  bootstrap: []
})
export class ImportingModule { }
