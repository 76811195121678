import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
// google-analytics.service.ts
declare let gtag: Function;
declare let filter: Function;
declare let tap: Function;

@Injectable({
	providedIn: 'root'
})

export class GoogleAnalyticsService {
  private googleAnalyticsId: string;
  private renderer2: Renderer2;
  private scriptsLoaded: boolean = false;

  constructor(
    private rendererFactory2: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document,
    private _config: RuntimeConfigLoaderService,
    private _router: Router,
  ) {
    this.renderer2 = this.rendererFactory2.createRenderer(null, null);
    this.googleAnalyticsId = this._config.getConfigObjectKey('analytics_id');
  }

  init() {
    if (!this.scriptsLoaded) {
      this.insertMainScript();
    }
  }

  private insertMainScript() {
    if (this.googleAnalyticsId) {
      const script: HTMLScriptElement = this.renderer2.createElement('script');
      script.type = 'text/javascript';
      script.onload = this.insertSecondHalfOfScript.bind(this);
      script.src = `https://www.googletagmanager.com/gtag/js?id=${this.googleAnalyticsId}`;
      script.text = '';
      this.renderer2.appendChild(this._document.body, script);
    }
  }

  private insertSecondHalfOfScript() {
    const script: HTMLScriptElement = this.renderer2.createElement('script');
    script.type = 'text/javascript';
    script.src = '/google-analytics-script.js';

	// http://localhost:4200/
	
    script.text = '';
    this.renderer2.appendChild(this._document.body, script);
	// console.log('despues de append');
    script.onload = () => {
      this.scriptsLoaded = true;
    };
  }

  trackSinglePageView(event: NavigationEnd) {
	if (this.googleAnalyticsId && this.scriptsLoaded) {
	  gtag('config', this.googleAnalyticsId, { page_path: event.urlAfterRedirects });
	}
  }
  
  // No se utiliza
  trackPageViews() {
	return this._router.events.pipe(
	  filter(() => this.scriptsLoaded === true),
	  filter((evt: RouterEvent) => evt instanceof NavigationEnd),
	  tap((event: NavigationEnd) => {
		this.trackSinglePageView(event);
	  }),
	);
  }
}