<header class="total-encabezado sombra w-100">
    <div>
        <div class="container-fluid bgMorado txBlanco d-none d-xl-block">
            <div class="row">
                <div class="col-md-3">
                    <div class="h-opl dis-flex justify-s m-0 pt-2 pb-2">
                        <p class="cursorPointer">
                            <img class="logo-opl" src="assets/img/ople/logo_ople_blanco.png" alt="Logo OPLE">
                            <a href="{{ objConfig?.header?.ople_link }}">
                                {{ objConfig?.header?.ople_link }}
                            </a>
                        </p>
                    </div>
                </div>
                <div class="col-md-9">
                    <ul class="dis-flex justify-e m-0 p-1">
                        <li *ngIf="datosService?.config?.estado != 'Veracruz'" class="pl-3 pr-3 cursorPointer" [routerLink]="['/base-de-datos']" routerLinkActive="router-link-active">
                            <i class="fas fa-database" aria-hidden="true"></i> Base de datos</li>
                        <li class="pl-3 pr-3 cursorPointer borde-izq-solido" routerLink="/preguntas-frecuentes">
                            <i class="fas fa-question-circle" aria-hidden="true"></i> Preguntas frecuentes</li>
                        <li class="pl-3 pr-3 borde-izq-solido">
                            <a href="{{ objConfig?.header?.ayuda_link }}" target="_blank"><i class="fas fa-info-circle" aria-hidden="true"></i> Centro de Ayuda</a>
                        </li>
                        <li class="pl-3 pr-3 tamanio-fuente borde-izq-solido dis-flex">
                            <span class="minimo">A</span>

                            <input type="range" class="input-rango" min="6" max="14" [ngModel]="globalService?.fontSize" (ngModelChange)="changeFontSize($event)">
                            <span class="maximo"><strong>A</strong></span>
                            <!-- {{globalService?.fontSize}} -->
                        </li>
                        <li class="pl-3 pr-3 modo-dark borde-izq-solido dis-flex">
                            <span class="claro">Claro</span>
                            <label for="modo_dia_y_noche" class="switch margin-r-40 margin-b-0">
                                <input [checked]="themeService.isDarkTheme()" (click)="toggleTheme()" type="checkbox" id="modo_dia_y_noche" value="Modo día o noche" aria-label="Modo día o noche">
                                <span class="slider round">
                                </span>
                            </label>
                            <span class="obscuro">Oscuro</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="container-fluid bgGrisTenue encabezado py-2" [ngClass]="{'brdr-dark': themeService.isDarkTheme()}">
            <div class="row">
                <div class="col-2 col-md-2 dis-flex d-none d-xl-flex">
                    <a href="{{ objConfig?.header?.ople_link }}" target="_blank">
                        <img class="max-logo img-fluid" src="assets/img/generales/logo.png" alt="Logo OPLE" *ngIf="!themeService.isDarkTheme()">
                        <img class="max-logo img-fluid" src="assets/img/ople/logo_ople_blanco.png" alt="Logo OPLE" *ngIf="themeService.isDarkTheme()">
                    </a>
                </div>
                <div class="col-2 col-md-2 pl-2 pr-0 d-block d-xl-none">
                    <div class="dis-flex w-100 h-100">
                        <img (click)="irInicio()" class="img-ine-mini" src="assets/img/generales/LogoPREP.png" alt="Logo PREP" *ngIf="!themeService.isDarkTheme()">
                        <img class="img-ine-mini" src="assets/img/ople/logo_ople_prep_blanco.png" alt="Logo PREP" *ngIf="themeService.isDarkTheme()">
                    </div>
                </div>
                <div class="col-8 col-md-8 dis-flex flex-col">
                    <h1 class="d-none d-xl-block" [innerHtml]="objConfig?.header?.subtitle"></h1>
                    <p class="subtitulo" [innerHtml]="objConfig?.header?.title"></p>
                </div>
                <div class="col-2 col-md-2 dis-flex d-none d-xl-flex">
                    <img class="max-logo2 img-fluid" style="max-height: 60px;" src="assets/img/generales/LogoPREP.png" alt="Logo PREP" *ngIf="!themeService.isDarkTheme()">
                    <img class="max-logo2 img-fluid" style="max-height: 60px;" src="assets/img/ople/logo_ople_prep_blanco.png" alt="Logo PREP" *ngIf="themeService.isDarkTheme()">
                </div>
                <div (click)="toggleMenu(true)" class="col-2 col-md-2 dis-flex d-block d-xl-none">
                    <img class="logo-menu" src="assets/img/generales/ic-menu.png" alt="Menú" *ngIf="!themeService.isDarkTheme()">
                    <img class="logo-menu" src="assets/img/generales/ic-menu-blanco.png" alt="Menú" *ngIf="themeService.isDarkTheme()">
                </div>
            </div>
        </div>
        <div class="container-fluid menu d-none d-xl-block bg-diputaciones">
            <div class="row">
                <div
                    (click)="irInicio()"
                    class="w-5 dis-flex flex-col cursorPointer boton-home">
                    <i class="ine ine-home px24" aria-hidden="true"></i>
                    <p class="px14">Inicio</p>
                </div>
                <div class="w-95">
                    <div class="container-fluid">
                        <div class="row">
                            <div *ngFor="let contienda of globalService.arrContiendasActivas" class="col-md-2 dis-flex contiendaOpt py-3" [ngClass]="{'bgRosaIne' : contienda.active}" (click)="changeContiendaActiva(contienda.contienda)">
                                <!--ngClass="{'bgRosaIne' : contienda.active}"-->
                                <p class="tituloHeader">
                                    <strong>{{contienda.contienda | titlecase}}</strong>
                                    <strong></strong>
                                </p>
                            </div>
                            <div *ngIf="datosService?.config?.estado == 'Veracruz'" class="col-md-{{ 12 - (globalService?.contiendas_total*2) }} dis-flex">
                                <ul class="w-100 dis-flex space-b justify-e m-0">
                                    <li class="pr-4 base-datos cursorPointer font-medium dis-flex" [routerLink]="['/base-de-datos']" routerLinkActive="router-link-active">
                                        <i class="ine ine-base-datos mr-2 em24" aria-hidden="true"></i>
                                        <p>Base de Datos</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div *ngIf="!this.globalService.hideAvance" class="d-none d-xl-inline" [ngClass]="{'sticky': globalService.isSticky, 'hidebar': !globalService.isSticky, 'brdr-dark': themeService.isDarkTheme()}"> class="d-none d-xl-block hidebar" -->
        <div *ngIf="false" class="d-none d-xl-inline" [ngClass]="{'sticky': globalService.isSticky, 'hidebar': !globalService.isSticky, 'brdr-dark': themeService.isDarkTheme()}"> <!-- class="d-none d-xl-block hidebar" -->
            <div class="row size-avance padding-t-10 padding-b-10">
                <div class="col-2">
                    <div class="center-vertical">
                        <span class="align-middle">
                            <strong class="txRosaIne" style="font-size: 1.4em !important;"> Avance Entidad </strong>
                        </span>
                    </div>
                </div>
                <div class="col-3">
                    <div class="card cards-avance p-1">
                        <div class="titulo">
                            <div class="row">
                                <div class="col-6">
                                    <strong>
                                        Actas capturadas
                                    </strong>
                                </div>
                                <div class="col-6 text-right prc-actas">
                                    ({{ datosContienda?.porcentaje_actas }})
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6 total-actas">
                                    <strong>
                                        {{ datosContienda?.monto_actas }}
                                    </strong> de {{ datosContienda?.total_actas }}
                                </div>
                                <div class="col-6">
                                    <div class="center-vertical">
                                        <div class="borde-barra w-100">
                                            <div class="barra-avance" [style.width]="datosContienda?.porcentaje_actas">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           <!--  <p>
                                <strong>
                                    Actas capturadas
                                </strong>
                            </p> -->
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="card cards-avance p-1">
                        <div class="titulo">
                            <p class="prueba text-center">
                                <strong>
                                    <i class="fas fa-question-circle cursor-hand" data-toggle="modal" data-target="#modalParticipacion"></i> Participación ciudadana
                                </strong>
                            </p>
                        </div>
                        <div class="contenido dis-flex flex-col">
                            <p class="participacion">
                                <strong>
                                    {{ datosContienda?.participacion }}
                                </strong>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="card cards-avance p-1">
                        <div class="titulo">
                            <p class="prueba">
                                <strong>
                                    Último corte
                                </strong>
                            </p>
                        </div>

                        <div class="contenido dis-flex flex-col ">
                            <div class="dis-flex w-100">
                                <p class="fecha mr-1">
                                    {{ fechaContienda | lowercase }}
                                </p>
                                <div class="dis-flex flex-col borde-tiempo">
                                    <p class="tiempo-centro">
                                        Hora local
                                    </p>
                                    <p class="utc">
                                        {{ horaContienda }} h {{ utc }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-1">
                    <div class="center-vertical">
                        <div (click)="actualizar()"class="actualizar dis-flex flex-col bgRosaIne txBlanco px-4 py-1 px14 cursorPointer">
                            <i class="fas fa-redo" aria-hidden="true"></i> Actualizar
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
