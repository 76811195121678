import { Component, OnInit } from '@angular/core';
// import { ActivatedRoute } from '@angular/router';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
// import { RutasService } from 'src/app/servicios/rutas/rutas.service';
import { ComparativaService } from 'src/app/servicios/comparativa/comparativa.service';

// declare var $;
// import * as $ from 'jquery';
import { ThemeService } from '../../../theme/theme.service';

@Component({
  selector: 'app-votos-ppyci-grafica',
  templateUrl: './votos-ppyci-grafica.component.html',
  styleUrls: ['./votos-ppyci-grafica.component.scss']
})
export class VotosPpyciGraficaComponent implements OnInit {
  grafica_table: any = [];
  fichas: any = [];
  total_votos: string = '';

  base: string;
  seccion: string;
  resumen: any = {};
  omitirExtranjero = false;

  constructor(
    public _globalService: GlobalesService,
  //   private readonly activatedRouter: ActivatedRoute,
  //   public readonly rutas: RutasService,
    public comparativaService: ComparativaService,
    public _obtenerDatosService: ObtenerDatosService,
    public themeService: ThemeService,
  ) { }

  ngOnInit(): void {
  //   this.obtenerParametros();
  //   this.base = this.rutas.rutaActual.base;
  //   this.rutas.rutaActual.base = 'casilla';
  //   // $('[data-toggle="tooltip"]').tooltip();
    this.comparativaService.stageStatus = false;
    this.comparativaService.pantalla = 'resumenPPyCI';
    this.comparativaService.resetear();
    this._obtenerDatosService.mainSubject.subscribe(data =>{
      if(data == null) return;

      this.loadData();
    });
    this._obtenerDatosService.configSubject.subscribe(data =>{
      if (data != null) {
        const omitir = data.omitir_extranjero;
        this.omitirExtranjero = (!omitir || (omitir && (omitir == 'false' || omitir == false) )) ? false : true;
      }
    });
  }

  loadData() {
    let selectedContienda = this._globalService.getContienda();
    // if (this._obtenerDatosService.contiendas[selectedContienda] && this._obtenerDatosService.contiendas[selectedContienda]['detalle.distritos.json']){
    //   this.data = this._obtenerDatosService.contiendas[selectedContienda]['detalle.distritos.json'].diputaciones_table.rows;
    // }else {
      this._obtenerDatosService.loadJSON(selectedContienda, 'entidad.partido.json').then(data => {
        this._globalService.bottomSheetTpl = 'comparador-contienda';
        this.grafica_table = this._globalService.deepCopy(data['grafica'].columns);
        this.fichas = this._globalService.deepCopy(data['fichas']);
        this.resumen = data['resumen'];
        // this.total_votos = '0';
        let tmpTotvotos = 0;
        // this.grafica_table.forEach((item, index) => {
        //   let tmp = +(item.data.r1.replace(/,/g, ''));
        //   if(tmp > tmpTotvotos && index < this.grafica_table.length - 1){
        //     tmpTotvotos = tmp;
        //   }
        // });
        // tmpTotvotos = this._globalService.roundUpTen(tmpTotvotos);
        // this.grafica_table.forEach(item => {
        //   let tmp = +item.data.r1.replace(/,/g, '');
        //   item.data.porcentaje_votos = ((tmp * 100)/tmpTotvotos)+'%';
        // });
        this.fichas.forEach(item => {
          item.pctj = item.porcentaje.replace(/%/g, '');
          let tmp = +item.total.replace(/,/g, '');
          if(tmpTotvotos < tmp){tmpTotvotos = tmp}
        });
        tmpTotvotos = this._globalService.roundUpTen(tmpTotvotos);

        this.fichas.forEach(item => {
          let tmp = +item.total.replace(/,/g, '');
          item.porcentaje_votos = ((tmp * 100)/tmpTotvotos)+'%';
        });
        // console.log(this.fichas);
        this.total_votos = ''+tmpTotvotos;
        // this.resumen_table = data['resumen_table'].columns;
        // this.contiendas_total = data['mapa'].distritos.length;

        // this.grafica_table.forEach(item => {
        //   item.pctj = item.porcentaje.replace(/%/g, '');
        // });
      });
    // }
  }
  // obtenerParametros() {
  //   this.activatedRouter.params.subscribe((parametros) => {
  //     const params = { parametros };
  //     this._globalService.detalles = params.parametros;
  //   });
  //   this._globalService.setearParametros();
  //   this.seccion = this.rutas.migas?.seccion.split(' ')[1];
  // }



  // @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;

  // public scrollRight(): void {
  //   this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  // }

  // public scrollLeft(): void {
  //   this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  // }

  selectedItemFun = (el: any): void => {
    let scope = (this instanceof GlobalesService)? this["bottomSheetContex"] : this;
      if (scope.comparativaService.arregloElementos.length < 3) {
          scope.comparativaService.agregarElemento(el);
          el.seleccionado = true;
      }
      if(this instanceof GlobalesService && scope.comparativaService.arregloElementos.length >= 3){
          this.toggleBottomSheet(false);
      }
      if (!scope.comparativaService.stageStatus) {
          scope.comparativaService.stageStatus = true;
          scope.comparativaService.guardarStage(scope.fichas);
      }
  }

  diselectedItemFun = (el: any): void => {
    this.comparativaService.eliminarElemento(el);
    el.seleccionado = false;
  }

}
