<div class="casilla-resumen container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 col-lg-10">
                        <h3 class="titulo-seccion">
                            Diputaciones - <span class="txRosaIne"><strong>Voto en el extranjero</strong></span>
                        </h3>
                    </div>
                    <div class="col-12 col-lg-10">
                        <div class="texto font-regular">
                            <p>Cantidad de votos de la Entidad, conforme a la información de las Mesas de Escrutinio y Cómputo</p>
                        </div>
                    </div>
                        
                </div>
                <app-resumen-votacion-mobile
                    [total_num]="_obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.listaSecciones[_globalService.rutaParametros.noSeccion]?.resumenVotacion?.totalVotos?.total"
                    [total_prc]="_obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.listaSecciones[_globalService.rutaParametros.noSeccion]?.resumenVotacion?.totalVotos?.porcentaje"
                ></app-resumen-votacion-mobile>
                <div class="row pb-4">
                    <div class="col mapa-puntos">
                        
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                        <p><strong>Ver acta digitalizada:</strong></p>
                        <div class="acta-digitalizada container-fluid">
                            <div class="row">
                                <div class="col-8 pl-0">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-3 dis-flex border-rightB padding-r-0">
                                                <button class="btn btn-sm btn-rosaFuerte texto cursorPointer my-3 px-4 py-1">
                                                    <i class="far fa-file"></i>
                                                    Acta voto total
                                                </button>
                                            </div>
                                            <div class="col-6">
                                                <p class="font-medium text-left padding-b-5">Imagen procedente de:</p>
                                                <div class="row">
                                                    <div class="col-6 dis-flex border-rightB padding-r-0">
                                                        <div class="grupo gris dis-flex">
                                                            <i class="fas fa-file"></i>
                                                            <i class="fas fa-hourglass"></i>
                                                            <p>
                                                                Acta en proceso
                                                            </p>
                                                            <div class="vline"></div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 dis-flex">
                                                        <div class="grupo gris dis-flex cursor-hand" placement="top" max-width="500">
                                                            <i class="far fa-file purpura"></i>
                                                            <p>
                                                                Voto total
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="row dis-flex pb-1">
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollLeft()">
                            <i class="fas fa-chevron-left" aria-hidden="true"></i>
                        </div>
                        <div class="col-11">
                            <div #widgetsContent class="row dis-flex justify-s scrollH">
                                <div *ngFor="let tarjeta of casilla_table.headers; let i=index" class="col-6 col-sm-4 col-md-3 col-lg-2 px-1">
                                    <div class="card-resumen" *ngIf="i < casilla_table.headers.length - 3">
                                        <div class="encabezado dis-flex w-100 py-2">
                                            <div class="dis-flex w-100">
                                                <div class="row w-100 dis-flex">
                                                    <p class="pe p-1" *ngFor="let img of tarjeta?.imgs" [ngStyle]="{
                                                        'background-image': 'url(./assets/img/logos-partidos/'+  img +')',
                                                        'background-size': 'contain',
                                                        'background-repeat': 'no-repeat',
                                                        'background-position': 'center',
                                                        'margin': '20px 2px'
                                                    }">
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cuerpo dis-flex bgGrisTenue p-2">
                                            <div class="w-50">
                                                <p>Votos</p>
                                                <p class="votos">
                                                    <strong>{{ tarjeta?.votos }}</strong>
                                                </p>
                                            </div>
                                            <div class="w-50 dis-flex flex-col align-e pr-3 pr-xl-0">
                                                <p>Porcentaje</p>
                                                <p>{{ tarjeta?.porcentaje}}%</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollRight()">
                            <i class="fas fa-chevron-right" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                <app-resumen-votacion
                    [acumulados_num]="_obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.listaSecciones[_globalService.rutaParametros.noSeccion]?.resumenVotacion?.acumulados?.total"
                    [acumulados_prc]="_obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.listaSecciones[_globalService.rutaParametros.noSeccion]?.resumenVotacion?.acumulados?.porcentaje"
                    [noregistradas_num]="_obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.listaSecciones[_globalService.rutaParametros.noSeccion]?.resumenVotacion?.noRegistradas?.total"
                    [noregistradas_prc]="_obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.listaSecciones[_globalService.rutaParametros.noSeccion]?.resumenVotacion?.noRegistradas?.porcentaje"
                    [nulos_num]="_obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.listaSecciones[_globalService.rutaParametros.noSeccion]?.resumenVotacion?.nulos?.total"
                    [nulos_prc]="_obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.listaSecciones[_globalService.rutaParametros.noSeccion]?.resumenVotacion?.nulos?.porcentaje"
                    [total_num]="_obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.listaSecciones[_globalService.rutaParametros.noSeccion]?.resumenVotacion?.totalVotos?.total"
                    [total_prc]="_obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.listaSecciones[_globalService.rutaParametros.noSeccion]?.resumenVotacion?.totalVotos?.porcentaje"
                ></app-resumen-votacion>
                
                
            </div>
        </div>
    </div>
    
<!--    <app-detalle-votos-casilla></app-detalle-votos-casilla>-->
    <div class="row">
    <div class="col-md-12 p-3">
        <div class="card p-3">
            <div class="acta-digitalizada container-fluid">
                <div class="row">
                    <h4 class="subtitulo-seccion pleca-izquierda">
                        <strong>Detalle de votos por tipo de Acta</strong>
                    </h4>
                </div>
                <div class="row">
                    <div class="col-6">
                        <p class="font-medium text-left padding-b-5">Imagen procedente de:</p>
                        <div class="row">
                            <div class="col-4 dis-flex border-rightB padding-r-0">
                                <div class="grupo gris dis-flex">
                                    <i class="fas fa-file"></i>
                                    <i class="fas fa-hourglass"></i>
                                    <p>Acta en proceso</p>
                                    <div class="vline"></div>
                                </div>
                            </div>
                            <div class="col-3 dis-flex border-rightB padding-r-0">
                                <div class="grupo purpura dis-flex">
                                    <i class="far fa-file"></i>
                                    <p>Voto Postal</p>
                                </div>
                            </div>
                            <div class="col-3 dis-flex">
                                <div class="grupo purpura dis-flex cursor-hand" placement="top" max-width="500">
                                    <i class="far fa-file"></i>
                                    <p>Voto Electrónico</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-12 px-0 mt-4">
                    <div class="">
                        <div class="table-responsive tabla-seccion">
                            <table class="table table-striped contenido table-bordered">
                                <thead class="titulos">
                                    <tr>
                                        <th class="sticky-cell table-header-background">
                                            <div class="dis-flex">
                                                <ng-container>
                                                    <ng-container>
                                                        <strong>Acta digitalizada</strong>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </th>
                                        <th *ngFor="let itemHeader of casilla_table?.headers; let i = index">
                                            <div class="dis-flex">
                                                <ng-container>
                                                    <ng-container *ngIf="itemHeader?.text != ''">
                                                        {{itemHeader?.text}}
                                                    </ng-container>
                                                    <ng-container *ngFor="let img of itemHeader?.imgs">
                                                        <!-- {{_obtenerDatosService?.diccionarioPartidos[itemPartido].imgPartido}} -->
                                                        <p style="width: 2.25em; height: 2.25em;" class="m-1" [ngStyle]="{
                                                                'background-image': 'url(./assets/img/logos-partidos/'+  img +')',
                                                                'background-size': 'contain',
                                                                'background-repeat': 'no-repeat',
                                                                'background-position': 'center'
                                                            }">
                                                        </p>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let itemBody of casilla_table?.rows">
                                        <td class="sticky-cell">
                                          <a target="_blank" [href]="itemBody.status.file"
                                            *ngIf="itemBody.status != 'proceso'">
<!--                                            <ng-container *ngIf="itemBody.status.movil">-->
                                              <i class="far fa-file" aria-hidden="true"></i>
                                          </a>
                                          <a target="_blank" *ngIf="itemBody.status == 'proceso'">
                                            <div class="txGris">
                                              <i class="fas fa-file txGris"></i>
                                              <i class="fas fa-hourglass txGris"></i>
                                            </div>
                                          </a>
                                            {{itemBody.text}}
                                        </td>
                                        <td *ngFor="let itemVoto of itemBody.columns">
                                          {{itemVoto}}
                                        </td>
                                      </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!--    <div class="card p-3">-->
    <div class="row">
        <div class="col-md-12">
            <div class="estadistica-casilla card mt-3 p-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <h4 class="subtitulo-seccion pleca-izquierda">
                                <strong>Estadística de voto en el extranjero</strong>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col">
                            <p class="subtitulo">
                                <strong>Participación ciudadana con base en la Lista Nominal de las Actas PREP Contabilizadas</strong>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="row my-2" >
                        <div class="col-10">
                            <div class="grafica dis-flex justify-s px-2">
                                <div class="textos">
                                    <p>Participación ciudadana</p>
                                    <p class="porcentaje em12">
                                        <strong>{{estadistica_extranjero.participacion}}</strong>
                                    </p>
                                </div>
                                    <div class="interno" [ngStyle]="{ 'width.': estadistica_extranjero.participacion}">
                                </div>
                            </div>
                        </div>
                        <div class="col-2 dis-flex flex-col align-e pr-2">
                            <p class="lista-nominal em10">
                                <strong>
                                    Total de la Lista Nominal
                                </strong>
                            </p>
                            <p class="total-nominal em12">
                                <strong>
                                    {{ estadistica_extranjero.total_ln }}
                                </strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!--    </div>-->
</div>
