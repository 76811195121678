import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComparativaService } from 'src/app/servicios/comparativa/comparativa.service';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';

// declare var $;
// import * as $ from 'jquery';

@Component({
  selector: 'app-distrito-distrito',
  templateUrl: './distrito-distrito.component.html',
  styleUrls: ['./distrito-distrito.component.scss']
})
export class DistritoDistritoComponent implements OnInit {
//  resumen_table: any;
  fichas: any;
  resumen_votacion: any;
  data_comparador: any = [];
  distribucion_table: [];
  selectedContienda: string = "";

  base: string;
  labels: any = {};
  short_data: any = {};
  resumen: any = {};

  constructor(
    public _globalService: GlobalesService,
    public activatedRoute: ActivatedRoute,
    public readonly rutas: RutasService,
    public _obtenerDatosService: ObtenerDatosService,
    public comparativaService: ComparativaService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this._globalService.setearParametros();
    
    this.checkInicial();
    this.base = this.rutas.rutaActual.base;
    this.comparativaService.stageStatus = false;
    this.comparativaService.pantalla = 'distritoCandidatura';
    this.comparativaService.resetear();
      
    this._globalService.bottomSheetContex = this;
    this._globalService.bottomSheetCallback = this.agregarElemento;
      
    // $('[data-toggle="tooltip"]').tooltip();

    this.route.data.subscribe(v => {
      this.labels.contienda = v.contienda || '[-]';
      this.labels.contienda_s = v.contienda_s || '[-]';
    });

    // this._obtenerDatosService.checkMain(() => {
    //   this.loadData();
    // });
    this._obtenerDatosService.mainSubject.subscribe(data => {
      if (data == null) return;

      this.loadData();
    });
  }

  // ngOnDestroy(): void {
  //   this._obtenerDatosService.mainSubject.unsubscribe();
  // }

	loadData() {
        // console.log(this.rutas);
		this.selectedContienda = this._globalService.getContienda();
		/*
        {{ obtenerDatosService?.dataDiputacionesEntidad['entidad'+ globalService.rutaParametros.noEntidad]?.distritos['distrito'+globalService.rutaParametros.noDistrito]?.resumenVotacion?.totalVotos?.total  }}
        */
        // console.log(this.selectedContienda, 'detalle.' + this._globalService.param_distrito + '.candidatura.json');
		this._obtenerDatosService.loadJSON(this.selectedContienda, 'detalle.' + this._globalService.param_distrito + '.candidatura.json').then(data => {
            this._globalService.bottomSheetTpl = 'comparador-candidato';
			this.fichas = data['fichas'];
			this.resumen = data['resumen'];
			this._obtenerDatosService.desglose = data['distribucion_table'].rows;
            
            this.comparativaService.votos_total = this.resumen['total'].total;
            this.comparativaService.votos_pctj = this.resumen['total'].prc;
//      console.log("data['distribucion_table'].rows", data['distribucion_table'].rows);
            
		});

		this._obtenerDatosService.loadJSON(this.selectedContienda, 'detalle.' + this._globalService.param_distrito + '.participacion.json').then(data => {
			this.short_data = (data && data['actas_participacion'] ? data['actas_participacion']['head_info'] : {});
      //console.log("this.short_data\n", this.short_data)
      this._globalService.shortData.next(this.short_data);
		});
	}

  checkInicial() {

    this.rutas.limpiarSeccion();

    this.obtenerParametro()
      .then(() => {
        this._globalService.compararParametros();
        if (!this.rutas.migas.distrito) {
            this.rutas.migas.distrito = `Distrito ${this._globalService.rutaParametros.noDistrito}.${this._globalService.rutaParametros.distritoTexto}`;
//            console.log(this.rutas.migas);
        }
      });
  }

  obtenerParametro() {
    return new Promise((res, rej) => {
      this.activatedRoute.params.subscribe((param) => {
        this._globalService.detalles = param;
        res();
      });
    })
  }

  agregarElemento(el: any) {
      //Fix del contexto de la variable 
      let scope = (this instanceof GlobalesService)? this["bottomSheetContex"] : this;
//      console.log(scope);
//      console.log('arreglo elementos', scope.comparativaService.arregloElementos);
      if (scope.comparativaService.arregloElementos.length < 3) {
          scope.comparativaService.agregarElemento(el);
          el.seleccionado = true;
      }
      if(this instanceof GlobalesService && scope.comparativaService.arregloElementos.length >= 3){
          this.toggleBottomSheet(false);
      }
      if (!scope.comparativaService.stageStatus) {
          scope.comparativaService.stageStatus = true;
          scope.comparativaService.guardarStage(scope.fichas);
      }
  }

  eliminarElemento(el: any) {
    this.comparativaService.eliminarElemento(el);
    el.seleccionado = false;
  }

  selectedItemFun = (el: any): void => {
      this.agregarElemento(el);
  }

  diselectedItemFun = (el: any): void => {
      this.eliminarElemento(el);
  }

}
