import { Component, OnInit } from '@angular/core';
import { GlobalesService } from '../../../servicios/globales.service';

@Component({
  selector: 'app-accesibilidad',
  templateUrl: './accesibilidad.component.html',
  styleUrls: ['./accesibilidad.component.scss']
})
export class AccesibilidadComponent implements OnInit {

  constructor(public globalService: GlobalesService) { }

  ngOnInit(): void {
    this.globalService.unselectContiendas();
  }

}
