import { Component, OnInit } from '@angular/core';
// import { ActivatedRoute } from '@angular/router';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
// import { RutasService } from 'src/app/servicios/rutas/rutas.service';
import { ComparativaService } from 'src/app/servicios/comparativa/comparativa.service';

// declare var $;
// import * as $ from 'jquery';
import { ThemeService } from '../../../theme/theme.service';

@Component({
  selector: 'app-votos-ppyci-tarjetas',
  templateUrl: './votos-ppyci-tarjetas.component.html',
  styleUrls: ['./votos-ppyci-tarjetas.component.scss']
})
export class VotosPpyciTarjetasComponent implements OnInit {
  data_comparador: any = [];
  grafica_table: any = [];
  fichas: any = [];
  total_votos: string = '';

  base: string;
  seccion: string;
  resumen: any = {};
  omitirExtranjero = false;

  constructor(
    public _globalService: GlobalesService,
    //   private readonly activatedRouter: ActivatedRoute,
    //   public readonly rutas: RutasService,
    public comparativaService: ComparativaService,
    public _obtenerDatosService: ObtenerDatosService,
    public themeService: ThemeService,
  ) { }

  ngOnInit(): void {
      this.comparativaService.stageStatus = false;
      this.comparativaService.pantalla = 'resumenPPyCI';
      this.comparativaService.resetear();
      
      this._globalService.bottomSheetContex = this;
      this._globalService.bottomSheetCallback = this.agregarElemento;
    //   this.obtenerParametros();
    //   this.base = this.rutas.rutaActual.base;
    //   this.rutas.rutaActual.base = 'casilla';
    //   // $('[data-toggle="tooltip"]').tooltip();
    this._obtenerDatosService.mainSubject.subscribe(data => {
      if (data == null) return;

      this.loadData();
    });
    this._obtenerDatosService.configSubject.subscribe(data =>{
      if (data != null) {
        const omitir = data.omitir_extranjero;
        this.omitirExtranjero = (!omitir || (omitir && (omitir == 'false' || omitir == false) )) ? false : true;
      }
    });
  }


  loadData() {
    let selectedContienda = this._globalService.getContienda();
    // if (this._obtenerDatosService.contiendas[selectedContienda] && this._obtenerDatosService.contiendas[selectedContienda]['detalle.distritos.json']){
    //   this.data = this._obtenerDatosService.contiendas[selectedContienda]['detalle.distritos.json'].diputaciones_table.rows;
    // }else {
    this._obtenerDatosService.loadJSON(selectedContienda, 'entidad.partido.json').then(data => {
        this._globalService.bottomSheetTpl = 'comparador-contienda';
        this.grafica_table = data['grafica'].columns;
        this.fichas = data['fichas'];
        this.resumen = data['resumen'];
        this.total_votos = '1000';
        
        this.comparativaService.votos_total = this.resumen['total'].total;
        this.comparativaService.votos_pctj = this.resumen['total'].prc;
//        this.comparativaService.votos_pctj = (this.resumen['total'].prc.toString().includes('%'))? this.resumen['total'].prc : this.resumen['total'].prc+'%' ;
    });
    // }
  }

  agregarElemento(el: any) {
      //Fix del contexto de la variable 
      let scope = (this instanceof GlobalesService)? this["bottomSheetContex"] : this;
      if (scope.comparativaService.arregloElementos.length < 3) {
          scope.comparativaService.agregarElemento(el);
          el.seleccionado = true;
      }
      if(this instanceof GlobalesService && scope.comparativaService.arregloElementos.length >= 3){
          this.toggleBottomSheet(false);
      }
      if (!scope.comparativaService.stageStatus) {
          scope.comparativaService.stageStatus = true;
          scope.comparativaService.guardarStage(scope.fichas);
      }
  }

  eliminarElemento(el: any) {
    this.comparativaService.eliminarElemento(el);
    el.seleccionado = false;
  }

  selectedItemFun = (el: any): void => {
    this.agregarElemento(el);
  }

  diselectedItemFun = (el: any): void => {
    this.eliminarElemento(el);
  }

  // obtenerParametros() {
  //   this.activatedRouter.params.subscribe((parametros) => {
  //     const params = { parametros };
  //     this._globalService.detalles = params.parametros;
  //   });
  //   this._globalService.setearParametros();
  //   this.seccion = this.rutas.migas?.seccion.split(' ')[1];
  // }



  // @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;

  // public scrollRight(): void {
  //   this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  // }

  // public scrollLeft(): void {
  //   this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  // }

}
