import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { ActivatedRoute } from '@angular/router';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';

// declare var $;
// import * as $ from 'jquery';

@Component({
  selector: 'app-distrito-circunscripcion',
  templateUrl: './distrito-circunscripcion.component.html',
  styleUrls: ['./distrito-circunscripcion.component.scss']
})
export class DistritoCircunscripcionComponent implements OnInit {

  votosActas: any = [];
  votosTotalActas: any = [];
  resumenVotacion: any;


  constructor(
    public _globalService: GlobalesService,
    public activatedRoute: ActivatedRoute,
    public readonly _obtenerDatosService: ObtenerDatosService,
    public rutas: RutasService
  ) { }

  ngOnInit(): void {
    this._globalService.setearParametros();
    this.checkInicial();
    // $('[data-toggle="tooltip"]').tooltip();
  }

  checkInicial() {
    this.obtenerParametro();
  }

  obtenerParametro() {
    return new Promise((res, rej) => {
      this.activatedRoute.params.subscribe((param) => {
        this._globalService.detalles = param;
        res();
      });
    })
  }

  

  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

}
