<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 col-lg-10">
                        <app-breadcrumb
                            subtitle="{{ labels?.contienda == 'Municipios' ? 'Detalle por Municipio' : (labels?.contienda == 'Juntas' ? 'Detalle por Juntas' : 'Detalle del ' + (labels?.contienda == 'Distritos' ? 'Distrito' : labels?.contienda) ) }}"
                            subtitle_detalle="Votos por Candidatura"
                            [estado]="_obtenerDatosService?.config?.estado"
                            [distrito]="rutas.migas?.distrito"
                            [distrito_param]="rutas.migas?.distrito_clave"
                        ></app-breadcrumb>
                        <div class="texto font-regular">
                            <p *ngIf="selectedContienda == 'gubernatura'">
                                El total de votos calculado y porcentaje que se muestran, se refieren a los votos asentados en las Actas PREP hasta el momento.
                            </p>
                            <p *ngIf="selectedContienda != 'gubernatura'">
                                El total de votos calculado y porcentaje que se muestran, se refieren a los votos asentados en las Actas PREP hasta el momento.
                            </p>
                            <p>
                                Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%.
                            </p>
                        </div>
                    </div>
                    <div class="col-2 d-none d-lg-block">
                    </div>
                </div>
                <app-resumen-votacion-mobile
                    [total_num]="resumen?.total?.total"
                    [total_prc]="resumen?.total?.prc"
                ></app-resumen-votacion-mobile>
                <div class="row pb-4">
                    <div class="col">
                        <h4 class="subtitulo-seccion pleca-izquierda my-3">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                    </div>
                </div>
                <!-- TARJETAS DE ESCRITORIO -->

                <div class="container-fluid d-none d-md-none d-xl-block">
                    <div class="row justify-content-center">
                        <div class="col-8 col-sm-4 col-md-3 col-lg-2" *ngFor="let tarjeta of fichas; let indx = index;">
                            <div class="tarjeta card dis-flex flex-col mb-2" [ngClass]="{cardGanador: tarjeta?.winner}">
<!--                                <img src="assets/res/avatar-persona.svg">-->
                                <img [src]="'./assets/img/avatar-candidatos/'+tarjeta?.img_perfil?.toLowerCase()">
                                <p class="nombre text-center px-1">
                                    {{ tarjeta?.text }}
                                </p>
                                <div class="distritos w-100 dis-flex py-4 px-1">
                                    <ng-container *ngFor="let img of tarjeta?.imgs">
                                        <img style="height: 30px; border-radius: 0%; width: auto;" [src]="'./assets/img/logos-partidos/'+img?.toLowerCase()" />
                                    </ng-container>
                                </div>
                                <div class="cuerpo bgGrisTenue dis-flex w-100 p-2">
                                    <div class="w-50">
                                        <p><strong>Votos</strong></p>
                                        <p class="votos" [ngClass]="{textGanador: tarjeta?.winner}" style="padding-right: 8px;">
                                            <strong>
                                                {{ tarjeta?.total }}
                                            </strong>
                                        </p>
                                    </div>
                                    <div class="w-50 dis-flex flex-col align-e">
                                        <p>Porcentaje</p>
                                        <p>
                                            {{ tarjeta?.porcentaje ? tarjeta?.porcentaje : tarjeta?.prc }}
                                        </p>
                                    </div>
                                </div>
                                <!-- <div *ngIf="!tarjeta.seleccionado" (click)="agregarElemento(tarjeta);" class="pie w-100 dis-flex bgMorado txBlanco p-2 cursorPointer">
                                    <p>Seleccionar</p>
                                </div>
                                <div *ngIf="tarjeta.seleccionado" (click)="eliminarElemento(tarjeta);" class="pie w-100 dis-flex bgMorado txBlanco p-2 cursorPointer seleccionado">
                                    <p>Quitar selección</p>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- TARJETAS MOVIL -->
                <movil-listado-content
                    [selectedItemFunc]="selectedItemFun"
                    [diselectedItemFunc]="diselectedItemFun"
                    [(infoList)]="fichas"
                    [tipoTarjeta]="4"></movil-listado-content>
                <!--********************************************************************************************************************-->

                <div class="container-fluid">
                    <div class="row dis-flex">
                        <div class="col-md-12">
                            <div class="row dis-flex justify-s">

                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="container-fluid py-4 d-none d-xl-block">
                    <div class="row">
                        <div class="col-md-12 dis-flex justify-e">
                            <i class="fas fa-info-circle mr-2" aria-hidden="true"></i>
                            <p class="texto">
                                Da clic en <strong>Seleccionar</strong> para visualizar el detalle de la votación. Puedes seleccionar hasta tres opciones.
                            </p>
                            <button [disabled]="comparativaService?.arregloElementos.length < 1" (click)="comparativaService.reiniciarModal()" class="boton-detalle btn bgMorado txBlanco btn-sm texto ml-2 px-4 cursorPointer" data-toggle="modal" data-target="#modalComparativa">
                                Ver detalle
                            </button>
                        </div>
                    </div>
                </div> -->

                <!-- <p class="p-3" style="font-size: 1.4em; text-align: justify;">Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%.</p> -->

				<app-resumen-votacion
					[acumulados_num]="resumen?.acumulados?.total"
					[acumulados_prc]="resumen?.acumulados?.prc"
					[noregistradas_num]="resumen?.no_registradas?.total"
					[noregistradas_prc]="resumen?.no_registradas?.prc"
					[nulos_num]="resumen?.nulos?.total"
					[nulos_prc]="resumen?.nulos?.prc"
					[total_num]="resumen?.total?.total"
					[total_prc]="resumen?.total?.prc"
                    [hide_prc]="false"
				></app-resumen-votacion>

                <!-- <div class="container-fluid py-4 d-block d-xl-none">
                    <div class="row">
                        <div class="col-md-12 dis-flex justify-e">
                            <button [disabled]="comparativaService?.arregloElementos.length < 1" (click)="comparativaService.reiniciarModal()" class="boton-detalle btn bgMorado txBlanco btn-sm texto ml-2 px-4 cursorPointer" data-toggle="modal" data-target="#modalComparativa">
                                Ver detalle
                            </button>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>
    </div>
</div>
<app-distribucion-votos [contienda]="labels?.contienda_s"></app-distribucion-votos>
<app-estadistica-nacional
    [tipo_estadistica]="rutas.migas?.distrito"
    [short_tpl]="true"
    [short_data]="short_data"
></app-estadistica-nacional>
