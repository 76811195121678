import { Component, OnInit } from '@angular/core';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
// import { ActivatedRoute } from '@angular/router';
import { GlobalesService } from 'src/app/servicios/globales.service';
// import { RutasService } from 'src/app/servicios/rutas/rutas.service';
import { ComparativaService } from 'src/app/servicios/comparativa/comparativa.service';

// declare var $;
// import * as $ from 'jquery';
import { ThemeService } from '../../../theme/theme.service';

@Component({
  selector: 'app-votos-candidatura-tarjetas',
  templateUrl: './votos-candidatura-tarjetas.component.html',
  styleUrls: ['./votos-candidatura-tarjetas.component.scss']
})
export class VotosCandidaturaTarjetasComponent implements OnInit {
  resumen_table: any = [];
  fichas: any = [];
  data_comparador: any = [];
  base: string;
  seccion: string;
  resumen: any= {};
  omitirExtranjero = false;

  constructor(
    public _globalService: GlobalesService,
    //   private readonly activatedRouter: ActivatedRoute,
    //   public readonly rutas: RutasService,
    public _comparativaService: ComparativaService,
    public _obtenerDatosService: ObtenerDatosService,
    public themeService: ThemeService
  ) { }

  ngOnInit(): void {
      this._comparativaService.stageStatus = false;
      this._comparativaService.pantalla = 'entidadCandidatura';
      this._comparativaService.resetear();
      
      this._globalService.bottomSheetContex = this;
      this._globalService.bottomSheetCallback = this.agregarElemento;
    //   this.obtenerParametros();
    //   this.base = this.rutas.rutaActual.base;
    //   this.rutas.rutaActual.base = 'casilla';
    //   // $('[data-toggle="tooltip"]').tooltip();
      
    this._obtenerDatosService.configSubject.subscribe(data =>{
      if (data != null) {
        const omitir = data.omitir_extranjero;
        this.omitirExtranjero = (!omitir || (omitir && (omitir == 'false' || omitir == false) )) ? false : true;
      }
    });
      
      this._obtenerDatosService.mainSubject.subscribe(data => {
        if (data == null) return;

      this.loadData();
    });
  }


  loadData() {
      let selectedContienda = this._globalService.getContienda();
      this._obtenerDatosService.loadJSON(selectedContienda, 'entidad.candidatura.json').then(data => {
          this._globalService.bottomSheetTpl = 'comparador-candidato';
          console.log('SOY DATA', data);
          this.resumen_table = data['resumen_table'].columns;
          this.fichas = data['fichas'];
          this.resumen = data['resumen'];
          this.data_comparador = this.fichas;
          
          this._comparativaService.votos_total = this.resumen['total'].total;
          this._comparativaService.votos_pctj = this.resumen['total'].prc;
//          this._comparativaService.votos_pctj = (this.resumen['total'].prc.toString().includes('%'))? this.resumen['total'].prc : this.resumen['total'].prc+'%' ;
          //elemento?.porcentaje?.includes('%') ? elemento?.porcentaje : elemento?.porcentaje+'%'
      });
    // }
  }

  agregarElemento(el: any) {
      //Fix del contexto de la variable 
      let scope = (this instanceof GlobalesService)? this["bottomSheetContex"] : this;
//      console.log('arreglo elementos', scope.comparativaService.arregloElementos);
      if (scope._comparativaService.arregloElementos.length < 3) {
          scope._comparativaService.agregarElemento(el);
          el.seleccionado = true;
      }
      if(this instanceof GlobalesService && scope._comparativaService.arregloElementos.length >= 3){
          this.toggleBottomSheet(false);
      }
      if (!scope._comparativaService.stageStatus) {
          scope._comparativaService.stageStatus = true;
          scope._comparativaService.guardarStage(scope.fichas);
      }
  }

  eliminarElemento(el: any) {
    this._comparativaService.eliminarElemento(el);
    el.seleccionado = false;
  }

  selectedItemFun = (el: any): void => {
    this.agregarElemento(el);
  }

  diselectedItemFun = (el: any): void => {
    this.eliminarElemento(el);
  }
}
