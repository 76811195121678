import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { ActivatedRoute } from '@angular/router';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { ComparativaService } from 'src/app/servicios/comparativa/comparativa.service';

// declare var $;
// import * as $ from 'jquery';

@Component({
  selector: 'app-ppyci-distrito',
  templateUrl: './ppyci-distrito.component.html',
  styleUrls: ['./ppyci-distrito.component.scss']
})
export class PpyciDistritoComponent implements OnInit {
//  resumen_table: any;
  fichas: any;
  resumen_votacion: any;
  distribucion_table: [];

  base: string;
  votosPPyCI: any = [];
  labels: any = {};
  resumen: any = {};
  short_data: any = {};

  constructor(
    public _globalService: GlobalesService,
    public activatedRoute: ActivatedRoute,
    public readonly rutas: RutasService,
    public _obtenerDatosService: ObtenerDatosService,
    public comparativaService: ComparativaService
  ) { }

  ngOnInit(): void {
    this._globalService.setearParametros();
    this.checkInicial();
    this.base = this.rutas.rutaActual.base;
    this.comparativaService.stageStatus = false;
    this.comparativaService.pantalla = 'distritoPPyCI';
    this.comparativaService.resetear();
    this.rutas.migas.seccion = null;
      
    this._globalService.bottomSheetContex = this;
    this._globalService.bottomSheetCallback = this.agregarElemento;
    // $('[data-toggle="tooltip"]').tooltip();

    this.activatedRoute.data.subscribe(v => {
      this.labels.contienda = v.contienda || '[-]';
      this.labels.contienda_s = v.contienda_s || '[-]';
    });

    this._obtenerDatosService.mainSubject.subscribe(data =>{
      if(data == null) return;

      this.loadData();
    });
  }

  loadData() {
    let selectedContienda = this._globalService.getContienda();
    // if (this._obtenerDatosService.contiendas[selectedContienda] && this._obtenerDatosService.contiendas[selectedContienda]['detalle.distritos.json']){
    //   this.data = this._obtenerDatosService.contiendas[selectedContienda]['detalle.distritos.json'].diputaciones_table.rows;
    // }else {
      this._obtenerDatosService.loadJSON(selectedContienda, 'detalle.'+this._globalService.param_distrito+'.partido.json').then(data => {
//        this.resumen_table = data['resumen_table'].columns;
          this.fichas = data['fichas'];
          this.resumen = data['resumen'];
          this._obtenerDatosService.desglose = data['distribucion_table'].rows;
          this._globalService.bottomSheetTpl = 'comparador-contienda';
          
          this.comparativaService.votos_total = this.resumen['total'].total;
          this.comparativaService.votos_pctj = this.resumen['total'].prc;
//          this.comparativaService.votos_pctj = (this.resumen['total'].prc.toString().includes('%'))? this.resumen['total'].prc : this.resumen['total'].prc+'%' ;

        // this.grafica_table = data['grafica'].columns;
        // this.total_votos = '1000';
      });

      this._obtenerDatosService.loadJSON(selectedContienda, 'detalle.' + this._globalService.param_distrito + '.participacion.json').then(data => {
        this.short_data = (data && data['actas_participacion'] ? data['actas_participacion']['head_info'] : {});
        this._globalService.shortData.next(this.short_data);
      });
    // }
  }

  checkInicial() {
    this.rutas.limpiarSeccion();
    this.obtenerParametro()
        .then(() => {

        });
  }

  obtenerParametro() {
    return new Promise((res, rej) => {
      this.activatedRoute.params.subscribe((param) => {
        this._globalService.detalles = param;
        res();
      });
    })
  }

  agregarElemento(el: any) {
      //Fix del contexto de la variable 
      let scope = (this instanceof GlobalesService)? this["bottomSheetContex"] : this;
      if (scope.comparativaService.arregloElementos.length < 3) {
          scope.comparativaService.agregarElemento(el);
          el.seleccionado = true;
      }
      if(this instanceof GlobalesService && scope.comparativaService.arregloElementos.length >= 3){
          this.toggleBottomSheet(false);
      }
      if (!scope.comparativaService.stageStatus) {
          scope.comparativaService.stageStatus = true;
          scope.comparativaService.guardarStage(scope.fichas);
      }
  }

  eliminarElemento(el: any) {
    this.comparativaService.eliminarElemento(el);
    el.seleccionado = false;
  }

  selectedItemFun = (el: any): void => {
    this.agregarElemento(el);
  }

  diselectedItemFun = (el: any): void => {
    this.eliminarElemento(el);
  }

  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

}
