import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, Data  } from '@angular/router';
// import { ObtenerDatosService } from './servicios/datos/obtener-datos/obtener-datos.service';
// import { GlobalesService } from './servicios/globales.service';
// import { RutasService } from './servicios/rutas/rutas.service';

// declare var $;

@Component({
  selector: 'app-not-found',
  templateUrl: './notfound.component.html'
})
export class NotFoundComponent implements OnInit {
  title = 'publicacion';

    constructor(
        // public globalService: GlobalesService,
        // private readonly router: Router,
        // private readonly rutas: RutasService,
        // private readonly _obtenerDatosService: ObtenerDatosService,
        private readonly activatedRoute: ActivatedRoute,
        private titleService: Title
    ) {

    }

    ngOnInit(): void {
    }
}
