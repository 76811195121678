import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { ActivatedRoute } from '@angular/router';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';

// declare var $;
// import * as $ from 'jquery';
import { ThemeService } from '../../../../../theme/theme.service';

@Component({
  selector: 'app-distrito-entidad',
  templateUrl: './distrito-entidad.component.html',
  styleUrls: ['./distrito-entidad.component.scss']
})
export class DistritoEntidadComponent implements OnInit {

  base: string;
  visualizarMapa = true;

  contenido: any = [];
  votosActas: any = [];
  votosTotalActas: any = [];
  resumenVotacion: any;


  constructor(
    public _globalService: GlobalesService,
    public activatedRoute: ActivatedRoute,
    public readonly rutas: RutasService,
    public  _obtenerDatosService: ObtenerDatosService,
    public  themeService: ThemeService
  ) { }

  ngOnInit(): void {
    this._globalService.setearParametros();
    this.checkInicial();
    this.base = `/${this.rutas.rutaActual.base}/tarjetas`;
    // $('[data-toggle="tooltip"]').tooltip();
  }

  checkInicial() {
    this.obtenerParametro();
  }

  obtenerParametro() {
    return new Promise((res, rej) => {
      this.activatedRoute.params.subscribe((param) => {
        this._globalService.detalles = param;
        res();
      });
    })
  }



  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

}
