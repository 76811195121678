<div class="contenido container-fluid bgAzul txBlanco py-5">
    <div class="row justify-content-center">
        <div class="col-11">
            <h2>Carpeta de archivos</h2>
            <h3 class="font-medium">- Contenido -</h3>
            <p class="py-2">Se trata de un archivo comprimido con extensión <strong>.zip</strong>, contiene: ​</p>
            <div class="container-fluid px-0">
                <div class="row">
                    <div class="col-6">
                        <div class="item pt-4 pb-2">
                            <p>
                                <strong>Diputaciones.csv</strong>
                            </p>
                            <p>
                                ​Contiene los resultados de la votación a nivel Acta para cada tipo de elección.
                            </p>
                            <p>
                                ​Muestra el número de votos recibidos por cada uno de los Partidos Políticos, {{ _obtenerDatosService.config?.estado == 'Yucatán' ? 'Candidaturas Comunes' : 'Coaliciones' }}, Candidaturas no registradas y votos nulos.​
                            </p>
                        </div>
                        <div class="item pt-4 pb-2">
                            <p>
                                <strong>Diputaciones.csv</strong>
                            </p>
                            <p>
                                ​Contiene los resultados de la votación a nivel Acta para cada tipo de elección.
                            </p>
                            <p>
                                ​Muestra el número de votos recibidos por cada uno de los Partidos Políticos, {{ _obtenerDatosService.config?.estado == 'Yucatán' ? 'Candidaturas Comunes' : 'Coaliciones' }}, Candidaturas no registradas y votos nulos.​
                            </p>
                        </div>
                        <div class="item pt-4 pb-2">
                            <p>
                                <strong>LEEME.txt</strong>
                            </p>
                            <p>
                                ​Incluye la descripción y codificación de cada uno de los campos que contiene la Base de datos. ​
                            </p>
                        </div>
                    </div>
                    <div class="col-6">
                        <img src="./assets/img/basededatos/carpetaarchivos.gif" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Descargar -->
<div class="descarga container-fluid py-5">
    <div class="row justify-content-center">
        <div class="col-11">
            <h3 class="font-medium">- Descargar -</h3>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-11 w-100 dis-flex space-b align-s">
            <div class="item dis-flex align-s">
                <div class="numero dis-flex justify-s">
                    1
                </div>
                <div class="contenido dis-flex flex-col align-s">
                    <p class="titulo">
                        <strong>
                            Descarga
                        </strong>
                    </p>
                    <p>
                        Da clic en el botón
                    </p>
                    <p><strong>
                        Base de datos​.
                    </strong></p>
                </div>
            </div>
            <div class="item dis-flex align-s">
                <div class="numero dis-flex justify-s">
                    2
                </div>
                <div class="contenido dis-flex flex-col align-s">
                    <p class="titulo">
                        <strong>
                            Localiza
                        </strong>
                    </p>
                    <p>
                        El archivo en la
                    </p>
                    <p>
                        carpeta de <strong>Descargas.</strong>
                    </p>
                </div>
            </div>
            <div class="item dis-flex align-s">
                <div class="numero dis-flex justify-s">
                    3
                </div>
                <div class="contenido dis-flex flex-col align-s">
                    <p class="titulo">
                        <strong>
                            Utiliza los comandos<br>para localizar​
                        </strong>
                    </p>
                    <p>
                        Windows: CTRL + J​
                    </p>
                    <p>
                        Mac: Alt + Cmd + L​
                    </p>
                </div>
            </div>
            <div class="item dis-flex align-s">
                <div class="numero dis-flex justify-s">
                    4
                </div>
                <div class="contenido dis-flex flex-col align-s">
                    <p class="titulo">
                        <strong>
                            Selecciona
                        </strong>
                    </p>
                    <p>
                        Da clic sobre el archivo
                    </p>
                    <p>
                        para seleccionarlo.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-11 w-100 dis-flex space-b align-s">
            <img class="img-descargar" src="./assets/img/basededatos/descargar.gif" alt="descargar">
        </div>
    </div>
</div>

<!-- Descomprimir -->
<div class="descomprimir container-fluid bgAzul txBlanco py-5">
    <div class="row justify-content-center">
        <div class="col-11">
            <h3 class="font-medium">- Descomprimir -</h3>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-11">
            <div class="container-fluid">
                <div class="row h-100 dis-flex">
                    <div class="col-6 col-md-3">
                        <div class="item">
                            <p>
                                1. Sobre el archivo seleccionado, da clic derecho con el mouse e indica el tipo de extracción a realizar.​
                            </p>
                        </div>
                    </div>
                    <div class="col-6 col-md-3">
                        <div class="item">
                            <p>
                                Se abrirá una ventana mostrando el proceso de extracción. ​
                            </p>
                        </div>
                    </div>
                    <div class="col-6 col-md-3">
                        <div class="item">
                            <p>
                                2. Vuelve a dar clic derecho sobre el archivo zip que se extrajo y selecciona Extraer aquí​.
                            </p>
                        </div>
                    </div>
                    <div class="col-6 col-md-3 h-100 dis-flex">
                        <div class="item dis-flex h-100">
                            <p>
                                Se extraerá la carpeta contenida. ​
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div  class="row justify-content-center pt-5">
        <img class="img-descomprimir" src="./assets/img/basededatos/descomprimir.gif" alt="descomprimir">
    </div>
    <div class="row justify-content-center pt-5">
        <div class="col-8 col-md-6 text-center">
            <p>
                Software para descomprimir archivos zip:
            </p>
            <p>
                ​Winrar o 7-Zip (Windows) / Zipeg, Expander o RAR (Mac)
            </p>
            <p>
                <strong>
                    Winzip, no funciona​
                </strong>
            </p>
        </div>
    </div>
</div>


<!-- Abrir -->
<div class="descomprimir container-fluid py-5">
    <div class="row justify-content-center">
        <div class="col-11">
            <h3 class="font-medium">- Abrir -</h3>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-11">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-6 col-md-4">
                        <div class="item">
                            <p>
                                <strong>Da doble clic​</strong> sobre el archivo que quieras abrir. ​
                            </p>
                        </div>
                    </div>
                    <div class="col-6 col-md-4">
                        <div class="item">
                            <p>
                                <strong>Información de archivos “csv”​</strong><br>Se puede ver en programas de hoja de cálculo como: Excel, Access, Calc Open Office, Smartsheet, entre otros.​
                            </p>
                        </div>
                    </div>
                    <div class="col-6 col-md-4">
                        <div class="item">
                            <p>
                                <strong>Archivo LEEME.TXT​</strong><br>Abre con el bloc de notas en Windows o Notas en Mac.​
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-11">
            <img class="img-abrir" src="./assets/img/basededatos/abrir.gif" alt="abrir">
        </div>
    </div>
</div>