import { Component, OnInit } from '@angular/core';
import { ObtenerDatosService } from '../../../servicios/datos/obtener-datos/obtener-datos.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-pre-pie-pagina',
  templateUrl: './pre-pie-pagina.component.html',
  styleUrls: ['./pre-pie-pagina.component.scss']
})
export class PrePiePaginaComponent implements OnInit {

  config:any = {};

  constructor(public obtenerDatosService: ObtenerDatosService, private readonly meta: Meta) {
    this.obtenerDatosService.configSubject.subscribe(config => {
      this.config = config;
    });
  }

  ngOnInit(): void {
  }

  onChangeViewPortToDesktop(){
    this.meta.removeTag('name="viewport"');
    this.meta.addTag({ name: 'viewport', content: 'width=1300' })
  }

}
