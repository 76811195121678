<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 d-block d-xl-none" style="margin-bottom: 15px;">
                        <p class="px12 text-left"><strong>Visualiza como:</strong></p>
                        <div class="visualizar dis-flex flex-col text-center">
                            <div class="selector dis-flex w-100 cursorPointer" [ngClass]="{'brdr-selector': !themeService.isDarkTheme()}">
                                <div [routerLink]="['../']" class="izq dis-flex flex-col w-50 p-2">
                                    <img *ngIf="!themeService.isDarkTheme()"class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Grafica-A.svg">
                                    <img *ngIf="themeService.isDarkTheme()" class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Grafica-A-dark.svg">
                                    <!-- <i class="ine ine-mapa" aria-hidden="true"></i> -->
                                    <p>Gráfica</p>
                                </div>
                                <div class="activo dis-flex der flex-col w-50 p-2">
                                    <img *ngIf="!themeService.isDarkTheme()" class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Tarjetas-A.svg" style="opacity: 0.5;">
                                    <img *ngIf="themeService.isDarkTheme()" class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Tarjetas-B.svg" style="opacity: 0.5;">
                                    <!-- <i class="ine ine-tarjetas" aria-hidden="true"></i> -->
                                    <p>Tarjetas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-10">
                        <app-breadcrumb
                            subtitle="Circunscripción"
                            subtitle_detalle="Votos por Partido Político"
                            estado="Nacional"
                        ></app-breadcrumb>
                    </div>
                    <div class="col-2 d-none d-xl-block">
                        <p class="px12 text-left"><strong>Visualiza como:</strong></p>
                        <div class="visualizar dis-flex flex-col text-center">
                            <div
                                class="selector dis-flex w-100 cursorPointer">
                                <div [routerLink]="['../']" class="izq dis-flex flex-col w-50 p-2">
                                    <img *ngIf="!themeService.isDarkTheme()" class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Grafica-A.svg" style="opacity: 0.5;">
                                    <img *ngIf="themeService.isDarkTheme()" class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Grafica-A-dark.svg" style="opacity: 0.5;">
                                    <!-- <i class="far fa-chart-bar"></i> -->
                                    <p>Gráfica</p>
                                </div>
                                <div class="activo der dis-flex flex-col w-50 p-2">
                                    <img *ngIf="!themeService.isDarkTheme()" class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Tarjetas-A.svg">
                                    <img *ngIf="themeService.isDarkTheme()" class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Tarjetas-B.svg">
                                    <!-- <i class="ine ine-tarjetas" aria-hidden="true"></i> -->
                                    <p>Tarjetas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-resumen-votacion-mobile
                    [total_num]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.totalVotos?.total"
                    [total_prc]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.totalVotos?.porcentaje"
                ></app-resumen-votacion-mobile>
                <div class="row pb-3 mt-2">
                    <div class="col">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="row dis-flex pb-1">
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollLeft()">
                            <i class="fas fa-chevron-left" aria-hidden="true"></i>
                        </div>
                        <div class="col-11">
                            <div #widgetsContent class="row dis-flex justify-s scrollH">
                                <div *ngFor="let tarjeta of _obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.votos"
                                    class="col-6 col-sm-4 col-md-3 col-lg-2 px-1">
                                    <!-- <div class="card-resumen" [ngClass]="{cardGanador: tarjeta?.ganador}"> -->
                                    <div class="card-resumen">
                                        <div class="encabezado dis-flex w-100 py-2">
                                            <div class="dis-flex w-50">
                                                <div class="row w-80 dis-flex"
                                                    [ngClass]="{'justify-s': tarjeta?.partidos?.length > 1}">
                                                    <!-- <p class="pe p-0"
                                                        *ngFor="let partido of tarjeta.partidos"
                                                        [ngClass]="{'col-6 pePequeno': tarjeta?.partidos?.length > 1}">
                                                        {{ partido.idPartidos }}
                                                    </p> -->
                                                    <p class="pe p-0" [ngClass]="{'col-6 pePequeno': tarjeta?.partidos?.length > 1}">
                                                        <img class="img-partido" [src]="_obtenerDatosService.pathImg+_obtenerDatosService.diccionarioPartidos[tarjeta?.partido]?.imgPartido?.toLowerCase() " />
                                                    </p>
                                                </div>
                                            </div>
                                            <!-- <div class="separador"></div>
                                            <div class="distritos dis-flex w-50">
                                                <div class="dis-flex flex-col">
                                                    <p class="titulos">
                                                        Distritos
                                                    </p>
                                                    <p class="distrito font-medium w-100">
                                                        <strong>{{ tarjeta?.distritos }}</strong>
                                                    </p>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="cuerpo dis-flex bgGrisTenue p-2">
                                            <div class="w-50">
                                                <p>Votos</p>
                                                <p class="votos">
                                                    <strong>{{ tarjeta?.votos }}</strong>
                                                </p>
                                            </div>
                                            <div class="w-50 dis-flex flex-col align-e pr-3 pr-xl-0">
                                                <p>Porcentaje</p>
                                                <p>{{ tarjeta?.porcentaje }}%</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollRight()">
                            <i class="fas fa-chevron-right" aria-hidden="true"></i>
                        </div>
                    </div>
               </div>
                <div class="container-fluid">
                    <div class="row dis-flex">
                        <div class="col-md-12">
                            <div class="row dis-flex justify-s">
                                <!-- <app-ppyci-circunscripcion-grafica></app-ppyci-circunscripcion-grafica> -->
                            </div>
                        </div>
                    </div>
                </div>

                <app-resumen-votacion
                    [acumulados_num]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.acumulados?.total"
                    [acumulados_prc]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.acumulados?.porcentaje"
                    [noregistradas_num]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.noRegistradas?.total"
                    [noregistradas_prc]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.noRegistradas?.porcentaje"
                    [nulos_num]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.nulos?.total"
                    [nulos_prc]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.nulos?.porcentaje"
                    [total_num]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.totalVotos?.total"
                    [total_prc]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.totalVotos?.porcentaje"
                ></app-resumen-votacion>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card p-3 mt-3 mapa-puntos">
                        <div class="row justify-content-center">
                            <div class="col-4 text-center">
                                <i class="fas fa-globe-americas"></i>
                                <h4 class="subtitulo-seccion py-3 text-center">Diputación migrante</h4>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-4 dis-flex">
                                <button
                                    [routerLink]="['/'+_globalService.detalles.circunscripcion+'/votos-ppyci/representacion-proporcional']"
                                    class="btn btn-sm btn-rosaFuerte texto cursorPointer my-3 px-4 py-1">
                                    Ver Resultados
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card p-3 mt-3">
                        <div class="row justify-content-center">
                            <div class="col-4">
                                <h4 class="subtitulo-seccion py-3 text-center">
                                    <strong>Representación Proporcional</strong>
                                </h4>
                                <p class="texto">
                                    El apartado "Actas RP" muestra la cantidad de votos asentada en las Actas PREP de Casilla Especial de Representación Proporcional.
                                </p>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-4 dis-flex">
                                <button [routerLink]="['/'+_globalService.detalles.circunscripcion+'/votos-ppyci/representacion-proporcional']"
                                class="btn btn-sm btn-rosaFuerte texto cursorPointer my-3 px-4 py-1">
                                    Ver Actas RP
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-estadistica-nacional></app-estadistica-nacional>
