import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { ThemeService } from 'src/app/theme/theme.service';

// import * as moment from 'moment';
// import 'moment/locale/pt-br';
import { RutasService } from '../../../servicios/rutas/rutas.service';

@Component({
  selector: 'app-encabezado',
  templateUrl: './encabezado.component.html',
  styleUrls: ['./encabezado.component.scss']
})
export class EncabezadoComponent implements OnInit {

  selectedContienda: any = '';
  datosContienda: any = {};
  themeDark: any = false;
  fechaContienda: any = '';
  horaContienda: any = '';
  _fontSize: number = 14;
  utc: any = '';

  constructor(
    public router: Router,
    public globalService: GlobalesService,
    public datosService: ObtenerDatosService,
    public themeService: ThemeService,
    private readonly rutasService: RutasService
  ) { }

  datosAvance: any = [];
  isSticky: boolean = false;

  objConfig: any = {};

  ngOnInit(): void {
    this.globalService.momentjs.locale('es-mx');

    this.datosService.mainSubject.subscribe(data =>{
      if (data != null) {
        this.selectedContienda = this.globalService.getContienda();
        if (this.selectedContienda) {
          this.datosContienda = data[this.selectedContienda];
          let arrFechaVersion = this.datosContienda?.fecha_version?.split(' ');
          let strFechaVersion = (arrFechaVersion && arrFechaVersion[0] ? (arrFechaVersion[0] + ' ' + arrFechaVersion[1]) : '');
          this.fechaContienda = (strFechaVersion ? this.globalService.momentjs(strFechaVersion).format('D MMMM YYYY') : '');
          this.horaContienda = (strFechaVersion ? this.globalService.momentjs(strFechaVersion).format('HH:MM') : '');
          this.utc = (arrFechaVersion && arrFechaVersion[2] ? arrFechaVersion[2].replace('(', '').replace(')', '') : '');
        }
      }
    });

    this.loadEtiquetas();
  }

  loadEtiquetas() {
    this.datosService.configSubject.subscribe(data =>{
      if (data != null) this.objConfig = data;
    });
  }

  irInicio() {
    this.router.navigateByUrl('/');
    this.globalService.menuActual = 'Votos';
    this.globalService.idCircuns = null;
  }

  toggleMenu (show) {
    this.globalService.toggleMenuResponsive(show);
  }

  changeContiendaActiva(contienda) {
    this.datosService.changeContiendaSubject.next(contienda);
    this.globalService.changeContiendaActiva(contienda);

    let router = this.router;
    setTimeout(function() {router.navigateByUrl(`/${contienda}`)}, 100)
  }

  changeFontSize(value){
//      console.log(value);
      this.globalService.fontSize = value
      localStorage.setItem('fontSize', String(value))
  }

  actualizar() {

    window.location.reload();
  }

  toggleTheme() {
    // console.log('aqui');
    // this.themeService.setDarkTheme();
    if (this.themeService.isDarkTheme()) this.themeService.setLightTheme();
    else this.themeService.setDarkTheme();
  }

}
