<div class="menu-responsive">
	<div class="menu-header">
		Menú
		<i (click)="globalService.toggleMenuResponsive(false)" class="fa fa-times"></i>
	</div>
	<div class="menu-body">
		<div class="menu-opt">
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMenuReponsive" aria-controls="navbarMenuReponsive" aria-expanded="false" aria-label="Toggle navigation">
				Tipo de elección &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-chevron-down"></i><i class="fa fa-chevron-up"></i>
			</button>
			<div class="collapse" id="navbarMenuReponsive">
				<div (click)="changeContiendaActiva(contienda.contienda)" *ngFor="let contienda of globalService.arrContiendasActivas" class="menu-list-item" [ngClass]="{'menu-active': contienda.active}">
					<p><i class="fa fa-square-full"></i> {{contienda.contienda | titlecase}}</p>
				</div>
			</div>
		</div>
        <div (click)="onMiCasilla()" class="menu-opt">
			Mi Sección
		</div>
		<div (click)="onNavigate('preguntas-frecuentes')" class="menu-opt">
			Preguntas Frecuentes
		</div>
		<div (click)="onCentroAyuda()" class="menu-opt">
			Centro de ayuda
		</div>
		<div class="menu-opt bb-0">
			Tema:

			<div class="themeOptMobile d-flex flex-row">
				<div class="type-container">
					<p>Claro</p>
					<div class="d-flex flex-column align-items-center justify-content-center type light" (click)="toggleTheme('light')" [ngClass]="{'active': !themeService.isDarkTheme()}">
						<img src="./assets/img/switch-mode/light-menu.svg" alt="light">
					</div>
				</div>
				<div class="type-container">
					<p>Oscuro</p>
					<div class="d-flex flex-column align-items-center justify-content-center type dark" (click)="toggleTheme('dark')" [ngClass]="{'active': themeService.isDarkTheme()}">
						<img src="./assets/img/switch-mode/night-menu.svg" alt="dark">
					</div>
				</div>
			</div>
		</div>

		<div class="menu-opt bb-0 d-none">
			Tamaño de caracter:
			<div class="d-flex flex-row justify-content-center font-slider">
				<span (click)="changeFontSize('minus')">
					<i class="fas fa-minus"></i>
				</span>
				<p>{{ (globalService.fontSize * 10) }} %</p>
				<span (click)="changeFontSize('plus')">
					<i class="fas fa-plus"></i>
				</span>
			</div>
		</div>
	</div>

	<form #FormCasillaMob="ngForm" (ngSubmit)="buscaCasilla(FormCasillaMob)" class="menu-body micasilla d-none">
		<div class="title-casilla">
			<i (click)="onReturnCasilla()" class="fas fa-arrow-left"></i> Mi Sección
		</div>

		<div class="casilla-content">
			<h1>Conoce los resultados de tu sección</h1>

			<p *ngIf="datosService?.config?.estado == 'Veracruz'">Para conocer los resultados de tu sección, ingresa los siguientes datos.</p>
			<p *ngIf="datosService?.config?.estado == 'Oaxaca'">Para conocer los resultados, ingresa el número de tu sección.</p>
			<p class="asterisco">* Campo obligatorio</p>

			<div class="row">
				<!-- <div class="col-6">
					<div class="form-group">
						<label class="m-0" for="primerApellido">Primer apellido</label>
						<div class="input-group df-sw">
							<div class="dropdown w-100 bgBlanco">
								<input type="text" class="form-control" 
								id="apellidoCasilla" name="apellidoCasilla" ngModel required #apellidoCasilla="ngModel" [ngClass]="{'no-border': !themeService.isDarkTheme()}" apellido>
							</div>
						</div>
					</div>
				</div> -->

				<div class="col-12">
					<div class="form-group m-0">
						<label class="m-0" for="seccion">Sección *</label>
						<div class="input-group df-sw">
							<!-- <div class="dropdown w-100 bgBlanco">
								<select class="form-control select-entidad" 
								id="seccionCasilla" name="seccionCasilla" ngModel required #seccionCasilla="ngModel" [ngClass]="{'no-border': !themeService.isDarkTheme()}" seccion>
									<option></option>
									<option [ngValue]="itemSeccion.param" *ngFor="let itemSeccion of arrSecciones">
										{{ itemSeccion.text }}
									</option>
								</select>
							</div> -->
							<div class="dropdown w-100 bgBlanco">
                                <input type="number" class="form-control" min="0" min="0" (change)="onChangeEvent($event)" pattern="[0-9]*" inputmode="numeric" maxlength="6"
                                id="seccion" name="seccionCasilla" ngModel required #seccionCasilla="ngModel" [ngClass]="{'no-border': !themeService.isDarkTheme()}" seccion>
                            </div>
						</div>
					</div>
				</div>
			</div>

			<div class="info-content">
				<!-- <p>Identifica el <b>número de sección</b> y tu <b>primer apellido</b> de acuerdo con el modelo de tu credencial.</p> -->
				<p>Identifica el <b>número de sección</b> de acuerdo con el modelo de tu credencial.</p>

				<img src="./assets/img/resultadoscasilla/credencial-a.png" alt="credencial">

				<button type="submit" class="btn-consulta-casilla">
					Consulta
				</button>

				<!-- <p class="aviso">Aviso de privacidad: El uso de los datos que nos proporciones es exclusivo para visualizar el detalle de votación de tu casilla, no quedarán guardados en ninguna base de datos.</p> -->
			</div>
		</div>
	</form>
</div>