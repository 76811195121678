<div class="container-fluid estadisticaNacional py-3" [ngClass]='{"ContDip": _globalService.getContienda() == "diputaciones"}'>
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="bgPrototipo">
                    <div class="padding-b-30">
                        <h4 class="subtitulo-seccion pleca-izquierda d-none d-xl-block">
                            <!-- {{_globalService.rutaParametros.nivelPagina}} -->
                            <strong>Estadística {{(_obtenerDatosService?.config?.estado == 'Veracruz' || _obtenerDatosService?.config?.estado == 'Oaxaca') ? (_rutasService.migas.distrito ? 'Municipal' : 'Entidad') : 'Distrital'}}
                                <!-- {{ tipo_estadistica ? ( (_globalService.getContienda() == 'ayuntamientos' ? 'Municipio ' : (_globalService.getContienda() == 'juntas' ? 'Junta ' : (_obtenerDatosService?.config?.estado == 'Baja California' ? '' : 'Distrito '))) + tipo_estadistica ) : 'Entidad' }} -->
                            </strong>
                        </h4>

                        <h4 class="subtitulo-seccion pleca-izquierda d-xl-none">
                            <!-- {{_globalService.rutaParametros.nivelPagina}} -->
                            <strong>Estadística {{(_obtenerDatosService?.config?.estado == 'Veracruz' || _obtenerDatosService?.config?.estado == 'Oaxaca') ? (_rutasService.migas.distrito ? _rutasService.migas.distrito : 'Entidad') : 'Distrital'}}
                                <!-- {{ tipo_estadistica ? ( (_globalService.getContienda() == 'ayuntamientos' ? 'Municipio ' : (_globalService.getContienda() == 'juntas' ? 'Junta ' : (_obtenerDatosService?.config?.estado == 'Baja California' ? '' : 'Distrito '))) + tipo_estadistica ) : 'Entidad' }} -->
                            </strong>
                        </h4>

                    </div>
                    <div class="row dis-flex space-a">
                        <div class="col-3 d-none d-xl-block">
                            <p class="titulo pb-3" style="text-decoration: underline;">
                                <strong>Actas {{_globalService.getContienda() == 'ayuntamientos' && config?.estado != 'Veracruz' ? 'del Municipio' : ''}}
                                    <!-- {{ short_tpl ? ( _globalService.getContienda() == 'juntas' ? 'por Junta' : 'del ' + (_globalService.getContienda() == 'ayuntamientos' ? 'Municipio' : (_obtenerDatosService?.config?.estado == 'Baja California' ? '' : 'Distrito')) ) : ''}} -->
                                </strong>
                            </p>
                        </div>
                        <div class="col-4 d-none d-xl-block">
                            <p class="titulo pb-3">
<!--                                <strong>Lista Nominal {{_rutasService.migas.distrito_clave ? 'de Actas Contabilizadas' : ''}}</strong>-->
                                <strong>Lista Nominal {{(_rutasService.migas.distrito_clave && _globalService.getContienda() != 'ayuntamientos') ? 'de Actas Contabilizadas' : ''}}</strong>
                            </p>
                        </div>
                        <div class="col-4 d-none d-xl-block">
                            <p class="titulo p-3">
                                <strong>Participación ciudadana con base en la Lista Nominal de las Actas PREP Contabilizadas</strong>
                            </p>
                        </div>
                    </div>
                    <div class="row dis-flex space-a align-e align-c"
                        [ngClass]="{'align-c': short_tpl}">
                        <div class="col-12 col-sm-6 col-xl-3 pb-sm-5">
                            <div class="titulo-actas dis-flex justify-s d-xl-none">
                                <p class="titulo pb-3" style="text-decoration: underline;">
                                    <strong>Actas {{ short_tpl ? ('del ' + (_globalService.getContienda() == 'ayuntamientos' ? 'Municipio' : (_globalService.getContienda() == 'juntas' ? 'Junta' : (_obtenerDatosService?.config?.estado == 'Baja California' ? '' : 'Distrito')))) : ''}}</strong>
                                </p>
                            </div>
                            <div class="row dis-flex space-a">
                                <div class="col-4 col-lg-2 col-xl-4 dis-flex flex-col">
                                    <div class="grafica">
                                        <div class="progreso bgGrafica1" [ngStyle]="{'height':  (short_tpl ? short_data?.prow?.c1 : datosEstadistica?.grafica_estadistica?.esperadas?.porcentaje) }"></div>
                                    </div>
                                    <p class="px16 pt-1">
                                        <strong class="">
                                            {{ short_tpl ? short_data?.trow?.c1 : datosEstadistica?.grafica_estadistica?.esperadas?.monto }}
                                        </strong>
                                    </p>
                                    <p class="px14">
                                        <span class="">{{ short_tpl ? short_data?.prow?.c1 : datosEstadistica?.grafica_estadistica?.esperadas?.porcentaje }}</span>
                                        <!-- <span class="d-block d-xl-none">{{ short_tpl ? short_data?.prow?.c1 : datosEstadistica?.grafica_estadistica?.esperadas?.monto }}</span> -->
                                    </p>
                                    <p class="px12">Esperadas</p>
                                </div>
                                <div class="col-4 col-lg-2 col-xl-4 dis-flex flex-col">
                                    <div class="grafica">
                                        <div class="progreso bgGrafica2" [ngStyle]="{'height':  (short_tpl ? short_data?.prow?.c2 : datosEstadistica?.grafica_estadistica?.capturadas?.porcentaje) }"></div>
                                    </div>
                                    <p class="px16 pt-1">
                                        <strong class="">
                                            {{ short_tpl ? short_data?.trow?.c2 : datosEstadistica?.grafica_estadistica?.capturadas?.monto }}
                                        </strong>
                                        <!-- <strong class="d-block d-xl-none">
                                            {{ short_tpl ? short_data?.trow?.c2 : datosEstadistica?.grafica_estadistica?.capturadas?.porcentaje }}
                                        </strong> -->
                                    </p>
                                    <p class="px14">
                                        <span class="">{{ short_tpl ? short_data?.prow?.c2 : datosEstadistica?.grafica_estadistica?.capturadas?.porcentaje }}</span>
                                        <!-- <span class="d-block d-xl-none">{{ short_tpl ? short_data?.prow?.c2 : datosEstadistica?.grafica_estadistica?.capturadas?.monto }}</span> -->
                                    </p>
                                    <p class="px12">Capturadas</p>
                                </div>
                                <div class="col-4 col-lg-2 col-xl-4 dis-flex flex-col">
                                    <div class="grafica">
                                        <div class="progreso bgGrafica3" [ngStyle]="{'height':  (short_tpl ? short_data?.prow?.c3 : datosEstadistica?.grafica_estadistica?.contabilizadas?.porcentaje) }"></div>
                                    </div>
                                    <p class="px16 pt-1">
                                        <strong class="">
                                            {{ short_tpl ? short_data?.trow?.c3 : datosEstadistica?.grafica_estadistica?.contabilizadas?.monto }}
                                        </strong>
                                        <!-- <strong class="d-block d-xl-none">
                                            {{ short_tpl ? short_data?.trow?.c3 : datosEstadistica?.grafica_estadistica?.contabilizadas?.porcentaje }}
                                        </strong> -->
                                    </p>
                                    <p class="px14">
                                        <span class="">{{ short_tpl ? short_data?.prow?.c3 : datosEstadistica?.grafica_estadistica?.contabilizadas?.porcentaje }}</span>
                                        <!-- <span class="d-block d-xl-none">{{ short_tpl ? short_data?.prow?.c3 : datosEstadistica?.grafica_estadistica?.contabilizadas?.monto }}</span> -->
                                    </p>
                                    <p class="px12">Contabilizadas</p>
                                </div>
                            </div>

                            <div class="row pt-4 justify-s" *ngIf="!short_tpl">
                                <div class="col-12 separator-h"></div>
                            </div>

                            <div class="row pt-4 justify-s" *ngIf="!short_tpl">
                                <div class="col-12 titulo-actas">
                                    <p class="titulo pb-3">
                                        <strong>Actas Contabilizadas por ubicación de casilla:</strong>
                                    </p>
                                </div>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-6 dis-flex justify-e">
                                            <img *ngIf="!themeService.isDarkTheme()" class="img-fluid grafico izquierdo" src="assets/img/estadistica/Ciudad.svg" alt="Urbanas">
                                            <img *ngIf="themeService.isDarkTheme()" class="img-fluid grafico izquierdo" src="assets/img/estadistica/CiudadDark.svg" alt="Urbanas">
                                        </div>
                                        <div class="col-6 dis-flex justify-s">
                                            <img *ngIf="!themeService.isDarkTheme()" class="img-fluid grafico derecho" src="assets/img/estadistica/Nourbanos.svg" alt="Urbanas">
                                            <img *ngIf="themeService.isDarkTheme()" class="img-fluid grafico derecho" src="assets/img/estadistica/NourbanosDark.svg" alt="Urbanas">
                                        </div>
                                    </div>
                                    <div class="row justify-content-center txBlanco text-center">
                                        <div class="col-6 pr-0 separador">
                                            <div class="rectangulo izquierdo dis-flex flex-col py-2">
                                                <p class="numero">
                                                    {{ datosEstadistica?.grafica_estadistica?.urbanas }}
                                                </p>
                                                <p class="titulo-rec">
                                                    Urbanas
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-6 pl-0">
                                            <div class="rectangulo derecho dis-flex flex-col py-2">
                                                <p class="numero">
                                                    {{ datosEstadistica?.grafica_estadistica?.no_urbanas }}
                                                </p>
                                                <p class="titulo-rec">
                                                    No Urbanas
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-100 d-xl-none"></div>
                        <div class="col-12 col-sm-6 col-xl-4 py-0 mobMaTop txtResEstadistica">
                            <p class="titulo pb-3 d-xl-none">
                                <strong>Lista Nominal de Actas Contabilizadas</strong>
                            </p>
                            <!-- <ng-container *ngIf="!short_tpl"> -->
                            <ng-container *ngIf="!short_tpl">
                                <p class="px12 mb-3">
                                    El <strong style="color: var(--txt-nb);">número de personas</strong> registrado en la Lista Nominal de las Actas Contabilizadas se utiliza para obtener el porcentaje de Participación ciudadana calculando:
                                </p>
                                <p class="px12 text-center">
                                    % de Participación Ciudadana = Total de votos de las Actas contabilizadas, por 100, dividido entre la Lista Nominal de Actas contabilizadas.
                                </p>
                            </ng-container>
                            <!-- <p *ngIf="!short_tpl"> -->
                            <p>
                                <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions" style="width: 100%; height: 300px; display: block;"></highcharts-chart>
                            </p>
                            <div *ngIf="!short_tpl"></div>
                            <ng-container *ngIf="!short_tpl && selectedContienda == 'diputaciones'">
                                <p class="px12">
                                    En el gráfico {{_obtenerDatosService?.main?.texto_porcentaje_estadistica ? 'se incluye' : 'no se considera'}} la cantidad de votos asentada en las Actas PREP de Casilla Especial de Representación Proporcional.
                                </p>
                            </ng-container>

                            <!-- <ng-container *ngIf="short_tpl">
                                <p class="total-numero" style="text-align: center;">
                                    <strong>
                                        {{ short_tpl ? short_data?.trow?.total : actasOficial }}
                                    </strong>
                                </p>

                                <p class="px12" style="text-align: center;"> -->
                                    <!-- Mantener comanetada {{ selectedContienda == 'ayuntamientos' || selectedContienda == 'juntas' ? 'Lista Nominal' : 'Lista Nominal de Actas Contabilizadas' }} -->
                                    <!-- Liconsole.log(this.short_data)
            console.log(this.dataGraficaInicial)
            this.pintaGrafica(this.dataGraficaInicial)sta Nominal de Actas Contabilizadas -->
                                <!-- </p>
                            </ng-container> -->


                        </div>
                        <div class="w-100 d-xl-none"></div>
                        <div class="col-12 col-xl-4 pb-sm-5">
                            <p class="titulo pb-3 d-xl-none">
                                <strong>Participación ciudadana con base en la Lista Nominal de las Actas PREP Contabilizadas</strong>
                            </p>
                            <p class="texto text-center font-medium" *ngIf="short_tpl">
                                El porcentaje de participación ciudadana se obtiene al multiplicar los Votos por 100 y dividir el resultado de esta operación entre el número de personas registrado en la Lista Nominal de Actas Contabilizadas.
                            </p>
                            <p class="texto text-center d-block d-sm-none" *ngIf="short_tpl">
                                <br>
                                <strong>Porcentaje</strong>
                            </p>
                            <p class="px38 font-medium text-center" style="color: var(--txt-rb);">
                                {{ short_tpl ? short_data?.prow?.participacion : datosEstadistica?.estadistica_participacion?.actas?.porcentaje }}
                            </p>

                            <ng-container>
                                <p class="texto text-center" style="color: var(--txt-estadistica);" *ngIf="!config?.hide_especiales">
                                    Porcentaje calculado <strong style="color: var(--txt-nb);">{{_obtenerDatosService?.main?.texto_porcentaje_estadistica ? 'incluyendo' : 'sin considerar'}}</strong> la votación en <strong style="color: var(--txt-nb);">Casillas Especiales</strong>
                                </p>
                                <br *ngIf="!config?.hide_especiales">
                                <p class="texto text-center" *ngIf="short_tpl">
                                    (Total de votos de las Actas Contabilizadas/ Lista Nominal) * 100 = % de Participación Ciudadana.
                                </p>
                            </ng-container>

                            <ng-container *ngIf="!short_tpl">
                                <div class="row mt-4 px-0 mx-0 bgGris">
                                    <div class="col-md-9">
                                        <p class="px12 font-medium" [innerHTML]="casilla1_txt">
                                        </p>
                                    </div>
                                    <div class="col-md-3 dis-flex justify-e align-e">
                                        <p class="px16">
                                            <strong>
                                                {{ datosEstadistica?.estadistica_participacion?.casillas?.basica_contigua_extraordinaria }}
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                                <div class="row pt-1">
                                    <div class="col-md-12">
                                        <div class="graficaHorizontal">
                                            <div class="progresoHorizontal" [style.width.%]="datosEstadistica?.estadistica_participacion?.casillas?.bce_avance"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row px-0 mx-0 mt-2 bgGris" *ngIf="!config?.hide_especiales"
                                    [ngClass]="{'apagado': !datosEstadistica?.ultimoCorte}">
                                    <div class="col-md-9">
                                        <p class="px12 font-medium">
                                            Votos en Casillas <br>Especiales {{ selectedContienda == 'diputaciones' ? 'MR' : '' }}
                                        </p>
                                    </div>
                                    <div class="col-md-3 dis-flex justify-e align-e">
                                        <p class="px16">
                                            <strong>
                                                {{ datosEstadistica?.estadistica_participacion?.casillas?.especial }}
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                                <div class="row pt-1" *ngIf="!config?.hide_especiales"
                                [ngClass]="{'apagado': !datosEstadistica?.ultimoCorte}">
                                    <div class="col-md-12">
                                        <div class="graficaHorizontal">
                                            <div class="progresoHorizontal" [style.width.%]="datosEstadistica?.estadistica_participacion?.casillas?.especial_avance"></div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row px-0 mx-0 mt-2 bgGris" *ngIf="selectedContienda == 'diputaciones'"
                                [ngClass]="{'apagado': !datosEstadistica?.ultimoCorte}">
                                    <div class="col-md-9">
                                        <p class="px12 font-medium">
                                            Votos en Casillas <br>Especiales RP
                                        </p>
                                    </div>
                                    <div class="col-md-3 dis-flex justify-e align-e">
                                        <p class="px16">
                                            <strong>
                                                {{ datosEstadistica?.estadistica_participacion?.casillas?.especial_rp || 0  }}
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                                <div class="row pt-1" *ngIf="selectedContienda == 'diputaciones'"
                                [ngClass]="{'apagado': !datosEstadistica?.ultimoCorte}">
                                    <div class="col-md-12">
                                        <div class="graficaHorizontal">
                                            <div class="progresoHorizontal" [style.width.%]="datosEstadistica?.estadistica_participacion?.casillas?.especial_rp_avance"></div>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="px11 padding-t-5 txGris2">
                                    En esta tabla se considera la cantidad de votos asentada en las Actas PREP de Casilla Especial de Representación Proporcional.
                                </div> -->
                                <div class="dis-flex space-b pt-4">
                                    <p class="total-texto">
                                        <strong>Total<br>de Votos</strong>
                                    </p>
                                    <p class="total-numero">
                                        <strong>
                                            {{ datosEstadistica?.estadistica_participacion?.casillas?.total }}
                                        </strong>
                                    </p>
                                </div>

                            </ng-container>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
