import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { VotosPpyciGraficaComponent } from './votos-ppyci/votos-ppyci-grafica.component';
import { VotosPpyciTarjetasComponent } from './votos-ppyci/votos-ppyci-tarjetas.component';

import { PpyciDistritoComponent } from '../diputacion/votos-partido/componentes/ppyci-distrito/ppyci-distrito.component';
import { VotosExtranjeroComponent } from './votos-extranjero/votos-extranjero.component';
// import { DistritoEntidadDetalleCasillaComponent } from '../diputacion/votos-distrito/componentes/distrito-entidad-detalle-casilla/distrito-entidad-detalle-casilla.component';

import { ContiendasComponent } from '../contiendas.component';
import { AuthGuard } from '../../auth/auth.guard';

const routes: Routes = [
  {
    path: 'gubernatura',
    component: ContiendasComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'votos-ppyci',
        children: [
          {
            path: '',
            redirectTo: 'grafica',
            pathMatch: 'full'
          },
          {
            path: 'grafica',
            component: VotosPpyciGraficaComponent,
          },
          {
            path: 'tarjetas',
            component: VotosPpyciTarjetasComponent,
          },
          {
            path: 'voto-extranjero',
            component: VotosExtranjeroComponent,
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: ':distrito/votos-ppyci',
        component: PpyciDistritoComponent,
        data : { contienda : 'Distrito', contienda_s : 'Distrito' }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GubernaturaPartidoRoutingModule { }
