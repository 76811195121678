import { Component, OnInit } from '@angular/core';
import { ObtenerDatosService } from '../../../servicios/datos/obtener-datos/obtener-datos.service';
import { ThemeService } from 'src/app/theme/theme.service';

declare var $: any;

@Component({
  selector: 'app-pie-pagina',
  templateUrl: './pie-pagina.component.html',
  styleUrls: ['./pie-pagina.component.scss']
})
export class PiePaginaComponent implements OnInit {

  config:any = {};

  constructor(public obtenerDatosService: ObtenerDatosService,public themeService: ThemeService) {
    this.obtenerDatosService.configSubject.subscribe(config => {
      this.config = config;
    });
  }

  ngOnInit(): void {
  }

  openModal() {
		$('#modalAvisoPrivacidad').modal('show');
	}
}
