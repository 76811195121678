import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { ComparativaService } from 'src/app/servicios/comparativa/comparativa.service';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { RutasService } from '../../../servicios/rutas/rutas.service';

@Component({
  selector: 'app-distritos-votos-candidatura',
  templateUrl: './distrito-votos-candidatura.component.html',
  styleUrls: ['./distrito-votos-candidatura.component.scss']
})

export class DistritoVotosCandidaturaComponent implements OnInit {
//  resumen_table: any;
  resumen_votacion: any;
  distribucion_table: [];
  fichas: any = [];
  resumen: any = {};
  short_data: any = {};

  constructor(
    public _globalService: GlobalesService,
    public _obtenerDatosService: ObtenerDatosService,
    public rutas: RutasService,
    public comparativaService: ComparativaService
  ) {}

  ngOnInit(): void {
    this.comparativaService.stageStatus = false;
    this.comparativaService.pantalla = 'distritoPPyCI';
    this.comparativaService.resetear();
    this._obtenerDatosService.mainSubject.subscribe(data =>{
      if(data == null) return;

      this.loadData();
    });
    
    this._globalService.bottomSheetContex = this;
    this._globalService.bottomSheetCallback = this.agregarElemento;
  }

  loadData() {
    let selectedContienda = this._globalService.getContienda();
    // if (this._obtenerDatosService.contiendas[selectedContienda] && this._obtenerDatosService.contiendas[selectedContienda]['detalle.distritos.json']){
    //   this.data = this._obtenerDatosService.contiendas[selectedContienda]['detalle.distritos.json'].diputaciones_table.rows;
    // }else {
      this._obtenerDatosService.loadJSON(selectedContienda, 'detalle.'+this._globalService.param_distrito+'.candidatura.json').then(data => {
//        this.resumen_table = data['resumen_table'].columns;
          this.resumen = data['resumen'];
          this.fichas = data['fichas'];
          this._obtenerDatosService.desglose = data['distribucion_table'].rows;
          this._globalService.bottomSheetTpl = 'comparador-contienda';
          
          this.comparativaService.votos_total = this.resumen['total'].total;
          this.comparativaService.votos_pctj = this.resumen['total'].prc;
//          this.comparativaService.votos_pctj = (this.resumen['total'].prc.toString().includes('%'))? this.resumen['total'].prc : this.resumen['total'].prc+'%' ;

          this.resumen_votacion = {
              votos_acumulados : { votos: 0, pctj: 0 },
              votos_no_registrados : { votos: 0, pctj: 0 },
              votos_nulos : { votos: 0, pctj: 0 },
              votos_total : { votos: 0, pctj: 0 },
          };

//          this.resumen_table.forEach(item => {
//              if(item.header.text == 'Candidaturas no registradas'){
//                  this.resumen_votacion.votos_no_registrados.votos += parseInt(item.data.r1.replace(/,/g, ''));
//              }else if(item.header.text == 'Votos nulos'){
//                  this.resumen_votacion.votos_nulos.votos += parseInt(item.data.r1.replace(/,/g, ''));
//              }else if(item.header.text == 'Total'){
//                  this.resumen_votacion.votos_total.votos += parseInt(item.data.r1.replace(/,/g, ''));
//              }else{
//                  this.resumen_votacion.votos_acumulados.votos += parseInt(item.data.r1.replace(/,/g, ''));
//              }
//          });

        // data['diputaciones_table'].rows.forEach(distrito => {
        //   this.data.push({
        //     "id": distrito.param,
        //     "claveURL": distrito.param,
        //     "migas": distrito.text,
        //   });
        // });
      });

      this._obtenerDatosService.loadJSON(selectedContienda, 'detalle.' + this._globalService.param_distrito + '.participacion.json').then(data => {
        this.short_data = (data && data['actas_participacion'] ? data['actas_participacion']['head_info'] : {});
      });
    // }
  }

  agregarElemento(el: any) {
      //Fix del contexto de la variable 
      let scope = (this instanceof GlobalesService)? this["bottomSheetContex"] : this;
      if (scope.comparativaService.arregloElementos.length < 3) {
          scope.comparativaService.agregarElemento(el);
          el.seleccionado = true;
      }
      if(this instanceof GlobalesService && scope.comparativaService.arregloElementos.length >= 3){
          this.toggleBottomSheet(false);
      }
      if (!scope.comparativaService.stageStatus) {
          scope.comparativaService.stageStatus = true;
          scope.comparativaService.guardarStage(scope.fichas);
      }
  }

  eliminarElemento(el: any) {
    this.comparativaService.eliminarElemento(el);
    el.seleccionado = false;
  }

  selectedItemFun = (el: any): void => {
    this.agregarElemento(el);
  }

  diselectedItemFun = (el: any): void => {
    this.eliminarElemento(el);
  }

  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

}

