import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseDatosInicioComponent } from '../componentes/paginas/base-datos/base-datos-inicio/base-datos-inicio.component';
import { AuthGuard } from '../auth/auth.guard';
import { AccesibilidadComponent } from '../componentes/menu/accesibilidad/accesibilidad.component';
import { PreguntaInicioComponent } from '../componentes/paginas/preguntas-frecuentes/pregunta-inicio/pregunta-inicio.component';
import { InicioComponent } from '../auth/inicio/inicio.component';
import { ContiendasComponent } from './contiendas.component';
import data from '../../assets/json/main.json';

let routes_principal = [];


let auxContiendas = false,
    arrContiendas: any = [
      'gubernatura',
      'diputaciones',
      'ayuntamientos',
      'juntas',
    ],
    url = '';
for (const contienda in data) {
  if (arrContiendas.indexOf(contienda) >= 0) {
    if (auxContiendas == false) {
      auxContiendas = true;

      switch (contienda) {
        case 'gubernatura':
          url = 'gubernatura/votos-candidatura/grafica';
          break;

        case 'diputaciones':
          url = 'diputaciones/votos-distrito/mapa';
          break;

        case 'ayuntamientos':
          url = 'ayuntamientos/votos-municipio/mapa';
          break;

        case 'juntas':
          url = 'juntas/votos-municipio/mapa';
          break;
      
        default:
          break;
      }
    }
  }
  else {}
}

if (url != '') {
  routes_principal.push({
    path: '',
    redirectTo: url,
    pathMatch: 'full'
  });
  // routes_principal.push({
  //   path: '**',
  //   redirectTo: url,
  // });

}

routes_principal.push(
  {
    path: '',
    component: ContiendasComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'base-de-datos',
        component: BaseDatosInicioComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'accesibilidad',
        component: AccesibilidadComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'preguntas-frecuentes',
        component: PreguntaInicioComponent,
        canActivate: [AuthGuard]
      },
    ]
  });


const routes: Routes = routes_principal;


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class ContiendasRoutingModule { }
