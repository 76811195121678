import { Component, OnInit } from '@angular/core';
import { ComparativaService } from 'src/app/servicios/comparativa/comparativa.service';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';
import { CommonModule } from '@angular/common';
import { Chart, MapChart} from 'angular-highcharts';
import { ThemeService } from 'src/app/theme/theme.service';


@Component({
  selector: 'app-comparativa',
  templateUrl: './comparativa.component.html',
  styleUrls: ['./comparativa.component.scss']
})
export class ComparativaComponent implements OnInit {

    public mapa_distritoCandidatura: MapChart;
    public mapa_resumenDistrito: MapChart;
    public mapa: MapChart;
    
    modalFull = false;
    isOpenCombo = false;
    
    constructor(
        public comparativaService: ComparativaService,
        public obtenerDatosService: ObtenerDatosService,
        public globalService: GlobalesService,
        public rutas: RutasService,
        public themeService: ThemeService,
    ) { }

    ngOnInit(): void {
        this.comparativaService.observable.subscribe(() => {
            let selectedContienda = this.globalService.getContienda();
//            console.log(this.comparativaService.pantalla);
            if(this.comparativaService.pantalla == 'resumenDistrito' || (this.comparativaService.pantalla == 'distritoCandidatura' && selectedContienda != 'gubernatura')){
                
                this.obtenerDatosService.loadJSON(selectedContienda, (selectedContienda == 'ayuntamientos') ? 'entidad.municipios.json' : (selectedContienda == 'juntas' ? 'entidad.juntas.json' : 'entidad.distritos.json')).then(data => {
                    this.pintarMapa((selectedContienda == 'ayuntamientos' || selectedContienda == 'juntas')? data['mapa'].municipios : data['mapa'].distritos );
                });
            }
        });
    }
    
    openBottomSheet(){
        this.isOpenCombo = !this.globalService.bottomSheetStatus;
        this.globalService.toggleBottomSheet(this.isOpenCombo);
    }

    pintarMapa(data_mapa) {
        let selectedContienda = this.globalService.getContienda();
        var key = 'JUNTA';
        var map_file = 'juntas.geojson';
        if(selectedContienda != 'juntas'){
            key = (selectedContienda == 'diputaciones'? 'DISTRITO_L' : 'MUNICIPIO');
            map_file = (selectedContienda == 'diputaciones')? 'distritos.geojson' : 'municipio.geojson';
        }
        
        this.obtenerDatosService.loadMapa(map_file).then(json_mapa => {
            let map_data = [] as any;
            let features;
            let data;
            let enlace_key: Number;
            
            if(this.comparativaService.pantalla == 'resumenDistrito'){
                data_mapa.forEach(ficha => {
                    let aux = Object.assign({}, ficha);
                    let highlight = false;
                    this.comparativaService.arregloElementos.forEach( elem => {
                        if(elem.ganados.indexOf(ficha[key]) >= 0){
                            highlight = true;
                        }
                    });
                    if(highlight){
                        if(ficha.color){
                            aux.color = ficha.color+'ff';
                            aux.borderColor = 'black';
                            aux.borderWidth = '1';
                        }
                    }else{
                        if(ficha.color){
                            if(aux.color.toLowerCase() == '#ffffff' || aux.color.toLowerCase() == '#fff' || aux.color.toLowerCase() == 'white'){
                                aux.color = '#e8e8e8';
                            }else{
                                aux.color = ficha.color +'33';
                            }
                        }
                    }
                    map_data.push(aux);
                });
            }
            
            if(this.comparativaService.pantalla == 'distritoCandidatura'){
                json_mapa['features'].forEach(item => {
                    if(item.properties.ENLACE == this.rutas.migas.distrito_clave){
                        enlace_key = item.properties[key];
                    }
                });
                
                data_mapa.forEach(item => {
                    let aux = Object.assign({}, item);
                    if(item[key] != enlace_key){
                        if(item.color){
                            if(aux.color.toLowerCase() == '#ffffff' || aux.color.toLowerCase() == '#fff' || aux.color.toLowerCase() == 'white'){
                                aux.color = '#e8e8e8';
                            }else{
                                aux.color = item.color +'33';
                            }
                        }  
                    }else {
                        aux.color = item.color+'ff';
                        aux.borderColor = 'black';
                        aux.borderWidth = '1';
                    }
                    map_data.push(aux);
                });
            }
            
            
            this['mapa_'+this.comparativaService.pantalla] = new MapChart({
                title: {
                    text: ' '
                },
                chart: {
                    map: json_mapa as any,
                    height: 230,
                    width: 330,
                    backgroundColor: '#FFFFFF',
                    events: {
                        render: function(){
//                            this.resetZoomButton.hide();
                        }
                    },
                    pinchType: 'xy',
                },
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                legend: {
                    enabled: false
                },
                mapNavigation: {
                    enabled: true,
                    enableButtons: false,
                    enableTouchZoom: true,
                },
                plotOptions: {
                    map: {
                        animation: false,
                        tooltip: {},
                        cursor: 'pointer'
                    },
                },
                series: [{
                    type: 'map',
                    data: map_data,
                    keys: [key, 'value'],
                    joinBy: key,
                }],
                tooltip: {
                    enabled: false,
                }
            });
            
            this['mapa_'+this.comparativaService.pantalla].destroy();
        });   
    }
}
