import { Component, OnInit, Input} from '@angular/core';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';

@Component({
    selector: 'movil-estadistica-content',
    templateUrl: './estadistica-movil.component.html',
    styleUrls: ['./estadistica-movil.component.scss']
})
export class EstadisticaMovilComponent implements OnInit {

    @Input() nombreCasilla: string;
    @Input() listaNominal: string;
    @Input() contiendaActual: string;
    @Input() tipoEstadistica: number;//1 - extranjero/casilla individual | 2 - participacion ciudadana
    @Input() infoPorcentaje: any;
    @Input() infoParticipacion: [any];

    config: any = {};

    constructor(public _obtenerDatosService: ObtenerDatosService) { }

    ngOnInit(): void {
        this._obtenerDatosService.configSubject.subscribe(data =>{
            if (data != null) this.config = data;
        });
    }
    
    addWordCasilla(value){
        if(!value)return value;
        const separate = (`${value}`).split(" ")

        if(separate.length > 1){
            var casNum = separate.slice(0, 1)
            casNum.push("Casilla")
            const casName = separate.slice(1, (separate.length+1))
            casNum = casNum.concat(casName)

            const val = casNum.join(' ');
            return val;
        }
        return value;
    }
    
}
