import { Component, OnInit, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, Data  } from '@angular/router';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { ObtenerDatosService } from './servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from './servicios/globales.service';
import { RutasService } from './servicios/rutas/rutas.service';
import { ThemeService } from './theme/theme.service';
import { GoogleAnalyticsService } from './servicios/googleanalytics/googleanalytics.service';

declare var $: any;
declare var window: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [GoogleAnalyticsService]
})
export class AppComponent implements OnInit {
  title = 'publicacion';

    constructor(
        public globalService: GlobalesService,
        private readonly router: Router,
        private readonly rutas: RutasService,
        public readonly _obtenerDatosService: ObtenerDatosService,
        private readonly activatedRoute: ActivatedRoute,
        private titleService: Title,
        public themeService: ThemeService,
        private _analytics: GoogleAnalyticsService
    ) {

      // this.activatedRoute.params.subscribe((parametros) => {
      // });

      //   this.router.events.subscribe((e) => {
      //     if (e instanceof NavigationEnd) {
      //       console.log('aquillego');
      //       window.ga('send', 'pageview');
      //     //         // let selectedContienda = this.globalService.getContienda();
      // //         // this.tryMigas(selectedContienda, this.activatedRoute.snapshot.firstChild.params);

      //     }
      // });

    }

    ngOnInit(): void {
      this._analytics.init();
      // this._analytics.trackPageViews().subscribe();
		// console.log('font size', this.globalService.fontSize);
		// this.GlobalesService.fontSize = 14;

		// this._obtenerDatosService.configSubject.subscribe(data =>{
		// 	if (data != null) {
		// 		{{}}
		// 	}
		// });
        let selectedTheme = localStorage.getItem('activeTheme');

        if (selectedTheme == 'dark') this.themeService.setDarkTheme();
        else this.themeService.setLightTheme();

        this._obtenerDatosService.urlSubject.subscribe(response => {
          if (response != null) {
            // this.idDistrito = this.rutasService.migas.distrito_clave;
            // this.idSeccion = this.rutasService.migas.seccion_clave;
            // this.idCasilla = this.rutasService.migas.casilla_clave;
            // console.log('APP.COMPONENT -> aqui indices');
            this._obtenerDatosService.cargarIndices();
          }

        });

        var myElement=document.getElementById("mainDivApp");
        myElement.addEventListener("scroll", (ev) => {
          this.globalService.isSticky = myElement.scrollTop >= 200;
          // console.log('aqui estoy', this.globalService.isSticky);
          // console.log(myElement.scrollTop);
        });


        this._obtenerDatosService.changeContiendaSubject.subscribe(contienda => {
          if (contienda) {
            delete this.rutas.migas.distrito;
            delete this.rutas.migas.distrito_clave;
            delete this.rutas.migas.seccion;
            delete this.rutas.migas.seccion_clave;
            delete this.rutas.migas.casilla;
            delete this.rutas.migas.casilla_clave;

            // this.idDistrito = '';
            // this.idSeccion = '';
            // this.idCasilla = '';

          }
        });

        // this._obtenerDatosService.configSubject.subscribe(config =>{
        //   if (config != null) {
        //     if (config?.deshabilitar_btn_home === true) {
        //       this.router.navigate(['/inicio']);
        //       return false;
        //     }
        //   }
        // });
    }
}
