import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';

// declare var $;
// import * as $ from 'jquery';

@Component({
  selector: 'app-ppyci-casilla',
  templateUrl: './ppyci-casilla.component.html',
  styleUrls: ['./ppyci-casilla.component.scss']
})
export class PpyciCasillaComponent implements OnInit {

  base: string;
  contenido: any = [];

  constructor(
    public readonly globalService: GlobalesService,
    private readonly activatedRouter: ActivatedRoute,
    public readonly rutas: RutasService
  ) { }

  ngOnInit(): void {
    this.obtenerParametros();
    this.base = this.rutas.rutaActual.base;
    // $('[data-toggle="tooltip"]').tooltip();
  }

  obtenerParametros() {
    this.activatedRouter.params.subscribe((parametros) => {
      const params = { parametros };
      this.globalService.detalles = params.parametros;
    });
    this.globalService.setearParametros();
  }

}
