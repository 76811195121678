import { Component, OnInit } from '@angular/core';
import { GlobalesService } from 'src/app/servicios/globales.service';

@Component({
  selector: 'app-pregunta-elementos-apoyo',
  templateUrl: './pregunta-elementos-apoyo.component.html',
  styleUrls: ['./pregunta-elementos-apoyo.component.scss']
})
export class PreguntaElementosApoyoComponent implements OnInit {

  Search = "";

  constructor(public _globalService: GlobalesService) { }

  ngOnInit(): void {
    this._globalService.searchItem.subscribe(data =>{
      if(data == null) return;
      this.Search = data
    });
  }

}
