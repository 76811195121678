<div class="row tabla-seccion tabla-scroll mt-3 d-none d-xl-block">
    <div class="col-12">
        <div class="tabla card pb-3">
            <div class="p-3 pb-0">
                <p class="titulo text-left">
                    <strong>Detalle de votos por Casilla</strong>
                </p>
                <div class="acta-digitalizada container-fluid">
                    <div class="row">
                        <div class="col-8 em12 pl-0">
                            <p class="font-medium text-left"> Imagen procedente de: </p>
                            <div class="container-fluid pl-0 my-2">
                                <div class="row">
                                    <div class="col-3 dis-flex">
                                        <div class="grupo gris dis-flex">
                                            <i class="far fa-file"></i>
                                            <i class="far fa-hourglass"></i>
                                            <p>Acta en proceso</p>
                                        </div>
                                    </div>
                                    <div class="col-3 dis-flex bordes-laterales">
                                        <div class="grupo dis-flex cursor-hand" tooltip="Acta PREP digitalizada por medio de escáner." placement="top" max-width="500">
                                            <i class="far fa-file"></i>
                                            <i class="fas fa-print" aria-hidden="true"></i>
                                            <p> Escáner </p>
                                        </div>
                                    </div>
                                    <div class="col-3 dis-flex">
                                        <div class="grupo dis-flex cursor-hand" tooltip="Acta PREP digitalizada por medio de dispositivo móvil." placement="top" max-width="500">
                                            <i class="far fa-file"></i>
                                            <i class="fas fa-mobile-alt"></i>
                                            <p>Móvil {{showLabel ? "Casilla" : ''}}</p>
                                        </div>
                                    </div>
                                    <div class="col-3 dis-flex borde-izq" *ngIf="showCATD">
                                        <div class="grupo dis-flex cursor-hand" 
                                            tooltip="Acta PREP digitalizada por medio de dispositivo móvil."
                                            placement="top" max-width="500">
                                            <div class="grupo dis-flex">
                                                <div *ngIf="!themeService.isDarkTheme()" style="width: 33px;height: 20px;background-image: url('./assets/img/generales/logo_movil_catd.png');background-repeat: no-repeat;image-rendering: -webkit-optimize-contrast;background-size:contain;"></div>
                                                <div *ngIf="themeService.isDarkTheme()" style="width: 33px;height: 20px;background-image: url('./assets/img/generales/logo_movil_catd_rosa.png');background-repeat: no-repeat;image-rendering: -webkit-optimize-contrast;background-size:contain;"></div>
                                                <p class="leyenda">
                                                    Móvil CATD
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        <div class="contenido barraScrollH">
            <div class="container-fluid">
                <div class="table-responsive max-500">
                    <table class="table table-striped table-bordered header-scroll">
                        <thead>
                            <tr>
                                <th class="sticky-cell">
                                    <div class="dis-flex">
                                        <strong>Casilla</strong>
                                    </div>
                                </th>
                                <th class="sticky-cell">
                                    <div class="dis-flex">
                                        <ng-container>
                                            <strong>Acta digitalizada</strong>
                                        </ng-container>
                                    </div>
                                </th>
                                <th *ngFor="let itemHeader of this._obtenerDatosService?.desglose?.headers">
                                    <div class="dis-flex">
                                        <ng-container *ngIf="itemHeader?.text != ''">
                                            <strong>{{itemHeader?.text}}</strong>
                                        </ng-container>
                                        <ng-container *ngFor="let img of itemHeader?.imgs">
                                            <img class="img-partido" src="./assets/img/logos-partidos/{{img.toLowerCase()}}">
                                        </ng-container>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let itemBody of this._obtenerDatosService?.desglose?.rows">
                                <td class="sticky-cell"> 
                                    <p *ngIf="itemBody.status == 'proceso'"><b>{{itemBody?.casilla?.numero}} {{itemBody?.casilla?.text}}</b></p>
                                    <a *ngIf="itemBody.status != 'proceso'" [routerLink]="['/'+contiendaActual+'/'+param+'/secciones/'+itemBody?.casilla?.seccion+'/casilla/'+itemBody?.casilla?.param]"><b>{{itemBody?.casilla?.numero}} {{itemBody?.casilla?.text}}</b> </a>
                                </td>
                                <td class="sticky-cell">
                                    <a target="_blank" [href]="itemBody.status.catd ? itemBody.status.catd : (itemBody.status.movil? itemBody.status.movil : itemBody.status.scanner)" *ngIf="(itemBody.status != 'proceso' || itemBody.status != 'sin_acta') && itemBody?.casilla?.param">
                                        <ng-container *ngIf="itemBody.status.movil">
                                            <i class="far fa-file" aria-hidden="true"></i>
                                            <i class="fas fas fa-mobile-alt" aria-hidden="true"></i>
                                        </ng-container>
                                        <ng-container *ngIf="itemBody.status.scanner">
                                            <i class="far fa-file" aria-hidden="true"></i>
                                            <i class="fas fa-print" aria-hidden="true"></i>
                                        </ng-container>
                                        <ng-container *ngIf="itemBody.status.catd">
                                            <img *ngIf="!themeService.isDarkTheme()" height="15" [src]="'./assets/img/generales/logo_movil_catd.png'">
                                            <img *ngIf="themeService.isDarkTheme()" height="15" [src]="'./assets/img/generales/logo_movil_catd_rosa.png'">
                                        </ng-container>
                                        <ng-container *ngIf="itemBody.actaDigitalizada === '-'">
                                            <i class="far fa-file"></i>
                                            <i class="far fa-hourglass"></i>
                                        </ng-container>
                                    </a>
                                    <a target="_blank" *ngIf="(itemBody.status == 'proceso' || itemBody.status == 'sin_acta')  || !itemBody?.casilla?.param">
                                        <ng-container *ngIf="itemBody.status.movil">
                                            <i class="far fa-file" aria-hidden="true"></i>
                                            <i class="fas fas fa-mobile-alt" aria-hidden="true"></i>
                                        </ng-container>
                                        <ng-container *ngIf="itemBody.status.scanner">
                                            <i class="far fa-file" aria-hidden="true"></i>
                                            <i class="fas fa-print" aria-hidden="true"></i>
                                        </ng-container>
                                        <ng-container *ngIf="itemBody.actaDigitalizada === '-'">
                                            <i class="far fa-file"></i>
                                            <i class="far fa-hourglass"></i>
                                        </ng-container>
                                        
                                        <div class="txGris" *ngIf="itemBody?.status == 'proceso' || itemBody?.status == 'sin_acta'">
                                            <i class="far fa-file txGris"></i>
                                            <i class="far fa-hourglass txGris"></i>
                                        </div>
                                    </a>
                                </td>
                                <td *ngFor="let itemVoto of itemBody.col;  let i=index;">
                                    <span *ngIf="i < itemBody.col.length-1; else observacion">{{itemVoto}}</span>
                                    <ng-template #observacion>
                                        <span class="link-text" (click)="scrollComponent('observaciones')">{{itemVoto}}</span>
                                    </ng-template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
            
    </div>
  </div>
</div>
