import { Component, Input } from '@angular/core';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';

@Component({
  selector: 'app-resumen-votacion-mobile',
  templateUrl: './resumen-votacion-mobile.component.html',
  styleUrls: ['./resumen-votacion-mobile.component.scss']
})

export class ResumenVotacionMobileComponent {

  @Input() public total_num:any = '';
  @Input() public total_prc:any = '';
  @Input() public hide_prc:any = false;

  config: any = {};
  omitir_extranjero: any = false;

  constructor(
    public readonly _obtenerDatosService: ObtenerDatosService,
  ) {
    this._obtenerDatosService.configSubject.subscribe(config => {
      if (config != null) {
        this.config = config;
        if (config.omitir_extranjero === 'true' || config.omitir_extranjero === true) this.omitir_extranjero = true;
      }
    });
  }

  ngOnInit(): void {
    if (this.hide_prc === 'true' || this.hide_prc === true) this.hide_prc = true;
    else this.hide_prc = false;

    // $('[data-toggle="tooltip"]').tooltip();
  }

}
