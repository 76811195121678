import { Component, Inject, OnInit } from '@angular/core';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { Router } from '@angular/router';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';
import { CombosService } from 'src/app/servicios/combos/combos.service';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import { ObtenerDatosService } from '../../../servicios/datos/obtener-datos/obtener-datos.service';

import { PageScrollService } from 'ngx-page-scroll-core';

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.scss']
})
export class MenuLateralComponent implements OnInit {

  distrito = 'Todas';
  seccion = 'Todas';

  combos: any = [];
  combosEntidad: any = [];

  idCircuns: any;
  idEntidad: any;
  idDistrito: any;
  idSeccion: any;
  idCasilla: any;

  // BIGSoft Variables menu lateral
  filteredComboEntidad: any = [];
  comboDistritos: any = [];
  selectedDistrito: any = '';
  comboSecciones: any = [];
  selectedSeccion: any = '';
  comboCasillas: any = [];
  stateParams: any = {};
  selectedCasilla: any = '';
  labelComboDistritos: any = '';
  initSubscribed: any = false;

  constructor(
    public rutasService: RutasService,
    public globalService: GlobalesService,
    public router: Router,
    public combosService: CombosService,
    public activatedRoute: ActivatedRoute,
    public location: Location,
    public datosService: ObtenerDatosService,
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private document: any
    // public navigationEnd: NavigationEnd
  ) {
    
  }

  ngOnInit(): void {
  }

  filterEntidad(idCircuns) {
    let arr_return = [];
    this.combosEntidad.forEach(entidad => {
      if (entidad.idCircuns == idCircuns) arr_return.push(entidad);
    });

    return arr_return;
  }
    
  goToAnchor(anchor) {
      var el = document.getElementById(anchor);
//      el.scrollIntoView();
      el.scrollIntoView();
  }

  onOpenDistritos() {
    // console.log('aqui', this.datosService.comboDistritos);
    let comboDistritos = this.datosService.comboDistritos,
        aux_checked = false;

    for (let i = 0; i < comboDistritos.length; i++) {
      const el = comboDistritos[i];
      
      if (this.rutasService.migas.distrito_clave == el.claveURL) {
        aux_checked= true;        
        comboDistritos[i].checked = true;
      }
    }

    if (aux_checked) this.globalService.bottomSheetTodosChecked = false;
    else this.globalService.bottomSheetTodosChecked = true;

	this.globalService.bottomSheetSinDefinir = false;
    this.globalService.bottomSheetTpl = 'default';
    this.globalService.bottomSheeTitle = (this.globalService.getContienda() == 'ayuntamientos' ? 'Municipios' : (this.globalService.getContienda() == 'juntas' ? 'Juntas' : 'Distritos'));
	this.globalService.bottomSheetTodosTxt = 'Todos';
    this.globalService.bottomSheetData = comboDistritos;
    this.globalService.bottomSheetCallback = this.callbackDistritos;
    this.globalService.toggleBottomSheet(true);
  }

  callbackDistritos(cDistrito: any) {
	this.globalService.toggleBottomSheet(false);
      if (cDistrito && cDistrito != 'Todos') {
        this.rutasService.migas.distrito = cDistrito?.migas;
        this.rutasService.migas.distrito_clave = cDistrito?.claveURL;
        this.datosService.irDistrito(cDistrito?.claveURL);
      } else this.datosService.irDistrito('Todos');
  }

  onOpenSecciones() {
    let comboSecciones = this.datosService.comboSecciones,
        aux_checked = false;

    for (let i = 0; i < comboSecciones.length; i++) {
      const el = comboSecciones[i];
      
      if (this.rutasService.migas.seccion_clave == el.claveURL) {
        aux_checked= true;        
        comboSecciones[i].checked = true;
      }
    }

    if (aux_checked) {
		this.globalService.bottomSheetTodosChecked = false;
		this.globalService.bottomSheetSinDefinirChecked = false;
	} else {
		let arr_url_ini = window.location.href.split('//'),
		contienda = '';
		
		let arr_url = arr_url_ini[1].split('/');
		
		if (arr_url[(arr_url.length - 1)] == 'secciones') {
			this.globalService.bottomSheetTodosChecked = true;
			this.globalService.bottomSheetSinDefinirChecked = false;
		} else {
			this.globalService.bottomSheetTodosChecked = false;
			this.globalService.bottomSheetSinDefinirChecked = true;
		}
	}
		

	this.globalService.bottomSheetSinDefinir = true;
    this.globalService.bottomSheetTpl = 'default';
    this.globalService.bottomSheeTitle = 'Secciones';
	this.globalService.bottomSheetTodosTxt = 'Todas';
    this.globalService.bottomSheetData = comboSecciones;
    this.globalService.bottomSheetCallback = this.callbackSecciones;
    this.globalService.toggleBottomSheet(true);
  }

  callbackSecciones(cSeccion: any) {
	this.globalService.toggleBottomSheet(false);
	if (cSeccion) {
	  if (cSeccion == 'Todos') this.datosService.irSeccion('Todas');
	  else {
		  this.rutasService.migas.seccion = cSeccion?.migas;
		  this.rutasService.migas.seccion_clave = cSeccion?.claveURL;
		  this.datosService.irSeccion(cSeccion?.claveURL);
	  }
	} else this.datosService.irSeccion('');
  }

  onOpenCasillas() {
    let comboCasillas = this.datosService.comboCasillas,
        aux_checked = false;

    for (let i = 0; i < comboCasillas.length; i++) {
      const el = comboCasillas[i];
      
      if (this.rutasService.migas.casilla_clave == el.claveURL) {
        aux_checked= true;        
        comboCasillas[i].checked = true;
      }
    }

    if (aux_checked) this.globalService.bottomSheetTodosChecked = false;
    else this.globalService.bottomSheetTodosChecked = true;

	this.globalService.bottomSheetSinDefinir = false;
    this.globalService.bottomSheetTpl = 'default';
    this.globalService.bottomSheeTitle = 'Casillas';
	this.globalService.bottomSheetTodosTxt = 'Todas';
    this.globalService.bottomSheetData = comboCasillas;
    this.globalService.bottomSheetCallback = this.callbackCasillas;
    this.globalService.toggleBottomSheet(true);
  }

  callbackCasillas(cCasilla: any) {
	this.globalService.toggleBottomSheet(false);
	if (cCasilla) {
	  if (cCasilla == 'Todos') this.datosService.irCasilla('Todas');
	  else {
		  this.rutasService.migas.casilla = cCasilla?.migas;
		  this.rutasService.migas.casilla_clave = cCasilla?.claveURL;
		  this.datosService.irCasilla(cCasilla?.claveURL);
	  }
	}
  }


//  goToAnchor(anchor) {
//      console.log(this.pageScrollService);
//      console.log(this.document);
//      console.log('#'+anchor);
//      
//      this.pageScrollService.scroll({
//          document: this.document,
//          scrollTarget: ('#' + anchor),
//          duration: 500
//      });
//  }

  navegarDistrito() {
    const base = this.rutasService.rutaActual.base;
    if(base == 'votos-ppyci') {
      if(this.rutasService.nivelRutaEspecifica) {
        let ruta:string = this.rutasService.rutaActual.ruta;
        ruta = ruta.split('/').slice(0, -2).join('/');
        this.router.navigateByUrl(ruta+'/votos-distrito');
      } else {
        // this.router.navigateByUrl('/votos-distrito');
      }
    } else {
      // let ruta: any = this.rutasService.rutaActual.ruta;
      // ruta = ruta.split('/').slice(0, -1).join('/');
      // this.router.navigateByUrl(ruta + '/votos-ppyci');
    }
  }
  navegarVotosPPyCI() {
    const base = this.rutasService.rutaActual.base;

    if(base == 'votos-distrito') {
      if(this.rutasService.nivelRutaEspecifica) {
        let ruta:string = this.rutasService.rutaActual.ruta;
        ruta = ruta.split('/').slice(0, -2).join('/');

        this.router.navigateByUrl(ruta+'/votos-ppyci');
      } else {
        this.router.navigateByUrl('/votos-ppyci');
      }
    } else if(base == 'detalle-casilla' || base == 'votos-candidatura') {
      let ruta:string = this.rutasService.rutaActual.ruta;
      ruta = ruta.split('/').slice(0, -1).join('/');
      this.router.navigateByUrl(`/${ruta}/votos-ppyci`);
    }
  }
  // navegarPorCandidatura() {
  //   let ruta: any = this.rutasService.rutaActual.ruta;
  //   ruta = ruta.split('/').slice(0, -1).join('/');
  //   this.router.navigateByUrl(ruta + '/votos-candidatura');
  // }
  navegarDetalleCasilla() {
    let ruta: any = this.rutasService.rutaActual.ruta;
    ruta = ruta.split('/').slice(0, -1).join('/');
    this.router.navigateByUrl(ruta + '/detalle-casilla');
  }

  setearParametros() {
    return new Promise((res, rej) => {
      this.globalService.setearParametros();
      res();
    })
  }
    

  atras() {
    this.globalService.back()
    this.globalService.menuActual = 'Votos';
    window.scrollTo(0, 0);
  }

}
