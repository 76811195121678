import { Component, OnInit } from '@angular/core';
import { ObtenerDatosService } from '../../../../servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';

@Component({
  selector: 'app-pregunta-consulta-votacion',
  templateUrl: './pregunta-consulta-votacion.component.html',
  styleUrls: ['./pregunta-consulta-votacion.component.scss']
})
export class PreguntaConsultaVotacionComponent implements OnInit {

  Search = "";
  
  constructor(public readonly _obtenerDatosService : ObtenerDatosService,public _globalService: GlobalesService) { }

  ngOnInit(): void {
    this._globalService.searchItem.subscribe(data =>{
      if(data == null) return;
      this.Search = data
    });
  }

}
