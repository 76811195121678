import { Component, OnInit } from '@angular/core';
import { GlobalesService } from 'src/app/servicios/globales.service';

@Component({
  selector: 'app-pregunta-avance-nacional',
  templateUrl: './pregunta-avance-nacional.component.html',
  styleUrls: ['./pregunta-avance-nacional.component.scss']
})
export class PreguntaAvanceNacionalComponent implements OnInit {

  constructor(public _globalService: GlobalesService) { }

  Search = "";

  ngOnInit(): void {
    this._globalService.searchItem.subscribe(data =>{
      if(data == null) return;
      this.Search = data
    });
  }

}
