<div class="inicio d-md-none">
	<img class="logoTest" src="assets/img/generales/logo_inicio.png" alt="PREP" *ngIf="!themeService.isDarkTheme()"/>
	<img class="logoTest" src="assets/img/generales/logo_inicio_ople.png" alt="PREP" *ngIf="themeService.isDarkTheme()"/>

	<h1 [innerHTML]="title"></h1>

	<p [innerHTML]="config?.programa" class="font-medium"></p>
	<p [innerHTML]="config?.aviso_legal_inicio" class="font-medium"></p>
	<p [innerHTML]="config?.computos" class="font-medium"></p>

	<button *ngIf="!main?.deshabilitar_btn_home" (click)="onConsultar()" class="font-light">Consultar Resultados Preliminares</button>
</div>

<div class="inicioDesk d-none d-md-block">
	<div class="d-flex align-items-center">
		<img class="ople" src="assets/img/generales/logo.png" alt=""> 
		<div class="separator-v"></div>
		<img class="oplePREP" src="assets/img/generales/LogoPREP.png" alt=""> 
	</div>

	<h1 [innerHTML]="title"></h1>

	<p [innerHTML]="config?.programa" class="font-medium"></p>
	<p [innerHTML]="config?.aviso_legal_inicio" class="font-medium"></p>
	<p [innerHTML]="config?.computos" class="font-medium"></p>

	<button *ngIf="!main?.deshabilitar_btn_home" (click)="onConsultar()" class="font-light">Consultar Resultados Preliminares</button>
</div>
