import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';
import { environment as env } from 'src/environments/environment';
import { Router } from '@angular/router';

// declare var $;
// import * as $ from 'jquery';

/* HighCharts */
import { Chart, MapChart} from 'angular-highcharts';
import { ThemeService } from '../../../../../theme/theme.service';

@Component({
  selector: 'app-distrito-entidad-mapa',
  templateUrl: './distrito-entidad-mapa.component.html',
  styleUrls: ['./distrito-entidad-mapa.component.scss']
})
export class DistritoEntidadMapaComponent implements OnInit {

    baseMigajas: string;

    /* Variables Mapa */
    public map: MapChart;
    rutaActual;
    entidadActual;
    noEntidadActual;
    diccionarioEntidades = ["OFICINAS CENTRALES","AGUASCALIENTES","BAJACALIFORNIA","BAJACALIFORNIASUR","CAMPECHE","COAHUILA","COLIMA","CHIAPAS","CHIHUAHUA","CIUDADDEMEXICO","DURANGO","GUANAJUATO","GUERRERO","HIDALGO","JALISCO","MEXICO","MICHOACAN","MORELOS","NAYARIT","NUEVOLEON","OAXACA","PUEBLA","QUERETARO","QUINTANAROO","SANLUISPOTOSI","SINALOA","SONORA","TABASCO","TAMAULIPAS","TLAXCALA","VERACRUZ","YUCATAN","ZACATECAS"];

    constructor(
        public _globalService: GlobalesService,
        public activatedRoute: ActivatedRoute,
        public _rutasService: RutasService,
        public _obtenerDatosService: ObtenerDatosService,
        public _router:Router,
        public themeService:ThemeService
    ) { }

    ngOnInit(): void {
        this.checkInicial();
        this.baseMigajas = this._rutasService.rutaActual.base;

        /* MAPA */
        this.obtenerEntidad();
        this.checarDataMapa();
        /* Observable cambio de URL*/
        this._rutasService.observ.subscribe( texto =>{
            this.obtenerEntidad();
            this.checarDataMapa();
        });
        // $('[data-toggle="tooltip"]').tooltip();
    }

    checkInicial() {
        this._rutasService.limpiarSeccion();
        this.obtenerParametro()
            .then(() => {
            });
    }

    obtenerParametro() {
        return new Promise((res, rej) => {
        this.activatedRoute.params.subscribe((param) => {
            this._globalService.detalles = param;
            this._globalService.setearParametros();
            res();
        });
        })
    }


    obtenerEntidad(){
        this.rutaActual = this._router.url; 
        let separaUrl = this.rutaActual.split("/");
        this.entidadActual = (separaUrl[2].toUpperCase()).replaceAll("-","");
        this.noEntidadActual = this.diccionarioEntidades.indexOf(this.entidadActual);
        // this._obtenerDatosService.llenadoEntidad(this.noEntidadActual);
    }
  

    checarDataMapa(){
        if( this._obtenerDatosService.dataMapaEntidades === undefined){
            this._obtenerDatosService.getInfoJson(env.urls.urlDataMapaEntidad)
                .subscribe(res =>{
                    this._obtenerDatosService.dataMapaEntidades = res;
                    this.checarGeoJsonMapa();
            });
        }else{
            this.checarGeoJsonMapa();
        }
    }

    checarGeoJsonMapa(){
        let objetoGeojson = "geojsonEntidad"+this.noEntidadActual;
        if(this._obtenerDatosService[objetoGeojson] === undefined){
            this._obtenerDatosService.getInfoJson(env.urls.urlMapaEntidad+'entidad'+this.noEntidadActual+'.geojson')
            .subscribe(res =>{
                this._obtenerDatosService[objetoGeojson] = res;
                this.pintarMapaCircunscripcion(this._obtenerDatosService[objetoGeojson],this.noEntidadActual);
            })
        }else{
            this.pintarMapaCircunscripcion(this._obtenerDatosService[objetoGeojson],this.noEntidadActual);
        }
      
    } 

    pintarMapaCircunscripcion(geomap, noEnti){
        /* Asignamos el GeoJson dependiendo de la Circunscripcion seleccionada */
        let geoJson = geomap;
        let noEntidad = noEnti;
        
        this.map = new MapChart({
            title: {
                text: ''
            },
  
            chart: {
                map:  geoJson as any,
                backgroundColor: '#FFFFFF'
            },
  
            credits: {
                enabled: false
            },
  
            exporting: {
                enabled: false
            },
  
            legend: {
                enabled: false
            },
            mapNavigation: {
                enabled: true,
                enableButtons: true,
                enableMouseWheelZoom: true,
                buttonOptions: {
                    align: 'right',
                    verticalAlign: 'top'
    
                }
            },
            plotOptions: {
                map: {
                     animation: false,
                    tooltip: {},
                    cursor: 'pointer'
                }
                
            },
            colorAxis: {
                dataClasses:  this._obtenerDatosService.dataMapaEntidades.dataClasses
            },
            series: [{
                type: undefined,
                joinBy: ['ENTIDAD_DISTRITO', 'ENTIDAD_DISTRITO'],
                data: this._obtenerDatosService.dataMapaEntidades["entidad"+noEntidad],
                states: {
                    hover: {
                        color: '#D3057F',
                        borderColor: 'gray'
                    }
                },
                point: {
                    events: {
                        click: 
                        (row) => {
                            this._router.navigate([row.point.properties.URL_DTTAL]);
                            /* this.router.navigate(['/diputaciones/distrito/detalle/1/3/4/1'], {queryParams: {'entidad': row.point.idNodoPadre, 'distrito': row.point.idNodo}}); */
                        }
                    }
                },
            }],
            tooltip: {
                useHTML: true,
                padding: 0, 
                borderRadius: 8,
                shadow:null,
                backgroundColor: '#EEEEEE',
                borderColor: "#EEEEEE",
                headerFormat: '',
                pointFormat:'<div style="background:#D3057F;padding:10px;text-align:center;border-radius:  8px 8px 0px 0px;"><img src="./assets/img/logos-partidos/{point.options.img_partido}" height="30"></div>'+
                            '<div style="padding-left:10px;padding-right:10px;padding-top:10px;font-size:14px;font-family: Roboto-Medium, sans-serif">Distrito</div>'+
                            '<div style="padding-left:10px;padding-right:10px;font-size:12px;font-family: Roboto-Regular, sans-serif">{point.properties.DISTRITO_F}. {point.properties.CAB_DTTAL}</div>'+
                            '<div style="padding-left:10px;padding-right:10px;padding-top:10px;font-size:14px;font-family: Roboto-Medium, sans-serif">Entidad {point.properties.NOCIRCUNSCIPCION}</div>'+
                            '<div style="padding-left:10px;padding-right:10px;font-size:12px;font-family: Roboto-Regular, sans-serif">{point.properties.NOM_ENT}</div>'+
                            '<div style="padding-left:10px;padding-right:10px;padding-top:10px;font-size:14px;font-family: Roboto-Medium, sans-serif">Votos {point.properties.NOCIRCUNSCIPCION}</div>'+
                            '<div style="padding-left:10px;padding-right:10px;font-size:18px;font-family: Roboto-Bold, sans-serif">{point.options.votos}</div>',
                            
                style: {
                    pointerEvents: 'auto'
                }  
            }
        });
    }

}
