import { Component, Inject, Input, OnInit } from '@angular/core';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { Router } from '@angular/router';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';
import { CombosService } from 'src/app/servicios/combos/combos.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ObtenerDatosService } from '../../../servicios/datos/obtener-datos/obtener-datos.service';
import { ThemeService } from '../../../theme/theme.service';

declare var $: any;


@Component({
	selector: 'app-menu-responsive',
	templateUrl: './menu-responsive.component.html',
	styleUrls: ['./menu-responsive.component.scss']
})
export class MenuResponsiveComponent implements OnInit {
	@Input() public tpl:any = 'default';
	@Input() public data:any = [];
	@Input() public callback:any = function() {};

	constructor(
		public rutasService: RutasService,
		public globalService: GlobalesService,
		public router: Router,
		public combosService: CombosService,
		public activatedRoute: ActivatedRoute,
		public location: Location,
		public datosService: ObtenerDatosService,
		public themeService: ThemeService,
	) {
	
	}

	selectedContienda: any = '';
	arrSecciones: any = [];
	arrCasillasSecciones: any = [];
	objConfig: any = {};

	ngOnInit(): void {
		// $('#navbarMenuReponsive').on('shown.bs.collapse'), function() {
		// 	console.log('ABIERTO');
		// }
		
		// $('#navbarMenuReponsive').on('hidden.bs.collapse'), function() {
		// 	console.log('CERRADO');
		// }
		this.selectedContienda = this.globalService.getContienda();

		this.datosService.loadSeccionesCombo(this.selectedContienda).then(response => {

			//this.arrSecciones = this.globalService.orderObjectByKeyNumber('seccion', response['secciones']);
			this.arrSecciones = response['secciones'];
			this.arrCasillasSecciones = response['casillas'];
		});

		this.datosService.configSubject.subscribe(data =>{
			if (data != null) this.objConfig = data;
		});
	}

	changeContiendaActiva(contienda) {
		this.datosService.changeContiendaSubject.next(contienda);
		this.globalService.changeContiendaActiva(contienda);
	
		let router = this.router;
		setTimeout(function() {router.navigateByUrl(`/${contienda}`)}, 100);

		this.globalService.toggleMenuResponsive(false);
	}

	toggleTheme(type) {
		if (this.themeService.isDarkTheme() && type == 'light') this.themeService.setLightTheme();
		else if(!this.themeService.isDarkTheme() && type == 'dark')  this.themeService.setDarkTheme();
	}

	changeFontSize(action) {
		if (action == 'plus' && this.globalService.fontSize < 14) this.globalService.fontSize = this.globalService.fontSize + 1;
		else if (action == 'minus' && this.globalService.fontSize > 6) this.globalService.fontSize = this.globalService.fontSize - 1;
	}

	onMiCasilla() {
		// $('#modalAvisoPrivacidad').modal('show');
		// $('#modalAvisoPrivacidad').addClass('backdropBlur');

		// $('#modalAvisoPrivacidad').on('hidden.bs.modal', function (e) {
		// 	$('#modalAvisoPrivacidad').unbind('hidden.bs.modal');
		// 	$('#modalAvisoPrivacidad').removeClass('backdropBlur');

			$('.menu-body').addClass('d-none');
			$('.micasilla').removeClass('d-none');
		// });
	}

	onReturnCasilla() {
		$('.menu-body').removeClass('d-none');
		$('.micasilla').addClass('d-none');
	}

	buscaCasilla(form){
		// console.log('aqui estoy');

		// document.getElementById('#myModal').modal('show')
		// return;
		let values = form.form.value;

		// console.log(values);
		// return;

		if (values.seccionCasilla && values.seccionCasilla > 0) {
			let auxDistrito = '',
				auxSeccion = 'seccion' + values.seccionCasilla,
				ruta = '';

			// this.arrSecciones.forEach(el => {
			// 	if (auxDistrito == '' && el.param == auxSeccion) auxDistrito = el.distrito;
			// });

			// console.log(values.seccionCasilla);
			// console.log(auxDistrito);

			if (this.arrSecciones[auxSeccion] && !this.globalService.isObjEmpty(this.arrSecciones[auxSeccion])) {
				if (this.selectedContienda == 'gubernatura' || this.selectedContienda == 'diputaciones') auxDistrito = this.arrSecciones[auxSeccion].distrito;
				else auxDistrito = this.arrSecciones[auxSeccion].municipio;
	
				// console.log(auxDistrito);return;
	
				if (auxDistrito != '') {
					ruta = `/${this.selectedContienda}/${auxDistrito}/secciones/${auxSeccion}`;
					if (values.apellidoCasilla) {
						values.apellidoCasilla = values.apellidoCasilla.toUpperCase();
	
						let inicioApellido = values.apellidoCasilla.substring(0, 2);
						if (this.arrCasillasSecciones[auxSeccion]) {
							let arrCasillas = this.arrCasillasSecciones[auxSeccion],
								// inicialesInicio = '',
								// inicialesFin = '',
								matchFound = false,
								arrIniciales = [];
							for (const iniciales in arrCasillas) {
								arrIniciales = iniciales.split('-');
	
								arrIniciales[0] = arrIniciales[0].toUpperCase();
								arrIniciales[1] = arrIniciales[1].toUpperCase();
	
								if (matchFound == false) {
									if (inicioApellido >= arrIniciales[0]) {
										if (inicioApellido <= arrIniciales[1]) {
											matchFound = true;
		
											ruta += `/casilla/${arrCasillas[iniciales]}`;
										}
									}
								}
								// console.log('iniciales', iniciales);
								// console.log('iniciales', arrCasillas[iniciales]);
							}
	
							this.irRuta(ruta)
	
							// location.replace(ruta);
						} else {
							this.irRuta(ruta);
							// location.replace(ruta);
						}
					} else {
						this.irRuta(ruta);
						// location.replace(ruta);
					}
				} else alert('No se encontró la sección seleccionada.');
			} else alert('No se encontró la sección seleccionada.');
		} else alert('Debe seleccionar una sección válida.');

	}

	onNavigate(url) {
		if (url) {
			this.globalService.toggleMenuResponsive(false);
			this.router.navigateByUrl(url);
		}
	}

	irRuta(ruta) {
		this.globalService.toggleMenuResponsive(false);
		location.replace(ruta);
	}

	onChangeEvent(e: any){
		if(e && e.target.value){
			if(e.target.value < 0){e.target.value = 0;}
			else e.target.value = parseInt(e.target.value);
		}
	}

	onCentroAyuda() {
		this.globalService.toggleMenuResponsive(false);
		let url = 'https://www.ine.mx/voto-y-elecciones/';

		if (this.objConfig?.header?.ayuda_link) url = this.objConfig?.header?.ayuda_link;

		window.open(url);
	}
}
