import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CombosService } from './combos/combos.service';
import { RutasService } from './rutas/rutas.service';
import data from '../../assets/json/main.json';
import { faAngular } from '@fortawesome/free-brands-svg-icons';
import { BehaviorSubject} from 'rxjs';

import * as moment from 'moment';
import { objectEach } from 'highcharts';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT, Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class GlobalesService {

  modulo: string;
  param_municipio: string;
  param_distrito: string;
  param_seccion: string;
  param_casilla: string;
  navigationUrl: any = '';
  navigationSubscribed: any = false;
  bottomSheetStatus: any = false;
  detalles: any = {};
  urls: any = {};
  menuActual = 'Votos';
  idCircuns: any;
  fontSize: number = localStorage.getItem('fontSize') ? Number(localStorage.getItem('fontSize')) : 10; //10;

  momentjs:any = moment;

  /* variables para parametros ruta */
  rutaActual='';
  nivelesRutaActual:any;
  menu = [];
  rutaParametros = {
    "nivelPagina":undefined,
    "circunscripcion" : undefined,
    "circunscripcionTexto":undefined,
    "noEntidad": undefined,
    "entidadTexto":undefined,
    "distritoCompleto": undefined,
    "distritoTexto":undefined,
    "noDistrito" : undefined,
    "noSeccion": undefined,
    "casilla" : undefined,
  }
  diccionarioEntidadesURL = ["OFICINAS CENTRALES","AGUASCALIENTES","BAJACALIFORNIA","BAJACALIFORNIASUR","CAMPECHE","COAHUILA","COLIMA","CHIAPAS","CHIHUAHUA","CIUDADDEMEXICO","DURANGO","GUANAJUATO","GUERRERO","HIDALGO","JALISCO","MEXICO","MICHOACAN","MORELOS","NAYARIT","NUEVOLEON","OAXACA","PUEBLA","QUERETARO","QUINTANAROO","SANLUISPOTOSI","SINALOA","SONORA","TABASCO","TAMAULIPAS","TLAXCALA","VERACRUZ","YUCATAN","ZACATECAS"];


  arrContiendas: any = [
    'gubernatura',
    'diputaciones',
    'ayuntamientos',
    'juntas',
  ];

  arrRutasSinAvance:any = [
    'base-de-datos',
    'accesibilidad',
    'preguntas-frecuentes',
  ];

  hideAvance: boolean = false;

  contiendas_total: number = 0;
  arrContiendasActivas: any = [];
  mainJSON:any = {};

  menuLateral = true;
  leyendaMenu: string = 'Ocultar Menú';
  returnURL = ''
  private history: string[] = []

  public callbackData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public callbackCasillaData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public shortData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  bottomSheetTpl = 'default';
  bottomSheetSinDefinir = false;
  bottomSheetTodosTxt = 'Todos';
  bottomSheeTitle = '';
  bottomSheetData = [];
  bottomSheetContex: any;
  bottomSheetTodosChecked: any = true;
  bottomSheetSinDefinirChecked: any = true;
  bottomSheetCallback = function(params:any) {
    this.callbackData.next(params);
  };
  bottomSheetCasillaCallback = function(params:any) {
    this.callbackCasillaData.next(params);
  };
  public searchItem: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  isSticky: boolean = false;
  omitirExtranjero: any;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly rutasService: RutasService,
	  private combosService: CombosService,
    private pageScrollService: PageScrollService,
    private router: Router, 
    private location: Location,
    @Inject(DOCUMENT) private document: any
  ) {

    this.mainJSON = data;

    let auxContiendas = [];
    for (const contienda in data) {
      if (this.arrContiendas.indexOf(contienda) >= 0) {

        localStorage.setItem('contienda', contienda);

        this.contiendas_total++;
        // auxContiendas.push({contienda: contienda, order: this.arrContiendas.indexOf(contienda)});
        this.arrContiendasActivas.push({contienda, data: data[contienda], order: this.arrContiendas.indexOf(contienda)});
      }
      else {}

    }

    // auxContiendas.sort((a, b) => (a.order > b.order) ? 1 : -1);

    this.arrContiendasActivas.sort((a, b) => (a.order > b.order) ? 1 : -1);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    })
  }

  isObjEmpty(obj) {
    for(var prop in obj) {
      if(obj.hasOwnProperty(prop)) {
        return false;
      }
    }
  
    return JSON.stringify(obj) === JSON.stringify({});
  }

  orderObjectByKeyNumber(separator, arrObj) {
    let arrSplit = null;

    // let testObj = {
    //   "seccion2": "Sección 2",
    //   "seccion100": "Sección 100",
    //   "seccion1": "Sección 1",
    //   "seccion10": "Sección 10",
    //   "seccion20": "Sección 20",
    // };

    let arrS = Object.keys(arrObj),
        auxS = [];

    arrS.forEach(el => {
      arrSplit = el.split(separator);
      auxS.push({
        param: el,
        text: (`${separator.toUpperCase()} ${arrSplit[1]}`),
        seccionNumber: parseInt(arrSplit[1]),
        distrito: arrObj[el]
      });
    });

    auxS.sort((a, b) => (a.seccionNumber > b.seccionNumber) ? 1 : -1);

    return auxS;
  }

  toggleBottomSheet(show) {
      let body = document.getElementById('mainBody'),
          bottomSheetContainer = document.getElementById('app-bottom-sheet');
      
      if (show) {
          body.classList.add("bottom-sheet-body");
          bottomSheetContainer.classList.remove("d-none");
      } else {
          body.classList.remove("bottom-sheet-body");
          bottomSheetContainer.classList.add("d-none");
      }
      
      this.bottomSheetStatus = show;
  }

  toggleMenuResponsive(show) {
    let menuResponsive = document.getElementById('app-menu-responsive');

    if (show) {
      menuResponsive.classList.remove("d-none");
    } else {
      menuResponsive.classList.add("d-none");
    }
  }

  inicializarContienda(ruta) {
    let cont = ruta.split('/');

    this.changeContiendaActiva(cont[0]);
  }
  getContiendaDefault() {
    let auxCont = '';

    this.arrContiendasActivas.forEach(el => {
      if (auxCont == '') auxCont = el.contienda;
    });
    // for (const cont in this.arrContiendasActivas) {
    //   if (auxCont == '') auxCont = cont;
    // }

    return auxCont;
  }

  unselectContiendas() {
    localStorage.setItem('contienda', '');
    for (let i = 0; i < this.arrContiendasActivas.length; i++) {
      this.arrContiendasActivas[i].active = false;
    }
  }

  changeContiendaActiva(contienda) {
    if (contienda) {
      localStorage.setItem('contienda', contienda);

      for (let i = 0; i < this.arrContiendasActivas.length; i++) {
        if (this.arrContiendasActivas[i].contienda == contienda) this.arrContiendasActivas[i].active = true;
        else this.arrContiendasActivas[i].active = false;
      }
    }

  }

  getContienda() {
  	let arr_url_ini = window.location.href.split('//'),
      contienda = '';
  
  	let arr_url = arr_url_ini[1].split('/');
    // console.log('ENTRO ARR URL', arr_url);
    // console.log('*********************');

    for (let i = 0; i < arr_url.length; i++) {
      if (this.arrContiendas.indexOf(arr_url[i]) >= 0) contienda = arr_url[i];
    }
    // let contienda = this.__getContienda(arr_url, 1);
  	// localStorage.setItem('contienda', contienda);
    // console.log('*********************');
    // return localStorage.getItem('contienda');
//    console.log('CONTIENDA', contienda);
    return contienda;
  }

  __getContienda(arr_url, index) {
    // console.log('ENTRO ARR URL', arr_url[index]);
    // console.log('-------------');
    if (arr_url[index]) {
      if (this.arrContiendas.indexOf(arr_url[index]) >= 0) {console.log(arr_url[index]); return arr_url[index];}
      else this.__getContienda(arr_url, (index + 1));
    } else return '';
  }

  setearParametros() {
    localStorage.setItem('prep', JSON.stringify(this.detalles));
    this.rutasService.detalles = JSON.parse(localStorage.getItem('prep'));
    if (this.rutasService.detalles.seccion == 'votos-ppyci') {
      this.rutasService.detalles.seccion = 'Todas';
    }
    this.rutasService.construirRutas();
  }

  scrollToTop() {
	// this.pageScrollService.scroll({
	// 	document: this.document,
	// 	scrollTarget: ('#app-encabezado-anchor'),
	// 	duration: 500
	//   });
	window.scrollTo(0, 0);
  }

  cargarParametros() {
    if (localStorage.getItem('prep')) {
      this.detalles = JSON.parse(localStorage.getItem('prep'));
    } else {
      this.detalles = {
        circunscripcion: ''
      };
    }
  }

  compararParametros() {
    if (this.rutasService.migas.circunscripcion != this.rutaParametros.circunscripcionTexto) {
      this.rutasService.migas.circunscripcion = this.rutaParametros.circunscripcionTexto;
      this.combosService.cargarCombosEntidad()
          .subscribe((data) => {
			const diccionarioEntidades = data[this.rutaParametros.noEntidad - 1];

            this.rutasService.migas.entidad = diccionarioEntidades.migas;
          })
    }
  }

  toggleLateral() {
    this.menuLateral = !this.menuLateral;
    if(this.menuLateral) {
      this.leyendaMenu = 'Ocultar Menú';
    } else {
      this.leyendaMenu = 'Mostrar Menú';
    }
  }

  deepCopy(obj) {
    var copy;
    if (null == obj || "object" != typeof obj) return obj;
  
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.deepCopy(obj[i]);
        }
        return copy;
    }

    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
        }
        return copy;
    }

  }

  roundUpTen(number: number){
    if(number > 100000){
      return Math.ceil((number+1)/100000)*100000;
    }
    if(number > 10000){
      return Math.ceil((number+1)/10000)*10000;
    }
    if(number > 1000){
      return Math.ceil((number+1)/1000)*1000;
    }
    if(number > 100){
      return Math.ceil((number+1)/100)*100;
    }
    return Math.ceil((number+1)/10)*10;
  }

  back(): void {
    this.history.pop()
    if (this.history.length > 0) {
      this.location.back()
    } else {
      this.router.navigateByUrl(this.returnURL)
    }
  }
}
