<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 col-xl-10">
                        <app-breadcrumb
                            subtitle="Entidad"
                            subtitle_detalle="Votos por Partido Político"
                            [estado]="_obtenerDatosService?.config?.estado"
                        ></app-breadcrumb>
                        <div class="texto font-regular">
                            <p>
                                El total de votos calculado y porcentaje que se muestran, se refiere a los votos asentados en las Actas PREP hasta el momento.
                            </p>
                            <p>
                                Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%.
                            </p>
                        </div>
                    </div>
                    <div class="col-2 d-none d-xl-block" *ngIf="_obtenerDatosService?.config?.estado == 'Veracruz'">
                        <p class="px12 text-left"><strong>Visualiza como:</strong></p>
                        <div class="visualizar dis-flex flex-col text-center">
                            <div class="selector dis-flex w-100 cursorPointer">
                                <div class="activo izq dis-flex flex-col w-50 p-2">
                                    <img class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Grafica-B.svg">
                                    <!-- Mantener comentado <i class="far fa-chart-bar"></i> -->
                                    <p>Gráfica</p>
                                </div>
                                <div [routerLink]="['../tarjetas']" class="dis-flex der flex-col w-50 p-2">
                                    <img class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Tarjetas-B.svg" style="opacity: 0.5;">
                                    <!-- Mantener comentado <i class="ine ine-tarjetas" aria-hidden="true"></i> -->
                                    <p>Tarjetas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-resumen-votacion-mobile
                    [total_num]="resumen?.total?.total"
                    [total_prc]="resumen?.total?.prc"
                ></app-resumen-votacion-mobile>
                <div class="row pb-3 mt-2">
                    <div class="col">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                    </div>
                </div>
                <div class="row padding-b-10 d-none d-xl-block">
                    <div class="col-12 px-0">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <td class=""></td>
                                        <td class="fondoGrafica td-relative" *ngFor="let item of grafica_table"></td>
                                    </tr>
                                    <tr>
                                        <td class="td-relative table-borderedHeader sinBorde">
                                            <!-- <div class="my-chart-label "><b>Votos</b></div> -->
                                            <div class="linea-chart-nl line-chart-100 line-char-first ">{{ (total_votos | stringtoint) | formatmiles }}</div>
                                            <div class="linea-chart-nl line-chart-80 line-char-first ">{{ (((total_votos | stringtoint) * .8) | round) | formatmiles }}</div>
                                            <div class="linea-chart-nl line-chart-60 line-char-first ">{{ (((total_votos | stringtoint) * .6) | round) | formatmiles }}</div>
                                            <div class="linea-chart-nl line-chart-40 line-char-first ">{{ (((total_votos | stringtoint) * .4) | round) | formatmiles }}</div>
                                            <div class="linea-chart-nl line-chart-20 line-char-first ">{{ (((total_votos | stringtoint) * .2) | round) | formatmiles }}</div>
                                            <div class="linea-chart-nl line-chart-0 line-char-first ">0</div>
                                            <div class="my-chart ">
                                                <div style="height: 0px; "></div>
                                            </div>
                                        </td>
                                        <td class="fondoGrafica td-relative" *ngFor="let itemPartido of grafica_table; let indx = index;">
                                            <div class="linea-chart line-chart-100 "></div>
                                            <div class="linea-chart line-chart-80 "></div>
                                            <div class="linea-chart line-chart-60 "></div>
                                            <div class="linea-chart line-chart-40 "></div>
                                            <div class="linea-chart line-chart-20 "></div>
                                            <div class="linea-chart-nl line-chart-0 "></div>
                                            <div class="progress-bar-vertical">
                                                <div aria-valuemax="100" aria-valuemin="0" class="progress-bar progres-chart " placement="top" role="progressbar" [attr.title]="" tooltip="<p>{{ itemPartido?.header?.text }}</p><p>Total de votos: {{itemPartido?.data?.r1}}</p><p>Porcentaje: {{itemPartido?.data?.r2}}</p>"
                                                    [ngStyle]="{ 'height': itemPartido?.data?.porcentaje_votos,'background-color': itemPartido?.header?.color ? itemPartido?.header?.color : 'var(--bg-accesibilidad)'}">
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="pdv-0">
                                            <div class="cont-alg" *ngIf="_globalService.getContienda() != 'diputaciones'">
                                                <span class="px18 font-bold abttm">
                                                    <strong>Votos</strong>
                                                </span>
                                            </div>
                                        </td>
                                        <td class="text-center" *ngFor="let itemPartido of grafica_table; let indx = index;">
                                            <ng-container>
                                                <div class="dis-flex">
                                                    <img class="img-partido" [src]="pathImg+itemPartido.header?.imgs[0] " />
                                                    <!-- <span *ngIf="indx >= (grafica_table.length - 3)" class="font-medium px12" style="width: 90px;"><strong>{{itemPartido.header?.text}}</strong></span> -->
                                                </div>
                                            </ng-container>

                                        </td>
                                    </tr>
                                    <tr style="border-top: hidden;">
                                        <td class="pdv-0" style="border: none;">
                                            <span class="px12 font-bold" *ngIf="_globalService.getContienda() == 'diputaciones'">
                                                <strong>Votos</strong>
                                            </span>

                                            <span class="px12 font-bold" *ngIf="_globalService.getContienda() != 'diputaciones'">
                                                <strong>Total</strong>
                                            </span>
                                        </td>
                                        <td class="text-center pdv-0" *ngFor="let itemPartido of grafica_table; let indx = index;">
                                            <ng-container>
                                                <div>
                                                    <span class="px14 font-bold">
                                                        <strong>{{itemPartido.data?.r1}}</strong>
                                                    </span>
                                                </div>
                                            </ng-container>

                                        </td>
                                    </tr>
                                    <tr class="border3-t-rosaINE ">
                                        <td class="bg-gris-3 pdv-2" style="border: none;">
                                            <span class="px12">
                                                Porcentaje
                                            </span>
                                        </td>
                                        <td *ngFor="let item of grafica_table" class="text-center bg-rosa2 pdv-2">
                                            <span class="px12">
<!--                                                {{item.data?.r2?.toString()?.includes('%') ? item.data?.r2 : item.data?.r2+'%'}}-->
                                                {{item.data?.r2}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- TARJETAS MOVIL -->
                <movil-listado-content 
                    [selectedItemFunc]="selectedItemFun" 
                    [diselectedItemFunc]="diselectedItemFun"
                    [infoList]="fichas" 
                    [tipoTarjeta]="4"></movil-listado-content>

                <div class="container-fluid py-1">
                    <div class="row padding-b-10" *ngIf="_globalService.getContienda() == 'diputaciones'">
                        <div class="col-10">
                            <div class="texto font-medium">
                                <p>
                                    {{_obtenerDatosService?.main?.texto_porcentaje_estadistica ? 'Se incluye' : 'No se considera'}} la cantidad de votos asentada en las Actas PREP de
                                    Casilla Especial de
                                    Representación Proporcional.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <app-resumen-votacion
                    [acumulados_num]="resumen?.acumulados?.total"
                    [acumulados_prc]="resumen?.acumulados?.prc"
                    [noregistradas_num]="resumen?.no_registradas?.total"
                    [noregistradas_prc]="resumen?.no_registradas?.prc"
                    [nulos_num]="resumen?.nulos?.total"
                    [nulos_prc]="resumen?.nulos?.prc"
                    [total_num]="resumen?.total?.total"
                    [total_prc]="resumen?.total?.prc"
                ></app-resumen-votacion>

                <!-- <div class="row d-block d-xl-none pt-4 py-4">
                    <div class="col-md-12 dis-flex flex-col align-e">
                        <button [disabled]="comparativaService?.arregloElementos.length < 1"
                            (click)="comparativaService.reiniciarModal()"
                            class="boton-detalle btn bgMorado txBlanco btn-sm texto ml-2 px-4 cursorPointer"
                            data-toggle="modal" data-target="#modalComparativa">
                            Ver detalle
                        </button>
                    </div>
                </div> -->
            </div>
            <!--<div class="row" *ngIf="contienda_actual != 'ayuntamientos'">
                <div class="col-md-12">
                    <div class="card p-3 mt-3 mapa-puntos">
                        <div class="row justify-content-center">
                            <div class="col-12 col-xl-4 text-center">
                                <i class="fas fa-globe-americas"></i>
                                <h4 class="subtitulo-seccion py-3 text-center">
                                    <strong class="txRosaIne">Diputación Migranteeee</strong>
                                </h4>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <img class="img-partido" src="./assets/img/logos-partidos/{{estadistica_extranjero?.img?.toLowerCase()}}">
                        </div>
                        <div class="row justify-content-center row-votos-extranjero">
                            <p>Votos <span>{{estadistica_extranjero.votos}}</span></p>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-12 col-xl-4 dis-flex">
                                <button
                                    [routerLink]="['/diputaciones/votos-ppyci/diputacion-migrante']"
                                    class="btn btn-sm btn-rosaFuerte texto cursorPointer my-3 px-4 py-1">
                                    Ver Resultados
                                </button>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-6">
                                <p class="texto">
                                    Partido político o {{ _obtenerDatosService.config?.estado == 'Yucatán' ? 'Candidatura Común' : 'Coalición' }} que ha obtenido la Diputación Migrante hasta el momento
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->
            <div class="row" *ngIf="contienda_actual != 'ayuntamientos' && contienda_actual != 'juntas'">
                <div class="col-md-12">
                    <div class="card p-3 mt-3">
                        <div class="row justify-content-center">
                            <div class="col-12 col-xl-6">
                                <h4 class="subtitulo-seccion py-3 text-center">
                                    <strong>Representación Proporcional</strong>
                                </h4>
                                <p class="texto">
                                    El apartado “Actas RP” muestra la cantidad de votos asentada en las Actas PREP de Casilla Especial de Representación Proporcional, los cuales se suman únicamente a nivel Entidad.
                                </p>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-12 col-xl-4 dis-flex">
                                <button [routerLink]="['../representacion-proporcional']" class="btn btn-sm btn-rosaFuerte texto cursorPointer my-3 px-4 py-1">
                                    Ver Actas RP
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-estadistica-nacional [load_main]="true"></app-estadistica-nacional>
