<div class="container-fluid py-5">
    <div class="row justify-content-center">
        <div class="col-11">
            <span class="titulo">
                Definición
            </span> 
            <span class="subtitulo">
                - Base de Datos y utilidad -
            </span>
        </div>
    </div>
    <div class="row justify-content-center pb-5">
        <div class="col-5">
            <p>
                La <strong>base de datos</strong> se refiere al conjunto de información perteneciente al <strong>Programa de Resultados Electorales Preliminares</strong>, ordenada de forma sistemática para su descarga y análisis.​ Es actualizada conforme al último corte. Permite realizar distintas operaciones ya que incluye datos numéricos, alfanuméricos y cronológicos.​
            </p>
        </div>
    </div>
</div>