<div class="d-xl-none estadistica-casilla-movil card mt-3 mb-3 p-3">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <p class="subtitulo-seccion pleca-izquierda" *ngIf="tipoEstadistica == 1">
                    <strong>Estadística de la Casilla<br>{{nombreCasilla}}</strong>
                </p>
                
                <p class="subtitulo-seccion pleca-izquierda" *ngIf="tipoEstadistica == 2">
                    <strong>Estadística {{nombreCasilla}}</strong>
                </p>
            </div>
            <div class="subtitulo-participacion">
                <p *ngIf="tipoEstadistica != 4 && ( tipoEstadistica != 2 &&  contiendaActual != 'gubernatura')"><strong>Participación ciudadana con base en la Lista Nominal del Acta PREP Contabilizada</strong></p>
                <p *ngIf="tipoEstadistica == 4 || ( tipoEstadistica == 2 && contiendaActual == 'gubernatura')"><strong>Participación ciudadana con base en la Lista Nominal del Acta PREP Contabilizada</strong></p>
            </div>
        </div>
    </div>
    <div class="container-fluid" >
        <div class="row" *ngIf="tipoEstadistica == 1">
            <div class="col-12">
                <div class="grafica pt-3">
                    <div class="textos">
                        <p class="em1">{{nombreCasilla}}</p>
                        <p><strong>{{infoPorcentaje}}</strong></p>
                    </div>
                    <div class="container-interno">
                            <div class="barra" [ngStyle]="{ 'width.': infoPorcentaje }"></div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="tipoEstadistica == 2 || tipoEstadistica == 3 ">
            <div class="row" *ngFor="let casilla of infoParticipacion">
                <div class="col-12">
                    <div class="grafica pt-3">
                        <div class="textos" style="display: flex;">
                            <div class="w-50">
                                <p class="em6" *ngIf="tipoEstadistica == 2">{{casilla?.c1}}</p>
                                <p class="em6" *ngIf="tipoEstadistica == 3">{{casilla?.c1?.numero}} {{casilla?.c1?.text}}</p>
                                <p><strong>{{casilla?.c2}}</strong></p>
                            </div>
                            <div class="w-50">
                                <p class="em6" *ngIf="tipoEstadistica == 2" style="text-align: right;"><strong>Total Lista Nominal</strong></p>
                                <p *ngIf="tipoEstadistica == 2" style="text-align: right;"><strong>{{casilla?.c3}}</strong></p>
                            </div>
                        </div>
                        <div class="container-interno">
                                <div class="barra" [ngStyle]="{ 'width.': casilla?.c2}"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="tipoEstadistica == 4">
            <div class="col-12">
                <div class="grafica pt-3">
                    <div class="textos">
                        <p style="font-size: 0.8em;"><strong>{{nombreCasilla}}</strong></p>
                        <div style="display: flex;">
                            <div class="w-50">
                                <p><strong>{{listaNominal}}</strong></p>
                            </div>
                            <div class="w-50 align-right">
                                <p><strong>{{infoPorcentaje}}</strong></p>
                            </div>
                        </div>
                    </div>
                    <div class="container-interno">
                            <div class="barra" [ngStyle]="{ 'width.': infoPorcentaje }"></div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="lista-nominal row mt-3" *ngIf="tipoEstadistica != 2 && tipoEstadistica != 4 && tipoEstadistica != 1">
            <div class="col">
                <p><strong>Lista Nominal</strong></p>
                <h4 class="align-center">{{listaNominal}}</h4>
                <p class="align-center em8">Lista nominal de actas contablizadas</p>
            </div>
        </div>
        <div class="lista-nominal row mt-3" *ngIf="tipoEstadistica == 1">
            <div class="col">
                <p><strong>Lista Nominal</strong></p>
                <h4 class="align-center">{{listaNominal}}</h4>
                <p class="align-center em8 mb-4">Lista nominal de actas contablizadas</p>
                <p class="align-center em8">El cálculo de Participación ciudadana puede rebasar el 100% si votan todos los ciudadanos de la Lista Nominal de una casilla; así como los Representantes de Partido Político.</p>
            </div>
        </div>
    </div>
</div>