<div class="bottom-sheet">
    <div class="bottom-sheet-bg">

    </div>
	<div class="bottom-sheet-container">
		<div class="bottom-sheet-btn-container">
			<div class="bottom-sheet-close-btn" (click)="globalService.toggleBottomSheet(false)">
				Cerrar
			</div>
		</div>
        <div style="overflow-y: scroll; max-height: 75vh;">
            <ng-container *ngIf="tpl == 'default'">

                <p class="simple-title">{{ globalService?.bottomSheeTitle }}</p>
                <!-- <div *ngIf="globalService.bottomSheetSinDefinir" (click)="callback('')" class="bottom-sheet-item simple-item">
                    <input type="radio" value="Sin definir" name="bottom-sheet-list-item" [checked]="globalService.bottomSheetSinDefinirChecked"> <strong></strong>
                </div> -->
                <div (click)="callback('Todos')" class="bottom-sheet-item simple-item">
                    <input type="radio" value="Todos" name="bottom-sheet-list-item" [checked]="globalService.bottomSheetTodosChecked"> <strong>{{ globalService.bottomSheetTodosTxt ? globalService.bottomSheetTodosTxt : 'Todos' }}</strong>
                </div>
                <div *ngFor="let item of globalService.bottomSheetData;" (click)="callback(item)" class="bottom-sheet-item simple-item">
                    <input type="radio" value="{{item?.claveURL}}" name="bottom-sheet-list-item" [checked]="item?.checked"> <strong>{{item?.migas}}</strong>
                </div>

            </ng-container>
            <ng-container *ngIf="tpl == 'test'">
                SoyTest
            </ng-container>
            <ng-container *ngIf="tpl == 'simple'">
                <p class="simple-title">{{globalService?.bottomSheeTitle}}</p>
                <div *ngFor="let item of globalService.bottomSheetData;" (click)="globalService.bottomSheetCasillaCallback(item)" class="simple-item">
                    <strong>{{item?.text}}</strong>
                </div>
            </ng-container>
            <ng-container *ngIf="tpl == 'comparador-contienda'">
                <p class="simple-title">Puede agregar hasta tres opciones con el botón +.</p>
                <div class="container-list">
                    <div *ngFor="let item of globalService.bottomSheetData;" class="simple-item row" [hidden]="item.seleccionado">
                        <div class="col-10">
                            <div>
                                <img class="img-partido" *ngFor="let img of item?.imgs" [src]="'./assets/img/logos-partidos/'+ img?.toLowerCase()" >
                            </div>
                        </div>
                        <div class="col-2" >
                            <span class="btn-add" (click)="globalService.bottomSheetCallback(item)"><i class="fas fa-plus-circle"></i></span>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="tpl == 'comparador-candidato'">
                <p class="comparador-title">Puede agregar hasta tres opciones con el botón +.</p>
                <div class="container-list">
                    <div *ngFor="let item of globalService.bottomSheetData;" class="simple-item row" [hidden]="item.seleccionado">
                        <div class="col-2">
                            <img [src]="'./assets/img/avatar-candidatos/'+item?.img_perfil?.toLowerCase()" width="40px" height="40px">
                        </div>
                        <div class="col-8">
                            <p><strong>{{item?.text}}</strong></p>
                            <div>
                                <img class="img-partido" *ngFor="let img of item?.imgs" [src]="'./assets/img/logos-partidos/'+ img?.toLowerCase()" >
                                <img class="img-partido" *ngFor="let img of item?.partidos" [src]="'./assets/img/logos-partidos/'+ img?.toLowerCase()" >
                            </div>
                        </div>
                        <div class="col-2" >
                            <span class="btn-add" (click)="globalService.bottomSheetCallback(item)"><i class="fas fa-plus-circle"></i></span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

	</div>
</div>
