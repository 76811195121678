import { Component, OnInit } from '@angular/core';
import { ObtenerDatosService } from '../../../../servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';

@Component({
  selector: 'app-pregunta-votos-actas',
  templateUrl: './pregunta-votos-actas.component.html',
  styleUrls: ['./pregunta-votos-actas.component.scss']
})
export class PreguntaVotosActasComponent implements OnInit {
  config:any = {};
  Search = "";

  constructor(public readonly _obtenerDatosService : ObtenerDatosService, public _globalService: GlobalesService) { }

  ngOnInit(): void {
    this._globalService.searchItem.subscribe(data =>{
      if(data == null) return;
      this.Search = data
    });
    this._obtenerDatosService.configSubject.subscribe(data =>{
      if (data != null) this.config = data;
    });
  }

}
