import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConvertirMapaService {

  constructor(public http: HttpClient) { }

  public extractDataFromJSON(origenDatos: string) {
    return this.http.get(origenDatos);

    /* return this.http.get(origenDatos).map(res => {
      return res;
    }); */
  }


}
