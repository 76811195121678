<div class="container pregunta-buscador">
    <div class="row">
        <div class="col-12">
            <div class="card dis-flex flex-row space-b p-3">
                <div class="textos dis-flex justify-s">
                    <p>
                        <strong>
                            Busca palabras clave
                        </strong>
                    </p>
                    <input class="mx-3 px-1 sub" type="text" [(ngModel)]="Search" #ctrl="ngModel" name="FAQBuscador" id="FAQBuscador" [placeholder]="termino" (keydown.enter)="onSearch()">
                </div>
                <i class="fas fa-search px-3" aria-hidden="true" (click)="onSearch()"></i>
            </div>
        </div>
    </div>
</div>