import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InicioComponent } from './inicio/inicio.component';
import { InicioGuard } from './inicio.guard';


const routes: Routes = [
	{
		path: 'inicio',
		component: InicioComponent,
		canActivate: [InicioGuard]
	},

	// { path: 'path/:routeParam', component: MyComponent },
	// { path: 'staticPath', component: ... },
	// { path: '**', component: ... },
	// { path: 'oldPath', redirectTo: '/staticPath' },
	// { path: ..., component: ..., data: { message: 'Custom' }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AuthRoutingModule { }