import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { VotosDistritoComponent } from './votos-distrito/componentes/votos-distrito/votos-distrito.component';
import { ResumenDistritoComponent } from './votos-distrito/componentes/resumen-distrito/resumen-distrito.component';
// import { DistritoCircunscripcionComponent } from './votos-distrito/componentes/distrito-circunscripcion/distrito-circunscripcion.component';
// import { DistritoEntidadComponent } from './votos-distrito/componentes/distrito-entidad/distrito-entidad.component';
import { DistritoDistritoComponent } from './votos-distrito/componentes/distrito-distrito/distrito-distrito.component';
import { ResumenDistritoMapaComponent } from './votos-distrito/componentes/resumen-distrito-mapa/resumen-distrito-mapa.component';
import { SeccionResumenComponent } from './seccion/seccion-resumen/seccion-resumen.component';
import { CasillaResumenComponent } from './casilla/casilla-resumen/casilla-resumen.component';
// import { DistritoCircunscripcionMapaComponent } from './votos-distrito/componentes/distrito-circunscripcion-mapa/distrito-circunscripcion-mapa.component';
// import { DistritoEntidadMapaComponent } from './votos-distrito/componentes/distrito-entidad-mapa/distrito-entidad-mapa.component';
import { DistritoEntidadDetalleCasillaComponent } from './votos-distrito/componentes/distrito-entidad-detalle-casilla/distrito-entidad-detalle-casilla.component';
import { DistritoSeccionesComponent } from './votos-distrito/componentes/distrito-secciones/distrito-secciones.component';


// import { ResumenPartidoGraficaComponent } from './votos-partido/componentes/resumen-partido-grafica/resumen-partido-grafica.component';
// import { ResumenPartidoComponent } from './votos-partido/componentes/resumen-partido/resumen-partido.component';
import { AuthGuard } from '../../auth/auth.guard';
import { ContiendasComponent } from '../contiendas.component';

// seccion
// secciones
// casilla
// votos-candidatura
// votos-distrito
// votos-ppyci
// detalle-casilla
const routes: Routes = [
  {
    path: 'diputaciones',
    canActivate: [AuthGuard],
    component: ContiendasComponent,
    children: [
      {
        path: '',
        redirectTo: 'votos-distrito',
        pathMatch: 'full',
      },
      {
        path: 'votos-distrito',
        children: [
          {
            path: '',
            redirectTo: 'mapa',
            pathMatch: 'full'
          },
          {
            path: 'mapa',
            component: ResumenDistritoMapaComponent,
            data : { contienda : 'Distritos' }
          },
          {
            path: 'tarjetas',
            component: ResumenDistritoComponent,
            data : { contienda : 'Distritos' }
          },
        ]
      },
      {
        path: ':distrito/votos-candidatura',
        component: DistritoDistritoComponent,
        data : { contienda : 'Distritos', contienda_s : 'Distrito' }
      },
      {
        path: ':distrito/detalle-casilla',
        component: DistritoEntidadDetalleCasillaComponent,
      },
      {
        path: ':distrito/secciones',
        component: DistritoSeccionesComponent,
      },
      {
        path: ':distrito/secciones/:seccion',
        component: SeccionResumenComponent,
      },
      {
        path: ':distrito/secciones/:seccion/casilla/:casilla',
        component: CasillaResumenComponent,
      },
      // {
      //   path: 'votos-ppyci',
      //   component: VotosDistritoComponent,
      //   children: [
      //     {
      //       path: '',
      //       redirectTo: 'grafica',
      //       pathMatch: 'full'
      //     },
      //     {
      //       path: 'grafica',
      //       component: ResumenPartidoGraficaComponent,
      //     },
      //     {
      //       path: 'tarjetas',
      //       component: ResumenPartidoComponent,
      //     },
      //   ]
      // },
      // {
      //   path: 'votos-extranjero',
      //   component: DistritoDistritoComponent,
      // },
      // {
      //   path: 'casillas-especiales',
      //   component: DistritoDistritoComponent,
      // },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DiputacionDistritosRoutingModule { }
