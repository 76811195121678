import { Component, OnInit } from '@angular/core';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalesService } from 'src/app/servicios/globales.service';

// declare var $;
// import * as $ from 'jquery';
import { RutasService } from '../../../../../servicios/rutas/rutas.service';
import { ThemeService } from '../../../../../theme/theme.service';

@Component({
  selector: 'app-ppyci-entidad-grafica',
  templateUrl: './ppyci-entidad-grafica.component.html',
  styleUrls: ['./ppyci-entidad-grafica.component.scss']
})
export class PpyciEntidadGraficaComponent implements OnInit {
  base: string;
  rutaActual;
  constructor(
    public activatedRoute: ActivatedRoute,
    public _obtenerDatosService: ObtenerDatosService,
    public _rutasService:RutasService,
    public _globalService: GlobalesService,
    public themeService: ThemeService,
    public _router:Router) { }

    pathImg = './assets/img/logos-partidos/'

  ngOnInit(): void {
    this.obtenerParametro();
    /* Observable cambio de URL*/
    this._rutasService.observ.subscribe( texto =>{
    });
    this.base = this._rutasService.rutaActual.base;
    // $('[data-toggle="tooltip"]').tooltip();
  }

  obtenerParametro() {
    return new Promise((res, rej) => {
      this.activatedRoute.params.subscribe((param) => {
        this._globalService.detalles = param;
        res();
      });
    })
  }
  

}
