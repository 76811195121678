
<div id="mainDivApp" [ngStyle]="{'font-size.px': globalService.fontSize}" style="height: 100vh; overflow: scroll;">
	<router-outlet></router-outlet>
	
	<app-bottom-sheet id="app-bottom-sheet"
		class="d-none"
		[tpl]="globalService.bottomSheetTpl"
		[data]="globalService.bottomSheetData"
		[callback]="globalService.bottomSheetCallback"
	></app-bottom-sheet>

	<app-menu-responsive class="d-none" id="app-menu-responsive"></app-menu-responsive>
	<div class="modal fade" id="modalAvisoPrivacidad" tabindex="-1" role="dialog" aria-labelledby="modalAvisoPrivacidadTitle" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLongTitle">Aviso de privacidad</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-dismiss="modal">Aceptar</button>
				</div>
			</div>
		</div>
	</div>
</div>
