import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { ContiendasRoutingModule } from './contiendas/contiendas-routing.module';
import { WildcardRoutingModule } from './servicios/wildcard/wildcard-routing.module';



const routes: Routes = [
  {
    path: '',
    redirectTo: 'diputaciones/votos-distrito/mapa',
    pathMatch: 'full'
  },

	// { path: '**', component: NopagefoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
		ContiendasRoutingModule,
    AuthRoutingModule,
    // WildcardRoutingModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
