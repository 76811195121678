<div id="elementos-apoyo" class="anchor"></div>
<div class="container-fluid bgAzul txBlanco p-4 p-lg-5" >
    <div class="row">
        <div class="col-12 col-lg-10 p-0 p-lg-5">
            <h2 class="font-medium pb-4">- Elementos de apoyo -</h2>
            <div class="container">
                <div class="row">
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Es necesario actualizar en cada momento?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'No es necesario actualizar todo el tiempo, ya que la información en el PREP se actualiza cada 15 minutos. Sin embargo, puedes dar clic en el botón Actualizar para refrescar la página.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cómo puedo regresar a la página principal?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Puedes regresar a la página principal dándole clic en el logotipo del PREP, que se encuentra en la parte superior de la derecha. Si le das clic al logotipo del INE, te llevará a la página del INE.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿El chat está disponible las 24 horas?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'El servicio de chat estará activo únicamente por 24 horas, que empieza a las 8 pm del domingo, hasta las 8 pm del lunes, es el mismo tiempo que el PREP está contando las actas. En estas horas podrás atender las dudas correspondientes a la publicación
                        de los resultados preliminares.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿En las versiones móvil y escritorio puedo encontrar la misma información?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Desde luego, tanto en la versión móvil como en la de escritorio encontrarás la misma información de los resultados publicados en el PREP.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué información encontraré en la sección Ayuda?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Al dar clic en Ayuda ingresarás al sitio Centro de ayuda, donde encontrarás la explicación detallada de los elementos comprendidos en el proceso de votación: Qué es el PREP, Elecciones Federales, Voto y Tipos de Actas.' | highlightSearch: Search"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>