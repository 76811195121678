import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DiputacionPartidoRoutingModule } from './diputacion-partido-routing.module';

import { ResumenPartidoComponent } from './votos-partido/componentes/resumen-partido/resumen-partido.component';

import { VotosPartidoComponent } from './votos-partido/componentes/votos-partido/votos-partido.component';
import { PpyciCircunscripcionComponent } from './votos-partido/componentes/ppyci-circunscripcion/ppyci-circunscripcion.component';
import { PpyciEntidadComponent } from './votos-partido/componentes/ppyci-entidad/ppyci-entidad.component';
import { PpyciDistritoComponent } from './votos-partido/componentes/ppyci-distrito/ppyci-distrito.component';
import { PpyciSeccionComponent } from './votos-partido/componentes/ppyci-seccion/ppyci-seccion.component';
import { PpyciCasillaComponent } from './votos-partido/componentes/ppyci-casilla/ppyci-casilla.component';

import { DiputacionMigranteComponent } from './votos-partido/componentes/diputacion-migrante/diputacion-migrante.component';

// import { EstadisticaNacionalComponent } from '../../componentes/plantilla/estadistica-nacional/estadistica-nacional.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ResumenPartidoGraficaComponent } from './votos-partido/componentes/resumen-partido-grafica/resumen-partido-grafica.component';
// import { EstadisticaNacionalComponent } from './componentes/compartidos/estadistica-nacional/estadistica-nacional.component';

import { HighchartsChartModule } from 'highcharts-angular';
import { ChartModule } from 'angular-highcharts';
import { PpyciCircunscripcionGraficaComponent } from './votos-partido/componentes/ppyci-circunscripcion-grafica/ppyci-circunscripcion-grafica.component';
import { PpyciEntidadGraficaComponent } from './votos-partido/componentes/ppyci-entidad-grafica/ppyci-entidad-grafica.component';
// import { DistribucionVotos2Component } from './votos-partido/componentes/compartidos/distribucion-votos2/distribucion-votos2.component';
import { ResumenPartidoRepresentacionComponent } from './votos-partido/componentes/resumen-partido-representacion/resumen-partido-representacion.component';
// import { PpyciCircunscripcionRepresentacionComponent } from './votos-partido/componentes/ppyci-circunscripcion-representacion/ppyci-circunscripcion-representacion.component';
import { ImportingModule } from '../../importing.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ResumenPartidoComponent,
    VotosPartidoComponent,
    PpyciCircunscripcionComponent,
    PpyciEntidadComponent,
    PpyciDistritoComponent,
    PpyciSeccionComponent,
    PpyciCasillaComponent,
    ResumenPartidoGraficaComponent,
    // EstadisticaNacionalComponent,
    DiputacionMigranteComponent,
      
    PpyciCircunscripcionGraficaComponent,
    PpyciEntidadGraficaComponent,
    // DistribucionVotos2Component,
    ResumenPartidoRepresentacionComponent
    // PpyciCircunscripcionRepresentacionComponent
    // EstadisticaNacionalComponent
  ],
  imports: [
    CommonModule,
    DiputacionPartidoRoutingModule,
    TooltipModule,
      
    HighchartsChartModule,
    ChartModule,
    ImportingModule,
    RouterModule
  ]
})
export class DiputacionPartidoModule { }
