import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ResumenDistritoComponent } from '../diputacion/votos-distrito/componentes/resumen-distrito/resumen-distrito.component';
import { DistritoDistritoComponent } from '../diputacion/votos-distrito/componentes/distrito-distrito/distrito-distrito.component';
import { ResumenDistritoMapaComponent } from '../diputacion/votos-distrito/componentes/resumen-distrito-mapa/resumen-distrito-mapa.component';
import { SeccionResumenComponent } from '../diputacion/seccion/seccion-resumen/seccion-resumen.component';
import { DistritoSeccionesComponent } from '../diputacion/votos-distrito/componentes/distrito-secciones/distrito-secciones.component';


import { AuthGuard } from '../../auth/auth.guard';
import { ContiendasComponent } from '../contiendas.component';
import { CasillaResumenComponent } from '../diputacion/casilla/casilla-resumen/casilla-resumen.component';

const routes: Routes = [
  {
    path: 'juntas',
    canActivate: [AuthGuard],
    component: ContiendasComponent,
    children: [
      {
        path: '',
        redirectTo: 'votos-municipio',
        pathMatch: 'full',
      },
      {
        path: 'votos-municipio',
        children: [
          {
            path: '',
            redirectTo: 'mapa',
            pathMatch: 'full'
          },
          {
            path: 'mapa',
            component: ResumenDistritoMapaComponent,
            data : { contienda : 'Juntas'}
          },
          {
            path: 'tarjetas',
            component: ResumenDistritoComponent,
            data : { contienda : 'Juntas'}
          },
        ]
      },
      {
        path: ':municipio/votos-candidatura',
        component: DistritoDistritoComponent,
        data : { contienda : 'Juntas', contienda_s : 'Junta' }
      },
      {
        path: ':distrito/secciones',
        component: DistritoSeccionesComponent,
        data : { contienda : 'Juntas', contienda_s : 'Junta' }
      },
      {
        path: ':distrito/secciones/:seccion',
        component: SeccionResumenComponent,
      },
      {
        path: ':distrito/secciones/:seccion/casilla/:casilla',
        component: CasillaResumenComponent,
      },
      // {
      //   path: ':distrito/secciones/:seccion/casilla/:casilla',
      //   component: CasillaResumenComponent,
      // },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JuntaCandidaturaRoutingModule { }
