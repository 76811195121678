<div class="casilla-resumen container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 col-lg-10">
                        <app-breadcrumb
                            subtitle="Detalle del Distrito"
                            subtitle_detalle="Detalle por Casilla"
                            [estado]="_obtenerDatosService?.config?.estado"
                            [distrito]="rutas.migas?.distrito"
                            [distrito_param]="rutas.migas?.distrito_clave"
                        ></app-breadcrumb>
                        <div class="texto font-regular">
                            
                            <p *ngIf="contienda_actual == 'gubernatura'">
                                El total de votos calculado y porcentaje que se muestran, se refieren a los votos asentados en las Actas PREP hasta el momento.
                            </p>
                            <p *ngIf="contienda_actual == 'diputaciones'">
                                El total de votos calculado y porcentaje que se muestran, se refieren a los votos asentados en las Actas PREP hasta el momento.
                            </p>
                            <p>
                                Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%.
                            </p>
                        </div>
                    </div>
                </div>
                <app-resumen-votacion-mobile
                    [total_num]="resumen?.total?.total"
                    [total_prc]="resumen?.total?.prc"
                ></app-resumen-votacion-mobile>
                <div class="row pb-4">
                    <div class="col">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                    </div>
                </div>
                <!-- TARJETAS DE ESCRITORIO -->
                <div class="container-fluid d-none d-md-none d-xl-block">
                    <div class="row dis-flex pb-1">
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollLeft()">
                            <i class="fas fa-chevron-left" aria-hidden="true"></i>
                        </div>
                        <div class="col-11">
                            <div #widgetsContent class="row dis-flex justify-s scrollH">
                                <div *ngFor="let tarjeta of fichas; let i=index;" class="col-6 col-sm-4 col-md-3 col-lg-2 px-1">
                                    <div class="card-resumen">
                                        <div class="encabezado dis-flex w-100 py-2">
                                            <div class="dis-flex w-100">
                                                <div class="row w-100 dis-flex">
                                                    <img class="img-partido" *ngFor="let img of tarjeta?.imgs" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cuerpo dis-flex bgGrisTenue p-2">
                                            <div class="w-50">
                                                <p>Votos</p>
                                                <p class="votos">
                                                    <strong>{{ tarjeta?.total }}</strong>
                                                </p>
                                            </div>
                                            <div class="w-50 dis-flex flex-col align-e pr-3 pr-xl-0">
                                                <p>Porcentaje</p>
                                                <p>{{ tarjeta?.porcentaje }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollRight()">
                            <i class="fas fa-chevron-right" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                <!-- TARJETAS MOVIL -->
                <movil-listado-content 
                    [infoList]="fichas" 
                    [tipoTarjeta]="6"></movil-listado-content>
                <!--********************************************************************************************************************-->

                <!-- <p class="p-3" style="font-size: 1.4em; text-align: justify;">Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%.</p> -->
                <app-resumen-votacion
					[acumulados_num]="resumen?.acumulados?.total"
					[acumulados_prc]="resumen?.acumulados?.prc"
					[noregistradas_num]="resumen?.no_registradas?.total"
					[noregistradas_prc]="resumen?.no_registradas?.prc"
					[nulos_num]="resumen?.nulos?.total"
					[nulos_prc]="resumen?.nulos?.prc"
					[total_num]="resumen?.total?.total"
					[total_prc]="resumen?.total?.prc"
                ></app-resumen-votacion>

            </div>
        </div>
    </div>
    <app-detalle-votos-casilla [showCATD]="showCATD"></app-detalle-votos-casilla>
    <app-participacion-ciudadana></app-participacion-ciudadana>
    
    <movil-estadistica-content
        [tipoEstadistica]="3"
        [nombreCasilla]="rutas.migas?.seccion"
        [infoParticipacion]="_obtenerDatosService.participacion_table"
        [listaNominal]="lista_nominal"
    ></movil-estadistica-content>
    
    <div class="row" id="observaciones">
        <div class="col-md-12">
            <div class="estadistica-casilla observacionesActa card mt-3 p-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <h4 class="subtitulo-seccion pleca-izquierda">
                                <strong>Observaciones en Acta</strong>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-4">
                    <div class="row justify-content-center">
                        <div class="col-11">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <h4 class="subtitulo-seccion pleca-izquierda">
                                            <strong class="txRosa2">Contabilizadas</strong>
                                        </h4>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Ilegible
                                                </strong>
                                            </p>
                                            <p>
                                                La cantidad de votos en algún campo del Acta PREP no puede leerse, tanto en letra como en número.
                                            </p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Sin Dato
                                                </strong>
                                            </p>
                                            <p>
                                                La cantidad de votos no fue asentada en el Acta PREP ni en letra ni en número en algún campo del Acta PREP.
                                            </p>
                                        </div>
                                        <div class="grupo py-3 mb-2">
                                            <p class="pb-2">
                                                <strong>
                                                    Identificación por Proceso Técnico Operativo
                                                </strong>
                                            </p>
                                            <p>
                                                Los datos del Acta PREP no se identificaron y sé solicitó verificarlos por algún procedimiento válido establecido en el Proceso Técnico Operativo.
                                            </p>
                                        </div>
                                        <h4 class="subtitulo-seccion pleca-izquierda pleca-morada mt-5">
                                            <strong><span class="txMorado">No identificadas -</span> Fuera del catálogo</strong>
                                        </h4>
                                        <div class="grupo py-3">
                                            <p>
                                                No es posible ubicar el Acta PREP dentro de la lista de Actas de casillas aprobadas por el INE, al presentar alguna omisión, ilegibilidad o error en sus datos de identificación. Para fines de publicación, el Acta se muestra al final del listado de Actas
                                                aprobadas en la  Base de Datos.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <h4 class="subtitulo-seccion pleca-izquierda pleca-aqua">
                                            <strong class="txAqua">No Contabilizadas</strong>
                                        </h4>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Excede Lista Nominal
                                                </strong>
                                            </p>
                                            <p>
                                                La suma de todos los votos en el Acta PREP, excede el número de ciudadanas y ciudadanos en la Lista Nominal de Electores de la casilla, incluido el número de votos emitidos por los representantes de los Partidos Políticos.
                                            </p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Sin Acta
                                                </strong>
                                            </p>
                                            <p class="pb-2">
                                                El Acta PREP no llegó junto con el paquete electoral al Centro de Acopio y Transmisión de Datos (CATD), por algunas de las siguientes causas:
                                            </p>
                                            <p>
                                                1. Sin Acta por paquete no entregado.
                                            </p>
                                            <p>
                                                2. Sin Acta por casilla no instalada.
                                            </p>
                                            <p>
                                                3. Sin Acta por paquete entregado sin bolsa.
                                            </p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Todos los campos ilegibles
                                                </strong>
                                            </p>
                                            <p>
                                                Todos los campos del Acta PREP correspondientes al número de votos, son ilegibles tanto en letra como en número.
                                            </p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Todos los campos vacíos
                                                </strong>
                                            </p>
                                            <p>
                                                Todos los campos del Acta PREP correspondientes al número de votos, se encuentran vacíos tanto en letra como en número.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
