import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-votos-partido',
  templateUrl: './votos-partido.component.html',
  styleUrls: ['./votos-partido.component.scss']
})
export class VotosPartidoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
