<!-- Formato de Celdas -->
<div class="contenido container-fluid bgRosa txBlanco py-5">
    <div class="row justify-content-center">
        <div class="col-11">
            <h2>Consulta</h2>
            <h3 class="font-medium">- Formato de celdas -</h3>
            <p class="py-2">
                Para una lectura correctade las fechas y horas de<br>
                corte, cambia el formato de celdas de las siguientes<br>
                columnas a dd/mm/aaaa hh:mm​
            </p>
            <div class="container-fluid px-0">
                <div class="row">
                    <div class="col-6">
                        <div class="item pt-2 pb-2">
                            <p>
                                <strong>1.</strong> Selecciona las columnas
                            </p>
                            <p>
                                <strong>2.</strong> Da clic derecho en e mouse
                            </p>
                            <p>
                                <strong>3.</strong> Selecciona "Formato de celdas"
                            </p>
                            <p>
                                <strong>4.</strong> Elige el formato <br>
                                "dd/mm/aaaa hh:mm" y <strong>Acepta</strong>
                            </p>
                        </div>
                    </div>
                    <div class="col-6">
                        <!-- <img src="./assets/img/basededatos/carpetaarchivos.gif" alt=""> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Interpretacion -->
<div class="container-fluid py-5">
    <div class="row justify-content-center">
        <div class="col-11">
            <h3 class="font-medium txRosa">- Interpretación de Base de Datos -</h3>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-11">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-11">
                        <div class="item">
                            <p>
                                A continuación te presentamos cuáles son los elementos que conforman el archivo de la base de datos.​
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-11 pt-5">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-11">
                       
                            <div class="item">
                                <p>
                                    <span class="txRosa">Fila 1</span>
                                    <br>
                                    Tipo de elección
                                </p>
                            </div>
                    </div>
                    <div class="col-11 pt-2">
                        <div class="row">

                            <div class="col-8">
                                <div class="item">
                                    <p>
                                        <span class="txRosa">Fila 2</span>
                                        <br>
                                        <strong>Fecha y hora del corte de información</strong> con el formato <strong>DD/MM/AAAA HH:MM.</strong>
                                        <br>
                                        Se especifica la hora en formato de 24h, así como el uso horario del Centro de México.
                                    </p>
                                    <p>
                                        Si deseas una revisión detallada de las variables de la Base de Datos descarga el Manual Interpretación de la Base de Datos PREP, junio 2021.
                                    </p>
                                </div>
                            </div>
                            <div class="col-4">
                                <img src="./assets/img/basededatos/fila2.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-11">
                        <div class="item">
                            <p>
                                <span class="txRosa">Fila 3</span>
                                <br>
                                Variables del resumen de la base de datos:
                            </p>
                        </div>
                    </div>
                    <div class="col-11">
                        <div class="item">
                            <p>
                                <span class="txRosa">Fila 4</span>
                                <br>
                               Resultado de las variables del resumen de la base de datos
                            </p>
                        </div>
                    </div>
                    <div class="col-11">
                        <div class="item">
                            <p>
                                <span class="txRosa">Fila 6</span>
                                <br>
                               Indica las variables de los resultados de votación, las cuales se pueden agrupar en:
                            </p>
                        </div>
                    </div>
                    <div class="col-11">
                        <div class="item">
                            <p>
                                <span class="txRosa">Fila 7</span>
                                <br>
                               A partir de esta fila se indica los datos correspondientes a cada Acta.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>