<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 col-xl-10">
                        <app-breadcrumb [subtitle]="subTitle" [subtitle_detalle]="subTitleDetalle"
                            [estado]="_obtenerDatosService?.config?.estado"></app-breadcrumb>
                        <div class="texto font-regular">
                            <p>
                                El total de votos calculado y porcentaje que se muestran, se refieren a los votos asentados en las Actas PREP hasta el momento.
                            </p>
                        
                            <p>
                                Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No
                                obstante, al considerar todos los decimales, suman 100%.
                            </p>
                        </div>
                    </div>

                    <div class="col-2 d-none d-xl-block" *ngIf="_obtenerDatosService?.config?.estado == 'Veracruz'">
                        <p class="px12 text-left"><strong>Visualiza como:</strong></p>
                        <div class="visualizar dis-flex flex-col text-center">
                            <div class="selector dis-flex w-100 cursorPointer">
                                <div class="activo izq dis-flex flex-col w-50 p-2">
                                    <img *ngIf="!themeService.isDarkTheme()" class="img-visualiza img-fluid"
                                        src="assets/img/iconos-visualiza/Grafica-B.svg">
                                    <img *ngIf="themeService.isDarkTheme()" class="img-visualiza img-fluid"
                                        src="assets/img/iconos-visualiza/Grafica-A-dark.svg">
                                    <!-- <i class="ine ine-mapa" aria-hidden="true"></i> -->
                                    <p>Gráfica</p>
                                </div>
                                <div [routerLink]="['../tarjetas']" class="dis-flex der flex-col w-50 p-2">
                                    <img *ngIf="!themeService.isDarkTheme()" class="img-visualiza img-fluid"
                                        src="assets/img/iconos-visualiza/Tarjetas-A.svg" style="opacity: 0.5;">
                                    <img *ngIf="themeService.isDarkTheme()" class="img-visualiza img-fluid"
                                        src="assets/img/iconos-visualiza/Tarjetas-B.svg" style="opacity: 0.5;">
                                    <!-- <i class="ine ine-tarjetas" aria-hidden="true"></i> -->
                                    <p>Tarjetas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-resumen-votacion-mobile [total_num]="resumen?.total?.total" [total_prc]="resumen?.total?.prc">
                </app-resumen-votacion-mobile>

                <div class="d-block d-xl-none" *ngIf="!omitirExtranjero">
                    <p class="movil-titulo-votos">Total de votos en:</p>
                    <div class="dis-flex ml-2">
                        <div class="w-50 votos-container ml-3 mr-1">
                            <p class="label-tipo ml-1"><strong>La Entidad</strong></p>
                            <p class="label-cantidad mr-2">{{resumen?.acumulados?.total}}</p>
                        </div>
                        <div class="w-50 votos-container mr-2">
                            <p class="label-tipo ml-1"><strong>El Extranjero</strong></p>
                            <p class="label-cantidad mr-2">{{resumen?.extranjero?.total}}</p>
                        </div>
                    </div>
                </div>

                <div class="row pb-3 mt-2">
                    <div class="col">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                    </div>
                </div>
                <div class="row padding-b-10 d-none d-md-none d-xl-block">
                    <div class="col-12 px-0">
                        <div class="table-responsive">
                            <table class="table ft-14">
                                <thead>
                                    <tr>
                                        <td class="" style="background-color: transparent;"></td>
                                        <td class="fondoGrafica td-relative" *ngFor="let item of grafica_table" style="background-color: transparent;"></td>
                                    </tr>
                                    <tr>
                                        <td class="td-relative table-borderedHeader sinBorde" style="background-color: transparent;">
                                            <!-- <div class="my-chart-label "><b>Votos</b></div> -->
                                            <div class="linea-chart-nl line-chart-100 line-char-first ">{{ (total_votos
                                                | stringtoint) |
                                                formatmiles }}</div>
                                            <div class="linea-chart-nl line-chart-80 line-char-first ">{{ (((total_votos
                                                | stringtoint) * .8)
                                                | round) | formatmiles }}</div>
                                            <div class="linea-chart-nl line-chart-60 line-char-first ">{{ (((total_votos
                                                | stringtoint) * .6)
                                                | round) | formatmiles }}</div>
                                            <div class="linea-chart-nl line-chart-40 line-char-first ">{{ (((total_votos
                                                | stringtoint) * .4)
                                                | round) | formatmiles }}</div>
                                            <div class="linea-chart-nl line-chart-20 line-char-first ">{{ (((total_votos
                                                | stringtoint) * .2)
                                                | round) | formatmiles }}</div>
                                            <div class="linea-chart-nl line-chart-0 line-char-first ">0</div>
                                            <div class="my-chart ">
                                                <div style="height: 0px; "></div>
                                            </div>
                                        </td>
                                        <td class="fondoGrafica td-relative" *ngFor="let itemPartido of grafica_table" style="background-color: transparent;"
                                            [ngClass]="{'grapGanador-init': itemPartido?.winner}">
                                            <div class="linea-chart line-chart-100 " [ngClass]="{'hide-line': itemPartido?.winner}"></div>
                                            <div class="linea-chart line-chart-80 "></div>
                                            <div class="linea-chart line-chart-60 "></div>
                                            <div class="linea-chart line-chart-40 "></div>
                                            <div class="linea-chart line-chart-20 "></div>
                                            <div class="linea-chart-nl line-chart-0 "></div>
                                            <div class="progress-bar-vertical">
                                                <div aria-valuemax="100" aria-valuemin="0"
                                                    class="progress-bar progress-chart " placement="top"
                                                    role="progressbar" [attr.title]=""
                                                    tooltip="<p>{{ itemPartido?.header?.text }}</p><p>Total de votos: {{itemPartido?.total}}</p><p>Porcentaje: {{itemPartido?.porcentaje}}</p>"
                                                    [ngStyle]="{ 'height': itemPartido?.porcentaje_votos, 'background-color': itemPartido?.color ? itemPartido?.color : 'var(--bg-accesibilidad)'}">
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                        </td>
                                        <td class="text-center"
                                            *ngFor="let itemPartido of grafica_table; let indx = index;"
                                            [ngClass]="{'grapGanador-middle': itemPartido?.winner}">
                                            <div>
                                                <img class="avatar"
                                                    src="./assets/img/avatar-candidatos/{{itemPartido?.img_perfil}}">
                                            </div>
                                        </td>
                                    </tr>
                                    
                                    <tr class="no-bort">
                                        <td>
                                        </td>
                                        <td class="text-center pdv-0"
                                            *ngFor="let itemPartido of grafica_table; let indx = index;"
                                            [ngClass]="{'grapGanador-middle': itemPartido?.winner}">
                                            <div>
                                                <span class="14px font-bold"
                                                    [ngClass]="{'cardGanador-titulo': itemPartido?.winner}">
                                                    {{itemPartido.text}}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="no-bort">
                                        <td class="pdv-0 first-cell" style="border: none;">
                                            <div class="cont-alg">
                                                <span class="px18 font-bold abttm">
                                                    <strong>Votos</strong>
                                                </span>
                                            </div>
                                        </td>
                                        <td class="text-center"
                                            *ngFor="let itemPartido of grafica_table; let indx = index;"
                                            [ngClass]="{'grapGanador-middle': itemPartido?.winner}">
                                            <ng-container>
                                                <div>
                                                    <img *ngFor="let img of itemPartido.partidos" class="img-partido"
                                                        [src]="'./assets/img/logos-partidos/'+img?.toLowerCase()" />
                                                </div>
                                            </ng-container>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td style="border-top: none;" class="pdv-0">
                                            <span class="em10 font-bold">
                                                <strong>Total</strong>
                                            </span>
                                        </td>
                                        <td class="text-center pdv-0"
                                            *ngFor="let itemPartido of grafica_table; let indx = index;"
                                            [ngClass]="{'grapGanador-middle': itemPartido?.winner, 'no-bort': itemPartido?.winner || !itemPartido?.winner}">
                                            <ng-container>
                                                <div>
                                                    <span class="font-bold"
                                                        [ngClass]="{'cardGanador-total_votos': itemPartido?.winner}">
                                                        <strong>{{itemPartido.total}}</strong>
                                                    </span>
                                                </div>
                                            </ng-container>
                                        </td>
                                    </tr>
                                    <tr class="border3-t-rosaINE ">
                                        <td class="bg-gris-3 pdv-2">
                                            <span class="px12">
                                                Porcentaje
                                            </span>
                                        </td>
                                        <td *ngFor="let item of grafica_table; let indx = index;"
                                            class="text-center bg-rosa2 pdv-2"
                                            [ngClass]="{'grapGanador-middle': (item?.winner && !omitirExtranjero), 'grapGanador-end': (item?.winner && omitirExtranjero)}">
                                            <span class="ft-10">
                                                {{item.porcentaje}}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class="border3-t-rosaINE "  *ngIf="!omitirExtranjero">
                                        <td class="bg-gris-3 pdv-2">
                                            <span class="px12">
                                                <strong>En la Entidad</strong>
                                            </span>
                                        </td>
                                        <td *ngFor="let item of grafica_table; let indx = index;"
                                            class="text-center bg-rosa2 pdv-2"
                                            [ngClass]="{'grapGanador-middle': (item?.winner && !omitirExtranjero), 'grapGanador-end': (item?.winner && omitirExtranjero)}">
                                            <span class="ft-10">
                                                {{item.votos_entidad}}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr class="border3-t-rosaINE " *ngIf="!omitirExtranjero">
                                        <td class="bg-gris-3 pdv-2">
                                            <span class="px12">
                                                <strong>En el Extrajero</strong>
                                            </span>
                                        </td>
                                        <td *ngFor="let item of grafica_table; let indx = index;"
                                            class="text-center bg-rosa2 pdv-2" [ngClass]="{'grapGanador-end': item?.winner}">
                                            <span class="ft-10">
                                                {{item.votos_extranjero}}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- TARJETAS MOVIL -->
                <movil-listado-content 
                    [selectedItemFunc]="selectedItemFun" 
                    [diselectedItemFunc]="diselectedItemFun" 
                    [infoList]="fichas"
                    [tipoTarjeta]="1"></movil-listado-content>

                <!-- <p class="p-3" style="font-size: 1.4em; text-align: justify;">Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%.</p> -->
                <app-resumen-votacion extranjero="true" [acumulados_num]="resumen?.acumulados?.total"
                    [acumulados_prc]="resumen?.acumulados?.prc" [extranjero_num]="resumen?.extranjero?.total"
                    [extranjero_prc]="resumen?.extranjero?.prc" [noregistradas_num]="resumen?.no_registradas.total"
                    [noregistradas_prc]="resumen?.no_registradas?.prc" [nulos_num]="resumen?.nulos?.total"
                    [nulos_prc]="resumen?.nulos?.prc" [total_num]="resumen?.total?.total"
                    [total_prc]="resumen?.total?.prc"></app-resumen-votacion>
                    
                <!-- <div class="container-fluid py-4 d-block d-md-block d-xl-none">
                    <div class="row">
                        <div class="col-md-12 dis-flex justify-e">
                            <button [disabled]="_comparativaService?.arregloElementos.length < 1"
                                (click)="_comparativaService.reiniciarModal()"
                                class="boton-detalle btn bgMorado txBlanco btn-sm texto ml-2 px-4 cursorPointer" data-toggle="modal"
                                data-target="#modalComparativa">
                            Ver detalle
                            </button>
                        </div>
                    </div>
                </div> -->
            </div>

            <div class="row" *ngIf="!omitirExtranjero">
                <div class="col-md-12">
                    <div class="card p-3 mt-3 mapa-puntos">
                        <div class="row justify-content-center">
                            <div class="col-12 col-xl-4 text-center">
                                <i class="fas fa-globe-americas"></i>
                                <h4 class="subtitulo-seccion py-3 text-center">Voto en el Extranjero</h4>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-6 col-xl-4 dis-flex">
                                <button [routerLink]="['/gubernatura/votos-ppyci/voto-extranjero']"
                                    class="btn btn-sm btn-rosaFuerte texto cursorPointer my-3 px-4 py-1">
                                    Ver Resultados
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<app-estadistica-nacional [load_main]="true"></app-estadistica-nacional>