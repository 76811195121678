import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { VotosCandidaturaGraficaComponent } from './votos-candidatura/votos-candidatura-grafica.component';
import { VotosCandidaturaTarjetasComponent } from './votos-candidatura/votos-candidatura-tarjetas.component';

// import { VotosPpyciGraficaComponent } from './votos-ppyci/votos-ppyci-grafica.component';
import { DistritoDistritoComponent } from '../diputacion/votos-distrito/componentes/distrito-distrito/distrito-distrito.component';
import { DistritoEntidadDetalleCasillaComponent } from '../diputacion/votos-distrito/componentes/distrito-entidad-detalle-casilla/distrito-entidad-detalle-casilla.component';
import { DistritoSeccionesComponent } from '../diputacion/votos-distrito/componentes/distrito-secciones/distrito-secciones.component';

import { SeccionResumenComponent } from '../diputacion/seccion/seccion-resumen/seccion-resumen.component';

// import { VotosPpyciTarjetasComponent } from './votos-ppyci/votos-ppyci-tarjetas.component';
// import { DistritoVotosCandidaturaComponent } from './distritos/distrito-votos-candidatura.component';
import { ContiendasComponent } from '../contiendas.component';
import { AuthGuard } from '../../auth/auth.guard';
import { CasillaResumenComponent } from '../diputacion/casilla/casilla-resumen/casilla-resumen.component';

const routes: Routes = [
  {
    path: 'gubernatura',
    component: ContiendasComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'votos-candidatura/grafica',
        pathMatch: 'full'
      },
      {
        path: 'votos-candidatura',
        children: [
          {
            path: '',
            redirectTo: 'grafica',
            pathMatch: 'full'
          },
          {
            path: 'grafica',
            component: VotosCandidaturaGraficaComponent,
          },
          {
            path: 'tarjetas',
            component: VotosCandidaturaTarjetasComponent,
          },
        ]
      },
      {
        path: ':distrito/votos-candidatura',
        component: DistritoDistritoComponent,
        data : { contienda : 'Distrito', contienda_s : 'Distrito' }
      },
      {
        path: ':distrito/detalle-casilla',
        component: DistritoEntidadDetalleCasillaComponent,
        data : { contienda : 'Distrito', contienda_s : 'Distrito' }
      },
      {
        path: ':distrito/secciones',
        component: DistritoSeccionesComponent,
      },
      {
        path: ':distrito/secciones/:seccion',
        component: SeccionResumenComponent,
      },
      {
        path: ':distrito/secciones/:seccion/casilla/:casilla',
        component: CasillaResumenComponent,
      },
      // {
      //   path: 'votos-ppyci',
      //   children: [
      //     {
      //       path: '',
      //       redirectTo: 'grafica',
      //       pathMatch: 'full'
      //     },
      //     {
      //       path: 'grafica',
      //       component: VotosPpyciGraficaComponent,
      //     },
      //     // {
      //     //   path: 'tarjetas',
      //     //   component: VotosPpyciTarjetasComponent,
      //     // },
      //   ]
      // },
      // {
      //   path: ':distrito/votos-candidatura',
      //   component: DistritoVotosCandidaturaComponent,
      // },
      // {
      //   path: ':distrito/votos-ppyci',
      //   component: DistritoVotosCandidaturaComponent,
      // },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GubernaturaCandidaturaRoutingModule { }
