<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 col-xl-10">
                        <app-breadcrumb subtitle="Entidad"
                            subtitle_detalle="Votos por Partido Político"
                            [estado]="_obtenerDatosService?.config?.estado"></app-breadcrumb>
                        <div class="texto font-regular">
                            <p>
                                El total de votos calculado y porcentaje que se muestran, se refiere a los votos
                                asentados en las Actas
                                PREP hasta el momento.
                                <br>
                            </p>
                            <p>
                                Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No
                                obstante, al
                                considerar todos los decimales, suman 100%.
                                <br>
                            </p>
                        </div>
                    </div>
                    <div class="col-2 d-none d-xl-block" *ngIf="_obtenerDatosService?.config?.estado == 'Veracruz'">
                        <p class="px12 text-left"><strong>Visualiza como:</strong></p>
                        <div class="visualizar dis-flex flex-col text-center">
                            <div class="selector dis-flex w-100 cursorPointer">
                                <div [routerLink]="['../grafica']" class="izq dis-flex flex-col w-50 p-2">
                                    <img *ngIf="!themeService.isDarkTheme()" class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Grafica-A.svg" style="opacity: 0.5;">
                                    <img *ngIf="themeService.isDarkTheme()" class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Grafica-A-dark.svg" style="opacity: 0.5;">
                                    <!-- <i class="far fa-chart-bar"></i> -->
                                    <p>Gráfica</p>
                                </div>
                                <div class="activo der dis-flex flex-col w-50 p-2">
                                    <img *ngIf="!themeService.isDarkTheme()" class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Tarjetas-A.svg">
                                    <img *ngIf="themeService.isDarkTheme()" class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Tarjetas-B.svg">
                                    <!-- <i class="ine ine-tarjetas" aria-hidden="true"></i> -->
                                    <p>Tarjetas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-resumen-votacion-mobile
                    [total_num]="resumen?.total?.total"
                    [total_prc]="resumen?.total?.prc"
                ></app-resumen-votacion-mobile>
                <div class="row pb-3 mt-2">
                    <div class="col">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                    </div>
                </div>
                <!-- TARJETAS DE ESCRITORIO -->
                <div class="container-fluid d-none d-md-none d-xl-block">
                    <div class="row dis-flex pb-1">
                        <div class="col-md-12">
                            <div class="row dis-flex justify-s">
                                <div *ngFor="let tarjeta of fichas; let indx = index;"
                                    class="col-6 col-sm-4 col-md-3 col-lg-2 px-1">
                                    <div class="card-resumen mb-1"
                                        [ngClass]="{cardGanador: tarjeta?.winner}">
                                        <!-- <div class="card-resumen my-1"> -->
                                        <div class="encabezado dis-flex w-100 py-2">
                                            <div class="dis-flex w-50">
                                                <div class="row w-80 dis-flex"
                                                    [ngClass]="{'justify-s': tarjeta?.imgs?.length > 1}">
                                                    <!-- <img class="img-partido" [src]="_obtenerDatosService.pathImg+_obtenerDatosService.diccionarioPartidos[tarjeta?.partido]?.imgPartido?.toLowerCase() " /> -->
                                                    <img class="img-partido" [src]="'./assets/img/logos-partidos/'+tarjeta?.imgs[0]?.toLowerCase() ">
<!--
                                                    <p class="pe p-0" [ngStyle]="{
                                                        'background-image': 'url(./assets/img/logos-partidos/'+  tarjeta?.header?.imgs[0]?.toLowerCase() +')',
                                                        'background-size': 'auto 27px',
                                                        'background-repeat': 'no-repeat',
                                                        'background-position': 'left center'
                                                    }">
                                                    </p>
-->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cuerpo dis-flex bgGrisTenue p-2">
                                            <div class="w-50">
                                                <p>Votos</p>
                                                <p class="votos">
                                                    <strong>{{ tarjeta?.total }}</strong>
                                                </p>
                                            </div>
                                            <div class="w-50 dis-flex flex-col align-e pr-3 pr-xl-0">
                                                <p>&nbsp;</p>
                                                <p>{{ tarjeta?.porcentaje }}</p>
                                            </div>
                                        </div>
                                        <div *ngIf="!tarjeta.seleccionado" (click)="agregarElemento(tarjeta);"
                                            class="pie dis-flex bgMorado txBlanco p-2 cursorPointer">
                                            <p>Seleccionar</p>
                                        </div>
                                        <div *ngIf="tarjeta.seleccionado" (click)="eliminarElemento(tarjeta);"
                                            class="pie dis-flex bgMorado txBlanco p-2 cursorPointer seleccionado">
                                            <p>Quitar selección</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- TARJETAS MOVIL -->
                <movil-listado-content 
                    [selectedItemFunc]="selectedItemFun" 
                    [diselectedItemFunc]="diselectedItemFun"
                    [infoList]="fichas" 
                    [tipoTarjeta]="4"></movil-listado-content>
                <!--********************************************************************************************************************-->

                <div class="container-fluid">
                    <div class="row dis-flex">
                        <div class="col-md-12">
                            <div class="row dis-flex justify-s">

                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-1">
                    <div class="row padding-b-10" *ngIf="_globalService.getContienda() == 'diputaciones'">
                        <div class="col-10">
                            <div class="texto font-medium">
                                <p>
                                    {{_obtenerDatosService?.main?.texto_porcentaje_estadistica ? 'Se incluye' : 'No se considera'}} la cantidad de votos asentada en las Actas PREP de
                                    Casilla Especial de
                                    Representación Proporcional.
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row d-none d-xl-block">
                        <div class="col-md-12 dis-flex flex-col align-e">
                            <div class="dis-flex">
                                <i class="fas fa-info-circle mr-2" aria-hidden="true"></i>
                                <p class="texto">
                                    Da clic en <strong>Seleccionar</strong> para visualizar el detalle de la votación.
                                    Puedes seleccionar
                                    hasta tres opciones.
                                </p>
                            </div>
                            <button [disabled]="comparativaService?.arregloElementos.length < 1"
                                (click)="comparativaService.reiniciarModal()"
                                class="boton-detalle btn bgMorado txBlanco btn-sm texto ml-2 px-4 cursorPointer"
                                data-toggle="modal" data-target="#modalComparativa">
                                Ver detalle
                            </button>
                        </div>
                    </div> -->
                </div>
                <app-resumen-votacion [acumulados_num]="resumen?.acumulados?.total"
                    [acumulados_prc]="resumen?.acumulados?.prc"
                    [noregistradas_num]="resumen?.no_registradas?.total"
                    [noregistradas_prc]="resumen?.no_registradas?.prc" 
                    [nulos_num]="resumen?.nulos?.total"
                    [nulos_prc]="resumen?.nulos?.prc" 
                    [total_num]="resumen?.total?.total"
                    [total_prc]="resumen?.total?.prc"
                ></app-resumen-votacion>

                <div class="container-fluid py-1 pt-4 d-block d-xl-none">
                    <div class="row">
                        <div class="col-md-12 dis-flex flex-col align-e">
                            <button [disabled]="comparativaService?.arregloElementos.length < 1"
                                (click)="comparativaService.reiniciarModal()"
                                class="boton-detalle btn bgMorado txBlanco btn-sm texto ml-2 px-4 cursorPointer"
                                data-toggle="modal" data-target="#modalComparativa">
                                Ver detalle
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="contienda_actual == 'diputaciones'">
                <!--<div class="col-md-12">
              <div class="card p-3 mt-3 mapa-puntos">
                  <div class="row justify-content-center">
                      <div class="col-4 text-center">
                          <i class="fas fa-globe-americas"></i>
                            <h4 class="subtitulo-seccion py-3 text-center">
                                <strong class="txRosaIne">Diputación Migrante</strong>
                          </h4>
                      </div>
                  </div>
                  <div class="row justify-content-center">
                      <img class="img-partido" src="./assets/img/logos-partidos/{{estadistica_extranjero?.img?.toLowerCase()}}">
                  </div>
                    <div class="row justify-content-center row-votos-extranjero">
                        <p>Votos <span>{{estadistica_extranjero.votos}}</span></p>
                  </div>
                    <div class="row justify-content-center">
                        <div class="col-4 dis-flex">
                            <button
                                [routerLink]="['/diputaciones/votos-ppyci/diputacion-migrante']"
                                class="btn btn-sm btn-rosaFuerte texto cursorPointer my-3 px-4 py-1">
                                Ver Resultados
                            </button>
                        </div>
                  </div>
                  <div class="row justify-content-center">
                      <div class="col-6">
                          <p class="texto">Partido político o {{ _obtenerDatosService.config?.estado == 'Yucatán' ? 'Candidatura Común' : 'Coalición' }} que ha obtenido la Diputación Migrante hasta el momento.</p>
                      </div>
                  </div>
              </div>
          </div>-->
                <div class="col-md-12 bkg-gray">
                    <div class="card p-3 mt-3 bkg-gray">
                        <div class="row justify-content-center">
                            <div class="col-10 col-xl-6">
                                <h4 class="subtitulo-seccion py-3 text-center">
                                    <strong>Representación Proporcional</strong>
                                </h4>
                                <p class="texto">
                                    El apartado "Actas RP" muestra la cantidad de votos asentada en las Actas PREP de
                                    Casilla Especial de
                                    Representación Proporcional, los cuales se suman únicamente a nivel Entidad.
                                </p>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-6 col-xl-4 dis-flex">
                                <button [routerLink]="['../representacion-proporcional']"
                                    class="btn btn-sm btn-rosaFuerte texto cursorPointer my-3 px-4 py-1">
                                    Ver Actas RP
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--      <div class="row">-->

            <!--      </div>-->
        </div>
    </div>
</div>

<app-estadistica-nacional [load_main]="true"></app-estadistica-nacional>