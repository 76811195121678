import { Component, OnInit } from '@angular/core';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { environment as env } from 'src/environments/environment';


@Component({
    selector: 'app-consulta-ciudadana',
    templateUrl: './consulta-ciudadana.component.html',
    styleUrls: ['./consulta-ciudadana.component.scss']
})
export class ConsultaCiudadanaComponent implements OnInit {

    arrPreguntas:any = [];
    resumen: any = {};

    constructor(
        public _globalService: GlobalesService,
        public obtenerDatosService: ObtenerDatosService
    ) { }

    ngOnInit(): void {
        // this.obtenerDatosService.mainSubject.subscribe(data => {
        //     if (data == null) return;

        //     this.loadInfo();
        // });
        this.loadInfo();
        // console.log("PREGunNTAS: ", this.arrPreguntas)
    }

    loadInfo(){
        const contienda = 'consulta-ciudadana'
        const file = 'consulta.json'
        const carpeta = "122\/consulta_ciudadana"

        if (this.obtenerDatosService.contiendas[contienda] && this.obtenerDatosService.contiendas[contienda][file]){
            const data = this.obtenerDatosService.contiendas[contienda][file]
            this.arrPreguntas = data["preguntas"]
            this.resumen = data['resumen'];
        }
        else {
          this.obtenerDatosService.getInfoJson(env.urls.urlAssets + env.urls.urlJSON + carpeta + '/' + file).subscribe(data =>{
            if (!this.obtenerDatosService.contiendas[contienda]) this.obtenerDatosService.contiendas[contienda] = {};
            this.obtenerDatosService.contiendas[contienda][file] = data;
            this.arrPreguntas = data["preguntas"]
            this.resumen = data['resumen'];

            // console.log("PREGINTAS: ", this.arrPreguntas)
          });
        }

        // this.arrPreguntas = [
        //     {
        //         text: "¿Pregunta 1?",
        //         data: [
        //             {
        //                 text: "Si",
        //                 votos_entidad: "458,080",
        //                 prc_entidad: 99.9591,
        //                 votos_extranjero: "2,000",
        //                 prc_extranjero: 84.3174,
        //                 votos_total: "460,080",
        //                 prc_total: 99.4444
        //             },
        //             {
        //                 text: "No",
        //                 votos_entidad: "100",
        //                 prc_entidad: 0.0218,
        //                 votos_extranjero: "300",
        //                 prc_extranjero: 12.6475,
        //                 votos_total: "400",
        //                 prc_total: 0.4444
        //             },
        //             {
        //                 text: "Votos Nulos",
        //                 votos_entidad: "87",
        //                 prc_entidad: 0.0189,
        //                 votos_extranjero: "72",
        //                 prc_extranjero: 3.0354,
        //                 votos_total: "159",
        //                 prc_total: 0.0345
        //             },
        //             {
        //                 text: "Total",
        //                 votos_entidad: "458,267",
        //                 prc_entidad: 99.4850,
        //                 votos_extranjero: "2,372",
        //                 prc_extranjero: 0.5149,
        //                 votos_total: "460,639",
        //                 prc_total: 100
        //             }
        //         ]
        //     },
        //     {
        //         text: "¿Pregunta 2?",
        //         data: [
        //             {
        //                 text: "Si",
        //                 votos_entidad: "87",
        //                 prc_entidad: 0.0189,
        //                 votos_extranjero: "72",
        //                 prc_extranjero: 3.0354,
        //                 votos_total: "159",
        //                 prc_total: 0.0345
        //             },
        //             {
        //                 text: "No",
        //                 votos_entidad: "458,080",
        //                 prc_entidad: 99.9591,
        //                 votos_extranjero: "2,000",
        //                 prc_extranjero: 84.3174,
        //                 votos_total: "460,080",
        //                 prc_total: 99.4444
        //             },
        //             {
        //                 text: "Votos Nulos",
        //                 votos_entidad: "100",
        //                 prc_entidad: 0.0218,
        //                 votos_extranjero: "300",
        //                 prc_extranjero: 12.6475,
        //                 votos_total: "400",
        //                 prc_total: 0.4444
        //             },
        //             {
        //                 text: "Total",
        //                 votos_entidad: "458,267",
        //                 prc_entidad: 99.4850,
        //                 votos_extranjero: "2,372",
        //                 prc_extranjero: 0.5149,
        //                 votos_total: "460,639",
        //                 prc_total: 100
        //             }
        //         ]
        //     }
        // ];

        // this.obtenerDatosService.loadJSON('consulta-ciudadana', 'conulta.json').then(data => {
            //this.preguntas = data["preguntas"]
        // });
    }
}