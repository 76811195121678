<div class="modal fade modalComparativa" id="modalComparativa" tabindex="-1" role="dialog" aria-labelledby="ModalComparativa" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" [ngClass]="{'modal-dark': themeService.isDarkTheme()}" role="document">
        
        <!--******************************RESUMEN DISTRITO******************************-->
        <div class="modal-content" *ngIf="comparativaService.pantalla === 'resumenDistrito'">
            <div class="modal-header bgMorado txBlanco">
                <h5 class="modal-title" id="exampleModalLongTitle">Detalle</h5>
                <button
                    (click)="comparativaService.cerrar()"
                    type="button" class="cerrar dis-flex" data-dismiss="modal" aria-label="Close">
                    <img src="./assets/img/iconos-visualiza/cerrar.svg">
                </button>
            </div>
            <div class="modal-body">
                <!--Versión escritorio-->
                <div class="container-fluid d-none d-xl-block">
                    <div class="row">
                        <div class="col-3 totales">
                            <p class="w-100 em16 font-medium">
                                <strong>Total de votos</strong>
                            </p>
                            <p class="w-100 em43 text-total txRosaIne">
                                <strong>
                                    {{ comparativaService?.votos_total }}
                                </strong>
                            </p>
                            <p class="w-100 em20 text-total txRosaIne">
                                <strong>
                                    {{ comparativaService?.votos_pctj }}
                                </strong>
                            </p>
                        </div>
                        <div class="col-9">
                            <div class="container-fluid">
                                <div class="row" *ngFor="let elemento of comparativaService?.arregloElementos">
                                    <div class="col-10">
                                        <div class="elemento bgGris my-2">
                                            <div class="partidos dis-flex justify-s bgGrisTenue">
                                                <img class="partido" *ngFor="let img of elemento?.imgs" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()">
<!--                                                <p class="text-distrito font-medium em12"><b>{{(globalService.getContienda() == 'ayuntamientos')? 'Municipios' : (globalService.getContienda() == 'juntas' ? 'Juntas' : 'Distritos') }}</b> {{ elemento?.distritos }} </p>-->
                                                <p class="text-distrito font-medium em12 " *ngIf="globalService.getContienda() == 'diputaciones'"><small>Distritos</small><br> {{ elemento?.distritos }} </p>
                                            </div>
                                            <div class="container-fluid p-2">
                                                <div class="row">
                                                    <div class="col-8">
                                                        <p class="titulo em14"> Total de votos </p>
                                                        <p class="font-medium em12"> <b>{{ elemento?.total }}</b> {{ elemento?.porcentaje }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="barra-porcentaje" [ngStyle]="{ 'width': ( elemento?.porcentaje?.toString()?.includes('%') ? elemento?.porcentaje : elemento?.porcentaje+'%') }"></div>
                                        </div>
                                    </div>
                                    <div (click)="comparativaService.eliminarElemento(elemento, 'nacionalDistrito')" class="col-2 dis-flex">
                                        <i class="far fa-trash-alt cursorPointer em22" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-3 pb-2" *ngIf="comparativaService.arregloElementos.length < 3 && !comparativaService.modalFull">
                        <div class="col-3 offset-9">
                            <button (click)="comparativaService.modalFull = true" class="btn btn-block btn-rosaFuerte">Agregar</button>
                        </div>
                    </div>
                    <div class="row pt-3 pb-2" *ngIf="comparativaService.arregloElementos.length < 3 && comparativaService.modalFull">
                        <div class="col-3 offset-9">
                            <button (click)="comparativaService.modalFull = false" class="btn btn-block btn-rosaFuerte">Cancelar</button>
                        </div>
                    </div>
                </div>
                <!--Versión Movíl-->
                <div class="d-block d-xl-none">
                    <div class="row">
                    <div class="map-container">
                        <div class="label-container">
                            <p class="em14 font-bold">Entidad</p>
                            <p class="em12">{{ obtenerDatosService?.config?.estado }}</p>
                        </div>
                        <div class="map-canvas" [chart]="mapa_resumenDistrito"></div>
                    </div>
                </div>
                    <div class="row" *ngFor="let elemento of comparativaService?.arregloElementos">
                        <div class="col-12 ">
                            <div class="partido-movil">
                                <div class="row">
                                    <div class="col-10">
                                        <img class="partido" *ngFor="let img of elemento?.imgs" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()">
                                    </div>
                                    <div (click)="comparativaService.eliminarElemento(elemento, 'distritoPPyCI')" class="col-2 dis-flex">
                                        <i class="far fa-trash-alt cursorPointer em22" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="barra-movil">
                                    <div class="text-container">
                                        <div class="col-4">
                                            <p>Total de votos</p>
                                            <p><strong>{{ elemento?.total }}</strong></p>
                                        </div>
                                        <div class="col-4">
                                            <p>Porcentaje</p>
                                            <p><strong>{{ elemento?.porcentaje }}</strong></p>
                                        </div>
                                        <div class="col-4">
                                            <p>{{(globalService.getContienda() == 'ayuntamientos')? 'Municipios' : (globalService.getContienda() == 'juntas' ? 'Juntas' : 'Distritos') }}</p>
                                            <p><strong>{{ elemento?.distritos }}</strong></p>
                                        </div>
                                    </div>
                                    <div class="porcentaje" [ngStyle]="{ 'width': elemento?.porcentaje, 'background-color': elemento?.color  }"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 offset-3 mt-5" *ngIf="comparativaService.arregloElementos.length < 3 && !comparativaService.modalFull">
                        <button (click)="openBottomSheet()" class="btn btn-block btn-morado">Agregar</button>
                    </div>
                </div>
            </div>
        <div class="modal-footer" *ngIf="comparativaService.modalFull && comparativaService.arregloElementos.length < 3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <p class="em16">
                            Puedes agregar hasta tres opciones con el botón + .
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 px-3">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <div *ngFor="let elementoStage of comparativaService?.stageArray; let i = index;">
                                        <div class="elementoStage dis-flex space-b px-2 mx-3"
                                            *ngIf="i <= comparativaService.stageArray.length / 2"
                                            [hidden]="elementoStage.seleccionado">
                                            <div class="partidos" style="display: flex; text-align: right;">
                                                <img class="partido dis-flex m-2" *ngFor="let img of elementoStage?.imgs" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()">
                                                <p class="font-medium em12 mr-2 w-100" *ngIf="globalService.getContienda() == 'diputaciones'"><small>Distritos</small><br> {{ elementoStage?.distritos }} </p>
                                            </div>
                                            <i (click)="comparativaService.agregarElemento(elementoStage, 'nacionalDistrito'); comparativaService.modalFull = false"
                                            class="anadir fas fa-plus-circle cursorPointer"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 px-3">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <div *ngFor="let elementoStage of comparativaService?.stageArray; let i = index;">
                                        <div class="elementoStage dis-flex space-b px-2 mx-3"
                                            *ngIf="i > comparativaService.stageArray.length / 2"
                                            [hidden]="elementoStage.seleccionado">
                                            <div class="partidos" style="display: flex; text-align: right;">
                                                <img class="partido dis-flex m-2" *ngFor="let img of elementoStage?.imgs" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()">
                                                <p class="font-medium em12 mr-2 w-100" *ngIf="globalService.getContienda() == 'diputaciones'"><small>Distritos</small><br> {{ elementoStage?.distritos }} </p>
                                            </div>
                                            <i (click)="comparativaService.agregarElemento(elementoStage, 'nacionalDistrito'); comparativaService.modalFull = false" class="anadir fas fa-plus-circle cursorPointer"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center"></div>
            </div>
        </div>
      </div>
        
        <!--******************************RESUMEN PPyCI******************************-->
      <div class="modal-content" *ngIf="comparativaService.pantalla === 'resumenPPyCI'">
        <div class="modal-header bgMorado txBlanco">
          <h5 class="modal-title" id="exampleModalLongTitle">Detalle</h5>
          <button
          (click)="comparativaService.cerrar()"
          type="button" class="cerrar dis-flex" data-dismiss="modal" aria-label="Close">
            <img src="./assets/img/iconos-visualiza/cerrar.svg">
          </button>
        </div>
        <div class="modal-body">
            <!--Versión escritorio-->
          <div class="container-fluid d-none d-xl-block">
              <div class="row">
                  <div class="col-3 totales">
                    <p class="w-100 em16 font-medium">
                      <strong>Total de votos</strong>
                    </p>
                    <p class="w-100 em43 text-total txRosaIne">
                      <strong>
                        {{ comparativaService?.votos_total }}
                        </strong>
                    </p>
                    <p class="w-100 em20 text-total txRosaIne">
                      <strong>
                        {{ comparativaService?.votos_pctj }}
                      </strong>
                    </p>
                </div>
                  <div class="col-9">
                      <div class="container-fluid">
                        <div class="row" *ngFor="let elemento of comparativaService?.arregloElementos">
                            <div class="col-10">
                                <div class="elemento bgGris my-2">
                                    <div class="partidos dis-flex justify-s bgGrisTenue">
                                        <img class="partido dis-flex m-2" [src]="'./assets/img/logos-partidos/'+ elemento?.imgs[0]?.toLowerCase()">
                                    </div>
                                    <div class="container-fluid p-2">
                                        <div class="row">
                                            <div class="col-8">
                                                <p class="titulo em14">
                                                    Total de votos
                                                </p>
                                                <p class="font-medium em12">
                                                  <b>{{ elemento?.total }}</b> {{ elemento?.porcentaje }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="barra-porcentaje" [ngStyle]="{ 'width': ( elemento?.porcentaje?.toString()?.includes('%') ? elemento?.porcentaje : elemento?.porcentaje+'%') }"></div>
                                </div>
                            </div>
                            <div
                            (click)="comparativaService.eliminarElemento(elemento, 'nacionalPartido')"
                            class="col-2 dis-flex">
                              <i class="far fa-trash-alt cursorPointer em22" aria-hidden="true"></i>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
              <div class="row pt-3 pb-2" *ngIf="comparativaService.arregloElementos.length < 3 && !comparativaService.modalFull">
                <div class="col-3 offset-9">
                    <button
                    (click)="comparativaService.modalFull = true"
                    class="btn btn-block btn-rosaFuerte">Agregar</button>
                </div>
            </div>
            <div class="row pt-3 pb-2" *ngIf="comparativaService.arregloElementos.length < 3 && comparativaService.modalFull">
                <div class="col-3 offset-9">
                    <button
                    (click)="comparativaService.modalFull = false"
                    class="btn btn-block btn-rosaFuerte">Cancelar</button>
                </div>
            </div>
          </div>
            <!--Versión Movíl-->
            <div class="d-block d-xl-none">
                <div class="row">
                    <div class="col-12  mt-5 ml-4">
                        <p class="w-100 em16 font-medium"><strong>Total de votos</strong></p>
                        <div class="text-total-movil">
                            <span class="w-100 em50 txRosaIne"><strong> {{ comparativaService?.votos_total }} </strong></span><br>
                            <span class="w-100 em18 txRosaIne porcentaje">{{ comparativaService?.votos_pctj }}</span>
                        </div>
                            
                    </div>
                </div>
                <div class="row" *ngFor="let elemento of comparativaService?.arregloElementos">
                    <div class="col-12 ">
                        <div class="partido-movil">
                            <div class="row">
                                <div class="col-10">
                                    <img class="partido" [src]="'./assets/img/logos-partidos/'+ elemento?.imgs[0]?.toLowerCase()">
                                </div>
                                <div (click)="comparativaService.eliminarElemento(elemento, 'distritoPPyCI')" class="col-2 dis-flex">
                                    <i class="far fa-trash-alt cursorPointer em22" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="barra-movil">
                                <div class="text-container">
                                    <div class="col-4">
                                        <p>Total de votos</p>
                                        <p><strong>{{ elemento?.total }}</strong></p>
                                    </div>
                                    <div class="col-4">
                                        <p>Porcentaje</p>
                                        <p><strong>{{ elemento?.porcentaje }}</strong></p>
                                    </div>
                                </div>
                                <div class="porcentaje" [ngStyle]="{ 'width': elemento?.porcentaje, 'background-color': elemento?.color  }"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 offset-3 mt-5" *ngIf="comparativaService.arregloElementos.length < 3 && !comparativaService.modalFull">
                    <button (click)="openBottomSheet()" class="btn btn-block btn-morado">Agregar</button>
                </div>
            </div>
        </div>
        <div class="modal-footer" *ngIf="comparativaService.modalFull && comparativaService.arregloElementos.length < 3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <p class="em16">
                            Puedes agregar hasta tres opciones con el botón +.​
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 px-3">
                        <div class="container-fuid">
                            <div class="row">
                                <div class="col-12">
                                    <div *ngFor="let elementoStage of comparativaService?.stageArray; let i = index">
                                        <div class="elementoStage dis-flex space-b px-2 mx-3"
                                            *ngIf="i < comparativaService.stageArray.length / 2"
                                            [hidden]="elementoStage.seleccionado">
                                            <div class="partidos dis-flex">
                                                <img class="partido dis-flex m-2" *ngFor="let img of elementoStage?.imgs" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()">
                                            </div>
                                            <i
                                            (click)="comparativaService.agregarElemento(elementoStage, 'nacionalPartido'); comparativaService.modalFull = false"
                                            class="anadir fas fa-plus-circle cursorPointer"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 px-3">
                        <div class="container-fuid">
                            <div class="row">
                                <div class="col-12">
                                    <div *ngFor="let elementoStage of comparativaService?.stageArray; let i = index">
                                        <div class="elementoStage dis-flex space-b px-2 mx-3"
                                            *ngIf="i > comparativaService.stageArray.length / 2"
                                            [hidden]="elementoStage.seleccionado">
                                            <div class="partidos dis-flex">
                                                <img class="partido dis-flex m-2" *ngFor="let img of elementoStage?.imgs" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()">
                                            </div>
                                            <i
                                            (click)="comparativaService.agregarElemento(elementoStage, 'nacionalPartido'); comparativaService.modalFull = false"
                                            class="anadir fas fa-plus-circle cursorPointer"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        
        <!--******************************RESUMEN DISTRITO CANDIDATURA******************************-->
      <div class="modal-content" *ngIf="comparativaService.pantalla === 'distritoCandidatura'">
        <div class="modal-header bgMorado txBlanco">
          <h5 class="modal-title" id="exampleModalLongTitle">Detalle</h5>
          <button (click)="comparativaService.cerrar()" type="button" class="cerrar dis-flex" data-dismiss="modal" aria-label="Close">
            <img src="./assets/img/iconos-visualiza/cerrar.svg">
          </button>
        </div>
        <div class="modal-body">
            <!--Versión escritorio-->
            <div class="container-fluid d-none d-xl-block">
                <div class="row">
                    <div class="col-3 totales">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <p class="w-100 em16 font-medium"><strong>Total de votos</strong></p>
                                    <p class="w-100 em43 text-total txRosaIne">
                                        <strong>{{ comparativaService?.votos_total }}</strong>
                                    </p>
                                    <p class="w-100 em20 text-total txRosaIne">
                                        <strong>{{ comparativaService?.votos_pctj }}</strong>
                                    </p>
                                </div>
                            </div>
                            <div class="row pt-3">
                                <div class="col-12">
<!--                                    <p class="px16"><strong>Entidad</strong></p>-->
<!--                                    <p class="px16 pb-2">{{ obtenerDatosService?.config?.estado }}</p>-->
                                    <p class="em16"> <strong>{{(globalService.getContienda() == 'ayuntamientos')? 'Municipio' : (globalService.getContienda() == 'juntas' ? 'Junta' : 'Distrito') }}</strong> </p>
                                    <p class="em16"> {{ rutas?.migas?.distrito }} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                  <div class="col-9">
                      <div class="container-fluid">
                          <div class="row" *ngFor="let elemento of comparativaService?.arregloElementos">
                              <div class="col-10">
                                  <div class="elemento bgGris my-2">
                                      <div class="partidos dis-flex justify-s bgGrisTenue">
                                          <img [src]="'./assets/img/avatar-candidatos/'+elemento?.img_perfil.toLowerCase()" width="50px" height="50px">
                                          <img class="partido dis-flex m-2" *ngFor="let img of elemento?.imgs" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()">
<!--                                          <img class="partido" *ngFor="let img of elemento?.imgs" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()">-->
                                      </div>
                                      <div class="container-fluid p-2">
                                          <div class="row">
                                              <div class="col-12">
                                                  <p class="em16">
                                                      <strong> {{ elemento?.text | titlecase }} </strong>
                                                  </p>
                                              </div>
                                          </div>
                                          <div class="row">
                                              <div class="col-8">
                                                  <p class="titulo em14"> Total de votos </p>
                                                  <p class="font-medium em12"> <b>{{ elemento?.total }}</b> {{ elemento?.porcentaje }} </p>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="barra-porcentaje" [ngStyle]="{ 'width': ( elemento?.porcentaje?.toString()?.includes('%') ? elemento?.porcentaje : elemento?.porcentaje+'%') }"></div>
                                  </div>
                              </div>
                              <div (click)="comparativaService.eliminarElemento(elemento, 'distritoCandidatura')" class="col-2 dis-flex">
                                  <i class="far fa-trash-alt cursorPointer px22" aria-hidden="true"></i>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
                <div class="row pt-3 pb-2" *ngIf="comparativaService.arregloElementos.length < 3 && !comparativaService.modalFull">
                    <div class="col-3 offset-9">
                        <button (click)="comparativaService.modalFull = true" class="btn btn-block btn-rosaFuerte">Agregar</button>
                    </div>
                </div>
                <div class="row pt-3 pb-2" *ngIf="comparativaService.arregloElementos.length < 3 && comparativaService.modalFull">
                    <div class="col-3 offset-9">
                        <button (click)="comparativaService.modalFull = false" class="btn btn-block btn-rosaFuerte">Cancelar</button>
                    </div>
                </div>
            </div>
            
            <!--Versión Movil-->
            <div class="d-block d-xl-none">
                <div class="row"> 
                    <div class="col-12 ml-4" *ngIf="globalService.getContienda() == 'gubernatura'">
                        <p class="w-100 px18 font-medium">Total de votos</p>
                        <p class="w-100 px43 txRosaIne"><strong> {{ comparativaService?.votos_total }} </strong></p>
                        <p class="w-100 px22 txRosaIne"><strong> {{ comparativaService?.votos_pctj }} </strong></p>
                    </div>
                    <div class="map-container" *ngIf="globalService.getContienda() != 'gubernatura'">
                        <div class="label-container">
                            <p class="em14 font-bold">Entidad</p>
                            <p class="em12">{{ obtenerDatosService?.config?.estado }}</p>
                            <p class="em14 font-bold">{{(globalService.getContienda() == 'ayuntamientos')? 'Municipio' : (globalService.getContienda() == 'juntas' ? 'Junta' : 'Distrito') }} </p>
                            <p class="em12">{{ rutas?.migas?.distrito }}</p>
                        </div>
                        <div class="map-canvas" [chart]="mapa_distritoCandidatura"></div>
                    </div>
                </div>
                <div class="row" *ngFor="let elemento of comparativaService?.arregloElementos">
                    <div class="col-12 ">
                        <div class="partido-movil">
                            <div class="row">
                                <div class="col-10">
                                    <img [src]="'./assets/img/avatar-candidatos/'+elemento?.img_perfil.toLowerCase()" width="50px" height="50px">
                                    <img class="partido" *ngFor="let img of elemento?.imgs" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()">
<!--                                    <img class="partido" [src]="'./assets/img/logos-partidos/'+ elemento?.imgs[0]?.toLowerCase()">-->
                                </div>
                                <div (click)="comparativaService.eliminarElemento(elemento, 'distritoPPyCI')" class="col-2 dis-flex">
                                    <i class="far fa-trash-alt cursorPointer em22" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="text-candidato">
                                <p class="em14"><strong>{{elemento?.text}}</strong></p>
                            </div>
                            <div class="barra-movil">
                                <div class="text-container">
                                    <div class="col-4">
                                        <p>Total de votos</p>
                                        <p><strong>{{ elemento?.total }}</strong></p>
                                    </div>
                                    <div class="col-4">
                                        <p>Porcentaje</p>
                                        <p><strong>{{ elemento?.porcentaje }}</strong></p>
                                    </div>
                                </div>
                                <div class="porcentaje" [ngStyle]="{ 'width': elemento?.porcentaje, 'background-color': elemento?.color  }"></div>
<!--                                <div class="porcentaje" [ngStyle]="{ 'width': ( elemento?.porcentaje?.toString()?.includes('%') ? elemento?.porcentaje : elemento?.porcentaje+'%') }"></div>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 offset-3 mt-5" *ngIf="comparativaService.arregloElementos.length < 3 && !comparativaService.modalFull">
                    <button (click)="openBottomSheet()" class="btn btn-block btn-morado">Agregar</button>
                </div>
            </div>
            
          </div>
          <div class="modal-footer" *ngIf="comparativaService.modalFull && comparativaService.arregloElementos.length < 3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <p class="px16">
                            Puedes agregar hasta tres opciones con el botón +.​
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 px-3">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <div *ngFor="let elementoStage of comparativaService?.stageArray; let i = index">
                                        <div class="elementoStage dis-flex space-b px-2 mx-3 my-3"
                                            *ngIf="i < comparativaService.stageArray.length / 2"
                                            [hidden]="elementoStage.seleccionado">
                                            <img src="assets/res/avatar-persona.svg" width="50px" height="50px">
                                            <div class="nombres dis-flex flex-col align-s">
                                                <div class="candidatos">
                                                    <p>
                                                        {{ elementoStage?.text | titlecase }}
                                                    </p>
                                                </div>
                                                <div class="partidos dis-flex">
                                                    <img class="partido dis-flex m-2" *ngFor="let img of elementoStage?.imgs" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()">
                                                </div>
                                            </div>
                                            <i
                                            (click)="comparativaService.agregarElemento(elementoStage, 'distritoCandidatura'); comparativaService.modalFull = false"
                                            class="anadir fas fa-plus-circle cursorPointer"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 px-3">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <div *ngFor="let elementoStage of comparativaService?.stageArray; let i = index">
                                        <div class="elementoStage dis-flex space-b px-2 mx-3 my-3"
                                            *ngIf="i > comparativaService.stageArray.length / 2"
                                            [hidden]="elementoStage.seleccionado">
                                            <img src="assets/res/avatar-persona.svg" width="50px" height="50px">
                                            <div class="nombres dis-flex flex-col align-s">
                                                <div class="candidatos">
                                                    <p>
                                                        {{ elementoStage?.text | titlecase }}
                                                    </p>
                                                </div>
                                                <div class="partidos dis-flex">
                                                    <img class="partido dis-flex m-2" *ngFor="let img of elementoStage?.imgs" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()">
                                                </div>
                                            </div>
                                            <i (click)="comparativaService.agregarElemento(elementoStage, 'distritoCandidatura'); comparativaService.modalFull = false"
                                            class="anadir fas fa-plus-circle cursorPointer"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
        
        <!--******************************RESUMEN ENTIDAD CANDIDATURA******************************-->
      <div class="modal-content" *ngIf="comparativaService.pantalla === 'entidadCandidatura'">
        <div class="modal-header bgMorado txBlanco">
          <h5 class="modal-title" id="exampleModalLongTitle">Detalle</h5>
          <button (click)="comparativaService.cerrar()" type="button" class="cerrar dis-flex" data-dismiss="modal" aria-label="Close">
            <img src="./assets/img/iconos-visualiza/cerrar.svg">
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid d-none d-xl-block">
              <div class="row">
                    <div class="col-3 totales">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <div class="d-none d-lg-block apartado dis-flex flex-col">
                                        <div class="dis-flex w-100 justify-e">
                                            <p class="em16">
                                                <strong>Total de votos</strong>
                                            </p>
                                        </div>
                                        <div class="dis-flex w-100 space-b justify-e">
                                            <p class="em43 txRosaIne text-total">
                                                <strong>{{ comparativaService?.votos_total }}</strong>
                                            </p>
                                        </div>
                                        <div class="dis-flex w-100 txRosaIne justify-e">
                                            <p class="em22 text-total">
                                                <strong>{{ comparativaService?.votos_pctj }}</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  <div class="col-9">
                      <div class="container-fluid">
                        <div class="row" *ngFor="let elemento of comparativaService?.arregloElementos">
                            <div class="col-12 row">
                                <div class="col-3">
                                    <img [src]="'./assets/img/avatar-candidatos/'+elemento?.img_perfil.toLowerCase()" width="50px" height="50px">
                                    <p class="em14"><strong>{{ elemento?.text | titlecase }}</strong></p>
                                </div>
                                <div class="col-8 elemento bgGris my-2">
                                    <div class="partidos dis-flex justify-s bgBlanco">
<!--                                        <img [src]="'./assets/img/avatar-candidatos/'+elemento?.img_perfil.toLowerCase()" width="50px" height="50px">-->
                                        <img class="partido dis-flex m-2" *ngFor="let img of elemento?.partidos" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()">
                                    </div>
                                    <div class="container-fluid p-2">
                                        <div class="row">
                                            <div class="col-8">
                                                <p class="titulo em14">
                                                    Total de votos
                                                </p>
                                                <p class="font-medium em12">
                                                    <b>{{ elemento?.total }}</b> {{ elemento?.porcentaje }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
<!--                                    <div class="barra-porcentaje" [ngStyle]="{ 'width': (elemento?.porcentaje) }"></div>-->
                                    <div class="barra-porcentaje" [ngStyle]="{ 'width': ( elemento?.porcentaje?.toString()?.includes('%') ? elemento?.porcentaje : elemento?.porcentaje+'%') }"></div>
                                </div>
                                <div (click)="comparativaService.eliminarElemento(elemento, 'entidadCandidatura')" class="col-1 dis-flex">
                                    <i class="far fa-trash-alt cursorPointer em22" aria-hidden="true"></i>
                                </div>
                            </div>
<!--
                            <div
                            (click)="comparativaService.eliminarElemento(elemento, 'entidadCandidatura')"
                            class="col-2 dis-flex">
                              <i class="far fa-trash-alt cursorPointer em22" aria-hidden="true"></i>
                            </div>
-->
                        </div>
                      </div>
                  </div>
              </div>
              <div class="row pt-3 pb-2" *ngIf="comparativaService.arregloElementos.length < 3 && !comparativaService.modalFull">
                <div class="col-3 offset-9">
                    <button
                    (click)="comparativaService.modalFull = true"
                    class="btn btn-block btn-rosaFuerte">Agregar</button>
                </div>
            </div>
            <div class="row pt-3 pb-2" *ngIf="comparativaService.arregloElementos.length < 3 && comparativaService.modalFull">
                <div class="col-3 offset-9">
                    <button
                    (click)="comparativaService.modalFull = false"
                    class="btn btn-block btn-rosaFuerte">Cancelar</button>
                </div>
            </div>
          </div>
            <!--Versión Movil-->
            <div class="d-block d-xl-none">
                <div class="row mt-5 ml-4">
                    <div class="col-12">
                        <p class="w-100 em18 font-medium"><strong>Total de votos</strong></p>
                        <div class="text-total-movil">
                            <span class="w-100 em50 text-total txRosaIne"><strong> {{ comparativaService?.votos_total }} </strong></span> <br>
                            <span class="w-100 em20 text-total txRosaIne porcentaje">{{ comparativaService?.votos_pctj }}</span>
                        </div>
<!--                        <p class="w-100 em50 text-total txRosaIne"><strong> {{ comparativaService?.votos_total }} </strong></p>-->
<!--                        <p class="w-100 em22 text-total txRosaIne">{{ comparativaService?.votos_pctj }}</p>-->
                    </div>
                </div>
                <div class="row" *ngFor="let elemento of comparativaService?.arregloElementos">
                    <div class="col-12 ">
                        <div class="partido-movil">
                            <div class="row">
                                <div class="col-10">
                                    <img [src]="'./assets/img/avatar-candidatos/'+elemento?.img_perfil.toLowerCase()" width="50px" height="50px">
                                    <img class="partido" *ngFor="let img of elemento?.partidos" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()">
<!--                                    <img class="partido" [src]="'./assets/img/logos-partidos/'+ elemento?.imgs[0]?.toLowerCase()">-->
                                </div>
                                <div (click)="comparativaService.eliminarElemento(elemento, 'distritoPPyCI')" class="col-2 dis-flex">
                                    <i class="far fa-trash-alt cursorPointer em22" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="text-candidato">
                                <p class="em14"><strong>{{elemento?.text}}</strong></p>
                            </div>
                            <div class="barra-movil">
                                <div class="text-container">
                                    <div class="col-4">
                                        <p>Total de votos</p>
                                        <p><strong>{{ elemento?.total }}</strong></p>
                                    </div>
                                    <div class="col-4">
                                        <p>Porcentaje</p>
                                        <p><strong>{{ elemento?.porcentaje }}</strong></p>
                                    </div>
                                </div>
                                <div class="porcentaje" [ngStyle]="{ 'width': elemento?.porcentaje, 'background-color': elemento?.color  }"></div>
<!--                                <div class="porcentaje" [ngStyle]="{ 'width': ( elemento?.porcentaje?.toString()?.includes('%') ? elemento?.porcentaje : elemento?.porcentaje+'%') }"></div>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 offset-3 mt-5" *ngIf="comparativaService.arregloElementos.length < 3 && !comparativaService.modalFull">
                    <button (click)="openBottomSheet()" class="btn btn-block btn-morado">Agregar</button>
                </div>
            </div>
        </div>
        <div class="modal-footer" *ngIf="comparativaService.modalFull && comparativaService.arregloElementos.length < 3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <p class="px16">
                            Puedes agregar hasta tres opciones con el botón +.​
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 px-3">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <div *ngFor="let elementoStage of comparativaService?.stageArray; let i = index">
                                        <div class="elementoStage dis-flex space-b px-2 mx-3 my-3"
                                            *ngIf="i < comparativaService.stageArray.length / 2"
                                            [hidden]="elementoStage.seleccionado">
                                            <img [src]="'./assets/img/avatar-candidatos/'+elementoStage?.img_perfil.toLowerCase()" width="50px" height="50px">
                                            <div class="nombres dis-flex flex-col align-s">
                                                <div class="candidatos">
                                                    <p>
                                                        {{ elementoStage?.text | titlecase }}
                                                    </p>
                                                </div>
                                                <div class="partidos dis-flex">
                                                    <div class="container-partidos">
                                                        <img class="partido dis-flex m-2" *ngFor="let img of elementoStage?.partidos" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()">
                                                    </div>
                                                </div>
                                            </div>
                                            <i
                                            (click)="comparativaService.agregarElemento(elementoStage, 'entidadCandidatura'); comparativaService.modalFull = false"
                                            class="anadir fas fa-plus-circle cursorPointer"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 px-3">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12">
                                    <div *ngFor="let elementoStage of comparativaService?.stageArray; let i = index">
                                        <div class="elementoStage dis-flex space-b px-2 mx-3 my-3"
                                            *ngIf="i > comparativaService.stageArray.length / 2"
                                            [hidden]="elementoStage.seleccionado">
                                            <img [src]="'./assets/img/avatar-candidatos/'+elementoStage?.img_perfil.toLowerCase()" width="50px" height="50px">
                                            <div class="nombres dis-flex flex-col align-s">
                                                <div class="candidatos">
                                                    <p>
                                                        {{ elementoStage?.text | titlecase }}
                                                    </p>
                                                </div>
                                                <div class="container-partidos">
                                                    <img class="partido dis-flex m-2" *ngFor="let img of elementoStage?.partidos" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()">
                                                </div>
                                            </div>
                                            <i (click)="comparativaService.agregarElemento(elementoStage, 'entidadCandidatura'); comparativaService.modalFull = false"
                                            class="anadir fas fa-plus-circle cursorPointer"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
        
        <!--******************************RESUMEN DISTRITO PPyCI******************************-->
      <div class="modal-content" *ngIf="comparativaService.pantalla === 'distritoPPyCI'">
        <div class="modal-header bgMorado txBlanco"> 
          <h5 class="modal-title" id="exampleModalLongTitle">Detalle</h5>
          <button
          (click)="comparativaService.cerrar()"
          type="button" class="cerrar dis-flex" data-dismiss="modal" aria-label="Close">
            <img src="./assets/img/iconos-visualiza/cerrar.svg">
          </button>
        </div>
        <div class="modal-body">
            <!--Versión escritorio-->
            <div class="container-fluid d-none d-xl-block" >
              <div class="row">
                  <div class="col-3 totales">
                      <p class="w-100 em16 font-medium"><strong>Total de votos</strong></p>
                      <p class="w-100 em43 text-total txRosaIne"><strong> {{ comparativaService?.votos_total }} </strong></p>
                      <p class="w-100 em20 text-total txRosaIne"><strong> {{ comparativaService?.votos_pctj }} </strong></p>
                      <p class="em16"><strong>{{(globalService.getContienda() == 'ayuntamientos')? 'Municipio' : (globalService.getContienda() == 'juntas' ? 'Junta' : 'Distrito') }}</strong> </p>
                      <p class="em16">{{ rutas?.migas?.distrito }}</p>
                  </div>
                  <div class="col-9">
                      <div class="container-fluid">
                        <div class="row" *ngFor="let elemento of comparativaService?.arregloElementos">
                            <div class="col-10">
                                <div class="elemento bgGris my-2">
                                    <div class="partidos dis-flex justify-s bgGrisTenue">
                                        <img class="partido dis-flex m-2" [src]="'./assets/img/logos-partidos/'+ elemento?.imgs[0]?.toLowerCase()">
                                    </div>
                                    <div class="container-fluid p-2">
                                        <div class="row">
                                            <div class="col-8">
                                                <p class="titulo em14">
                                                    Total de votos
                                                </p>
                                                <p class="font-medium em12">
                                                  <b>{{ elemento?.total }}</b> {{ elemento?.porcentaje }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="barra-porcentaje" [ngStyle]="{ 'width': ( elemento?.porcentaje?.toString()?.includes('%') ? elemento?.porcentaje : elemento?.porcentaje+'%') }"></div>
                                </div>
                            </div>
                            <div
                            (click)="comparativaService.eliminarElemento(elemento, 'distritoPPyCI')"
                            class="col-2 dis-flex">
                              <i class="far fa-trash-alt cursorPointer em22" aria-hidden="true"></i>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
              <div class="row pt-3 pb-2" *ngIf="comparativaService.arregloElementos.length < 3 && !comparativaService.modalFull">
                <div class="col-3 offset-9">
                    <button
                    (click)="comparativaService.modalFull = true"
                    class="btn btn-block btn-rosaFuerte">Agregar</button>
                </div>
            </div>
            <div class="row pt-3 pb-2" *ngIf="comparativaService.arregloElementos.length < 3 && comparativaService.modalFull">
                <div class="col-3 offset-9">
                    <button
                    (click)="comparativaService.modalFull = false"
                    class="btn btn-block btn-rosaFuerte">Cancelar</button>
                </div>
            </div>
          </div>
            <!--Versión Movil-->
            <div class="d-block d-xl-none">
                <div class="row">
                    <div class="col-12 mt-5 ml-4">
                        <p class="w-100 em16 font-medium"><strong>Total de votos</strong></p>
                        <div class="text-total-movil">
                            <span class="w-100 em50 txRosaIne"><strong> {{ comparativaService?.votos_total }} </strong></span>
                            <br>
                            <span class="w-100 em18 txRosaIne porcentaje">{{ comparativaService?.votos_pctj }}</span>
                        </div>
                            
<!--
                        <p class="em16"><strong>{{(globalService.getContienda() == 'ayuntamientos')? 'Municipio' : (globalService.getContienda() == 'juntas' ? 'Junta' : 'Distrito') }}</strong> </p>
                        <p class="em16">{{ rutas?.migas?.distrito }}</p>
-->
                    </div>
                </div>
                <div class="row" *ngFor="let elemento of comparativaService?.arregloElementos">
                    <div class="col-12 ">
                        <div class="partido-movil">
                            <div class="row">
                                <div class="col-10">
                                    <img class="partido" [src]="'./assets/img/logos-partidos/'+ elemento?.imgs[0]?.toLowerCase()">
                                </div>
                                <div (click)="comparativaService.eliminarElemento(elemento, 'distritoPPyCI')" class="col-2 dis-flex">
                                    <i class="far fa-trash-alt cursorPointer em22" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="barra-movil">
                                <div class="text-container">
                                    <div class="col-4">
                                        <p>Total de votos</p>
                                        <p><strong>{{ elemento?.total }}</strong></p>
                                    </div>
                                    <div class="col-4">
                                        <p>Porcentaje</p>
                                        <p><strong>{{ elemento?.porcentaje }}</strong></p>
                                    </div>
                                </div>
                                <div class="porcentaje" [ngStyle]="{ 'width': elemento?.porcentaje, 'background-color': elemento?.color  }"></div>
<!--                                <div class="porcentaje" [ngStyle]="{ 'width': ( elemento?.porcentaje?.toString()?.includes('%') ? elemento?.porcentaje : elemento?.porcentaje+'%') }"></div>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 offset-3 mt-5" *ngIf="comparativaService.arregloElementos.length < 3 && !comparativaService.modalFull">
                    <button (click)="openBottomSheet()" class="btn btn-block btn-morado">Agregar</button>
                </div>
            </div>
        </div>
        <div class="modal-footer" *ngIf="comparativaService.modalFull && comparativaService.arregloElementos.length < 3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <p class="px16">
                            Puedes agregar hasta tres opciones con el botón +.
                        </p>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div *ngFor="let elementoStage of comparativaService?.stageArray"
                        class="col-5 elementoStage dis-flex space-b px-2 mx-3"
                        [hidden]="elementoStage.seleccionado">
                        <div class="partidos dis-flex">
                            <img class="partido m-2" [src]="'./assets/img/logos-partidos/'+ elementoStage?.imgs[0]?.toLowerCase()">
                        </div>
                        <i
                        (click)="comparativaService.agregarElemento(elementoStage, 'distritoPPyCI'); comparativaService.modalFull = false"
                        class="anadir fas fa-plus-circle cursorPointer"></i>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>
