import { Component, OnInit } from '@angular/core';
import { ObtenerDatosService } from '../../../../servicios/datos/obtener-datos/obtener-datos.service';

@Component({
  selector: 'app-base-datos-carpeta-archivos',
  templateUrl: './base-datos-carpeta-archivos.component.html',
  styleUrls: ['./base-datos-carpeta-archivos.component.scss']
})
export class BaseDatosCarpetaArchivosComponent implements OnInit {

  config: any = {};

  constructor(public readonly _obtenerDatosService: ObtenerDatosService) { }

  ngOnInit(): void {
    this._obtenerDatosService.configSubject.subscribe(data =>{
      if (data != null) this.config = data;
    });
  }

}
