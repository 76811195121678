import { Component, OnInit, Input } from '@angular/core';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service'

@Component({
  selector: 'app-distribucion-votos',
  templateUrl: './distribucion-votos.component.html',
  styleUrls: ['./distribucion-votos.component.scss']
})
export class DistribucionVotosComponent implements OnInit {
  @Input() public contienda:string = '';

  constructor(
    public _obtenerDatosService: ObtenerDatosService,
    public _globalService: GlobalesService
  ) { }

  ngOnInit(): void {

  }



}
