import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';
import { Location } from '@angular/common';

// declare var $;
// import * as $ from 'jquery';

@Component({
  selector: 'app-votos-extranjero',
  templateUrl: './votos-extranjero.component.html',
  styleUrls: ['./votos-extranjero.component.scss']
})

export class VotosExtranjeroComponent implements OnInit {
  status: any;
  acta_electronico: string = '';
  acta_postal: string = '';
  casilla_table: any = [];
  resumen: any = [];
  estadistica_extranjero: any = [];
  base: string;
  fichas: any = [];
  selectedContienda: any = '';
  selectedOptionIndx: number = -1;
  selectedOption = 'Todas';
  isOpenCombo = false;
  firstLoad = true; 


  constructor(
    public _globalService: GlobalesService,
    private readonly activatedRouter: ActivatedRoute,
    public readonly rutas: RutasService,
    public location: Location,
    public _obtenerDatosService: ObtenerDatosService
  ) { }

  ngOnInit(): void {
      this.obtenerParametros();
      this._globalService.menuActual = 'RP';
      this.getScreenSize()
      this._obtenerDatosService.mainSubject.subscribe(data =>{
        if(data == null) return;
        this.loadData();
        this._globalService.returnURL = '/gubernatura/votos-candidatura/grafica'
    });
  }

  loadData() {
      let selectedContienda = this._globalService.getContienda();
      this._obtenerDatosService.loadJSON(selectedContienda, 'voto-extranjero.json').then(data => {
          this.resumen = data['resumen'];
          //this.casilla_table = data['casilla_table'];
          this.estadistica_extranjero = data['estadistica_extranjero'];
          //this._obtenerDatosService.desglose = data['casilla_table'];
          this.fichas = data['fichas'];
          this._globalService.bottomSheetTpl = 'simple';
          this._globalService.bottomSheeTitle = 'Actas';
          //this._globalService.bottomSheetData = data['combo_movil'];
          this.selectedOptionIndx = -1
          this.status = data['status'];
          this.acta_electronico = ""//this.casilla_table.rows[0].status.file;
          this.acta_postal = ""//this.casilla_table.rows[1].status.file;
          this._globalService.callbackData.subscribe((parametros) => {
            this.itemSelected(parametros)
          });
//          console.log(this.casilla_table);
          
//      this._obtenerDatosService.participacion_table = data['participacion_table'].rows;
//
//      // this.grafica_table = data['grafica'].columns;
//      // this.total_votos = '1000';
          
      });
    // }
  }

  obtenerParametros() {
    this.activatedRouter.params.subscribe((parametros) => {
      const params = { parametros };
      this._globalService.detalles = params.parametros;
    });
    this._globalService.setearParametros();
  }

  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

  itemSelected(index:any) {
    if(this.firstLoad){
      this.firstLoad = false
      return;
    }
    this.openBottomSheet()
    this.selectedOptionIndx = +index;
    var posCombo = this.selectedOptionIndx + 1;
    this.selectedOption = this._globalService.bottomSheetData[posCombo].text;
  }

  openBottomSheet(){
    this.isOpenCombo = !this.isOpenCombo;
    this._globalService.toggleBottomSheet(this.isOpenCombo);
  }

  atras() {
    this.location.back();
    this._globalService.menuActual = 'Votos';
    window.scrollTo(0, 0);
}

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
      const scrWidth = window.innerWidth;
      //console.log('WINDOW WIDTH:  ', scrWidth);
      this._globalService.menuActual = scrWidth <= 1200 ? 'vP' : 'RP';
  }

}