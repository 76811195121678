<div class="seccion-resumen container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 col-lg-10 padding-b-20">
                        <h3 class="titulo-seccion">
                            Consulta ciudadana - <span class="txRosaIne"><strong>Chiapas</strong></span>
                        </h3>
                        <div class="texto font-regular">
                            <p>
                                Cantidad de votos por Representación Proporcional en cada casilla especial que conforma
                                la entidad; de acuerdo a la información asentada en las actas de escrutinio y cómputo.
                            </p>
                            <p>
                                Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No
                                obstante, al considerar todos los decimales, suman 100%.
                            </p>
                        </div>
                    </div>
                </div>
                <!--************* VISTA ESCRITORIO *************-->
                <div class="container-fluid d-none d-md-none d-xl-block">
                    <div class="col-12 col-lg-10 px-0 mt-4">
                        <div class="w-100 dis-flex" *ngFor="let item of arrPreguntas; let indx = index" style="display: inline-block;">
                            
                            <p class="label-pregunta"><strong>{{item.text}}</strong></p>
                            
                            <table class="w-100" style="font-size: 1.4em; margin: 0 0 0 16px;">
                                <thead>
                                    <tr>
                                        <td>
                                            <strong>Respuestas</strong>
                                        </td>
                                        <td>
                                            <strong>Votos en la Entidad</strong>
                                        </td>
                                        <td>
                                            <strong>Votos en el Extranjero</strong>
                                        </td>
                                        <td>
                                            <strong>Total de Votos</strong>
                                        </td>
                                        <td>
                                            
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of item.data;let i = index;">
                                        <td class="w-col">
                                            <strong>{{row.text}}</strong>
                                        </td>
                                        <td class="w-col" [ngClass]="{'textMayor': row?.winner}">
                                            <p class="label-votos">{{row.votos_entidad}}</p>
                                            <p class="sub-prc">{{row.prc_entidad}}%</p>
                                        </td>
                                        <td class="w-col" [ngClass]="{'textMayor': row?.winner}">
                                            <p class="label-votos">{{row.votos_extranjero}}</p>
                                            <p class="sub-prc">{{row.prc_extranjero}}%</p>
                                        </td>
                                        <td class="w-col" [ngClass]="{'textMayor': row?.winner}">
                                            <p class="label-votos">{{row.votos_total}}</p>
                                            <p class="sub-prc">{{row.prc_total}}%</p>
                                        </td>
                                        <td class="w-col-graf21">
                                            <div class="row pt-1" *ngIf="i < item.data.length-1">
                                                <div class="col-md-12">
                                                    <div class="graficaHorizontal">
                                                        <div class="progresoHorizontal" [style.width.%]="row.prc_total" [ngClass]="{'graficaSi': i == 0, 'graficaNo': i == 1, 'graficaNulo': i == 2}"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>     
                        </div>
                    </div>
                </div>
                <!--*************************** VISTA MOVIL *************************-->
                <div class="container-fluid w-100 d-block d-md-block d-xl-none">
                    <div class="movil-content-box" style="background-color: #F4F4F4; margin-bottom: 16px; padding-top: 16px;" *ngFor="let item of arrPreguntas; let indx = index;">
                        <div class="content-candidato">
                            <p class="movil-pregunta px-1"><strong>{{ item?.text }}</strong></p>
                            <div *ngFor="let row of item.data;let i = index;">
                                <div class="graf-container"  *ngIf="i < item.data.length-1">
                                    <p class="movil-respuesta"> <strong>{{ row?.text }}</strong> </p>
                                    <div class="row pt-1 movil-graf">

                                        <div class="graficaHorizontal movil-grafHorizontal dis-flex justify-s px-2">
                                            <div class="textos w-100">
                                                <p style="font-size: 1.3em;">
                                                    <strong>Total de Votos</strong>
                                                </p>
                                                <div class="dis-flex">
                                                    <p class="px12 title-graf w-50" style="font-size: 2.2em !important;">
                                                        <strong>
                                                            {{row.votos_total}}
                                                        </strong>
                                                    </p>
                                                    <p class="px12 w-50" style="font-size: 1.4em; text-align: right;">
                                                        <strong>
                                                            {{row.prc_total}}%
                                                        </strong>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="progresoHorizontal interno" [ngStyle]="{ 'width.%': row.prc_total}" [ngClass]="{'mov-graficaSi': i == 0, 'graficaNo': i == 1, 'graficaNulo': i == 2}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cuerpo dis-flex w-100 p-2"  *ngIf="i < item.data.length-1">
                                    <div class="w-100" style="font-size: 1.7em;">
                                        <p><strong>Votos</strong></p>
                                        <div class="dis-flex" style="margin: auto; width: 90%;">
                                            <div class="w-50 align-e">
                                                <p style="text-align: center;">En la entidad</p>
                                                <p class="mov-label-votos align-right" [ngClass]="{'textMayor': row?.winner}">{{row.votos_entidad}}</p>
                                                <p class="sub-prc align-right" [ngClass]="{'textMayor': row?.winner}">{{row.prc_entidad}}%</p>
                                            </div>
                                            <div class="vline"></div>
                                            <div class="w-50" style="text-align: right;">
                                                <p>En el extranjero</p>
                                                <p class="mov-label-votos" [ngClass]="{'textMayor': row?.winner}">{{row.votos_extranjero}}</p>
                                                <p class="sub-prc" [ngClass]="{'textMayor': row?.winner}">{{row.prc_extranjero}}%</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--*****************************************************************-->
            </div>
        </div>
    </div>
</div>
<app-estadistica-nacional></app-estadistica-nacional>