import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ChildActivationStart } from '@angular/router';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { Router } from '@angular/router';

// declare var $;
// import * as $ from 'jquery';
import { RutasService } from '../../../../../servicios/rutas/rutas.service';
import { ThemeService } from '../../../../../theme/theme.service';

@Component({
  selector: 'app-ppyci-circunscripcion-grafica',
  templateUrl: './ppyci-circunscripcion-grafica.component.html',
  styleUrls: ['./ppyci-circunscripcion-grafica.component.scss']
})
export class PpyciCircunscripcionGraficaComponent implements OnInit {

  base: string;
  pathImg = './assets/img/logos-partidos/'
  resumen: any = {};

  constructor(
    public _obtenerDatosService: ObtenerDatosService,
    public _globalService:GlobalesService,
    public _rutasService:RutasService,
    public _router:Router,
    public activatedRoute: ActivatedRoute,
    public themeService: ThemeService,
  ) { }

  

  ngOnInit(): void {
    this.base = this._rutasService.rutaActual.base;
    this.obtenerParametro();
    this._globalService.menuActual = 'Votos';
    
    /* Observable cambio de URL*/
    this._rutasService.observ.subscribe( texto =>{
    
    });
    // $('[data-toggle="tooltip"]').tooltip();
  }

  obtenerParametro() {
    return new Promise((res, rej) => {
    this.activatedRoute.params.subscribe((param) => {
        this._globalService.detalles = param;
        res();
    });
    })
}
  
  

}
