import { Component, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/theme/theme.service';
import { ObtenerDatosService } from '../../../servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from '../../../servicios/globales.service';
import { Router } from '@angular/router';

declare var $: any;

@Component({
	selector: 'app-buscador',
	templateUrl: './buscador.component.html',
	styleUrls: ['./buscador.component.scss']
})
export class BuscadorComponent implements OnInit {

	arrSecciones: any = [];
	arrCasillasSecciones: any = [];
	selectedContienda: any = '';

	constructor(
		public _obtenerDatosService:ObtenerDatosService,
		public globalService: GlobalesService,
		public themeService: ThemeService,
		public router: Router,
	) { }

	ngOnInit(): void {
		// console.log('AQUI ANTES DE SELECTED CONTIENDA BUSCADOR');
		this.selectedContienda = this.globalService.getContienda();
		// console.log('AQUI SELECTED CONTIENDA', this.selectedContienda);

		this._obtenerDatosService.loadSeccionesCombo(this.selectedContienda).then(response => {

			// console.log('response', response);
			// this.arrSecciones = this.globalService.orderObjectByKeyNumber('seccion', response['secciones']);
			// for (let i = 0; i < array.length; i++) {
			// 	const element = array[i];
				
			// }
			this.arrSecciones = response['secciones'];
			this.arrCasillasSecciones = response['casillas'];
		});
	}

	parseSeccion(itemSeccion) {
		let arrSplit = itemSeccion.split('seccion');

		return 'Sección ' + arrSplit[1];
	}

	buscaCasilla(form){
		// console.log('aqui estoy');

		// document.getElementById('#myModal').modal('show')
		// return;
		let values = form.form.value;

		// console.log('values', values);
		// console.log('values.seccion', values.seccion);
		values.seccion = parseInt(values.seccion)

		// console.log('values.seccion', values.seccion);
		if (values.seccion && values.seccion > 0) {
			values.seccion = parseInt(values.seccion);
			let auxDistrito = '',
				auxSeccion = 'seccion' + values.seccion,
				ruta = '';

			// this.arrSecciones.forEach(el => {
			// 	if (auxDistrito == '' && el.param == values.seccion) auxDistrito = el.distrito;
			// });

			// console.log('auxSeccion', auxSeccion);
			// console.log(this.arrSecciones);
			if (this.arrSecciones[auxSeccion] && !this.globalService.isObjEmpty(this.arrSecciones[auxSeccion])) {
				if (this.selectedContienda == 'gubernatura' || this.selectedContienda == 'diputaciones') auxDistrito = this.arrSecciones[auxSeccion].distrito;
				else auxDistrito = this.arrSecciones[auxSeccion].municipio;
	
				// console.log(auxDistrito);return;
	
				if (auxDistrito != '') {
					ruta = `/${this.selectedContienda}/${auxDistrito}/secciones/${auxSeccion}`;
					if (values.apellido) {
						values.apellido = values.apellido.toUpperCase();
	
						let inicioApellido = values.apellido.substring(0, 2);
						if (this.arrCasillasSecciones[auxSeccion]) {
							let arrCasillas = this.arrCasillasSecciones[auxSeccion],
								// inicialesInicio = '',
								// inicialesFin = '',
								matchFound = false,
								arrIniciales = [];
							for (const iniciales in arrCasillas) {
								arrIniciales = iniciales.split('-');
	
								arrIniciales[0] = arrIniciales[0].toUpperCase();
								arrIniciales[1] = arrIniciales[1].toUpperCase();
	
								if (matchFound == false) {
									if (inicioApellido >= arrIniciales[0]) {
										if (inicioApellido <= arrIniciales[1]) {
											matchFound = true;
		
											ruta += `/casilla/${arrCasillas[iniciales]}`;
										}
									}
								}
								// console.log('iniciales', iniciales);
								// console.log('iniciales', arrCasillas[iniciales]);
							}
	
							this.openModal(ruta)
	
							// location.replace(ruta);
						} else {
							this.openModal(ruta);
							// location.replace(ruta);
						}
					} else {
						this.openModal(ruta);
						// location.replace(ruta);
					}
				} else alert('No se encontró la sección seleccionada.');
			} else alert('No se encontró la sección seleccionada.');
		} else alert('Debe ingresar una sección válida.');

	}

	openModal(ruta) {
		// $('#modalAvisoPrivacidad').modal('show');
		// $('#modalAvisoPrivacidad').on('hidden.bs.modal', function (e) {
		// 	$('#modalAvisoPrivacidad').unbind('hidden.bs.modal');
			location.replace(ruta);
		// });
	}

	onChangeEvent(e: any){
		if(e && e.target.value){
			let newVal = e.target.value.replace(/\D/g, '');

			if(newVal.length === 0){e.target.value = '';}
			else e.target.value = parseInt(newVal);
		}
	}


}
