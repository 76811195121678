import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';

@Component({
  selector: 'app-resumen-partido-representacion',
  templateUrl: './resumen-partido-representacion.component.html',
  styleUrls: ['./resumen-partido-representacion.component.scss']
})
export class ResumenPartidoRepresentacionComponent implements OnInit {

    

    constructor( public _obtenerDatosService:ObtenerDatosService,
                public _globalService:GlobalesService) { }

    ngOnInit(): void {
      this._globalService.menuActual = 'RP';
    }

    @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

  asIsOrder(a, b): number {
    const aStepId = parseInt(a.key, 10);
    const bStepId = parseInt(b.key, 10);
    return aStepId > bStepId ? 1 : (bStepId > aStepId ? -1 : 0);
  }

}
