import { Component, OnInit } from '@angular/core';
import { ObtenerDatosService } from '../../../../servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';

@Component({
  selector: 'app-pregunta-visualizacion-tarjetas',
  templateUrl: './pregunta-visualizacion-tarjetas.component.html',
  styleUrls: ['./pregunta-visualizacion-tarjetas.component.scss']
})
export class PreguntaVisualizacionTarjetasComponent implements OnInit {

  Search = "";
  constructor(public readonly _obtenerDatosService : ObtenerDatosService, public _globalService: GlobalesService) { }

  ngOnInit(): void {
    this._globalService.searchItem.subscribe(data =>{
      if(data == null) return;
      this.Search = data
    });
  }

}
