import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { AyuntamientoPartidoRoutingModule } from './ayuntamiento-partido-routing.module';
import { AyuntamientoCandidaturaRoutingModule } from './ayuntamiento-candidatura-routing.module';

import { ResumenDistritoComponent } from '../diputacion/votos-distrito/componentes/resumen-distrito/resumen-distrito.component';
import { ResumenDistritoMapaComponent } from '../diputacion/votos-distrito/componentes/resumen-distrito-mapa/resumen-distrito-mapa.component';

// import { ResumenDistritoComponent } from './votos-distrito/componentes/resumen-distrito/resumen-distrito.component';
// import { VotosDistritoComponent } from './votos-distrito/componentes/votos-distrito/votos-distrito.component';
// import { DistritoCircunscripcionComponent } from './votos-distrito/componentes/distrito-circunscripcion/distrito-circunscripcion.component';
// import { DistritoEntidadComponent } from './votos-distrito/componentes/distrito-entidad/distrito-entidad.component';
// // import { DistritoDistritoComponent } from './votos-distrito/componentes/distrito-distrito/distrito-distrito.component';
// import { ResumenDistritoMapaComponent } from './votos-distrito/componentes/resumen-distrito-mapa/resumen-distrito-mapa.component';

// import { SeccionResumenComponent } from './seccion/seccion-resumen/seccion-resumen.component';
// import { CasillaResumenComponent } from './casilla/casilla-resumen/casilla-resumen.component';

// import { EstadisticaNacionalComponent } from '../componentes/plantilla/estadistica-nacional/estadistica-nacional.component';
// import { DistribucionVotosComponent } from './votos-distrito/componentes/compartidos/distribucion-votos/distribucion-votos.component';

import { TooltipModule } from 'ng2-tooltip-directive';

import { HighchartsChartModule } from 'highcharts-angular';
import { ChartModule } from 'angular-highcharts';
// import { DistritoCircunscripcionMapaComponent } from './votos-distrito/componentes/distrito-circunscripcion-mapa/distrito-circunscripcion-mapa.component';
// import { DistritoEntidadMapaComponent } from './votos-distrito/componentes/distrito-entidad-mapa/distrito-entidad-mapa.component';
// import { DistritoEntidadDetalleCasillaComponent } from './votos-distrito/componentes/distrito-entidad-detalle-casilla/distrito-entidad-detalle-casilla.component';
// import { DetalleVotosCasillaComponent } from './votos-distrito/componentes/compartidos/detalle-votos-casilla/detalle-votos-casilla.component';
// import { ParticipacionCiudadanaComponent } from './votos-distrito/componentes/compartidos/participacion-ciudadana/participacion-ciudadana.component';
// import { DistritoSeccionesComponent } from './votos-distrito/componentes/distrito-secciones/distrito-secciones.component';

// import { RoundPipe } from '../pipes/round.pipe';
// import { StringtointPipe } from '../pipes/stringtoint.pipe';
// import { FormatmilesPipe } from '../pipes/formatmiles.pipe';

// import { ResumenPartidoGraficaComponent } from './votos-partido/componentes/resumen-partido-grafica/resumen-partido-grafica.component';
// import { ResumenPartidoComponent } from './votos-partido/componentes/resumen-partido/resumen-partido.component';
// import { PpyciDistritoComponent } from './votos-partido/componentes/ppyci-distrito/ppyci-distrito.component';
// import { TituloPipeDistrito } from '../../pipes/titulo-distrito/titulo.pipe';
import { ImportingModule } from '../../importing.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    // TituloPipeDistrito,
    // ResumenDistritoComponent,
    // ResumenDistritoComponent,
    // ResumenDistritoMapaComponent
    // RoundPipe,
    // StringtointPipe,
    // FormatmilesPipe,

    // VotosDistritoComponent,
    // DistritoCircunscripcionComponent,
    // DistritoEntidadComponent,
    // // DistritoDistritoComponent,
    // ResumenDistritoMapaComponent,
    // SeccionResumenComponent,
    // CasillaResumenComponent,
    // // EstadisticaNacionalComponent,
    // // DistribucionVotosComponent,
    // DistritoCircunscripcionMapaComponent,
    // DistritoEntidadMapaComponent,
    // DistritoEntidadDetalleCasillaComponent,
    // DetalleVotosCasillaComponent,
    // ParticipacionCiudadanaComponent,
    // DistritoSeccionesComponent,
    // ResumenPartidoGraficaComponent,
    // ResumenPartidoComponent,
    // PpyciDistritoComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    HighchartsChartModule,
    ChartModule,
    TooltipModule,
    ImportingModule,
    AyuntamientoCandidaturaRoutingModule,
    AyuntamientoPartidoRoutingModule,
    RouterModule
  ]
})
export class AyuntamientoCandidaturaModule { }
