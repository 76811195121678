import { Component, OnInit } from '@angular/core';
import { GlobalesService } from 'src/app/servicios/globales.service';

@Component({
  selector: 'app-pregunta-resumen-votacion',
  templateUrl: './pregunta-resumen-votacion.component.html',
  styleUrls: ['./pregunta-resumen-votacion.component.scss']
})
export class PreguntaResumenVotacionComponent implements OnInit {

  Search = "";

  constructor(public _globalService: GlobalesService) { }

  ngOnInit(): void {
    this._globalService.searchItem.subscribe(data =>{
      if(data == null) return;
      this.Search = data
    });
  }

}
