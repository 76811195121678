import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatmiles'
})
export class FormatmilesPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if(value !== undefined && value != null){
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    }
  }

}
