<!-- {{_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion] | json}} -->
<!-- {{_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.totalVotos?.total}} -->

<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 d-block d-xl-none" style="margin-bottom: 15px;">
                        <p class="px12 text-left"><strong>Visualiza como:</strong></p>
                        <div class="visualizar dis-flex flex-col text-center">
                            <div class="selector dis-flex w-100 cursorPointer" [ngClass]="{'brdr-selector': !themeService.isDarkTheme()}">
                                <div class="activo izq dis-flex flex-col w-50 p-2">
                                    <img class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Grafica-B.svg">
                                    <!-- <i class="ine ine-mapa" aria-hidden="true"></i> -->
                                    <p>Mapa</p>
                                </div>
                                <div [routerLink]="['tarjetas']" class="dis-flex der flex-col w-50 p-2">
                                    <img class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Tarjetas-B.svg" style="opacity: 0.5;">
                                    <!-- <i class="ine ine-tarjetas" aria-hidden="true"></i> -->
                                    <p>Tarjetas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-10">
                        <app-breadcrumb
                            subtitle="Circunscripción"
                            subtitle_detalle="Votos por Partido Político"
                            estado="Nacional"
                        ></app-breadcrumb>
                        <div class="texto font-regular">
                            <p>
                                El total de votos calculado y porcentaje que se muestran, se refiere a los votos asentados en las Actas PREP hasta el momento.
                            </p>
                            <p>
                                Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%.
                            </p>
                        </div>
                    </div>
                    <div class="col-2 d-none d-xl-block">
                        <p class="px12 text-left"><strong>Visualiza como:</strong></p>
                        <div class="visualizar dis-flex flex-col text-center">
                            <div class="selector dis-flex w-100 cursorPointer">
                                <div class="activo izq dis-flex flex-col w-50 p-2">
                                    <img class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Grafica-B.svg">
                                    <!-- <i class="far fa-chart-bar"></i> -->
                                    <p>Gráfica</p>
                                </div>
                                <div [routerLink]="['tarjetas']" class="dis-flex der flex-col w-50 p-2">
                                    <img class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Tarjetas-B.svg" style="opacity: 0.5;">
                                    <!-- <i class="ine ine-tarjetas" aria-hidden="true"></i> -->
                                    <p>Tarjetas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-resumen-votacion-mobile
                    [total_num]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.totalVotos?.total"
                    [total_prc]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.totalVotos?.porcentaje"
                ></app-resumen-votacion-mobile>
                <div class="row pb-3 mt-2">
                    <div class="col">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                    </div>
                </div>
                <div class="row padding-b-10">
                    <div class="col-12 px-0">
                        <div class="table-responsive">
                            <table class="table table-borderedHeader" id="t-g-vpp">
                                <thead>
                                    <tr>
                                        <td class=""></td>
                                        <td class="fondoGrafica td-relative" *ngFor="let item of _obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.votos"></td>
                                    </tr>
                                    <tr>
                                        <td class="td-relative table-borderedHeader sinBorde">
                                            <!-- <div class="my-chart-label "><b>Votos</b></div> -->
                                            <div class="linea-chart-nl line-chart-100 line-char-first ">{{ (_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.totalVotos?.total | stringtoint) | formatmiles }}</div>
                                            <div class="linea-chart-nl line-chart-80 line-char-first ">{{ (((_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.totalVotos?.total | stringtoint) * .8) | round) | formatmiles
                                                }}</div>
                                            <div class="linea-chart-nl line-chart-60 line-char-first ">{{ (((_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.totalVotos?.total | stringtoint) * .6) | round) | formatmiles
                                                }}</div>
                                            <div class="linea-chart-nl line-chart-40 line-char-first ">{{ (((_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.totalVotos?.total | stringtoint) * .4) | round) | formatmiles
                                                }}</div>
                                            <div class="linea-chart-nl line-chart-20 line-char-first ">{{ (((_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.totalVotos?.total | stringtoint) * .2) | round) | formatmiles
                                                }}</div>
                                            <div class="linea-chart-nl line-chart-0 line-char-first ">0</div>
                                            <div class="my-chart ">
                                                <div style="height: 0px; "></div>
                                            </div>
                                        </td>
                                        <td class="fondoGrafica td-relative" *ngFor="let itemPartido of _obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.votos">
                                            <div class="linea-chart line-chart-100 "></div>
                                            <div class="linea-chart line-chart-80 "></div>
                                            <div class="linea-chart line-chart-60 "></div>
                                            <div class="linea-chart line-chart-40 "></div>
                                            <div class="linea-chart line-chart-20 "></div>
                                            <div class="linea-chart-nl line-chart-0 "></div>
                                            <div class="progress-bar-vertical">
                                                <div aria-valuemax="100" aria-valuemin="0" class="progress-bar progres-chart " placement="top" role="progressbar" [attr.title]="" tooltip="<p>{{ _obtenerDatosService.diccionarioPartidos[itemPartido.partido]?.nombreCortoPartido }}</p><p>Total de votos: {{itemPartido.votos}}</p><p>Porcentaje: {{itemPartido.porcentaje}}%</p>"
                                                    [ngStyle]="{ 'height': itemPartido.porcentaje+'%' ,'background-color': _obtenerDatosService.diccionarioPartidos[itemPartido.partido]?.colorPartido}">
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="thtitulos">
                                            <span class="px16 font-bold">
                                          Votos
                                        </span>
                                            <br>
                                            <span class="px14 font-bold">
                                          Total
                                        </span>
                                        </td>
                                        <td class="text-center" *ngFor="let itemPartido of _obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.votos">
                                            <!-- <ng-container *ngIf="_obtenerDatosService.diccionarioPartidos[itemPartido.partido]?.nombrePartido.toLowerCase() != 'CANDIDATURAS NO REGISTRADAS'.toLowerCase() &&
                                      _obtenerDatosService.diccionarioPartidos[itemPartido.partido]?.nombrePartido.toLowerCase() != 'VOTOS NULOS'.toLowerCase() &&
                                      _obtenerDatosService.diccionarioPartidos[itemPartido.partido]?.nombrePartido.indexOf('CANDIDATO INDEPENDIENTE') == '-1'">
                                        <img class="img-partido" [src]="_obtenerDatosService.diccionarioPartidos[itemPartido.partido]?.imgPartido?.toLowerCase() " />
                                      </ng-container>
                                      <ng-container *ngIf="_obtenerDatosService.diccionarioPartidos[itemPartido.partido]?.nombrePartido.toLowerCase() == 'CANDIDATURAS NO REGISTRADAS'.toLowerCase() ||
                                                           _obtenerDatosService.diccionarioPartidos[itemPartido.partido]?.nombrePartido.toLowerCase() == 'Votos nulos'.toLowerCase() ||
                                                           _obtenerDatosService.diccionarioPartidos[itemPartido.partido]?.nombrePartido.indexOf('CANDIDATO INDEPENDIENTE') >= '0' ">

                                        {{ diccionarioPartidos[itemPartido.partido]?.nombrePartido }}
                                      </ng-container> -->
                                            <ng-container>
                                                <div>
                                                    <img class="img-partido" [src]="pathImg+_obtenerDatosService.diccionarioPartidos[itemPartido.partido]?.imgPartido?.toLowerCase() " />
                                                </div>
                                                <div>
                                                    <span class="14px font-bold">
                                                    {{itemPartido.votos}}

                                                </span>
                                                </div>
                                            </ng-container>

                                        </td>
                                    </tr>
                                    <tr class="border3-t-rosaINE ">
                                        <td class="bg-gris-3">
                                            <span class="px14">
                                          Porcentaje
                                      </span>
                                        </td>
                                        <td *ngFor="let totales of _obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.votos" class="text-center bg-rosa2">
                                            <span class="px12">
                                            {{totales.porcentaje}}%
                                        </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <div class="row padding-b-10">
                    <div class="col-10">
                        <div class="texto font-regular">
                            <p>
                                En esta tabla se considera la cantidad de votos asentadas en las Actas PREP de Casilla Especial de Representación Proporcional.
                            </p>
                        </div>
                    </div>
                </div>
                <app-resumen-votacion
                    [acumulados_num]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.acumulados?.total"
                    [acumulados_prc]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.acumulados?.porcentaje"
                    [noregistradas_num]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.noRegistradas?.total"
                    [noregistradas_prc]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.noRegistradas?.porcentaje"
                    [nulos_num]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.nulos?.total"
                    [nulos_prc]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.nulos?.porcentaje"
                    [total_num]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.totalVotos?.total"
                    [total_prc]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosPartidosPoliticos?.resumenVotacion?.totalVotos?.porcentaje"
                ></app-resumen-votacion>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card p-3 mt-3">
                        <div class="row justify-content-center">
                            <div class="col-4">
                                <h4 class="subtitulo-seccion py-3 text-center">
                                    <strong>Representación Proporcional</strong>
                                </h4>
                                <p class="texto">
                                    El apartado “Actas RP” muestra la cantidad de votos asentada en las Actas PREP de Casilla Especial de Representación Proporcional.
                                </p>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-4 dis-flex">
                                <button [routerLink]="['/'+_globalService.detalles.circunscripcion+'/votos-ppyci/representacion-proporcional']" class="btn btn-sm btn-rosaFuerte texto cursorPointer my-3 px-4 py-1">
                                Ver Actas RP
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-estadistica-nacional></app-estadistica-nacional>
