import { Injectable, Output } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { EventEmitter } from 'events';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { RutasService } from '../../rutas/rutas.service';
import { GlobalesService } from '../../globales.service';
import { Router } from '@angular/router';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class ObtenerDatosService {
    @Output() mainchange = new EventEmitter();
    @Output() configChange = new EventEmitter();
    @Output() contiendasChange = new EventEmitter();
    @Output() test = new EventEmitter();

	public mainSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public configSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public combosSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public changeContiendaSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public urlSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public migasSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public seccionesSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public casillasSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    observableMain: Observable<Event>;
    observerMain: Observer<any>;

    estadistica: any = [];

    dataCircunscripcion: any = [];
    datosEspecificos: any = [];
    datosDetalle: any = [];


    pathImg = './assets/img/logos-partidos/'

    constructor(private http: HttpClient, public rutasService: RutasService, public globalService: GlobalesService, public router: Router) {
        this.observableMain = Observable.create((observer:Observer<any>) => {
            this.observerMain = observer;
        });
    }

    /* Variables Globales JSON */

    /* Variables Data Avance Entidad */
    avanceNacional: any = {};
    dataAvanceNacional:any;

    /*Variables Data Diputaciones Nacional */
    dataDiputacionesNacional:any
    dataEstadisticaNacional:any;

    /*Variables Data Diputaciones Circunscripcion */
    dataDiputacionesCircunscripcion:any = [];
    dataEstadisticaCiscunscripcion:any;

    /*Variables Data Diputaciones Entidad */
    dataDiputacionesEntidad:any = [];
    dataEstadisticaEntidad:any;

    /* Variables Data Diputaciones Distrito */
    dataDiputacionesDistrito:any;
    dataEstadisticaDistrito:any;

    /* Variables Data Diccionarios */
    diccionarioPartidos:any;
    diccionarioDistritos:any;

    /* Variables Data Mapas */
    dataMapaDistrito:any;
    dataMapaCircunscripciones:any;
    dataMapaEntidades:any;


    /* Variables para resultados de casilla */
    dataCircunscripcionByEntidad:any;
    dataDistritoBySeccion:any;

    /* Variables para resultados de casilla */
    desglose: any = [];
    participacion_table:any;


    /* BIGSoft - Variables para manejo de JSON's */
    main: any;
    config: any = {};
    contiendas: any = {};
    mapas: any = {};
    selectedContienda:any;
    mainCombos:any;
    arrSecciones: any = [];

    comboDistritos: any = [];
    comboSecciones: any = [];
    comboCasillas: any = [];
    labelComboDistritos: any = '';

    cargarIndices() {
        this.loadDistritos();

        if (this.rutasService.migas.distrito_clave) {
          this.rutasService.migas.distrito_clave;

          this.globalService.rutaParametros.distritoCompleto = this.rutasService.migas.distrito_clave;
          this.globalService.rutaParametros.noDistrito = this.rutasService.migas.distrito_clave;

          this.loadSecciones();
        }

        this.rutasService.migas.seccion_clave = this.rutasService.migas.seccion_clave == 'Todas' ? '' : this.rutasService.migas.seccion_clave;

        if (this.rutasService.migas.seccion_clave) {
          this.globalService.rutaParametros.noSeccion = this.rutasService.migas.seccion_clave;

          this.loadCasillas();
        }


        if(!this.globalService.rutaParametros.circunscripcion) {
          this.globalService.idCircuns = null;
        }
      }

      loadDistritos() {
          let selectedContienda = this.globalService.getContienda(),
		  auxDistritos = {};
		  if (this.globalService.arrContiendas.indexOf(selectedContienda) >= 0) {
	          this.comboDistritos = [];

	          if (selectedContienda) {
	            this.loadJSON(selectedContienda, 'rutas.json').then(data =>{
	              this.labelComboDistritos = data['rutas_nombre'];
	              data['rutas'].forEach(distrito => {
	                auxDistritos[distrito.param] = distrito.text;
	                this.comboDistritos.push({
	                  "id": distrito.param,
	                  "claveURL": distrito.param,
	                  "migas": distrito.text,
	                });

	              });

	              this.rutasService.migas.distrito = auxDistritos[this.rutasService.migas.distrito_clave];



	            });
			  }
		  }

      }

      loadSecciones() {
        let selectedContienda = this.globalService.getContienda(),
            auxSecciones = {},
            fileName = 'seccion.' + this.rutasService.migas.distrito_clave + '.json',
            rows_key = 'seccion_table';

            this.comboSecciones = [];

        if (selectedContienda) {
          if (selectedContienda == 'ayuntamientos' || selectedContienda == 'juntas') rows_key = 'resumen_detalle_table';

          if (this.contiendas[selectedContienda] && this.contiendas[selectedContienda][fileName]) {
            this.contiendas[selectedContienda][fileName][rows_key].rows.forEach(seccion => {
              if (seccion.param == this.rutasService.migas.seccion_clave) this.rutasService.migas.seccion = seccion.text;
              auxSecciones[seccion.param] = seccion.text;
              this.comboSecciones.push({
                "id": seccion.param,
                "claveURL": seccion.param,
                "migas": seccion.text,
              });
            });
          } else {
			//   console.log('ELSEEEEE');
			// // this.seccionesSubject.subscribe(secciones => {
			// // 	if (secciones != null) {
			// // 		secciones[rows_key].rows.forEach(seccion => {
			// // 			if (seccion.param == this.rutasService.migas.seccion_clave) this.rutasService.migas.seccion = seccion.text;
			// // 			auxSecciones[seccion.param] = seccion.text;
			// // 			this.comboSecciones.push({
			// // 			  "id": seccion.param,
			// // 			  "claveURL": seccion.param,
			// // 			  "migas": seccion.text,
			// // 			});
			// // 		});
			// // 	}
			// // });

            this.loadJSON(selectedContienda, fileName).then(data =>{
				// console.log('AQUILOADJSON');
				this.seccionesSubject.next(data);
				data[rows_key].rows.forEach(seccion => {
					if (seccion.param == this.rutasService.migas.seccion_clave) this.rutasService.migas.seccion = seccion.text;
					auxSecciones[seccion.param] = seccion.text;
					this.comboSecciones.push({
						"id": seccion.param,
						"claveURL": seccion.param,
						"migas": seccion.text,
					});
				});
            });
        }
          // if (this.idSeccion != null) this.rutasService.migas.seccion = auxSecciones[this.idSeccion];
        }
      }

      loadCasillas() {
        let selectedContienda = this.globalService.getContienda(),
            auxCasillas = [],
            rows_key = 'casillas';

            this.comboCasillas = [];

        // if (selectedContienda != 'diputaciones') rows_key = 'seccion_casilla_table';
        if (this.contiendas[selectedContienda] && this.contiendas[selectedContienda][this.rutasService.migas.seccion_clave + '.' + this.rutasService.migas.distrito_clave + '.casilla.json']) {

          this.comboCasillas = [];
          // this.contiendas[selectedContienda][this.rutasService.migas.seccion_clave + '.' + this.rutasService.migas.distrito_clave + '.casilla.json'][rows_key].rows.forEach(casilla => {
          let data = this.contiendas[selectedContienda][this.rutasService.migas.seccion_clave + '.' + this.rutasService.migas.distrito_clave + '.casilla.json'][rows_key];
          for (const casilla in data) {
            if (casilla == this.rutasService.migas.casilla_clave) this.rutasService.migas.casilla = data[casilla].text;
            auxCasillas[casilla] = data[casilla].text;
            this.comboCasillas.push({
              "id": data[casilla].text,
              "claveURL": casilla,
              "migas": data[casilla].text,
            });
          }
          // });
        } else {
			this.casillasSubject.subscribe(casillas => {
				if (casillas != null) {
          this.comboCasillas = [];
          let data = casillas[rows_key];
					// casillas[rows_key].rows.forEach(casilla => {
          for (const casilla in data) {
						if (casilla == this.rutasService.migas.casilla_clave) this.rutasService.migas.casilla = data[casilla].text;
							auxCasillas[casilla] = data[casilla].text;
							this.comboCasillas.push({
							"id": data[casilla].text,
							"claveURL": casilla,
							"migas": data[casilla].text,
						});
          }
					// });
				}
			});
          // this.loadJSON(selectedContienda, (this.rutasService.migas.seccion_clave + '.' + this.rutasService.migas.distrito_clave + '.casilla.json')).then(data =>{
          //   data[rows_key].rows.forEach(casilla => {
          //     if (casilla.param == this.rutasService.migas.casilla_clave) this.rutasService.migas.casilla = casilla.text;
          //     auxCasillas[casilla.param] = casilla.text;
          //     this.comboCasillas.push({
          //       "id": casilla.text,
          //       "claveURL": casilla.param,
          //       "migas": casilla.text,
          //     });
          //   });


          // });
          // if (this.idCasilla != null) this.rutasService.migas.casilla = auxCasillas[this.idCasilla];
        }
      }



      irEntidad(e) {
        let path = `${this.globalService.detalles.circunscripcion}`;
        let base = this.rutasService.rutaActual.base;
        if(this.rutasService.rutaActual.base !== 'votos-ppyci') {
          base = 'votos-distrito';
        }
        if (e === 'Todas') {
          const ruta = `/${this.globalService.detalles.circunscripcion}/${base}`;
          this.router.navigateByUrl(ruta);
        } else {
          if(this.globalService.detalles.entidad !== e) {
            path = `/${path}/${e}/${base}`;
            this.router.navigateByUrl(`${path}`);
          }
        }

        this.loadDistritos();
      }

      irDistrito(e) {
        let opt = '',
            selectedContienda = this.globalService.getContienda();

        this.globalService.menu.forEach(m => {
          if (m.active) opt = m.opt;
        });

        this.comboSecciones = [];
        this.comboCasillas = [];

        delete this.rutasService.migas.seccion;
        delete this.rutasService.migas.seccion_clave;
        delete this.rutasService.migas.casilla;
        delete this.rutasService.migas.casilla_clave;

        if (e == 'Todos') {
          // console.log('opt', opt);return;
          let url = `/${selectedContienda}`;

          delete this.rutasService.migas.distrito;
          delete this.rutasService.migas.distrito_clave;

          if (opt != '' && opt == 'votos-ppyci') url += `/${opt}`;

          this.router.navigateByUrl(url);

          // this.rutasService.setearMigas();
        } else {

          if (!opt || opt === null) opt = 'votos-candidatura';

          this.rutasService.migas.distrito_clave = e;
          this.router.navigateByUrl(`/${selectedContienda}/${this.rutasService.migas.distrito_clave}/${opt}`);


          // this.idDistrito = e;

          // this.rutasService.setearMigas();
        //   this.loadSecciones();

        }


      }

      irSeccion(e) {
        let opt = null,
            selectedContienda = this.globalService.getContienda();

        this.globalService.menu.forEach(m => {
          if (m.active) opt = m.opt;
        });

        this.comboCasillas = [];

        delete this.rutasService.migas.casilla;
        delete this.rutasService.migas.casilla_clave;

        // console.log('AQUI ENTRO SECCION', e);
        if (e == '') {

          this.rutasService.migas.seccion = '';
          this.rutasService.migas.seccion_clave = '';

          // this.rutasService.setearMigas();

          if (opt === null) opt = 'votos-candidatura';

          this.router.navigateByUrl(`/${selectedContienda}/${this.rutasService.migas.distrito_clave}/${opt}`);
        } else if (e == 'Todas') {

          // console.log('AQUIDEBO ENTRAR');
          delete this.rutasService.migas.seccion;
          delete this.rutasService.migas.seccion_clave;

          // this.rutasService.setearMigas();

          this.router.navigateByUrl(`/${selectedContienda}/${this.rutasService.migas.distrito_clave}/secciones`);
        } else {
          this.router.navigateByUrl(`/${selectedContienda}/${this.rutasService.migas.distrito_clave}/secciones/${this.rutasService.migas.seccion_clave}`);
          // this.rutasService.setearMigas();
          this.loadCasillas();
        }

      }

      irCasilla(e) {
        let selectedContienda = this.globalService.getContienda();

        console.log('e', e);
        console.log('this.rutasService.migas.casilla_clave', this.rutasService.migas.casilla_clave);
        if (e == 'Todas') {
          delete this.rutasService.migas.casilla;
          delete this.rutasService.migas.casilla_clave;

          // this.rutasService.setearMigas();

          this.router.navigateByUrl(`/${selectedContienda}/${this.rutasService.migas.distrito_clave}/secciones/${this.rutasService.migas.seccion_clave}`);
        } else {

          this.router.navigateByUrl(`/${selectedContienda}/${this.rutasService.migas.distrito_clave}/secciones/${this.rutasService.migas.seccion_clave}/casilla/${this.rutasService.migas.casilla_clave}`);
        }

      }

      establecerMigas(selectedContienda, url_params) {

        let idDistrito = url_params.distrito ? url_params.distrito : null;
          if (selectedContienda == 'ayuntamientos' || selectedContienda == 'juntas') {
            if (url_params.municipio) idDistrito = url_params.municipio;
          }

          if (idDistrito) this.rutasService.migas.distrito_clave = idDistrito;
          else {
            this.rutasService.migas.distrito = '';
            this.rutasService.migas.distrito_clave = '';
          }

          let idSeccion = url_params.seccion ? url_params.seccion : null;
          if (idSeccion) this.rutasService.migas.seccion_clave = idSeccion;
          else {
            this.rutasService.migas.seccion = '';
            this.rutasService.migas.seccion_clave = '';
          }

          let idCasilla = url_params.casilla ? url_params.casilla : null;
          if (idCasilla) this.rutasService.migas.casilla_clave = idCasilla;
          else {
            this.rutasService.migas.casilla = '';
            this.rutasService.migas.casilla_clave = '';
          }

          this.urlSubject.next({selectedContienda, url_params});
    }

    broadcastMain(data) {
        this.observerMain.next(data);
    }

    /* Funciones GET */
    getInfoJson(url){
      var difusores = window['DATA'].urlDifusores;

      if(url.includes('config.json') || url.includes('.geojson')) difusores = '.';

      return this.http.get(difusores + url);
    }

    loadMain(){
        return new Promise ((res, rej) => {
            if (this.main && Object.keys(this.main).length > 0 && this.main.constructor === Object) {
                // this.mainSubject.next(this.main);
                res(this.main);
            } else {
                this.getInfoJson(env.urls.urlAssets + env.urls.urlJSON + 'main.json').subscribe(data =>{
                    this.main = data;
                    // this.broadcastMain(data);
                    this.mainSubject.next(data);
                    // this.test.emit('data');
                    res(data);
                })
            }
        })
    }

    forceLoadMain(){
      this.getInfoJson(env.urls.urlAssets + env.urls.urlJSON + 'main.json').subscribe(data =>{
          this.main = data;
          this.mainSubject.next(data);
          // this.reloadAllData();
          this.emptyData();
      })
    }

    reloadAllData() {
      for (const contienda in this.contiendas) {
        for (const file in this.contiendas[contienda]) {
          this.forceLoadJSON(contienda, file);
        }
      }
    }

    emptyData() {
      for (const contienda in this.contiendas) {
        if (this.globalService.arrContiendas.indexOf(contienda) >= 0) {
          delete this.contiendas[contienda];
        }
      }
    }

    loadConfig(){
        return new Promise ((res, rej) => {
            if (this.config && Object.keys(this.config).length > 0 && this.config.constructor === Object) {
                // this.configSubject.next(this.config);
                res(this.config);
            } else {
                this.getInfoJson(env.urls.urlAssets + '/config.json').subscribe(data =>{
                    this.config = data;
                    this.globalService.omitirExtranjero = (data as any).omitir_extranjero;
                    this.configSubject.next(data);

                    let d = (data as any);
                    if (d.reload_main_interval && d.reload_main_interval >= 300000) {
                      setInterval(() => {
                        this.forceLoadMain();
                      }, d.reload_main_interval)
                    }
                    res(data);

                    // var script_tag = document.createElement('script');
                    // script_tag.type = 'text/javascript';
                    // script_tag.text = "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '"+this.config['analytics_id']+"');";
                    // document.body.appendChild(script_tag);

                    // script_tag = document.createElement('script');
                    // script_tag.defer = true;
                    // script_tag.src = '<script async src="https://www.googletagmanager.com/gtag/js?id='+this.config['analytics_id']+'"></script>';
                    // document.body.appendChild(script_tag);
                })
            }
        })
    }

    loadJSON(contienda, file){
      let contiendaData = this.main[contienda];
        return new Promise ((res, rej) => {
          if (this.globalService.arrContiendas.indexOf(contienda) >= 0) {
            if (this.contiendas[contienda] && this.contiendas[contienda][file]) res(this.contiendas[contienda][file]);
            else {
              this.getInfoJson(env.urls.urlAssets + env.urls.urlJSON + contiendaData.carpeta + '/' + file).subscribe(data =>{
                if (!this.contiendas[contienda]) this.contiendas[contienda] = {};
                this.contiendas[contienda][file] = data;
                res(data);
              });
            }
          } else res({});
        });
    }

    forceLoadJSON(contienda, file) {
      let contiendaData = this.main[contienda];
      this.getInfoJson(env.urls.urlAssets + env.urls.urlJSON + contiendaData.carpeta + '/' + file).subscribe(data =>{
        if (!this.contiendas[contienda]) this.contiendas[contienda] = {};
        this.contiendas[contienda][file] = data;
      });
    }

    loadMapa(file){
        // let contiendaData = this.main[contienda];
        return new Promise ((res, rej) => {
            if (this.mapas[file]) res(this.mapas[file]);
            else {
                this.getInfoJson(env.urls.urlAssets + env.urls.urlMapas + file).subscribe(data =>{
                    this.mapas[file] = data;
                    res(data);
                });
            }
        })
    }

    loadSeccionesCombo(contienda){
      // return new Promise ((res, rej) => {
			// 	res([]);
			// });
      // return;
		if (this.globalService.arrContiendas.indexOf(contienda) >= 0) {
			return new Promise ((res, rej) => {
				let fileName = contienda + '.secciones.json';
				if (this.arrSecciones[fileName]) res(this.arrSecciones[fileName]);
				else {
					this.getInfoJson(env.urls.urlAssets + env.urls.urlJSON + fileName).subscribe(data =>{
						this.arrSecciones[fileName] = data;
						res(data);
					});
				}
			});
		} else {
			return new Promise ((res, rej) => {
				res([]);
			});
		}
    }

    checkMain(fn) {
      if (this.main) fn();
      else {
        setTimeout(()=>{
          this.checkMain(fn);
        }, 500);
      }
    }

    /* Llenado de objetos Diputaciones Nacional */

    llenadoDiputacionNacional(){
        this.getInfoJson(env.urls.urlBaseNacionalGets+env.urls.urlDiputacionNacioal).subscribe(res =>{
            this.dataDiputacionesNacional = res;
        })
    }

    /* Llenado de objeto Diputaciones Circunscripcion */
    llenadoCircunscripcion(){
        this.getInfoJson(env.urls.urlBaseCircunsipcionGets+env.urls.urlDCircunscripciones).subscribe(res =>{
            this.dataDiputacionesCircunscripcion = res;
        })
    }

    /* Llenado de objeto Diputaciones Entidad */
    llenadoEntidad(id){

        this.getInfoJson(`${env.urls.urlBaseEntidadGets}entidad${id}.json`).subscribe(res => {
            this.dataDiputacionesEntidad["entidad"+id] = res;
        })

        // this.http.get(`${env.urls.urlDEntidades}/entidad${id}.json`).subscribe(res =>{
        //     this.dataDiputacionesEntidad['entidad'+id].push(res);
        // })
    }

    downloadBaseDatos() {
        // console.log(window['DATA'].urlDifusores + env.urls.urlAssets);
        // console.log(window['DATA'].urlDifusores + env.urls.urlAssets + env.urls.urlEntregables);
        window.open(window['DATA'].urlDifusores + env.urls.urlAssets + env.urls.urlEntregables + this.main['entregable']);
    }

	// {
	// 	  "type": "FeatureCollection",
    // 	"features": [
	//         {
	//         	"type": "Feature",
	// 			"geometry": {
	// 			    "type": "Polygon",
	// 			    "coordinates": [
	// 					[
	// 						[1212,1212]
	// 					]
	// 				]
	// 			  },
	//         }
    // 	],
    //     "properties": {
    //      	"MUNICIPIO": 1,
	//   		"NOMBRE": "001. ACACOYAGUA"
    //     }
    //   }
/* ------------------------------------------------------------------------------- */

    obtenerNacional() {
        //   return this.http.get(env.urls.urlResumen);
        return this.http.get(env.urls.urlNacional);
    }

    obtenerCasillas() {
        return this.http.get(env.urls.urlCasilla);
    }
}
