import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { JuntaPartidoRoutingModule } from './junta-partido-routing.module';
import { JuntaCandidaturaRoutingModule } from './junta-candidatura-routing.module';

import { TooltipModule } from 'ng2-tooltip-directive';

import { HighchartsChartModule } from 'highcharts-angular';
import { ChartModule } from 'angular-highcharts';
import { ImportingModule } from '../../importing.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    HighchartsChartModule,
    ChartModule,
    TooltipModule,
    ImportingModule,
    JuntaCandidaturaRoutingModule,
    JuntaPartidoRoutingModule,
    RouterModule
  ]
})
export class JuntaCandidaturaModule { }
