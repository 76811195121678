import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';

// declare var $;
// import * as $ from 'jquery';

@Component({
  selector: 'app-ppyci-seccion',
  templateUrl: './ppyci-seccion.component.html',
  styleUrls: ['./ppyci-seccion.component.scss']
})
export class PpyciSeccionComponent implements OnInit {

  base: string;
  contenido: any = [];
  casillas: any = [];

  constructor(
    public readonly globalService: GlobalesService,
    private readonly activatedRouter: ActivatedRoute,
    private readonly datosService: ObtenerDatosService,
    public readonly rutas: RutasService
  ) { }

  ngOnInit(): void {
    this.obtenerParametros();
    this.obtenerDatos();
    this.obtenerCasillas();
    this.base = this.rutas.rutaActual.base;
    // $('[data-toggle="tooltip"]').tooltip();
  }

  obtenerParametros() {
    this.activatedRouter.params.subscribe((parametros) => {
      const params = { parametros };
      this.globalService.detalles = params.parametros;
    });
    this.globalService.setearParametros();
  }

  obtenerDatos() {
    this.datosService.obtenerNacional()
        .subscribe((data) => {
          this.contenido = data;
        });
  }

  obtenerCasillas() {
    this.datosService.obtenerCasillas()
        .subscribe((data) => {
          this.casillas = data;
        });
  }

}
