import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { VotosDistritoComponent } from './votos-distrito/componentes/votos-distrito/votos-distrito.component';
// import { ResumenDistritoComponent } from '../diputacion/votos-distrito/componentes/resumen-distrito/resumen-distrito.component';
// import { DistritoCircunscripcionComponent } from './votos-distrito/componentes/distrito-circunscripcion/distrito-circunscripcion.component';
// import { DistritoEntidadComponent } from './votos-distrito/componentes/distrito-entidad/distrito-entidad.component';
// import { DistritoDistritoComponent } from './votos-distrito/componentes/distrito-distrito/distrito-distrito.component';
// import { ResumenDistritoMapaComponent } from '../diputacion/votos-distrito/componentes/resumen-distrito-mapa/resumen-distrito-mapa.component';
// import { SeccionResumenComponent } from './seccion/seccion-resumen/seccion-resumen.component';
// import { CasillaResumenComponent } from './casilla/casilla-resumen/casilla-resumen.component';
// import { DistritoCircunscripcionMapaComponent } from './votos-distrito/componentes/distrito-circunscripcion-mapa/distrito-circunscripcion-mapa.component';
// import { DistritoEntidadMapaComponent } from './votos-distrito/componentes/distrito-entidad-mapa/distrito-entidad-mapa.component';
// import { DistritoEntidadDetalleCasillaComponent } from './votos-distrito/componentes/distrito-entidad-detalle-casilla/distrito-entidad-detalle-casilla.component';
// import { DistritoSeccionesComponent } from './votos-distrito/componentes/distrito-secciones/distrito-secciones.component';

import { ResumenPartidoComponent } from '../diputacion/votos-partido/componentes/resumen-partido/resumen-partido.component';
import { ResumenPartidoGraficaComponent } from '../diputacion/votos-partido/componentes/resumen-partido-grafica/resumen-partido-grafica.component';

import { PpyciDistritoComponent } from '../diputacion/votos-partido/componentes/ppyci-distrito/ppyci-distrito.component';

// import { ResumenPartidoGraficaComponent } from './votos-partido/componentes/resumen-partido-grafica/resumen-partido-grafica.component';
// import { ResumenPartidoComponent } from './votos-partido/componentes/resumen-partido/resumen-partido.component';
import { AuthGuard } from '../../auth/auth.guard';
import { ContiendasComponent } from '../contiendas.component';

const routes: Routes = [
  {
    path: 'ayuntamientos',
    canActivate: [AuthGuard],
    component: ContiendasComponent,
    children: [
      {
        path: 'votos-ppyci',
        children: [
          {
            path: '',
            redirectTo: 'grafica',
            pathMatch: 'full'
          },
          {
            path: 'grafica',
            component: ResumenPartidoGraficaComponent,
          },
          {
            path: 'tarjetas',
            component: ResumenPartidoComponent,
          },
        ]
      },
      {
        path: ':distrito/votos-ppyci',
        component: PpyciDistritoComponent,
        data : { contienda : 'Municipios', contienda_s : 'Municipio' }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AyuntamientoPartidoRoutingModule { }
