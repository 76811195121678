<div class="bgPrototipo">
    <!-- <div class="colapsador dis-flex d-none d-xl-block bgMobile" (click)="globalService.toggleLateral()" [ngClass]="globalService.menuLateral ? 'abierto' : 'cerrado'" [ngbTooltip]="globalService.leyendaMenu" placement="right" show-delay="200" >
        <i class="fas" [ngClass]="globalService.menuLateral ? 'fa-chevron-left' : 'fa-chevron-right'" aria-hidden="true"></i>
    </div> -->
    <div class="h-100 dis-flex flex-col justify-s txBlanco cursorPointer bgMobile fijo align-center" style="width: 5%;" *ngIf="!globalService.menuLateral" (click)="globalService.toggleLateral()">
        <i class="fas fa-filter pt-5 mt-5 px22" aria-hidden="true"></i>
        <p class="px14">Consulta</p>
    </div>
<!--    <div class="container-fluid barra-lateral txBlanco px14" *ngIf="globalService.menuLateral && globalService.menuActual === 'Votos'">-->
    <div class="container-fluid barra-lateral txBlanco em14 bgMobile" *ngIf="globalService.menuLateral && globalService.menuActual === 'Votos'">
        <div class="row pl-2">
            <div class="col-12">
                <div class="pt-2">
                    <h3 class="em16"><strong>Consulta de votación</strong></h3>
                </div>
                <div class="row mb-4">
                    <div class="col">
                        <p>Selecciona el reporte que deseas consultar:</p>
                        <!-- <p>{{rutasService.migas | json}}</p> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
          <div *ngFor="let menu of globalService.menu;  let i=index;" class="col-6 col-xl-12 mb-1 btn-menu-cont" [ngClass]="{'d-none d-xl-block': i >= 2}">
            <div [routerLink]="[menu.url]" [ngClass]="{'btn-rosa-active': menu.active}" class="btn btn-rosa btn-block px14 px-1 py-3 btn-mob">
              {{menu.title}}
            </div>
          </div>
        </div>
        <div class="row my-4">
            <div class="col">
                <p class="em16 d-none d-xl-block">Detalle por:</p>
                <div class="row grupo-combos pl-2 pr-2">
                    <div class="col-12 col-xl-12 combo mb-2">
                        <label for="distrito" class="em10 d-none d-xl-block">
                            {{ datosService.labelComboDistritos }}
                        </label>
                        <div class="input-group df-sw">
                            <div class="dropdown w-100 bgBlanco d-none d-xl-block">
                                <button class="btn dropdown-toggle dis-flex space-b w-100 p-1 px14" type="button" id="dDistrito" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                &nbsp;
                                    {{ (rutasService?.migas?.distrito) ? ' ' + rutasService.migas.distrito : 'Todos' | titulo | titlecase }}
                                    <i class="mcad mcad-drop-down_flecha mr-1" aria-hidden="true"></i>
                                </button>
                                <div class="dropdown-menu px14 w-100" aria-labelledby="dDistrito">
                                    <a (click)="datosService.irDistrito('Todos'); $event.preventDefault();" class="dropdown-item">Todos</a>
                                    <a *ngFor="let cDistrito of datosService.comboDistritos" (click)="rutasService.migas.distrito = cDistrito.migas; rutasService.migas.distrito_clave = cDistrito.claveURL; datosService.irDistrito(cDistrito?.claveURL); $event.preventDefault();"
                                        class="dropdown-item">{{cDistrito?.migas}}</a>
                                </div>
                            </div>

                            <div class="dropdown w-100 bgBlanco d-block d-xl-none">
                                <button class="btn dropdown-toggle dis-flex space-b w-100 p-1 px14" type="button" id="dmDistrito" (click)="onOpenDistritos()">
                                &nbsp;
                                    {{ (rutasService?.migas?.distrito) ? ' ' + rutasService.migas.distrito : (globalService.getContienda() == 'ayuntamientos' ? 'Municipio' : (globalService.getContienda() == 'juntas' ? 'Junta' : 'Distrito')) }}
                                    <i class="mcad mcad-drop-down_flecha mr-1" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-none d-xl-block combo mb-2">
                        <label for="seccion" class="em10">
                            Sección
                        </label>
                        <div class="input-group df-sw">
                            <div class="dropdown w-100 bgBlanco">
                                <button [disabled]="!rutasService.migas.distrito_clave" [ngClass]="{'menu-disabled': !rutasService.migas.distrito_clave}" class="btn dropdown-toggle dis-flex space-b w-100 p-1 px14" type="button" id="dSeccion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                &nbsp;
                                    {{ (rutasService?.migas?.seccion) ? rutasService.migas.seccion : '&nbsp;' }}
                                    <i class="mcad mcad-drop-down_flecha mr-1" aria-hidden="true"></i>
                                </button>
<!--                                <div class="dropdown-menu px14 w-100" aria-labelledby="dSeccion" size="3">-->
                                <div class="dropdown-menu em10 w-100" aria-labelledby="dSeccion" size="3">
                                    <!-- <a (click)="datosService.irSeccion(''); $event.preventDefault();" class="dropdown-item" href><i>&nbsp;</i></a> -->
                                    <a (click)="datosService.irSeccion('Todas'); $event.preventDefault();" class="dropdown-item" href>Todas</a>
                                    <a *ngFor="let cSeccion of datosService.comboSecciones" (click)="rutasService.migas.seccion = cSeccion.migas; rutasService.migas.seccion_clave = cSeccion.claveURL; datosService.irSeccion(cSeccion?.claveURL); $event.preventDefault();"
                                        class="dropdown-item" href>{{cSeccion?.migas}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-none d-xl-block combo mb-2" *ngIf="rutasService.migas.seccion_clave">
                        <label for="casilla" class="em10">
                            Casilla
                        </label>
                        <div class="input-group df-sw">
                            <div class="dropdown w-100 bgBlanco">
                                <button [disabled]="!rutasService.migas.seccion_clave || rutasService.migas.seccion_clave == 'Todas'" [ngClass]="{'menu-disabled': (!rutasService.migas.seccion_clave || rutasService.migas.seccion_clave == 'Todas')}" class="btn dropdown-toggle dis-flex space-b w-100 p-1 px14" type="button" id="dCasilla" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                &nbsp;
                                    {{ (rutasService?.migas?.casilla) ? ' ' + rutasService.migas.casilla : 'Todas' }}
                                    <i class="mcad mcad-drop-down_flecha mr-1" aria-hidden="true"></i>
                                </button>
                                <div class="dropdown-menu px14 w-100" aria-labelledby="dCasilla">
                                    <a (click)="datosService.irCasilla('Todas'); $event.preventDefault();" class="dropdown-item" href>Todas</a>
                                    <a *ngFor="let cCasilla of datosService.comboCasillas" (click)="rutasService.migas.casilla = cCasilla.migas; rutasService.migas.casilla_clave = cCasilla.claveURL; idCasilla = cCasilla.id; datosService.irCasilla(cCasilla?.claveURL); $event.preventDefault();"
                                        class="dropdown-item" href>{{cCasilla?.migas}}</a>
                                    <!-- <a (click)="irCasilla('basica-1'); $event.preventDefault();" class="dropdown-item" href>Casilla 1</a>
                                    <a (click)="irCasilla('basica-2'); $event.preventDefault();" class="dropdown-item" href>Casilla 2</a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container d-block d-xl-none">
        <div class="row">
            <div class="col-6 combo mb-2">
                <div class="input-group df-sw">
                    <div class="dropdown w-100 bgBlanco mobileBtn">
                        <button
                            *ngIf="rutasService.migas.distrito_clave"
                            [disabled]="!rutasService.migas.distrito_clave"
                            [ngClass]="{'menu-disabled': !rutasService.migas.distrito_clave}"
                            class="btn dis-flex flex-column w-100 p-1 px14"
                            type="button"
                            id="dSeccion"
                            (click)="onOpenSecciones()"
                            >

                            <span style="font-size: 1.2em;" *ngIf="!rutasService?.migas?.seccion">Sección</span>
                            <div class="d-flex flex-row drop-btn" *ngIf="rutasService?.migas?.seccion">
                                <div class="d-flex flex-column drop-info">
                                    <p class="drop-title">Sección:</p>
                                    <p class="drop-text">{{ rutasService?.migas?.seccion }}</p>
                                </div>
                                <div class="d-flex align-items-center justify-content-center drop-caret">
                                    <i class="fas fa-caret-down"></i>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="rutasService.migas.seccion_clave || rutasService.migas.seccion == 'Todas' || rutasService.rutaActual.base == 'detalle-casilla' || rutasService.migas.seccion_clave == 'Todas'" class="col-6">
                <div class="input-group df-sw">
                    <div class="dropdown w-100 bgBlanco mobileBtn rosaIne">
                        <button
                            [disabled]="!rutasService.migas.seccion_clave || rutasService.migas.seccion_clave == 'Todas'"
                            [ngClass]="{'menu-disabled': (!rutasService.migas.seccion_clave || rutasService.migas.seccion_clave == 'Todas')}"
                            class="btn dis-flex flex-column w-100 p-1 px14"
                            type="button"
                            id="dCasilla"
                            (click)="onOpenCasillas()"
                            >

                            <div class="d-flex flex-row drop-btn" *ngIf="rutasService?.migas?.seccion">
                                <div class="d-flex flex-column drop-info">
                                    <p class="drop-title">Casilla:</p>
                                    <p class="drop-text">{{ rutasService?.migas?.casilla ? rutasService?.migas?.casilla : 'Todas' }}</p>
                                </div>
                                <div class="d-flex align-items-center justify-content-center drop-caret">
                                    <i class="fas fa-caret-down"></i>
                                </div>
                            </div>
                        </button>
                        <div class="dropdown-menu px14 w-100" aria-labelledby="dCasilla">
                            <a (click)="datosService.irCasilla('Todas'); $event.preventDefault();" class="dropdown-item" href>Todas</a>
                            <a *ngFor="let cCasilla of datosService.comboCasillas" (click)="rutasService.migas.casilla = cCasilla.migas; rutasService.migas.casilla_clave = cCasilla.claveURL; idCasilla = cCasilla.id; datosService.irCasilla(cCasilla?.claveURL); $event.preventDefault();"
                                class="dropdown-item" href>{{cCasilla?.migas}}</a>
                            <!-- <a (click)="irCasilla('basica-1'); $event.preventDefault();" class="dropdown-item" href>Casilla 1</a>
                            <a (click)="irCasilla('basica-2'); $event.preventDefault();" class="dropdown-item" href>Casilla 2</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid barra-lateral txBlanco w-100 fijo d-none d-xl-block" *ngIf="globalService.menuLateral && globalService.menuActual === 'PreguntasFrecuentes'">
        <div class="titulo-menu my-4">
            <h4 class="ml-2"><strong>Preguntas<br>frecuentes</strong></h4>
        </div>
        <div class="liga ml-3 mt-2">
            <a class="cursorPointer" (click)="goToAnchor('avance-nacional')"> Avance Entidad</a>
            <br>
        </div>
        <div class="liga ml-3 mt-2">
            <a class="cursorPointer" (click)="goToAnchor('consulta-votacion')"> Consulta de la votación</a>
        </div>
        <div class="liga ml-3 mt-2">
            <a class="cursorPointer" (click)="goToAnchor('resultado-casilla')"> Conoce los resultados de tu casilla</a>
        </div>
        <div class="liga ml-3 mt-2">
            <a class="cursorPointer" (click)="goToAnchor('votos-actas-contabilizadas')"> Votos en Actas Contabilizadas </a>
        </div>
        <div class="liga ml-3 mt-2">
            <a class="cursorPointer" (click)="goToAnchor('visualizacion-tarjetas')"> Visualización tarjetas</a>
        </div>
        <div class="liga ml-3 mt-2">
            <a class="cursorPointer" (click)="goToAnchor('visualizacion-graficos')"> Visualización gráficos</a>
        </div>
        <div class="liga ml-3 mt-2">
            <a class="cursorPointer" (click)="goToAnchor('ver-detalle')"> Ver detalle</a>
        </div>
        <div class="liga ml-3 mt-2">
            <a class="cursorPointer" (click)="goToAnchor('estadistica')"> Estadística</a>
        </div>
        <div class="liga ml-3 mt-2">
            <a class="cursorPointer" (click)="goToAnchor('observaciones-actas')"> Observaciones en Actas</a>
        </div>
        <div class="liga ml-3 mt-2">
            <a class="cursorPointer" (click)="goToAnchor('elementos-apoyo')"> Elementos de apoyo</a>
        </div>
    </div>

<!--    <div class="container-fluid barra-lateral txBlanco px14" *ngIf="globalService.menuLateral && globalService.menuActual === 'BaseDatos'">-->
    <div class="container-fluid barra-lateral txBlanco em14" *ngIf="globalService.menuLateral && globalService.menuActual === 'BaseDatos'">
        <div class="row">
            <div class="col-12">
                <div class="fijo pos-bd pt-4">
                    <div class="padding-b-20 px-2">
                        <h4 class="pleca-blanca"><strong>Menú</strong></h4>
                    </div>
                    <div class="menu padding-b-20 px-2">
                        <div class="titulo">
                            Definición
                        </div>
                        <div class="liga">
                            <a (click)="goToAnchor('baseutilidad')"> Base de datos y utilidad</a>
                        </div>
                    </div>
                    <div class="menu padding-b-20 px-2">
                        <div class="titulo">
                            Carpeta de archivos
                        </div>
                        <div class="liga">
                            <a (click)="goToAnchor('contenido')">Contenido</a>
                        </div>
                        <div class="liga">
                            <a (click)="goToAnchor('descargar')">Descarga</a>
                        </div>
                        <div class="liga">
                            <a (click)="goToAnchor('descomprimir')">Descomprimir</a>
                        </div>
                        <div class="liga">
                            <a (click)="goToAnchor('abrir')">Abrir</a>
                        </div>
                    </div>
                    <div class="menu px-2">
                        <div class="titulo">
                            Consulta
                        </div>
                        <div class="liga">
                            <a (click)="goToAnchor('formato-celdas')">Formato de celdas</a>
                        </div>
                        <div class="liga">
                            <a (click)="goToAnchor('interpretacion-base-de-datos')">Interpretación de base de datos</a>
                        </div>
                        <div class="liga">
                            <a (click)="goToAnchor('autenticidad')">Autenticidad</a>
                        </div>
                    </div>
                    <div class="px20 text-center padding-b-30 padding-t-30 download-section">
                        <div class="padding-b-10">Descargar</div>
                        <div>
                            <button (click)="datosService.downloadBaseDatos()" type="button" class="btn btn-blanco">Base de datos  <i class="fas fa-download"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="padding-t-20">

        </div>

    </div>

    <div class="container-fluid barra-lateral txBlanco" *ngIf="globalService.menuLateral && globalService.menuActual === 'RP'">
        <div class="pt-5 dis-flex">
            <button (click)="atras()" class="btn btn-blanco px14 btnBack">
                <i class="fas fa-long-arrow-alt-left"></i> Regresar
            </button>
        </div>

    </div>
</div>
