<div class="col-md-12 d-block d-md-block d-xl-none">
    <!-- <p class="em14" *ngIf="tipoTarjeta != 8">Presiona sobre el recuadro blanco y selecciona de una a tres opciones para conocer el detalle de la votación.</p> -->
    <div *ngIf="tipoTarjeta == 1" class="row main-cpm-container">
        <div class="movil-content-box" style="margin-bottom: 16px; padding-top: 16px;" *ngFor="let tarjeta of infoList; let indx = index;" [ngClass]="{cardGanador: tarjeta?.winner}">
            <div class="content-candidato">
                <div class="movil-candidato">
                    <!-- <img class="avatar" height="40px" src="assets/res/avatar-persona.svg"> -->
                    <img class="avatar rounded-circle" height="40px" [src]="'./assets/img/avatar-candidatos/' + tarjeta?.img_perfil">
                    <div>
                        <p class="nombre px-1"> {{ tarjeta?.text }} </p>
                        <div class="distritos w-100 py-4 px-1">
                            <ng-container *ngFor="let img of tarjeta?.partidos">
                                <img height="30px" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()" alt="">
                            </ng-container>
                        </div>
                    </div>
                    <!-- <input type="checkbox" class="check-align" [ngModel]="tarjeta.seleccionado" (change)="onSelectedCheckChange($event, tarjeta)"/> -->
                </div>
                <div class="cuerpo dis-flex w-100 p-2">
                    <div class="w-100">
                        <p><strong>Total de Votos <span style="float: right;">Porcentaje</span></strong></p>
                        <div class="w-100 dis-flex">
                            <div class="w-50 align-e">
                                <p class="votos" [ngClass]="{textGanador: tarjeta?.winner}"><strong>{{ tarjeta?.total }}</strong></p>
                            </div>
                            <div class="w-50" style="text-align: right;">
                                <p [ngClass]="{textGanador: tarjeta?.winner}"><strong>{{ tarjeta?.porcentaje ? tarjeta?.porcentaje : tarjeta?.prc }}</strong></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="movil-votos dis-flex w-100 p-2" *ngIf="!omitirExtranjero">
                    <div class="w-100 dis-flex">
                        <div class="w-48 align-e">
                            <p><strong>Votos</strong></p>
                            <p class="label-votos">En la entidad</p>
                            <div class="w-100 dis-flex">
                                <p class="w-50 label-votos votos align-center"><strong>{{ tarjeta?.votos_entidad }}</strong></p>
                                <!-- <p class="w-50 label-votos votos align-rigth">{{ tarjeta?.porcentaje_entidad }}</p> -->
                            </div>
                        </div>
                        <div class="vertical-sep"></div>
                        <div class="w-48 align-e">
                            <p style="color: #00000000;">.</p>
                            <p class="label-votos">En el extranjero</p>
                            <div class="w-100 dis-flex">
                                <p class="w-50 label-votos align-center"><strong>{{ tarjeta?.votos_extranjero }}</strong></p>
                                <!-- <p class="w-50 label-votos align-rigth">{{ tarjeta?.porcentaje_extranjero }}</p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="tipoTarjeta == 2" class="row main-cpm-container">
        <div class="movil-content-box" style="margin-bottom: 16px; padding-top: 16px;" *ngFor="let tarjeta of infoList; let indx = index;" [ngClass]="{cardGanador: tarjeta?.winner}">
            <div class="content-candidato">
                <div class="movil-candidato">
                    <img class="logo" height="40px" *ngIf="tarjeta?.imgs?.length > 0" 
                        src="./assets/img/logos-partidos/{{tarjeta?.imgs[0]?.toLowerCase()}}">
                    <!-- <input type="checkbox" class="check-align"  [ngModel]="tarjeta.seleccionado"(change)="onSelectedCheckChange($event, tarjeta)"/> -->
                </div>
                <div class="cuerpo dis-flex w-100 p-2">
                    <div class="w-100">
                        <p><strong>Total de Votos <span style="float: right;">Porcentaje</span></strong></p>
                        <div class="w-100 dis-flex">
                            <div class="w-50 align-e">
                                <p class="votos" [ngClass]="{textGanador: tarjeta?.winner}"><strong>{{ tarjeta?.total }}</strong></p>
                            </div>
                            <div class="w-50" style="text-align: right;">
                                <p [ngClass]="{textGanador: tarjeta?.winner}"><strong>{{ tarjeta?.porcentaje }}</strong></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="movil-votos dis-flex w-100 p-2" *ngIf="!omitirExtranjero">
                    <div class="w-100 dis-flex">
                        <div class="w-48 align-e">
                            <p><strong>Votos</strong></p>
                            <p class="label-votos">En la entidad</p>
                            <div class="w-100 dis-flex">
                                <p class="w-50 label-votos votos align-center"><strong>{{ tarjeta?.votos_entidad }}</strong></p>
                                <!-- <p class="w-50 label-votos votos align-rigth">{{ tarjeta?.porcentaje_entidad }}</p> -->
                            </div>
                        </div>
                        <div class="vertical-sep"></div>
                        <div class="w-48 align-e">
                            <p style="color: #00000000;">.</p>
                            <p class="label-votos">En el extranjero</p>
                            <div class="w-100 dis-flex">
                                <p class="w-50 label-votos align-center"><strong>{{ tarjeta?.votos_extranjero }}</strong></p>
                                <!-- <p class="w-50 label-votos align-rigth">{{ tarjeta?.porcentaje_extranjero }}</p> -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div> 

    <div *ngIf="tipoTarjeta == 3" class="row main-cpm-container">
        <div class="movil-content-box" style="margin-bottom: 16px; padding-top: 16px;" *ngFor="let tarjeta of infoList; let indx = index;" [ngClass]="{cardGanador: tarjeta?.winner}">
            <table class="w-100">
                <tr>
                    <td class="w-col1">
                        <img class="logo" height="30px" *ngIf="tarjeta?.imgs?.length > 0" 
                            src="./assets/img/logos-partidos/{{tarjeta?.imgs[0]?.toLowerCase()}}"/>
                    </td>
                    <td class="w-col2">
                        <p class="label-votos">Votos</p>
                        <p class="votos"><strong>{{ tarjeta?.total }}</strong></p>
                    </td>
                    <td class="w-col2">
                        <p class="label-votos">Porcentaje</p>
                        <p class="label-votos align-center"><strong>{{ tarjeta?.porcentaje }}</strong></p>
                    </td>
                    <td class="w-col3">
                        <!-- <input type="checkbox" class="check-align" style="margin: auto !important;" [ngModel]="tarjeta.seleccionado" (change)="onSelectedCheckChange($event, tarjeta)"/> -->
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <div *ngIf="tipoTarjeta == 4" class="row main-cpm-container">
        <div class="movil-content-box" style="margin-bottom: 16px; padding-top: 16px;" *ngFor="let tarjeta of infoList; let indx = index;" [ngClass]="{cardGanador: tarjeta?.winner}">
            <div class="content-candidato">
                <div class="movil-candidato">
                    <!-- <img class="avatar" height="40px" src="assets/res/avatar-persona.svg"> -->
                    <img *ngIf="tarjeta?.img_perfil" class="avatar rounded-circle" height="40px" [src]="'./assets/img/avatar-candidatos/' + tarjeta?.img_perfil">
                    <div>
                        <p class="nombre px-1"> {{ tarjeta?.text }} </p>
                        <div class="distritos w-100 py-4 px-1">
                            <ng-container *ngFor="let img of tarjeta?.imgs">
                                <img height="30px" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()" alt="">
                            </ng-container>
                        </div>
                    </div>
                    <!-- <input type="checkbox" class="check-align" [ngModel]="tarjeta.seleccionado" (change)="onSelectedCheckChange($event, tarjeta)"/> -->
                </div>
                <div class="cuerpo dis-flex w-100 p-2">
                    <div class="w-100">
                        <p><strong>Total de Votos <span style="float:right;">Porcentaje</span></strong></p>
                        <div class="w-100 dis-flex">
                            <div class="w-50 align-e">
                                <p class="votos" [ngClass]="{textGanador: tarjeta?.winner}"><strong>{{ tarjeta?.total }}</strong></p>
                            </div>
                            <div class="w-50" style="text-align: right;">
                                <p [ngClass]="{textGanador: tarjeta?.winner}"><strong>{{ tarjeta?.porcentaje ? tarjeta?.porcentaje : tarjeta?.prc }}</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="tipoTarjeta == 5 || tipoTarjeta == 7" class="row">
        <div class="movil-content-box" style="margin-bottom: 16px;padding-top: 0!important; display: flex;" *ngFor="let tarjeta of infoList; let indx = index;" [ngClass]="{cardGanador: tarjeta?.winner}">
            <div *ngIf="tipoTarjeta == 5" class="color-partido mx-1" style="margin: 0 !important;" [ngStyle]="{'background': tarjeta?.color}">
                &nbsp;&nbsp;&nbsp;
            </div>
            <div class="content-candidato w-100">
                <div class="movil-candidato">
                    <div>
                        <div class="distritos w-100 py-4 px-1">
                            <ng-container *ngFor="let img of tarjeta?.imgs">
                                <img height="30px" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()" alt="">
                            </ng-container>
                        </div>
                    </div>
                    <!-- <input type="checkbox" class="check-align" [ngModel]="tarjeta.seleccionado" (change)="onSelectedCheckChange($event, tarjeta)"/> -->
                </div>
                <div class="cuerpo dis-flex w-100 p-2">
                    <div class="w-100">
                        <table class="w-100">
                            <tr>
                                <td class="w-colsame">
                                    <p class="votos fontsize-tarjeta5" [ngClass]="{textGanador: tarjeta?.winner}"><strong>Total de Votos</strong><br><strong>{{ tarjeta?.total }}</strong></p>
                                </td>
                                <td class="w-colsame">
                                    <p class="align-center fontsize-tarjeta5" [ngClass]="{textGanador: tarjeta?.winner}"><br><strong>{{ tarjeta?.porcentaje ? tarjeta?.porcentaje : tarjeta?.prc }}</strong></p>
                                </td>
                                <td class="w-colsame">
                                    <p class="votos fontsize-tarjeta5" style="text-align: center;" [ngClass]="{textGanador: tarjeta?.winner}"><strong>{{title}}</strong><br><strong>{{ tarjeta?.distritos }}</strong></p>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="tipoTarjeta == 6" class="row main-cpm-container">
        <div class="movil-content-box" style="margin-bottom: 16px; padding-top: 16px;" *ngFor="let tarjeta of infoList; let indx = index;" [ngClass]="{cardGanador: tarjeta?.winner}">
            <div class="content-candidato">
                <div class="movil-candidato">
                    <div>
                        <div class="distritos w-100 px-1">
                            <ng-container *ngFor="let img of tarjeta?.imgs">
                                <img height="30px" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()" alt="">
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="cuerpo dis-flex w-100 p-2">
                    <div class="w-100">
                        <p><strong>Total de Votos</strong></p>
                        <div class="w-100 dis-flex">
                            <div class="w-50 align-e">
                                <p class="votos" [ngClass]="{textGanador: tarjeta?.winner}"><strong>{{ tarjeta?.total }}</strong></p>
                            </div>
                            <div class="w-50" style="text-align: right;">
                                <p [ngClass]="{textGanador: tarjeta?.winner}"><strong>{{ tarjeta?.porcentaje ? tarjeta?.porcentaje : tarjeta?.prc }}</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="tipoTarjeta == 8" class="row main-cpm-container">
        <div class="movil-content-box" style="margin-bottom: 16px; padding-top: 16px;" *ngFor="let tarjeta of infoList; let indx = index;" [ngClass]="{cardGanador: tarjeta?.winner}">
            <table class="w-100">
                <tr>
                    <td class="w-col">
                        <img class="logo" height="30px" *ngIf="tarjeta?.imgs?.length > 0" 
                            src="./assets/img/logos-partidos/{{tarjeta?.imgs[0]?.toLowerCase()}}"/>
                    </td>
                    <td class="w-col">
                        <p class="label-votos">Votos</p>
                        <p class="votos"><strong>{{ tarjeta?.total }}</strong></p>
                    </td>
                    <td class="w-col">
                        <p class="label-votos">Porcentaje</p>
                        <p class="label-votos align-center"><strong>{{ tarjeta?.porcentaje ? tarjeta?.porcentaje : tarjeta?.prc }}</strong></p>
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <div *ngIf="tipoTarjeta == 10" class="row">
        <div class="movil-content-box" style="margin-bottom: 16px;padding-top: 0!important; display: flex;" *ngFor="let tarjeta of infoList; let indx = index;" [ngClass]="{cardGanador: tarjeta?.winner}">
            <div class="color-partido mx-1" style="margin: 0 !important;" [ngStyle]="{'background': tarjeta?.color}">
                &nbsp;&nbsp;&nbsp;
            </div>
            <div class="content-candidato w-100">
                <div class="movil-candidato">
                    <div class="col-6">
                        <div class="distritos w-100 py-4 px-1">
                            <ng-container *ngFor="let img of tarjeta?.imgs">
                                <img height="30px" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()" alt="">
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-6 dis-flex">
                        <p class="votos fontsize-tarjeta5" style="text-align: center;" [ngClass]="{textGanador: tarjeta?.winner}"><strong>{{title}}</strong><br><strong>{{ tarjeta?.distritos }}</strong></p>
                    </div>
                    <!-- <input type="checkbox" class="check-align" [ngModel]="tarjeta.seleccionado" (change)="onSelectedCheckChange($event, tarjeta)"/> -->
                </div>
                <!-- <div class="cuerpo dis-flex w-100 p-2">
                    <div class="w-100">
                        <table class="w-100">
                            <tr>
                                <td class="w-colsame">
                                    <p class="votos fontsize-tarjeta5" [ngClass]="{textGanador: tarjeta?.winner}"></p>
                                </td>
                                <td class="w-colsame">
                                    <p class="align-center fontsize-tarjeta5" [ngClass]="{textGanador: tarjeta?.winner}"></p>
                                </td>
                                <td class="w-colsame">
                                    <p class="votos fontsize-tarjeta5" style="text-align: center;" [ngClass]="{textGanador: tarjeta?.winner}"><strong>{{title}}</strong><br><strong>{{ tarjeta?.distritos }}</strong></p>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div> -->
            </div>
        </div>
    </div>

</div>