<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 col-xl-10">
                        <app-breadcrumb
                            subtitle="Entidad"
                            subtitle_detalle="Votos por {{labels?.contienda}}"
                            [estado]="_obtenerDatosService?.config?.estado"
                        ></app-breadcrumb>
                        <div class="texto font-regular">
                            <p> El total de votos calculado y porcentaje que se muestran, se refiere a los votos asentados en las Actas PREP hasta el momento. </p>
                            <p> Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%. </p>
                            <p *ngIf="_globalService.getContienda() == 'ayuntamientos'"> En las tarjetas se muestra el número de Municipios que cada Partido Político, {{ _obtenerDatosService.config?.estado == 'Oaxaca' ? 'Candidatura Común' : 'Coalición' }} ha obtenido hasta el momento. </p>
                        </div>
                    </div>


                    <div class="col-2 d-none d-xl-block" *ngIf="_obtenerDatosService?.config?.estado == 'Veracruz'">
                        <p class="em12 text-left"><strong>Visualiza como:</strong></p>
                        <div class="visualizar dis-flex flex-col text-center">
                            <div class="selector dis-flex w-100 cursorPointer">
                                <div [routerLink]="['../mapa']"
                                    class="izq dis-flex flex-col w-50 p-2">
                                    <img class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Mapa-A.svg" style="opacity: 0.5;">
                                    <!-- <i class="ine ine-mapa" aria-hidden="true"></i> -->
                                    <p>Mapa</p>
                                </div>
                                <div
                                    class="activo dis-flex der flex-col w-50 p-2">
                                    <img class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Tarjetas-A.svg">
                                    <!-- <i class="ine ine-tarjetas" aria-hidden="true"></i> -->
                                    <p>Tarjetas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-resumen-votacion-mobile
                    [total_num]="resumen?.total?.total"
                    [total_prc]="resumen?.total?.prc"
                ></app-resumen-votacion-mobile>
                <!--Mapa versión movil-->
                <div class="col-md-6 d-block d-xl-none">
                    <div>
                        <span class="em18">
                            <strong>Mapa de {{labels?.contienda == 'Distritos' ? 'Distritos Electorales' : labels?.contienda}}</strong>
                        </span>
                        <p class="em12 padding-b-10">
                            El mapa resalta en {{ labels?.contienda != 'Juntas' ? 'los' : 'las' }} {{labels?.contienda == 'Distritos' ? 'Distritos Electorales' : labels?.contienda}} el Partido Político o {{ _obtenerDatosService.config?.estado == 'Oaxaca' ? 'Candidatura Común' : 'Coalición' }} que lleva ventaja hasta el momento.
                        </p>
                    </div>
                    <div class="h-100 map-container">
                        <div [chart]="mapa_movil"
                            data-toggle="modal"
                            data-target="#modalMovilMapa"
                        ></div>
                    </div>
                </div>
                <div class="row pb-3 mt-2">
                    <div class="col">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                    </div>
                </div>
                <!-- LISTADO DE ESCRITORIO -->
                <div class="container-fluid d-none d-md-none d-xl-block">
                    <div class="row dis-flex pb-1">
                        <div class="col-md-12">
                            <div class="row dis-flex justify-s">
                                <div *ngFor="let tarjeta of fichas; let i = index"
                                    class="col-6 col-sm-4 col-md-3 col-lg-2 px-1 w-tarjeta">
                                    <div class="card-resumen mb-1" [ngClass]="{cardGanador: tarjeta?.winner}">
                                        <div class="encabezado dis-flex w-100 py-2">
                                            <div class="dis-flex w-50">
                                                <div class="row w-80 dis-flex"
                                                    [ngClass]="{'justify-s': tarjeta?.imgs?.length > 1}">
                                                    <img class="partido" *ngFor="let img of tarjeta?.imgs" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()">
                                                </div>
                                            </div>
                                            <div class="separador"></div>
                                            <div class="distritos dis-flex w-50">
                                                <div class="dis-flex flex-col">
                                                    <p class="titulos">
                                                      {{labels?.contienda}}
                                                    </p>
                                                    <p class="distrito font-medium w-100 align-right">
                                                        <strong>{{ tarjeta?.distritos }}</strong>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cuerpo dis-flex bgGrisTenue p-2">
                                            <div class="w-50">
                                                <p>Votos</p>
                                                <p class="votos">
                                                    <strong>{{ tarjeta?.total }}</strong>
                                                </p>
                                            </div>
                                            <div class="w-50 dis-flex flex-col align-e pr-3 pr-xl-0">
                                                <p>&nbsp;</p>
                                                <p>{{ tarjeta?.porcentaje }}</p>
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="!tarjeta.seleccionado"
                                            (click)="agregarElemento(tarjeta);"
                                            class="pie dis-flex bgMorado txBlanco p-2 cursorPointer">
                                            <p>Seleccionar</p>
                                        </div>
                                        <div
                                            *ngIf="tarjeta.seleccionado"
                                            (click)="eliminarElemento(tarjeta);"
                                            class="pie dis-flex bgMorado txBlanco p-2 cursorPointer seleccionado">
                                            <p>Quitar selección</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- TARJETAS MOVIL -->
                <movil-listado-content 
                [selectedItemFunc]="selectedItemFun" 
                [diselectedItemFunc]="diselectedItemFun"
                [infoList]="fichas" 
                [title]="labels?.contienda"
                [tipoTarjeta]="5"></movil-listado-content>
                <!--********************************************************************************************************************-->
                <!-- <div class="container-fluid py-1 d-none d-xl-block">
                    <div class="row">
                        <div class="col-md-12 dis-flex flex-col align-e">
                            <div class="dis-flex">
                                <i class="fas fa-info-circle mr-2" aria-hidden="true"></i>
                                <p class="texto">
                                    Da clic en <strong>Seleccionar</strong> para visualizar el detalle de la votación. Puedes seleccionar hasta tres opciones.
                                </p>
                            </div>
                            <button
                                [disabled]="comparativaService?.arregloElementos.length < 1"
                                (click)="comparativaService.reiniciarModal()"
                                class="boton-detalle btn bgMorado txBlanco btn-sm texto ml-2 px-4 cursorPointer" data-toggle="modal" data-target="#modalComparativa">
                                Ver detalle
                            </button>
                        </div>
                    </div>
                </div> -->
                <div class="container-fluid" *ngIf="_globalService.getContienda() == 'diputaciones'">
                    <div class="row dis-flex px-2 py-3">
                        <div class="col-md-12">
                            <div class="row dis-flex justify-s">
                                <p class="em14">
                                    <strong>{{_obtenerDatosService?.main?.texto_porcentaje_estadistica ? 'Se incluye' : 'No se considera'}} la cantidad de votos asentada en las Actas PREP de Casilla Especial de Representación Proporcional.</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <app-resumen-votacion
                    [acumulados_num]="resumen?.acumulados?.total"
                    [acumulados_prc]="resumen?.acumulados?.prc"
                    [noregistradas_num]="resumen?.no_registradas?.total"
                    [noregistradas_prc]="resumen?.no_registradas?.prc"
                    [nulos_num]="resumen?.nulos?.total"
                    [nulos_prc]="resumen?.nulos?.prc"
                    [total_num]="resumen?.total?.total"
                    [total_prc]="resumen?.total?.prc"
                ></app-resumen-votacion>

                <!-- <div class="container-fluid py-1 d-block d-xl-none pt-4">
                    <div class="row">
                        <div class="col-md-12 dis-flex flex-col align-e">
                            <button
                                [disabled]="comparativaService?.arregloElementos.length < 1"
                                (click)="comparativaService.reiniciarModal()"
                                class="boton-detalle btn bgMorado txBlanco btn-sm texto ml-2 px-4 cursorPointer" data-toggle="modal" data-target="#modalComparativa">
                                Ver detalle
                            </button>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>
    </div>
</div>
<app-estadistica-nacional [load_main]="true"></app-estadistica-nacional>


<div class="modal fade modalMovilMapa" id="modalMovilMapa" tabindex="-1" role="dialog" aria-labelledby="ModalMovilMapa" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content contendor-mapa-movil" >
            <p class="texto-salir" data-dismiss="modal" >Salir del mapa</p>
            <div class="mapa" [chart]="mapa_modal"></div>
            <img  class="icono-expandir" src="assets/img/iconos-visualiza/finger_expand.svg">
        </div>
    </div>
</div>