import { Component, OnInit } from '@angular/core';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service'
import { RutasService } from 'src/app/servicios/rutas/rutas.service';
import { ComparativaService } from 'src/app/servicios/comparativa/comparativa.service';
// declare var $;
// import * as $ from 'jquery';
import { ThemeService } from '../../../../../theme/theme.service';

@Component({
  selector: 'app-resumen-partido-grafica',
  templateUrl: './resumen-partido-grafica.component.html',
  styleUrls: ['./resumen-partido-grafica.component.scss']
})
export class ResumenPartidoGraficaComponent implements OnInit {
    estadistica_extranjero: any = [];
    grafica_table: any = [];
    total_votos: string = '';
    contienda_actual: string = '';
    pathImg = './assets/img/logos-partidos/'
    resumen: any = {};
    fichas: any = [];

    constructor(
      public _obtenerDatosService: ObtenerDatosService,
      public _globalService: GlobalesService,
      public rutas: RutasService,
      public themeService: ThemeService,
      public comparativaService: ComparativaService,
      ) { }

    ngOnInit(): void {
      // $('[data-toggle="tooltip"]').tooltip();
      this._globalService.menuActual = 'Votos';
      this.rutas.reset();
      this.comparativaService.stageStatus = false;
      this.comparativaService.pantalla = 'resumenPPyCI';
      this.comparativaService.resetear();

      this._obtenerDatosService.mainSubject.subscribe(data =>{
        if(data == null) return;

        this.loadData();
      });
    }

    loadData() {
        let selectedContienda = this._globalService.getContienda();
        this.contienda_actual = selectedContienda;
        this._obtenerDatosService.loadJSON(selectedContienda, 'entidad.partido.json').then(data => {
          this.resumen = data['resumen'];
          let tmpVotos = 0;

          data['grafica'].columns.forEach( (item, index) => {
              let tmp = +item.data.r1.replace(/,/g, '');
              if(tmpVotos < tmp){tmpVotos = tmp;}
          });
          tmpVotos = this._globalService.roundUpTen(tmpVotos);

          this.grafica_table = this._globalService.deepCopy(data['grafica'].columns);
          this.grafica_table.forEach(item => {
            let tmp = +item.data.r1.replace(/,/g, '');
            item.data.porcentaje_votos = ((tmp * 100)/tmpVotos)+'%';
          });

          this.total_votos = ''+tmpVotos;
          this.estadistica_extranjero = data['extranjero'];
          this.fichas = data['fichas'];
            
          this.comparativaService.votos_total = this.resumen['total'].total;
          this.comparativaService.votos_pctj = this.resumen['total'].prc;
            
          // this.resumen_table = data['resumen_table'].columns;
          // this.contiendas_total = data['mapa'].distritos.length;

          // this.resumen_table.forEa ch(item => {
          //   item.data.pctj = item.data.r2.replace(/%/g, '');
          // });

          // this.resumen_votacion = {
          //   votos_acumulados : { votos: 0, pctj: 0 },
          //   votos_no_registrados : { votos: 0, pctj: 0 },
          //   votos_nulos : { votos: 0, pctj: 0 },
          //   votos_total : { votos: 0, pctj: 0 },
          // };

          // this.resumen_table.forEach(item => {
          //   if(item.header.text == 'Candidaturas no registradas'){
          //     this.resumen_votacion.votos_no_registrados.votos += parseInt(item.data.r1.replace(/,/g, ''));
          //   }else if(item.header.text == 'Votos nulos'){
          //     this.resumen_votacion.votos_nulos.votos += parseInt(item.data.r1.replace(/,/g, ''));
          //   }else if(item.header.text == 'Total'){
          //     this.resumen_votacion.votos_total.votos += parseInt(item.data.r1.replace(/,/g, ''));
          //   }else{
          //     this.resumen_votacion.votos_acumulados.votos += parseInt(item.data.r1.replace(/,/g, ''));
          //   }
          // });
        });
      // }
    }

    selectedItemFun = (el: any): void => {
      let scope = (this instanceof GlobalesService)? this["bottomSheetContex"] : this;
      if (scope.comparativaService.arregloElementos.length < 3) {
          scope.comparativaService.agregarElemento(el);
          el.seleccionado = true;
      }
      if(this instanceof GlobalesService && scope.comparativaService.arregloElementos.length >= 3){
          this.toggleBottomSheet(false);
      }
      if (!scope.comparativaService.stageStatus) {
          scope.comparativaService.stageStatus = true;
          scope.comparativaService.guardarStage(scope.fichas);
      }
    }
  
    diselectedItemFun = (el: any): void => {
      this.comparativaService.eliminarElemento(el);
      el.seleccionado = false;
    }
}
