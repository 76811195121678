import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { GubernaturaPartidoRoutingModule } from './gubernatura-partido-routing.module';

import { VotosPpyciGraficaComponent } from './votos-ppyci/votos-ppyci-grafica.component';
import { VotosPpyciTarjetasComponent } from './votos-ppyci/votos-ppyci-tarjetas.component';

import { VotosExtranjeroComponent } from './votos-extranjero/votos-extranjero.component';

import { DistritoVotosCandidaturaComponent } from './distritos/distrito-votos-candidatura.component';

// import { DistritoVotosCandidaturaComponent } from './distritos/distrito-votos-candidatura.component';
// import { DistritoEntidadDetalleCasillaComponent } from '../diputacion/votos-distrito/componentes/distrito-entidad-detalle-casilla/distrito-entidad-detalle-casilla.component';

// import { DesglosetableComponent } from '../componentes/plantilla/desglosetable/desglosetable.component';
import { ImportingModule } from '../../importing.module';

@NgModule({
  declarations: [
    VotosPpyciGraficaComponent,
    VotosPpyciTarjetasComponent,
    DistritoVotosCandidaturaComponent,
    VotosExtranjeroComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    GubernaturaPartidoRoutingModule,
    ImportingModule
  ]
})
export class GubernaturaPartidoModule { }
