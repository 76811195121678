import { Component, OnInit, HostListener } from '@angular/core';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { ThemeService } from '../../../theme/theme.service';
import { ComparativaService } from 'src/app/servicios/comparativa/comparativa.service';

@Component({
  selector: 'app-votos-candidatura-grafica',
  templateUrl: './votos-candidatura-grafica.component.html',
  styleUrls: ['./votos-candidatura-grafica.component.scss']
})
export class VotosCandidaturaGraficaComponent implements OnInit {
  grafica_table: any = [];
  table_info: any = [];
  total_votos: string = '';
  subTitle = "Detalle del Distrito"
  subTitleDetalle = "Votos por Candidatura"

  data: any;
  base: string;
  seccion: string;
  estadisticaInfo: any = {};
  resumen: any = null;
  fichas: any = [];
  omitirExtranjero = false;

  constructor(
    public _globalService: GlobalesService,
    //   private readonly activatedRouter: ActivatedRoute,
    //   public readonly rutas: RutasService,
    public _comparativaService: ComparativaService,
    public _obtenerDatosService: ObtenerDatosService,
    public themeService: ThemeService,
  ) { }

  ngOnInit(): void {
      this._comparativaService.stageStatus = false;
      this._comparativaService.pantalla = 'entidadCandidatura';
      this._comparativaService.resetear();
      
      this._globalService.bottomSheetContex = this;
      this._globalService.bottomSheetCallback = this.agregarElemento;
      
      this._obtenerDatosService.mainSubject.subscribe(data =>{
        if(data == null) return;
        this.loadData();
      });
      this._obtenerDatosService.configSubject.subscribe(data =>{
        if (data != null) {
          const omitir = data.omitir_extranjero;
          this.omitirExtranjero = (!omitir || (omitir && (omitir == 'false' || omitir == false) )) ? false : true;
        }
      });
    this.getScreenSize();
  }

  loadData() {
    let selectedContienda = this._globalService.getContienda();
      this._obtenerDatosService.loadJSON(selectedContienda, 'entidad.candidatura.json').then(data => {
          this._globalService.bottomSheetTpl = 'comparador-candidato';
          if (data == null) return;
          this.grafica_table = this._globalService.deepCopy(data['fichas']);
          this.table_info = data['resumen_table'].columns;
          this.resumen = data['resumen'];
          this.fichas = data['fichas'];
          
          let tmpVots = 0;

          this.grafica_table.forEach(item => {
              item.pctj = item.porcentaje.replace(/%/g, '');
              let tmp = +item.total.replace(/,/g, '');
              if(tmpVots < tmp){tmpVots = tmp}
          });
          tmpVots = this._globalService.roundUpTen(tmpVots);

          this.grafica_table.forEach(item => {
              let tmp = +item.total.replace(/,/g, '');
              item.porcentaje_votos = ((tmp * 100)/tmpVots)+'%';
          });

          this.total_votos = ''+tmpVots;
          
          this.estadisticaInfo = data['estadisticas_footer'];
          this._comparativaService.votos_total = this.resumen['total'].total;
          this._comparativaService.votos_pctj = this.resumen['total'].prc;
      });
  }

  agregarElemento(el: any) {
      //Fix del contexto de la variable 
      let scope = (this instanceof GlobalesService)? this["bottomSheetContex"] : this;
//      console.log('arreglo elementos', scope.comparativaService.arregloElementos);
      if (scope._comparativaService.arregloElementos.length < 3) {
          scope._comparativaService.agregarElemento(el);
          el.seleccionado = true;
      }
      if(this instanceof GlobalesService && scope._comparativaService.arregloElementos.length >= 3){
          this.toggleBottomSheet(false);
      }
      if (!scope._comparativaService.stageStatus) {
          scope._comparativaService.stageStatus = true;
          scope._comparativaService.guardarStage(scope.fichas);
      }
  }

  eliminarElemento(el: any) {
    this._comparativaService.eliminarElemento(el);
    el.seleccionado = false;
  }

  selectedItemFun = (el: any): void => {
    this.agregarElemento(el);
  }

  diselectedItemFun = (el: any): void => {
    this.eliminarElemento(el);
  }

//  selectedItemFun = (el: any): void => {
//    let scope = (this instanceof GlobalesService)? this["bottomSheetContex"] : this;
//      if (scope._comparativaService.arregloElementos.length < 3) {
//          scope._comparativaService.agregarElemento(el);
//          el.seleccionado = true;
//      }
//      if(this instanceof GlobalesService && scope._comparativaService.arregloElementos.length >= 3){
//          this.toggleBottomSheet(false);
//      }
//      if (!scope._comparativaService.stageStatus) {
//          scope._comparativaService.stageStatus = true;
//          scope._comparativaService.guardarStage(scope.fichas);
//      }
//  }
//
//  diselectedItemFun = (el: any): void => {
//    this._comparativaService.eliminarElemento(el);
//    el.seleccionado = false;
//  }

  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        const scrWidth = window.innerWidth;
        if(scrWidth < 1200){
          this.subTitle = "Entidad"
          this.subTitleDetalle = "Votos por Candidatura"
        }else{
          this.subTitle = "Entidad"
          //this.subTitle = "Detalle del Distrito"
          this.subTitleDetalle = "Votos por Candidatura"
        }
        
  }

}
