<div class="container-fluid bgGris py-1">
    <div class="row bgMorado txBlanco d-block d-md-block d-xl-none">
        <div class="col-xl-10 offset-xl-2 dis-flex space-a px12 px-0 py-2" style="margin-bottom: 15px;">
            <p class="m-0 align-center" (click)="onChangeViewPortToDesktop()">
                <a>Versión de escritorio <span class="fa fa-share-square"></span></a>
            </p>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-10 px12 text-center txtNg">
            <p class="m-0" [innerHTML]="config?.aviso_legal"></p>
            <!-- <p class="m-0" [innerHTML]="config?.footer?.aviso_legal">
                Con base en la Ley Federal del Derecho de Autor queda prohibida cualquier modificación al diseño de este sitio.
            </p> -->
            <!-- <p class="m-0">
                La modificación y/o destrucción de la información y/o contenido total o parcial de este sitio, es delito federal de acuerdo al Código Penal Federal.
            </p> -->
        </div>
    </div>
</div>