import { Component, OnInit, Input} from '@angular/core';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';

@Component({
  selector: 'movil-listado-content',
  templateUrl: './listado-content.component.html',
  styleUrls: ['./listado-content.component.scss']
})
export class ListadoContentComponent implements OnInit {

  @Input() selectedItemFunc: (args: any) => void;
  @Input() diselectedItemFunc: (args: any) => void;
  @Input() infoList: [any];
  @Input() tipoTarjeta: number;
  @Input() complementList: [any];
  @Input() title: string;

  omitirExtranjero = false;

  constructor(public _obtenerDatosService: ObtenerDatosService) { }

  ngOnInit(): void {
    this._obtenerDatosService.configSubject.subscribe(data =>{
      if (data != null) {
        const omitir = data.omitir_extranjero;
        this.omitirExtranjero = (!omitir || (omitir && (omitir == 'false' || omitir == false) )) ? false : true;
      }
    });
  }
  onSelectedCheckChange(e, item){  
      if (e.target.checked) {
          this.selectedItemFunc(item);
      } else {
          this.diselectedItemFunc(item);
      }
//      console.log(item);
  }

}
