import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

// Módulos
import { GubernaturaPartidoModule } from './gubernatura/gubernatura-partido.module';
import { GubernaturaCandidaturaModule } from './gubernatura/gubernatura-candidatura.module';
import { ConsultaCiudadanaModule } from './consulta-ciudadana/consulta-ciudadana.module';
// import { DiputacionModule } from './diputacion/diputacion.module';
// import { VotosPartidoModule } from './diputacion/votos-partido/votos-partido.module';
// import { AppRoutingModule } from '../app-routing.module';

import { HighchartsChartModule } from 'highcharts-angular';
import { ChartModule } from 'angular-highcharts';

// Tooltip
import { TooltipModule } from 'ng2-tooltip-directive';
import { MenuLateralComponent } from '../componentes/plantilla/menu-lateral/menu-lateral.component';
import { AvanceNacionalComponent } from '../componentes/plantilla/avance-nacional/avance-nacional.component';
import { BuscadorComponent } from '../componentes/plantilla/buscador/buscador.component';
import { BaseDatosComponent } from '../componentes/menu/base-datos/base-datos.component';
import { PreguntasFrecuentesComponent } from '../componentes/menu/preguntas-frecuentes/preguntas-frecuentes.component';
import { AccesibilidadComponent } from '../componentes/menu/accesibilidad/accesibilidad.component';
import { PiePaginaComponent } from '../componentes/plantilla/pie-pagina/pie-pagina.component';
import { PrePiePaginaComponent } from '../componentes/plantilla/pre-pie-pagina/pre-pie-pagina.component';
import { PreguntaAvanceNacionalComponent } from '../componentes/paginas/preguntas-frecuentes/pregunta-avance-nacional/pregunta-avance-nacional.component';
import { PreguntaConsultaVotacionComponent } from '../componentes/paginas/preguntas-frecuentes/pregunta-consulta-votacion/pregunta-consulta-votacion.component';
import { PreguntaResultadosCasillaComponent } from '../componentes/paginas/preguntas-frecuentes/pregunta-resultados-casilla/pregunta-resultados-casilla.component';
import { PreguntaInicioComponent } from '../componentes/paginas/preguntas-frecuentes/pregunta-inicio/pregunta-inicio.component';
import { PreguntaBuscadorComponent } from '../componentes/paginas/preguntas-frecuentes/pregunta-buscador/pregunta-buscador.component';
import { PreguntaVotosActasComponent } from '../componentes/paginas/preguntas-frecuentes/pregunta-votos-actas/pregunta-votos-actas.component';
import { PreguntaVisualizacionTarjetasComponent } from '../componentes/paginas/preguntas-frecuentes/pregunta-visualizacion-tarjetas/pregunta-visualizacion-tarjetas.component';
import { PreguntaVisualizacionGraficosComponent } from '../componentes/paginas/preguntas-frecuentes/pregunta-visualizacion-graficos/pregunta-visualizacion-graficos.component';
import { PreguntaVerDetalleComponent } from '../componentes/paginas/preguntas-frecuentes/pregunta-ver-detalle/pregunta-ver-detalle.component';
import { PreguntaResumenVotacionComponent } from '../componentes/paginas/preguntas-frecuentes/pregunta-resumen-votacion/pregunta-resumen-votacion.component';
import { PreguntaEstadisticaComponent } from '../componentes/paginas/preguntas-frecuentes/pregunta-estadistica/pregunta-estadistica.component';
import { PreguntaObservacionesActasComponent } from '../componentes/paginas/preguntas-frecuentes/pregunta-observaciones-actas/pregunta-observaciones-actas.component';
import { PreguntaElementosApoyoComponent } from '../componentes/paginas/preguntas-frecuentes/pregunta-elementos-apoyo/pregunta-elementos-apoyo.component';
import { BaseDatosInicioComponent } from '../componentes/paginas/base-datos/base-datos-inicio/base-datos-inicio.component';
import { BaseDatosDefinicionComponent } from '../componentes/paginas/base-datos/base-datos-definicion/base-datos-definicion.component';
import { BaseDatosCarpetaArchivosComponent } from '../componentes/paginas/base-datos/base-datos-carpeta-archivos/base-datos-carpeta-archivos.component';
import { BaseDatosConsultaComponent } from '../componentes/paginas/base-datos/base-datos-consulta/base-datos-consulta.component';
import { BotonCompartirComponent } from '../componentes/plantilla/boton-compartir/boton-compartir.component';
import { PpyciRepresentacionProporcionalComponent } from './diputacion/votos-partito/componentes/ppyci-representacion-proporcional/ppyci-representacion-proporcional.component';
// import { TituloPipe } from '../pipes/titulo/titulo.pipe';
import { ComparativaComponent } from '../componentes/comparativa/comparativa.component';
import { MarcaAguaComponent } from '../componentes/plantilla/marca-agua/marca-agua.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ThemeModule } from '../theme/theme.module';
import { ImportingModule } from '../importing.module';
import { ContiendasComponent } from './contiendas.component';

import { EncabezadoComponent } from '../componentes/plantilla/encabezado/encabezado.component';
import { RouterModule } from '@angular/router';
import { DiputacionDistritosModule } from './diputacion/diputacion-distritos.module';
import { DiputacionPartidoModule } from './diputacion/diputacion-partido.module';

import { AyuntamientoCandidaturaModule } from './ayuntamiento/ayuntamiento-candidatura.module';
import { JuntaCandidaturaModule } from './junta/junta-candidatura.module';
import { RutasService } from '../servicios/rutas/rutas.service';

import { HighlightSearchPipe } from '../pipes/highlight.pipe';

// MAIN.JSON
import data from '../../assets/json/main.json';

let imports = [
  RouterModule,
  BrowserModule,
  CommonModule,
  NgbModule,
  FormsModule,
  ThemeModule,
  ImportingModule,
    HighchartsChartModule,
    ChartModule
];

let arrContiendas: any = [
      'gubernatura',
      'diputaciones',
      'ayuntamientos',
      'juntas',
      'consulta-ciudadana'
    ]
for (const contienda in data) {
  if (arrContiendas.indexOf(contienda) >= 0) {
    switch (contienda) {
      case 'gubernatura':
        imports.push(GubernaturaCandidaturaModule);
        imports.push(GubernaturaPartidoModule);
        break;
        
      case 'diputaciones':
        imports.push(DiputacionDistritosModule);
        imports.push(DiputacionPartidoModule);
        break;
        
      case 'ayuntamientos':
        imports.push(AyuntamientoCandidaturaModule);
        break;
        
      case 'juntas':
        imports.push(JuntaCandidaturaModule);
        break;
      case 'consulta-ciudadana':
        imports.push(ConsultaCiudadanaModule)
        break;
      default:
        break;
    }
  }
  else {}
}

// Componentes

@NgModule({
  declarations: [
    EncabezadoComponent,
    MenuLateralComponent,
    AvanceNacionalComponent,
    BuscadorComponent,
    BaseDatosComponent,
    PreguntasFrecuentesComponent,
    AccesibilidadComponent,
    PiePaginaComponent,
    PrePiePaginaComponent,
    PreguntaAvanceNacionalComponent,
    PreguntaConsultaVotacionComponent,
    PreguntaResultadosCasillaComponent,
    PreguntaInicioComponent,
    PreguntaBuscadorComponent,
    PreguntaVotosActasComponent,
    PreguntaVisualizacionTarjetasComponent,
    PreguntaVisualizacionGraficosComponent,
    PreguntaVerDetalleComponent,
    PreguntaResumenVotacionComponent,
    PreguntaEstadisticaComponent,
    PreguntaObservacionesActasComponent,
    PreguntaElementosApoyoComponent,
    BaseDatosInicioComponent,
    BaseDatosDefinicionComponent,
    BaseDatosCarpetaArchivosComponent,
    BaseDatosConsultaComponent,
    BotonCompartirComponent,
    PpyciRepresentacionProporcionalComponent,
    // TituloPipe,
    ComparativaComponent,
    MarcaAguaComponent,
    ContiendasComponent,
    HighlightSearchPipe
  ],
  imports: imports,
  providers: [RutasService],
  exports: [
    // EncabezadoComponent,
    // MenuLateralComponent,
    // AvanceNacionalComponent,
    // BuscadorComponent,
    // BaseDatosComponent,
    // PreguntasFrecuentesComponent,
    // AccesibilidadComponent,
    // PiePaginaComponent,
    // PrePiePaginaComponent,
    // PreguntaAvanceNacionalComponent,
    // PreguntaConsultaVotacionComponent,
    // PreguntaResultadosCasillaComponent,
    // PreguntaInicioComponent,
    // PreguntaBuscadorComponent,
    // PreguntaVotosActasComponent,
    // PreguntaVisualizacionTarjetasComponent,
    // PreguntaVisualizacionGraficosComponent,
    // PreguntaVerDetalleComponent,
    // PreguntaResumenVotacionComponent,
    // PreguntaEstadisticaComponent,
    // PreguntaObservacionesActasComponent,
    // PreguntaElementosApoyoComponent,
    // BaseDatosInicioComponent,
    // BaseDatosDefinicionComponent,
    // BaseDatosCarpetaArchivosComponent,
    // BaseDatosConsultaComponent,
    // BotonCompartirComponent,
    // PpyciRepresentacionProporcionalComponent,
    // TituloPipe,
    // ComparativaComponent,
    // MarcaAguaComponent,
    // ContiendasComponent
  ],
  bootstrap: [ContiendasComponent]
})
export class ContiendasModule { }
