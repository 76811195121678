import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { VotosPartidoComponent } from './votos-partido/componentes/votos-partido/votos-partido.component';
// import { ResumenPartidoComponent } from './votos-partido/componentes/resumen-partido/resumen-partido.component';
// import { PpyciCircunscripcionComponent } from './votos-partido/componentes/ppyci-circunscripcion/ppyci-circunscripcion.component';
// import { PpyciEntidadComponent } from './votos-partido/componentes/ppyci-entidad/ppyci-entidad.component';
// import { PpyciDistritoComponent } from './votos-partido/componentes/ppyci-distrito/ppyci-distrito.component';
import { AuthGuard } from '../../auth/auth.guard';
import { ContiendasComponent } from '../contiendas.component';
// import { PpyciSeccionComponent } from './votos-partido/componentes/ppyci-seccion/ppyci-seccion.component';
// import { PpyciCasillaComponent } from './votos-partido/componentes/ppyci-casilla/ppyci-casilla.component';
import { ResumenPartidoComponent } from './votos-partido/componentes/resumen-partido/resumen-partido.component';
import { ResumenPartidoGraficaComponent } from './votos-partido/componentes/resumen-partido-grafica/resumen-partido-grafica.component';
import { DiputacionMigranteComponent } from './votos-partido/componentes/diputacion-migrante/diputacion-migrante.component';
// import { PpyciCircunscripcionGraficaComponent } from './votos-partido/componentes/ppyci-circunscripcion-grafica/ppyci-circunscripcion-grafica.component';
// import { PpyciEntidadGraficaComponent } from './votos-partido/componentes/ppyci-entidad-grafica/ppyci-entidad-grafica.component';
import { PpyciRepresentacionProporcionalComponent } from './votos-partito/componentes/ppyci-representacion-proporcional/ppyci-representacion-proporcional.component';
// import { ResumenPartidoRepresentacionComponent } from './votos-partido/componentes/resumen-partido-representacion/resumen-partido-representacion.component';
// import { PpyciCircunscripcionRepresentacionComponent } from './votos-partido/componentes/ppyci-circunscripcion-representacion/ppyci-circunscripcion-representacion.component';
import { PpyciDistritoComponent } from './votos-partido/componentes/ppyci-distrito/ppyci-distrito.component';


const routes: Routes = [
  {
    path: 'diputaciones',
    canActivate: [AuthGuard],
    component: ContiendasComponent,
    children: [
      {
        path: 'votos-ppyci',
        children: [
          {
            path: '',
            redirectTo: 'grafica',
            pathMatch: 'full'
          },
          {
            path: 'grafica',
            component: ResumenPartidoGraficaComponent,
          },
          {
            path: 'tarjetas',
            component: ResumenPartidoComponent,
          },
          {
            path: 'diputacion-migrante',
            component: DiputacionMigranteComponent,
          },
          {
            path: 'representacion-proporcional',
            component: PpyciRepresentacionProporcionalComponent
          }
        ]
      },
      {
        path: ':distrito/votos-ppyci',
        component: PpyciDistritoComponent,
        data : { contienda : 'Distritos', contienda_s : 'Distrito' }
      },
    ]

  //   path: 'votos-ppyci',
  //   component: VotosPartidoComponent,
  //   children: [
  //     {
  //       path: '',
  //       redirectTo: 'grafica',
  //       pathMatch: 'full'
  //     },
  //     {
  //       path: 'grafica',
  //       component: ResumenPartidoGraficaComponent
  //     },
  //     {
  //       path: 'tarjetas',
  //       component: ResumenPartidoComponent
  //     },
  //     {
  //       path: 'representacion-proporcional',
  //       component: ResumenPartidoRepresentacionComponent
  //     },
  //     // {
  //     //   path: 'circunscripcion/:circunscripcion/entidad/:entidad',
  //     //   component: PpyciEntidadGraficaComponent
  //     // },
  //     // {
  //     //   path: 'circunscripcion/:circunscripcion/entidad/:entidad/tarjetas',
  //     //   component: PpyciEntidadComponent
  //     // },
  //     // {
  //     //   path: 'circunscripcion/:circunscripcion/entidad/:entidad/distrito/:distrito',
  //     //   component: PpyciDistritoComponent
  //     // },
  //     {
  //       path: 'circunscripcion/:circunscripcion/entidad/:entidad/distrito/:distrito/seccion/:seccion',
  //       component: PpyciSeccionComponent
  //     },
  //     {
  //       path: 'circunscripcion/:circunscripcion/entidad/:entidad/distrito/:distrito/seccion/:seccion/casilla/:casilla',
  //       component: PpyciCasillaComponent
  //     }
  //   ]
  // },
  // {
  //   path: ':circunscripcion/votos-ppyci',
  //   component: PpyciCircunscripcionGraficaComponent,
  //   children: [
  //     {
  //       path: '',
  //       redirectTo: 'grafica',
  //       pathMatch: 'full'
  //     },
  //     {
  //       path: 'grafica',
  //       component: PpyciCircunscripcionGraficaComponent
  //     }
  //   ]
  // },
  // {
  //   path: ':circunscripcion/votos-ppyci/tarjetas',
  //   component: PpyciCircunscripcionComponent
  // },
  // {
  //   path: ':circunscripcion/votos-ppyci/representacion-proporcional',
  //   component: PpyciCircunscripcionRepresentacionComponent
  // },
  // {
  //   path: ':circunscripcion/:entidad/votos-ppyci',
  //   component: PpyciEntidadGraficaComponent,
  //   children: [
  //     {
  //       path: '',
  //       redirectTo: 'grafica',
  //       pathMatch: 'full'
  //     },
  //     {
  //       path: 'grafica',
  //       component: PpyciEntidadGraficaComponent
  //     },
  //     // {
  //     //   path: 'representacion-proporcional',
  //     //   component: PpyciRepresentacionProporcionalComponent
  //     // },
  //   ]
  // },
  // {
  //   path: ':circunscripcion/:entidad/votos-ppyci/tarjetas',
  //   component: PpyciEntidadComponent
  // },
  // {
  //   path: ':circunscripcion/:entidad/votos-ppyci/representacion-proporcional',
  //   component: PpyciRepresentacionProporcionalComponent
  // },
  // {
  //   path: ':circunscripcion/:entidad/:distrito/votos-ppyci',
  //   component: PpyciDistritoComponent
  // },
  // {
  //   path: ':circunscripcion/:entidad/:distrito/votos-ppyci/representacion-proporcional',
  //   component: PpyciRepresentacionProporcionalComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DiputacionPartidoRoutingModule { }
