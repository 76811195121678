import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';

// declare var $;
// import * as $ from 'jquery';
import { ThemeService } from '../../../../theme/theme.service';

@Component({
  selector: 'app-seccion-resumen',
  templateUrl: './seccion-resumen.component.html',
  styleUrls: ['./seccion-resumen.component.scss']
})
export class SeccionResumenComponent implements OnInit {
  fichas: any = [];
  casilla_table: any = [];
  participacion_table: any = [];
  lista_nominal: string;
  base: string;
  contiendaActual: string;
  resumen: any = {};
  showCATD = false
  showLabel = false
  showEscaner = true
  showMovil = true
  showConfCatd = true

  constructor(
    public _globalService: GlobalesService,
    private readonly activatedRouter: ActivatedRoute,
    public _obtenerDatosService: ObtenerDatosService,
    public readonly rutas: RutasService,
    public themeService: ThemeService,
  ) { }

  ngOnInit(): void {
    this.obtenerParametros();
    this.base = this.rutas.rutaActual.base;
    this.contiendaActual = this._globalService.getContienda();
    // $('[data-toggle="tooltip"]').tooltip();

    this._obtenerDatosService.configSubject.subscribe(data =>{
      if(data == null) return;
      this.showLabel = data.estado == "Veracruz"
      this.showEscaner = data.show_escaner;
      this.showMovil = data.show_movil;
      this.showConfCatd = data.show_catd;
    });
    this._obtenerDatosService.mainSubject.subscribe(data =>{
      if(data == null) return;
      this.loadData();
      this.showCATD = data?.show_catd ? data?.show_catd : false
    });
  }

  // ngOnDestroy(): void {
  //   this._obtenerDatosService.mainSubject.unsubscribe();
  // }

  loadData() {
    let selectedContienda = this._globalService.getContienda();
    if (this._globalService.param_seccion && this._globalService.param_distrito){
      this._obtenerDatosService.loadJSON(selectedContienda, this._globalService.param_seccion + '.' + this._globalService.param_distrito + '.casilla.json').then(data => {
        this._obtenerDatosService.casillasSubject.next(data);
        this.resumen = data['resumen'];
        this.fichas = data['fichas'];
        this.casilla_table = data['casilla_table']? data['casilla_table'] : data['seccion_casilla_table'];
        this.participacion_table = data['participacion_table']? data['participacion_table'].rows : data['participacion_casilla_table'].rows;
        this.lista_nominal = data['participacion_table'] ? data['participacion_table']['lista_nominal'] : data['participacion_casilla_table']['lista_nominal'];
  
        this.participacion_table.forEach(item => {
          item.pctj = item.c2.replace(/%/g, '');
        });
      }); 
    }
  }

  scrollComponent(el: HTMLElement) {
      el.scrollIntoView();
  }

  obtenerParametros() {
    this.activatedRouter.params.subscribe((parametros) => {
      const params = { parametros };
      this._globalService.detalles = params.parametros;
    });
    this._globalService.setearParametros();
  }


  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

}
