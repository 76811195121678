import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CombosService {

  constructor(
    private http: HttpClient
  ) { }

  cargarCombos() {
    return this.http.get(env.urls.urlCombos);
  }

  cargarCombosEntidad() {
    return this.http.get(env.urls.urlCombosEntidad);
  }
}
