import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { GlobalesService } from '../servicios/globales.service';


@Injectable({ providedIn: 'root' })
export class InicioGuard implements CanActivate {
    constructor(
        private router: Router,
        public globalService: GlobalesService
        // private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		let enableContienda = localStorage.getItem('enable-contienda');

        if (enableContienda === 'true') {
			let contienda = this.globalService.getContiendaDefault();
			if (contienda != '') this.router.navigate([('/' + contienda)], /*{ queryParams: { returnUrl: state.url } }*/);
			else return true;
		}

        return true;
    }
}