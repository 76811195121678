<div class="seccion-resumen container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 col-lg-10">
                        <app-breadcrumb
                            subtitle_detalle="Sección - Casillas"
                            [estado]="_obtenerDatosService?.config?.estado"
                            [distrito]="rutas.migas?.distrito"
                            [distrito_param]="rutas.migas?.distrito_clave"
                            [seccion]="rutas.migas?.seccion"
                            [seccion_param]="rutas.migas?.seccion_clave"
                        ></app-breadcrumb>
                        <div class="texto font-regular mb-2">
                            <p>El total de votos calculado y porcentaje que se muestran, se refiere a los votos asentados en las Actas PREP hasta el momento.</p>
                            <p>Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%.</p>
                        </div>
                    </div>
                </div>
                <app-resumen-votacion-mobile
                    [total_num]="resumen?.total?.total"
                    [total_prc]="resumen?.total?.prc"
                ></app-resumen-votacion-mobile>
                <div class="row pb-4">
                    <div class="col">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                    </div>
                </div>
                <!--************************ TARJETAS EN ESCRITORIO ***********************-->
                <div class="container-fluid d-none d-md-none d-xl-block">
                    <div class="row dis-flex pb-1 mt-2">
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollLeft()">
                            <i class="fas fa-chevron-left" aria-hidden="true"></i>
                        </div>
                        <div class="col-11">
                            <div #widgetsContent class="row dis-flex justify-s scrollH">
                                <div *ngFor="let tarjeta of fichas; let i=index;"
                                    class="col-6 col-sm-4 col-md-3 col-lg-2 px-1">
                                    <div class="card-resumen" *ngIf="i < casilla_table.headers.length - 4">
                                        <div class="encabezado dis-flex w-100 py-2">
                                            <div class="dis-flex w-100">
                                                <div class="row w-100 dis-flex">
                                                    <img class="img-partido" *ngFor="let img of tarjeta.imgs" src="./assets/img/logos-partidos/{{img?.toLowerCase()}}">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cuerpo dis-flex bgGrisTenue p-2">
                                            <div class="w-50">
                                                <p>Votos</p>
                                                <p class="votos">
                                                    <strong>{{ tarjeta?.total }}</strong>
                                                </p>
                                            </div>
                                            <div class="w-50 dis-flex flex-col align-e pr-3 pr-xl-0">
                                                <p>Porcentaje</p>
                                                <p>{{ tarjeta?.porcentaje }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollRight()">
                            <i class="fas fa-chevron-right" aria-hidden="true"></i>
                        </div>
                    </div>
               </div>
                <!--***********************************************************************-->
                <!-- TARJETAS MOVIL -->
                <movil-listado-content 
                [infoList]="fichas" 
                [tipoTarjeta]="8"></movil-listado-content>
                <!--********************************************************************************************************************-->

                <!-- <div class="texto font-regular p-3">
                    <p>
                        Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%.
                    </p>
                </div> -->

                <app-resumen-votacion
                    [acumulados_num]="resumen?.acumulados?.total"
                    [acumulados_prc]="resumen?.acumulados?.prc"
                    [noregistradas_num]="resumen?.no_registradas?.total"
                    [noregistradas_prc]="resumen?.no_registradas?.prc"
                    [nulos_num]="resumen?.nulos?.total"
                    [nulos_prc]="resumen?.nulos?.prc"
                    [total_num]="resumen?.total?.total"
                    [total_prc]="resumen?.total?.prc"
                ></app-resumen-votacion>

            
            </div>
        </div>
    </div>


    <div class="row d-none d-md-none d-xl-block">
        <div class="col-md-12">
            <div class="card mt-3 p-3">
                
                <div class="row">
                    <div class="col">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Detalle de votos por Casilla</strong>
                        </h4>
                    </div>
                </div>
                <div class="col-12 px-0 mt-4">
                    <div class="acta-digitalizada container-fluid">
                        <div class="row">
                            <div class="col-7 pl-0">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-5 dis-flex border-rightB padding-r-0">
                                            <p class="font-medium text-left padding-r-0 padding-b-15">
                                                Imagen procedente de:
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 dis-flex border-rightB padding-r-0">
                                            <div class="grupo gris dis-flex">
                                                <i class="far fa-file"></i>
                                                <i class="far fa-hourglass"></i>
                                                <p>Acta en proceso</p>

                                                <div class="vline"></div>
                                            </div>
                                        </div>
                                        <div class="col-2 dis-flex border-rightB padding-r-0 padding-l-0" *ngIf="showEscaner">
                                            <div class="grupo dis-flex cursor-hand" tooltip="Acta PREP digitalizada por medio de escáner." placement="top" max-width="500">
                                                <i class="far fa-file"></i>
                                                <i class="fas fa-print" aria-hidden="true"></i>
                                                <p>Escáner</p>
                                            </div>
                                        </div>
                                        <div class="col-3 dis-flex" *ngIf="showMovil">
                                            <div class="grupo dis-flex cursor-hand" tooltip="Acta PREP digitalizada por medio de dispositivo móvil." placement="top" max-width="500">
                                                <i class="far fa-file"></i>
                                                <i class="fas fa-mobile-alt"></i>
                                                <p>Móvil {{showLabel ? "Casilla" : ''}}</p>
                                            </div>
                                        </div>
                                        <div class="col-3 dis-flex" *ngIf="showCATD && showConfCatd">
                                            <div class="grupo dis-flex cursor-hand" 
                                                tooltip="Acta PREP digitalizada por medio de dispositivo móvil."
                                                placement="top" max-width="500">
                                                <div class="grupo dis-flex">
                                                    <div *ngIf="!themeService.isDarkTheme()" style="width: 33px;height: 20px;background-image: url('./assets/img/generales/logo_movil_catd.png');background-repeat: no-repeat;image-rendering: -webkit-optimize-contrast;background-size:contain;"></div>
                                                    <div *ngIf="themeService.isDarkTheme()" style="width: 33px;height: 20px;background-image: url('./assets/img/generales/logo_movil_catd_rosa.png');background-repeat: no-repeat;image-rendering: -webkit-optimize-contrast;background-size:contain;"></div>
                                                    <p class="leyenda">
                                                        Móvil CATD
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div> 
                        <div class="table-responsive tabla-seccion max-500">
                            <table class="table table-striped contenido table-bordered">
                                <thead class="titulos">
                                        <tr>
                                        <th class="sticky-cell table-header-background">
                                            <div class="dis-flex">
                                            <ng-container>
                                                <ng-container>
                                                <strong>Casilla</strong>
                                                </ng-container>
                                            </ng-container>
                                            </div>
                                        </th>
                                        <th class="sticky-cell table-header-background">
                                            <div class="dis-flex">
                                            <ng-container>
                                                <ng-container>
                                                <strong>Acta digitalizada</strong>
                                                </ng-container>
                                            </ng-container>
                                            </div>
                                        </th>
                                        <th *ngFor="let itemHeader of casilla_table?.headers; let i = index">
                                            <div class="dis-flex">
                                                <ng-container>
                                                    <ng-container *ngIf="itemHeader?.text != ''">
                                                        <strong>
                                                            {{itemHeader?.text}}
                                                        </strong>
                                                    </ng-container>
                                                    <ng-container *ngFor="let img of itemHeader?.imgs">
                                                        <img class="img-partido" src="./assets/img/logos-partidos/{{img?.toLowerCase()}}">
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let itemBody of casilla_table?.rows">
                                        <td class="sticky-cell">
                                            <a class="link-text" *ngIf="itemBody.status != 'proceso'" [routerLink]="['/'+contiendaActual+'/'+_globalService.param_distrito+'/secciones/'+ this._globalService.param_seccion+'/casilla/'+itemBody.param]"> {{itemBody?.text}} </a>
                                            <a *ngIf="itemBody.status == 'proceso'"> {{itemBody?.text}} </a>
                                        </td>
                                        <td class="sticky-cell">
                                            <a target="_blank" [href]="itemBody.status.catd ? itemBody.status.catd : (itemBody.status.movil? itemBody.status.movil : itemBody.status.scanner)"
                                            *ngIf="itemBody.status != 'proceso'">
                                                <ng-container *ngIf="itemBody.status.movil">
                                                    <i class="far fa-file" aria-hidden="true"></i>
                                                    <i class="fas fas fa-mobile-alt" aria-hidden="true"></i>
                                                </ng-container>
                                                <ng-container *ngIf="itemBody.status.scanner">
                                                    <i class="far fa-file" aria-hidden="true"></i>
                                                    <i class="fas fa-print" aria-hidden="true"></i>
                                                </ng-container>
                                                <ng-container *ngIf="itemBody.status.catd">
                                                    <img *ngIf="!themeService.isDarkTheme()" height="15" [src]="'./assets/img/generales/logo_movil_catd.png'">
                                                    <img *ngIf="themeService.isDarkTheme()" height="15" [src]="'./assets/img/generales/logo_movil_catd_rosa.png'">
                                                </ng-container>
                                                <ng-container *ngIf="itemBody.actaDigitalizada === '-'">
                                                    <i class="far fa-file"></i>
                                                    <i class="far fa-hourglass"></i>
                                                </ng-container>
                                            </a>
                                            <a target="_blank" *ngIf="itemBody.status == 'proceso' || itemBody.status == 'sin_acta'">
                                                <div class="txGris">
                                                    <i class="far fa-file txGris"></i>
                                                    <i class="far fa-hourglass txGris"></i>
                                                </div>
                                            </a>
                                        </td>
                                        <td *ngFor="let itemVoto of itemBody.columns; let i=index;">
<!--                                            {{itemVoto}}-->
                                            <span *ngIf="i < itemBody.columns.length-1; else observacion">{{itemVoto}}</span>
                                            <ng-template #observacion>
                                                <span class="link-observaciones" (click)="scrollComponent(observaciones)">{{itemVoto}}</span>
                                            </ng-template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="estadistica-casilla-movil card mt-3 p-3 d-none d-xl-block">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <h4 class="subtitulo-seccion pleca-izquierda">
                                <strong>Estadística de Casilla</strong>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col">
                            <p class="subtitulo underline" style="font-size: 1.6em;">
                                <strong class="cursorPointer">Participación ciudadana con base en la Lista Nominal de las Actas PREP Contabilizadas</strong>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="row my-2" *ngFor="let casilla of participacion_table">

                        <div class="col-12">
                            <div class="grafica pt-3">
                                <div class="textos" style="display: flex;">
                                    <div class="w-50">
                                        <p class="estadistica-label" >{{casilla?.c1}}</p>
                                        <p class="estadistica-val"><strong>{{casilla?.c2}}</strong></p>
                                    </div>
                                    <div class="w-50">
                                        <p class="estadistica-label"  style="text-align: right;"><strong>Total de la Lista Nominal</strong></p>
                                        <p class="estadistica-val" style="text-align: right;"><strong>{{casilla?.c3}}</strong></p>
                                    </div>
                                </div>
                                <div class="container-interno">
                                        <div class="barra" [ngStyle]="{ 'width.': casilla?.c2}"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col">
                            <p class="px14">
                                El cálculo de Participación ciudadana puede rebasar el 100% si vota toda la ciudadanía de la Lista Nominal de una casilla; así como las Representaciones de Partidos Políticos.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <movil-estadistica-content
                [tipoEstadistica]="2"
                [nombreCasilla]="'de Casillas'"
                [infoParticipacion]="participacion_table"
                [contiendaActual]="contiendaActual"
                [listaNominal]="lista_nominal"
            ></movil-estadistica-content>
        </div>
    </div>
    <div class="row" #observaciones>
        <div class="col-md-12">
            <div class="estadistica-casilla observacionesActa card mt-3 p-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <h4 class="subtitulo-seccion pleca-izquierda">
                                <strong>Observaciones en Acta</strong>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-4">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <h4 class="subtitulo-seccion pleca-izquierda">
                                            <strong class="txRosa2">Contabilizadas</strong>
                                        </h4>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Ilegible
                                                </strong>
                                            </p>
                                            <p>
                                                La cantidad de votos en algún campo del Acta PREP no puede leerse, tanto en letra como en número.
                                            </p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Sin Dato
                                                </strong>
                                            </p>
                                            <p class="d-none d-xl-block">La cantidad de votos no fue asentada en el Acta PREP ni en letra ni en número en algún campo del Acta PREP.</p>
                                            <p class="d-block d-xl-none">La cantidad de votos no fue asentada en algún campo del Acta PREP.</p>
                                        </div>
                                        <!-- <div class="grupo py-3 mb-2">
                                            <p class="pb-2">
                                                <strong class="d-none d-xl-block">Identificación por Proceso Técnico Operativo</strong>
                                                <strong class="d-block d-xl-none">Identificación por PTO</strong>
                                            </p>
                                            <p>
                                                Los datos del Acta PREP no se identificaron y sé solicitó verificarlos por algún procedimiento válido establecido en el Proceso Técnico Operativo.
                                            </p>
                                        </div> -->
                                        <div class="d-none d-xl-block">
                                            <h4 class="subtitulo-seccion pleca-izquierda pleca-morada mt-5">
                                                <strong><span class="txMorado">No identificadas -</span> Fuera del catálogo</strong>
                                            </h4>
                                            <div class="grupo py-3">
                                                <p>
                                                    No es posible ubicar el Acta PREP dentro de la lista de Actas de casillas aprobadas por el INE, al presentar alguna omisión, ilegibilidad o error en sus datos de identificación. Para fines de publicación, el Acta se muestra al final del listado de Actas aprobadas en la  Base de Datos.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <h4 class="subtitulo-seccion pleca-izquierda pleca-aqua">
                                            <strong class="txAqua">No Contabilizadas</strong>
                                        </h4>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Excede Lista Nominal
                                                </strong>
                                            </p>
                                            <p>
                                                La suma de todos los votos en el Acta PREP, excede el número de ciudadanas y ciudadanos en la Lista Nominal de Electores de la casilla, incluido el número de votos emitidos por los representantes de los Partidos Políticos.
                                            </p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Sin Acta
                                                </strong>
                                            </p>
                                            <p class="pb-2">
                                                El Acta PREP no llegó junto con el paquete electoral al Centro de Acopio y Transmisión de Datos (CATD), por algunas de las siguientes causas:
                                            </p>
                                            <p class="">1. Sin Acta por paquete no entregado.</p>
                                            <p class="">2. Sin Acta por casilla no instalada.</p>
                                            <p class="">3. Sin Acta por paquete entregado sin bolsa.</p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Todos los campos ilegibles
                                                </strong>
                                            </p>
                                            <p>
                                                Todos los campos del Acta PREP correspondientes al número de votos, son ilegibles tanto en letra como en número.
                                            </p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Todos los campos vacíos
                                                </strong>
                                            </p>
                                            <p>
                                                Todos los campos del Acta PREP correspondientes al número de votos, se encuentran vacíos tanto en letra como en número.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-12 d-block d-xl-none">
                                        <h4 class="subtitulo-seccion pleca-izquierda pleca-morada mt-2">
                                            <strong><span class="txMorado">No identificadas -</span> Fuera del catálogo</strong>
                                        </h4>
                                        <div class="grupo py-3">
                                            <p>
                                                No es posible ubicar el Acta PREP dentro de la lista de Actas de casillas aprobadas por el INE, al presentar alguna omisión, ilegibilidad o error en sus datos de identificación. La consulta de estas Actas puede hacerse en la Base de Datos del Sistema.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
