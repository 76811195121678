<p class="p-3" style="font-size: 1.4em; text-align: justify;">Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%.</p>
<div class="container-fluid resumen-votacion d-none d-xl-block">
  <div class="row">
    <div [ngClass]="{'col-8 col-md-8 col-lg-8 col-xl-8': use_observaciones, 'col-12 col-md-12 col-lg-12 col-xl-12': !use_observaciones}" class="bgResumen py-2 pxx">
      <p class="titulo">
        <strong>Resumen de la votación</strong>
      </p>
      <div class="container-fluid">
        <div class="row dis-flex">
          <div class="apartado dis-flex flex-row" [ngClass]="{'outer-box': extranjero && !omitir_extranjero}" *ngIf="!hide_acumulados">
            <span *ngIf="extranjero && !omitir_extranjero" class="tooltip-outer"
              tooltip="Es la sumatoria de los votos obtenidos por Partido Político, {{ _obtenerDatosService.config?.estado == 'Oaxaca' ? 'Candidatura Común' : 'Coalición' }}."
              placement="top" show-delay="200">
              <i class="fas fa-info-circle" aria-hidden="true"></i>
            </span>

            <div class="dis-flex flex-col" [ngClass]="{'w-50': extranjero && !omitir_extranjero, 'w-100': !extranjero || omitir_extranjero}">
              <div class="dis-flex w-100">
                <p class="subtitulo" *ngIf="!extranjero || omitir_extranjero">
                  Votos acumulados &nbsp;
                </p>
                <p class="subtitulo" *ngIf="extranjero && !omitir_extranjero">
                  En la Entidad &nbsp;
                </p>
                <span *ngIf="!extranjero || omitir_extranjero"
                  tooltip="Es la sumatoria de los votos obtenidos por Partido Político, {{ _obtenerDatosService.config?.estado == 'Oaxaca' ? 'Candidatura Común' : 'Coalición' }}."
                  placement="top" show-delay="200">
                  <i class="fas fa-info-circle" aria-hidden="true"></i>
                </span>
              </div>
              <div class="row dis-flex w-100 space-b">
                <p class="col-12 cantidad">
                  <strong>
                    {{
                      acumulados_num
                    }}
                  </strong>
                </p>
                <p *ngIf="!hide_prc" class="col-12 porcentaje">
                  {{
                    acumulados_prc
                  }}
                </p>
              </div>
            </div>
            
            <p *ngIf="extranjero && !omitir_extranjero" _ngcontent-heg-c78="" class="separador"><strong _ngcontent-heg-c78="">+</strong></p>

            <div *ngIf="extranjero && !omitir_extranjero" class="dis-flex w-50 flex-col">
              <div class="dis-flex w-100">
                <p class="subtitulo">
                  <!--Votos acumulados-->En el extranjero &nbsp;
                </p>
                <span
                  tooltip="Es la sumatoria de los votos obtenidos por Partido Político, {{ _obtenerDatosService.config?.estado == 'Oaxaca' ? 'Candidatura Común' : 'Coalición' }}."
                  placement="top" show-delay="200">
                  <i *ngIf="!extranjero || omitir_extranjero" class="fas fa-info-circle" aria-hidden="true"></i>
                </span>
              </div>
              <div class="row dis-flex w-100 space-b">
                <p class="col-12 cantidad">
                  <strong>
                    {{
                      extranjero_num
                    }}
                  </strong>
                </p>
                <p *ngIf="!hide_prc" class="col-12 porcentaje">
                  {{
                    extranjero_prc
                  }}
                </p>
              </div>
            </div>
          </div>
          <p class="separador" *ngIf="!hide_acumulados">
            <strong>+</strong>
          </p>
          <div class="apartado dis-flex flex-col pt-1">
            <div class="dis-flex w-100">
              <p class="subtitulo pb-1">
                Candidaturas no registradas
              </p>
            </div>
            <div class="row dis-flex w-100 space-b">
              <p class="col-12 cantidad">
                <strong>
                  {{
                  noregistradas_num
                  }}
                </strong>
              </p>
              <p *ngIf="!hide_prc" class="col-12 porcentaje">
                {{
                noregistradas_prc
                }}
              </p>
            </div>
          </div>
          <p class="separador">
            <strong>+</strong>
          </p>
          <div class="apartado dis-flex flex-col pt-1">
            <div class="dis-flex w-100">
              <p class="subtitulo pb-1">
                Nulos
              </p>
            </div>
            <div class="row dis-flex w-100 space-b">
              <p class="col-12 cantidad">
                <strong>
                  {{
                  nulos_num
                  }}
                </strong>
              </p>
              <p *ngIf="!hide_prc" class="col-12 porcentaje">
                {{
                  nulos_prc
                }}
              </p>
            </div>
          </div>
          <p class="d-none d-lg-block separador pr-3">
            <strong>=</strong>
          </p>
          <div class="d-none d-lg-block apartado dis-flex flex-col">
            <div class="dis-flex w-100 justify-e">
              <p class="px20">
                <strong>Total de votos</strong>
              </p>
            </div>
            <div class="dis-flex w-100 space-b justify-e">
              <p class="total">
                <strong class="txRosaIne">
                  {{ total_num }}
                </strong>
              </p> 
            </div>
            <div *ngIf="!hide_prc" class="dis-flex w-100 justify-e">
              <p>
                <strong>
                  <span class="txRosaIne">{{ total_prc }}</span>
                </strong>
              </p>
            </div>
          </div>
        </div>
        <div class="container-fluid d-lg-none">
          <div class="row">
            <div class="col-12 dis-flex flex-col mt-3">
              <div class="dis-flex w-100 justify-e">
                <p class="px20 mb-0">
                  <strong>Total de votos</strong>
                </p>
              </div>
              <div class="dis-flex w-100 justify-e">
                <p class="total">
                  <strong>
                    = <span class="txRosaIne">
                      {{ total_num }}
                    </span>
                  </strong>
                </p>
              </div>
              <div *ngIf="!hide_prc" class="dis-flex w-100 justify-e">
                <p>
                  <strong>
                    <span class="txRosaIne">
                      {{ total_prc }}
                    </span>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4 col-md-4 col-lg-4 col-xl-4" *ngIf="use_observaciones">
		<div class="bgResumen resumen-votacion observaciones p-2">
			<p class="titulo">
				<strong>Observaciones en Acta</strong>
			</p>
			<p>
			{{ observaciones ? observaciones : '' }}
			</p>
		</div>
	</div>
  </div>
</div>

<div class="container-fluid resumen-votacion-mobile d-block d-xl-none">
	<div class="row">
		<div class="col-12">
			<h2 class="title-resumen">Resumen de la votación</h2>

			<div class="list-block" *ngIf="!extranjero">
				<p class="tipo-resumen">
					Votos acumulados &nbsp;
          <span *ngIf="!extranjero || omitir_extranjero"
            tooltip="Es la sumatoria de los votos obtenidos por Partido Político, {{ _obtenerDatosService.config?.estado == 'Oaxaca' ? 'Candidatura Común' : 'Coalición' }}."
            placement="top" show-delay="200">
            <i class="fas fa-info-circle" aria-hidden="true"></i>
          </span>
				</p>
				<div class="dis-flex flex-row justify-content-between">
					<p>{{ acumulados_num }}</p>
					<p *ngIf="!hide_prc" class="block-prc">{{ acumulados_prc }}</p>
				</div>
			</div>

			<div class="list-block" *ngIf="extranjero && !omitir_extranjero">
				<p class="tipo-resumen">
					En el extranjero &nbsp;
				</p>
				<div class="dis-flex flex-row justify-content-between">
					<p>{{ extranjero_num }}</p>
					<p *ngIf="!hide_prc" class="block-prc">{{ extranjero_prc }}</p>
				</div>
			</div>

			<div class="list-block">
				<p class="tipo-resumen">
					Candidaturas no registradas &nbsp;
				</p>
				<div class="dis-flex flex-row justify-content-between">
					<p>{{ noregistradas_num }}</p>
					<p *ngIf="!hide_prc" class="block-prc">{{ noregistradas_prc }}</p>
				</div>
			</div>

			<div class="list-block">
				<p class="tipo-resumen">
					Nulos &nbsp;
				</p>
				<div class="dis-flex flex-row justify-content-between">
					<p>{{ nulos_num }}</p>
					<p *ngIf="!hide_prc" class="block-prc">{{ nulos_prc }}</p>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-12" *ngIf="use_observaciones">
			<div class="bgResumen resumen-votacion observaciones p-2">
				<p class="titulo">
					<strong>Observaciones en Acta</strong>
				</p>
				<p (click)="scrollComponent('observaciones')">
          {{ observaciones ? observaciones : '' }}
				</p>
			</div>
		</div>
	</div>
</div>
