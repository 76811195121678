import { Component, Injectable, Input, OnInit, ɵConsole } from '@angular/core';
import { ComparativaService } from 'src/app/servicios/comparativa/comparativa.service';
import { RutasService } from '../../../servicios/rutas/rutas.service'
import { GlobalesService } from '../../../servicios/globales.service'
import { environment as env } from 'src/environments/environment';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import * as Highcharts from 'highcharts';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { ThemeService } from '../../../theme/theme.service';
declare var require: any;
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);

@Component({
  selector: 'app-estadistica-nacional',
  templateUrl: './estadistica-nacional.component.html',
  styleUrls: ['./estadistica-nacional.component.scss']
})

export class EstadisticaNacionalComponent implements OnInit {
    // Variables para pintar Grafica
    @Input() public tipo_estadistica:any = '';
    @Input() public show_rp:any = false;
    @Input() public short_tpl:any = false;
    @Input() public short_data:any = {};
    @Input() public load_main:any = false;

    config: any = {};

    public shortDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    Highcharts = Highcharts;
    chartOptions: any = null;
    datosEstadistica: any = {};
    dataGraficaInicial = {"listaNominal":{"actasContabilizadas" : "0","oficial": "0","porcentaje": "0","color": "#D2127E"}}

    /* Saacar datos url */
    diccionarioEntidadesURL = ["OFICINAS CENTRALES","AGUASCALIENTES","BAJACALIFORNIA","BAJACALIFORNIASUR","CAMPECHE","COAHUILA","COLIMA","CHIAPAS","CHIHUAHUA","CIUDADDEMEXICO","DURANGO","GUANAJUATO","GUERRERO","HIDALGO","JALISCO","MEXICO","MICHOACAN","MORELOS","NAYARIT","NUEVOLEON","OAXACA","PUEBLA","QUERETARO","QUINTANAROO","SANLUISPOTOSI","SINALOA","SONORA","TABASCO","TAMAULIPAS","TLAXCALA","VERACRUZ","YUCATAN","ZACATECAS"];
    rutaActual:any;
    noNivelesURL:any;

    nomDistrito: string;

    selectedContienda: any = '';
    datosContienda: any = {};
    casilla1_txt: any = 'Votos en Casillas Básicas,<br/> Contiguas, Extraordinarias';
    
    actasOficial: any = '';

    constructor(
        public comparativaService: ComparativaService,
        public readonly _obtenerDatosService: ObtenerDatosService,
        public _rutasService:RutasService,
        public _router:Router,
        public _globalService:GlobalesService,
        public themeService:ThemeService,
    ) {}

    ngOnInit(): void {
        if (this.short_tpl === 'true' || this.short_tpl === true) this.short_tpl = true;
        else this.short_tpl = false;

        if (this.show_rp === 'true' || this.show_rp === true) this.show_rp = true;
        else this.show_rp = false;

        /* Data de la grafica inicial para no marcar error en consola */
        this.pintaGrafica(this.dataGraficaInicial)
        /* Iniciamos la funcion para checar que grafica pintara */
        // this.checkDataGrafica();
        /* Observable cambio de URL*/
        // this._rutasService.observ.subscribe( texto =>{
        //     this.checkDataGrafica();
        // });

        this._globalService.shortData.subscribe(data => {
            if(data){
                console.log("dataShort\n", data)
                var porc = data.trow?.porcentaje ? data.trow?.porcentaje.replace("%", '').replace(" ",'') : '0';
                var dataGraf = {estadistica_participacion: {
                    lista_nominal: {
                        contabilizadas: data.trow?.total ? data.trow?.total : 0,
                        porcentaje: porc ? parseFloat(porc) : 0,
                        total: (data.trow?.lista_nominal ? data.trow?.lista_nominal : 0),
                    }
                }};
                //console.log("dataShort\n", dataGraf)
                this.pintaGrafica(dataGraf)
            }
        });

        this._obtenerDatosService.mainSubject.subscribe(data =>{
          if (data != null) {
            this.selectedContienda = this._globalService.getContienda();
            this.datosContienda = data[this.selectedContienda];

            if (this.selectedContienda == 'gubernatura') this.casilla1_txt = 'Votos en Casillas Básicas, Contiguas,<br/> Extraordinarias y Mesas de Escrutinio'

            if (this.load_main) this.loadData();
          }
        });

        this._obtenerDatosService.configSubject.subscribe(data =>{
            if (data != null) this.config = data;
          });

    }

    loadData(){
      this._obtenerDatosService.loadJSON(this._globalService.getContienda(), 'entidad.estadistica.json')
        .then( res =>{
            // this._obtenerDatosService.dataEstadisticaNacional = res;
            this.datosEstadistica = res;
            this.pintaGrafica(this.datosEstadistica);

//            this.comparativaService.votos_total = this.datosEstadistica?.estadistica_participacion?.casillas?.basica_contigua_extraordinaria;
        })
    }

    checkDataGrafica(){
        // this.nombrarDistrito();
        this.rutaActual = this._router.url;
        /* mayusculas a minusculas */
        this.rutaActual = this.rutaActual.toLowerCase();
        /* Niveles de la url */
        this.noNivelesURL = this.rutaActual.split('/').length;

        let noCircunscripcion;
        let entidad;
        let noEntidad;
        let distritoCompleto;
        let noDistrito;

        let separaUrl = this.rutaActual.split("/");

        if( this.noNivelesURL === 3){
            /* Raiz Nacional */
            if(this._obtenerDatosService.dataEstadisticaNacional === undefined){
                this._obtenerDatosService.getInfoJson(env.urls.urlBaseNacionalGets+env.urls.urlEstadisticaNacional)
                .subscribe( res =>{
                    this._obtenerDatosService.dataEstadisticaNacional = res;
                    this.datosEstadistica = res;
                    this.pintaGrafica(this.datosEstadistica);
                })
            }else{
                this.datosEstadistica = this._obtenerDatosService.dataEstadisticaNacional;
                this.pintaGrafica(this.datosEstadistica );
            }
        }else{
            if(this.noNivelesURL === 4){
                /* Sacar circunscripcio url */
                noCircunscripcion = separaUrl[1]

                /* Nivel Ciscunscripcio */
                if(this._obtenerDatosService.dataEstadisticaCiscunscripcion === undefined){
                    this._obtenerDatosService.getInfoJson(env.urls.urlBaseCircunsipcionGets+env.urls.urlEstadisticaCircunscripcion)
                    .subscribe( res =>{
                        this._obtenerDatosService.dataEstadisticaCiscunscripcion = res;
                        this.datosEstadistica= this._obtenerDatosService.dataEstadisticaCiscunscripcion[noCircunscripcion];
                        this.pintaGrafica(this.datosEstadistica );
                    })

                }else{
                    this.datosEstadistica = this._obtenerDatosService.dataEstadisticaCiscunscripcion[noCircunscripcion];
                    this.pintaGrafica(this.datosEstadistica);
                }
            }
            else if(this.noNivelesURL === 5){
                if( this.rutaActual.indexOf('mapa') !== -1 || this.rutaActual.indexOf('grafica') !== -1 || this.rutaActual.indexOf('tarjetas') !== -1) {
                    /* Sacar Entidad URL */
                    entidad = (separaUrl[2].toUpperCase()).replace(/-/g,"");
                    if(this.diccionarioEntidadesURL.indexOf(entidad) !== -1){
                        noEntidad = this.diccionarioEntidadesURL.indexOf(entidad)

                    }
                    /* Nivel Entidad */
                    if(this._obtenerDatosService.dataEstadisticaEntidad === undefined){
                        this._obtenerDatosService.getInfoJson(env.urls.urlBaseEntidadGets+env.urls.urlEstadisticaEntidad)
                        .subscribe(res =>{
                            this._obtenerDatosService.dataEstadisticaEntidad = res;
                            this.datosEstadistica= this._obtenerDatosService.dataEstadisticaEntidad['entidad'+noEntidad];
                            this.pintaGrafica(this.datosEstadistica );
                        })
                    }else{
                        this.datosEstadistica= this._obtenerDatosService.dataEstadisticaEntidad['entidad'+noEntidad];
                        this.pintaGrafica(this.datosEstadistica );
                    }

                }else if(this.rutaActual.indexOf('representacion-proporcional') !== -1){
                    /* Representación proporcional entidad */
                }else{
                    /* Nivel Distrito */
                    /* sacar Distrito url */
                    distritoCompleto = separaUrl[3];
                    noDistrito = (distritoCompleto.split('distrito')[1]).split("-")[0];

                    if(this._obtenerDatosService.dataEstadisticaDistrito === undefined){
                        this._obtenerDatosService.getInfoJson(env.urls.urlBaseDistritoGets+env.urls.urlEstadisticaDistrito)
                        .subscribe(res =>{
                            this._obtenerDatosService.dataEstadisticaDistrito = res;
                            this.datosEstadistica= this._obtenerDatosService.dataEstadisticaDistrito[distritoCompleto];
                            if(this.datosEstadistica !== undefined) this.pintaGrafica(this.datosEstadistica );
                            else this.pintaGrafica(this.dataGraficaInicial);
                        })
                    }else{
                        this.datosEstadistica = this._obtenerDatosService.dataEstadisticaDistrito[distritoCompleto];

                        if(this.datosEstadistica !== undefined) this.pintaGrafica(this.datosEstadistica );
                        else this.pintaGrafica(this.dataGraficaInicial);
                    }



                }

            }
        }
    }

    checkDataGraficaDistritos(){
        let separaUrl = this.rutaActual.split("/");
        let distrito = (separaUrl[3].toUpperCase()).replaceAll('.', '').replaceAll('-','') ;

        if(this._obtenerDatosService.diccionarioDistritos[distrito] !== undefined){
            let noDistrito = this._obtenerDatosService.diccionarioDistritos[distrito];
            if(this._obtenerDatosService.dataEstadisticaDistrito === undefined){
                this._obtenerDatosService.getInfoJson(env.urls.urlBaseDistritoGets+env.urls.urlEstadisticaDistrito)
                .subscribe(res =>{
                    this._obtenerDatosService.dataEstadisticaDistrito = res;
                    this.datosEstadistica= this._obtenerDatosService.dataEstadisticaDistrito['distrito'+noDistrito];
                    this.pintaGrafica(this.datosEstadistica );
                })
            }else{
                this.datosEstadistica= this._obtenerDatosService.dataEstadisticaDistrito['distrito'+noDistrito];
                this.pintaGrafica(this.datosEstadistica );
            }
        }
    }

    nombrarDistrito() {
        if(this._globalService.rutaParametros.nivelPagina === 'Distrito') {
            if (!this._rutasService.migas.distrito) {
                this._rutasService.migas.distrito = `Distrito ${this._globalService.rutaParametros.noDistrito}.${this._globalService.rutaParametros.distritoTexto}`;
            }

            this.nomDistrito = this._rutasService.migas.distrito;
            this.nomDistrito = this.nomDistrito.split('Distrito ')[1];
        }
    }

    pintaGrafica(datosGrafica){
        var actasContabilizadas = datosGrafica.estadistica_participacion?.lista_nominal?.contabilizadas;
        var actasOficial = datosGrafica.estadistica_participacion?.lista_nominal?.total;
        this.actasOficial = actasOficial;
        // var actasPorcentaje = parseInt(datosGrafica.estadisticaVotosDistritos?.listaNominal?.porcentaje);
        var graficaColor = '#D2127E'; // datosGrafica.estadisticaVotosDistritos?.listaNominal?.color;

        // var monto = actasContabilizadas? parseInt(actasContabilizadas.replace(/,/g, '')) : 0;
        // var total = actasOficial? parseInt(actasOficial.replace(/,/g, '')) : 0;

        var actasPorcentaje =  datosGrafica.estadistica_participacion?.lista_nominal?.porcentaje ? datosGrafica.estadistica_participacion?.lista_nominal?.porcentaje : 0;

        this.chartOptions = {
            chart: {
                type: 'solidgauge'
            },
            title: {
                text: actasOficial,
                y: 235,
                style: {
                    fontSize: '21px',
                    color: graficaColor,
                    fontWeight: 'bold',
                    fontFamily: 'Roboto,sans-serif!important',
                }
            },
            subtitle:{
                text:'Lista Nominal de <br> casillas aprobadas',
                y: 200,
                style: {
                    fontSize: '13px',
                    color: '#707070',
                    fontWeight: 'bold',
                    fontFamily: 'Roboto,sans-serif!important',
                }
            },
            pane: {
                center: ['50%', '85%'],
                size: '110%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor || '#E3E3E3',
                    innerRadius: '85%',
                    outerRadius: '115%',
                    shape: 'arc'
                }
            },
            exporting: {
                enabled: false
            },
            tooltip: {
                enabled: false
            },
            yAxis: [{
                visible:false,
                min: 0,
                max: 100,
                lineWidth: 0,
                tickWidth: 0,
                minorTickInterval: null,
                tickAmount: 2,
                title: {
                    y: -70
                },
                labels: {
                    y: 16
                },
            }],
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true
                    }
                },
                series:{
                    animation: false
                }
            },
            series: [{
                name: 'Speed',
                data: [{
                    color: '#D1308A',
                    radius: '110%',
                    innerRadius: '90%',
                    y: actasPorcentaje,

                }],
                dataLabels: {
                    format:
                        '<div style="text-align:right; position:absolute; top: -230px;left: 25px">' +
                        '<span class="font-bold px12" style="color: var(--txt-title-barra)">Lista Nominal de <br>Actas Contabilizadas</span><br> <span class="font-medium px18" style="color:var(--bg-rosa-ine)">'+ actasContabilizadas+'</span>'+
                        '</div>'
                }
            }],
            credits: {
                enabled: false
            }
        }

    }
}
