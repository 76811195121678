// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  simulacro: '',
  urls: {
      urlCombos: './assets/data1/rutas/rutas.json',
      urlCombosEntidad: './assets/data1/rutas/entidades.json',
      urlBase: './assets/data1',
      urlBaseNacionalGets: "./assets/data1/",
      urlBaseCircunsipcionGets:'./assets/data1/circunscripcion/',
      urlBaseEntidadGets:'./assets/data1/circunscripcion/entidad/',
      urlBaseDistritoGets:'./assets/data1/circunscripcion/entidad/distrito/',
      urlBaseDiccionariosGets:'./assets/data1/diccionarios/',
      urlAvanceNacional:'avanceNacional.json',
      urlDVotosDistrito :'votosDistrito.json',
      urlDPartidosPoliticos :'votosPartidosPoliticos.json',
      urlDiputacionNacioal:'diputacionesNacional.json',
      urlEstadisticaNacional:"estadisticaNacional.json",
      urlDCircunscripciones:'circunscripciones.json',
      urlEstadisticaCircunscripcion:'estadisticaCircunscripciones.json',
      urlDEntidades:'entidad1.json',
      urlEstadisticaEntidad:'estadisticaEntidades.json',
      urlEstadisticaDistrito:'estadisticaDistritos.json',
      urlNacional: './assets/data1/diputacionesNacional.json',
      urlResumen: './assets/data/resumen-distrito.json',
      urlMapa: './assets/data/resumen-mapa.json',
      urlCandidaturasDistrito: './assets/data/resumen-candidaturas-distrito.json',
      urlCasilla: './assets/data/resumen-casillas.json',
      urlDiccionarioPartidos:'diccionarioPartidos.json',
      urlDiccionarioDistritos:'diccionarioDistritos.json',
      urlMapaDistritos:'./assets/mapas/distritos/distritos.geojson',
      urlDataMapaDistritos:'./assets/data1/mapas/circunscripcion/entidad/distrito/dataMapaDistritos.json',
      urlMapaCircunscripcion:'./assets/mapas/circunscripciones/',
      urlDataMapaCircunscripcion:'./assets/data1/mapas/circunscripcion/dataMapaCircunscripciones.json',
      urlMapaEntidad: './assets/mapas/entidades/',
      urlDataMapaEntidad:'./assets/data1/mapas/circunscripcion/entidad/dataMapaEntidades.json',
      urlDataCircunscripcioByEntidad:'./assets/data1/resultadosCasilla/circunscripcionByentidad.json',
      urlDataDistritoBySeccion:'./assets/data1/resultadosCasilla/distritoByseccion.json',

      urlDifusores: '.',
      urlAssets: '/assets',
      urlJSON: '/json/',
      urlEntregables: '/entregables/',
      urlMapas: '/mapas/',
  }
};

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
