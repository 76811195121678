import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringtoint'
})
export class StringtointPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    
    if(value !== undefined && value != null){
      var cadena;

        if(value == ''){
            cadena = "0"
        }else{
            if(value.indexOf(",") != -1 ){
                cadena = value.replace(/,/g, '') ;
            }else{
                cadena = value
            }
        }
  
        var int = parseInt(cadena)
        return int;
      }
  }

}
