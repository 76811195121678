import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


// Módulos
import { AppRoutingModule } from './app-routing.module';

// Componentes
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { ContiendasModule } from './contiendas/contiendas.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FormsModule } from '@angular/forms';
import { ImportingModule } from './importing.module';

// Módulos
// import { VotosDistritoModule } from './diputacion/votos-distrito/votos-distrito.module';
// import { GubernaturaCandidaturaModule } from './gubernatura/gubernatura-candidatura.module';
// import { GubernaturaModule } from './gubernatura/gubernatura.module';
import { TooltipModule } from 'ng2-tooltip-directive';
import { WildcardRoutingModule } from './servicios/wildcard/wildcard-routing.module';
import { GoogleAnalyticsService } from './servicios/googleanalytics/googleanalytics.service';
import { RuntimeConfigLoaderModule } from 'runtime-config-loader';

// Tooltip


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    CommonModule,
    AppRoutingModule,
    // NgbModule,
    FormsModule,
    TooltipModule,
    // ThemeModule,
    ImportingModule,

    AuthModule,
    ContiendasModule,
    WildcardRoutingModule,
    RuntimeConfigLoaderModule.forRoot(
      { configUrl: '../assets/config.json' }
    ),
  ],

  providers: [],
  exports: [ImportingModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
