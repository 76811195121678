<div id="consulta-votacion" class="anchor"></div>
<div class="container-fluid bgWhite p-4 p-lg-5" >
    <div class="row">
        <div class="col-12 col-lg-10 p-0 p-lg-5">
            <h2 class="font-medium pb-4">- Consulta de la votación -</h2>
            <div class="container">
                <div class="row">
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cómo puedo buscar los resultados en el PREP?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Los reportes de resultados puedes consultarlos a través de los combos principales: Votos por Municipios y Votos por Partido Político. Asímismo tendrás la opción de detallar tu búsqueda a través de las opciones: Municipio y Sección. También podrás consultar los resultados de tu casilla, indicando tu Sección y Clave de elector.' | highlightSearch: Search">
                        </p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Por qué algunas veces las opciones de búsqueda están deshabilitadas?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Sólo se activarán estas opciones cuando hayas seleccionado un nivel más alto, cuando lo hagas te permitirá escoger la información.' | highlightSearch: Search">
                        </p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué resultados se muestran en Detalle por Entidad?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Se muestran: Total de votos, Porcentaje y avance de votación por hora por Partido Político, ' + (_obtenerDatosService.config?.estado == 'Yucatán' ? 'Candidaturas Comunes' : 'Coaliciones') +', Candidaturas no registradas y votos nulos de las secciones que conforman la Entidad elegida. Puedes seleccionar desde uno
                        a tres actores políticos para visualizar su detalle y cambiar tu elección, agregando o eliminando opciones.' | highlightSearch: Search">
                        </p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué resultados se muestran en Detalle por Municipio?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Se muestra la distribución de votos por candidatura a nivel Municipio, indicando el porcentaje, total de votos y avance de votación por hora. Puedes agregar hasta tres opciones para ver el detalle, así como eliminar las opciones elegidas y agregar otras.' | highlightSearch: Search">
                        </p>
                    </div>
                    <!-- <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué resultados se muestran en Detalle por Sección?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Se muestra el Total de votos y porcentaje por Partido Político, Candidatura Independiente, ' + (_obtenerDatosService.config?.estado == 'Yucatán' ? 'Candidatura Común' : 'Coalición') + ', Candidatura no registrada y votos nulos de todas las secciones. Asimismo se puede elegir una sección en específico, donde se indican el tipo de casilla,
                        la imagen del Acta digitalizada, los votos por actor político, votos nulos, el total y observaciones. También podrás observar el origen del Acta: Acta en proceso, Escáner, Dispositivo móvil y Urna electrónica.' | highlightSearch: Search">
                        </p>
                    </div> -->
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué resultados se muestran en Detalle por Casilla?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Puedes ver el resultado de votación en todas las casillas o por tipo de casilla. Se mostrará el total de votos por Partido Político, ' + (_obtenerDatosService.config?.estado == 'Oaxaca' ? 'Candidatura Común' : 'Coalición') +', Candidatura no registrada y votos nulos. Tienes la opción de visualizar el Acta
                        y su origen.' | highlightSearch: Search">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>