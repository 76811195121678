import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';

@Component({
  selector: 'app-distribucion-votos2',
  templateUrl: './distribucion-votos2.component.html',
  styleUrls: ['./distribucion-votos2.component.scss']
})
export class DistribucionVotos2Component implements OnInit {
  @Input() public contienda:string = '';

  base: string;
  labels: any = {};
  addS = ''

  constructor(
    public _globalService: GlobalesService,
    public activatedRoute: ActivatedRoute,
    public readonly rutas: RutasService,
    public _obtenerDatosService: ObtenerDatosService
  ) { }

  ngOnInit(): void {
    this._globalService.setearParametros();
    this.checkInicial();
    this.base = this.rutas.rutaActual.base;

    this.activatedRoute.data.subscribe(v => {
      this.labels.contienda = v.contienda || '[-]';
      this.labels.contienda_s = v.contienda_s || '[-]';

      let selectedContienda = this._globalService.getContienda();
      this.addS = ((selectedContienda == 'diputaciones') && `${v.contienda}` == 'Distritos' ) ? '' : 's'
      //console.log('CONTIENDA: ',v.contienda, '  , ', v.contienda_s, '   , ',selectedContienda)
    });
  }

  checkInicial() {
    this.obtenerParametro()
        .then(() => {

        });
  }

  isInt(x) {
    if (parseInt(x) || parseInt(x) == 0) return true;
    else return false;
  }

  obtenerParametro() {
    return new Promise((res, rej) => {
      this.activatedRoute.params.subscribe((param) => {
        this._globalService.detalles = param;
        res();
      });
    })
  }



}
