<!-- <h1>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Autem illum repudiandae numquam quis error asperiores dicta repellat expedita incidunt commodi beatae sint voluptatibus id, aliquid praesentium distinctio, eligendi, maxime assumenda. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis consectetur adipisci porro expedita natus facere, repellendus ullam laudantium aspernatur laborum doloribus, vel recusandae rerum, esse quas maxime inventore praesentium repellat.</h1> -->
<app-boton-compartir></app-boton-compartir>
<app-encabezado id="app-encabezado-anchor"></app-encabezado>
<div class="cuerpo container-fluid {{config?.simulacro ? 'simulacro' : ''}}">
    <div class="row">
        <div *ngIf="!this.globalService.hideAvance" class="col-12 my-3 d-xl-none">
            <app-avance-nacional></app-avance-nacional>
        </div>
        <div class="px-0 bgMorado" [ngClass]="globalService.menuLateral ? 'col-md-12 col-xl-2' : 'w-5'">
            <app-menu-lateral></app-menu-lateral>
        </div>
        <div class="px-0 my-3 my-xl-0" [ngClass]="{'col-md-12 col-xl-10': globalService.menuLateral, 'w-95': !globalService.menuLateral, 'darkMode': themeService.isDarkTheme()}">
            <div class="container-fluid py-xl-3">
                <div *ngIf="!this.globalService.hideAvance" class="row">
                    <div class="col-xl-8 d-none d-xl-block altoAvance">
                        <app-avance-nacional></app-avance-nacional>
                    </div>
                    <div class="col-xl-4 d-none d-xl-block altoAvance pl-0">
                        <app-buscador></app-buscador>
                    </div>
                </div>
            </div>
            <router-outlet></router-outlet>
            <app-pre-pie-pagina></app-pre-pie-pagina>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 px-0">
            <app-pie-pagina></app-pie-pagina>
        </div>
    </div>
</div>
<!-- Modal Participacion -->
<div class="modal fade modal-paritcipacion" id="modalParticipacion" tabindex="-1" role="dialog" aria-labelledby="ModalParticipacion" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <div class="modal-title font-medium px16" id="exampleModalLabel">
            Conoce cómo calcular el porcentaje de Participación ciudadana
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="fas fa-times-circle"></i>
            </button>
        </div>
        <div class="modal-body px16 font-regular">
            <p class="padding-b-15">
                La Participación ciudadana es el porcentaje de ciudadanos que votaron, con base en la Lista Nominal de las Actas Contabilizadas.
            </p>
            <p class="padding-b-15">
                Por ejemplo, si en una casilla votan 500 electores y el número de personas inscritas en la Lista Nominal para esa casilla es de 750 ciudadanos, entonces:
            </p>
            <p class="padding-b-15">
                Multiplicamos 500 por 100 y el resultado lo dividimos entre 750.
            </p>
            <p class="padding-b-15" *ngIf="!config?.hide_especiales">
                La votación emitida en las Casillas Especiales se tomará en cuenta para el cálculo de la Participación ciudadana, hasta el último corte de información que se publique en el PREP.
            </p>
        </div>
        </div>
    </div>
</div>

<!-- Modal Casilla -->
<div class="modal fade modal-casilla" id="modalCasilla" tabindex="-1" role="dialog" aria-labelledby="ModalCasilla" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <div class="modal-title font-regular px16" id="exampleModalLabel">
                Identifica la <span class="font-bold">Sección</span> y <span class="font-bold">Primer apellido</span> de acuerdo con tu credencial.
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="fas fa-times-circle"></i>
            </button>
        </div>
        <div class="modal-body px16 font-regular">
           <div class="row">
            <div class="col-6">
                <img class="img-credencial" src="./assets/img/resultadoscasilla/Credencial-A.svg" alt="">
            </div>
            <div class="col-6">
                <img class="img-credencial" src="./assets/img/resultadoscasilla/Credencial-B.svg" alt="">
            </div>
           </div>
           <div class="row">
            <div class="col-6">
                <img class="img-credencial" src="./assets/img/resultadoscasilla/Credencial-C.svg" alt="">
            </div>
            <div class="col-6">
                <img class="img-credencial" src="./assets/img/resultadoscasilla/Credencial-D.svg" alt="">
            </div>
           </div>
        </div>
        </div>
    </div>
</div>

<!-- Modal Comparativa -->
<app-comparativa></app-comparativa>
