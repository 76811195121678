import { Component, OnInit } from '@angular/core';
import { GlobalesService } from 'src/app/servicios/globales.service';

@Component({
  selector: 'app-pregunta-ver-detalle',
  templateUrl: './pregunta-ver-detalle.component.html',
  styleUrls: ['./pregunta-ver-detalle.component.scss']
})
export class PreguntaVerDetalleComponent implements OnInit {

  Search = "";
  constructor(public _globalService: GlobalesService) { }

  ngOnInit(): void {
    this._globalService.searchItem.subscribe(data =>{
      if(data == null) return;
      this.Search = data
    });
  }

}
