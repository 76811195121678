<div id="avance-nacional" class="anchor"></div>
<div class="container-fluid bgAzul txBlanco p-4 p-lg-5">
    <div class="row">
        <div class="col-12 col-lg-10 p-0 p-lg-5">
            <h2 class="font-medium pb-4">- Avance Entidad -</h2>
            <div class="container">
                <div class="row">
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué muestra el Avance Entidad?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Muestra el porcentaje de la Participación ciudadana que hasta el momento ha votado; el número y porcentaje de Actas capturadas al momento y la hora del último corte, éste se actualiza aproximadamente cada 15 minutos.' | highlightSearch: Search">
                        </p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué indica el porcentaje de las Actas capturadas?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Indica el porcentaje de Actas que hasta el momento se han ingresado al PREP.' | highlightSearch: Search">
                        </p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué indica el Último corte y cada cuando se realiza?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Indica el corte de información que realiza el PREP, éste se actualiza aproximadamente cada 15 minutos.' | highlightSearch: Search">
                        </p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Qué indica el porcentaje de la Participación ciudadana?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Indica los votos que hasta el momento se han registrado con base en la Lista Nominal de personas con derecho a votar.' | highlightSearch: Search">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
