import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from 'src/environments/environment';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ComparativaService } from 'src/app/servicios/comparativa/comparativa.service';

// import * as jQuery from 'jquery';
declare var $;

/* HighCharts */
import { Chart, MapChart} from 'angular-highcharts';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';
import { ThemeService } from 'src/app/theme/theme.service';
import { ConvertirMapaService } from '../../../../../servicios/convertir-mapa.service';

@Component({
  selector: 'app-resumen-distrito-mapa',
  templateUrl: './resumen-distrito-mapa.component.html',
  styleUrls: ['./resumen-distrito-mapa.component.scss']
})
export class ResumenDistritoMapaComponent implements OnInit {
    tipo_contienda: any = '';
    mapa_table: any = [];
    data_mapa: any = [];
    resumen_table: any = [];
    resumen_votacion: any = [];
    contiendas_total: number = 0;
    
    contenido: any = [];
    votosActas: any = [];
    resumenVotacion: any = [];

    labels: any = {};
    resumen: any = {};
    fichas: any = [];
    disableTooltip: boolean = false;

    /* Variables Mapa */
    public mapa: MapChart;
    public mapa_movil: MapChart;
    public mapa_modal: MapChart;
    
    mapSelection: any = '';

    constructor(
        private http: HttpClient,
        public _convertirMapaService:ConvertirMapaService,
        public _obtenerDatosService: ObtenerDatosService,
        public _router:Router,
        public comparativaService: ComparativaService,
        public _globalService: GlobalesService,
        public rutasService: RutasService,
        public themeService: ThemeService,
        private route: ActivatedRoute
    ) {}
    
    ngOnInit(): void {
        this._globalService.bottomSheetContex = this;
        this._globalService.bottomSheetCallback = this.agregarElemento;
        
        this.route.data.subscribe(v => {
            this.labels.contienda = v.contienda || '[-]';
            switch(this.labels.contienda){
                case 'Distritos':{
                    this.labels.label_map = 'Distritos Electorales';
                    break;
                }
                case 'Juntas':{
                    this.labels.label_map = 'las Juntas';
                    break;
                }
                default:{
                    this.labels.label_map = this.labels.contienda;
                }
            }
        });

        this._obtenerDatosService.mainSubject.subscribe(data =>{
            if(data == null) return;

            /*Pintar mapa con la carga de datos*/
            this.loadData();
        });
    }
    // entidad.distritos.json
    reiniciar() {
        this.rutasService.reset();
        this._globalService.menuActual = 'Votos';
        this._globalService.idCircuns = null;
        this.rutasService.limpiarSeccion();
    }

    loadData() {
        let selectedContienda = this._globalService.getContienda();
        let json_file = '';
        if(selectedContienda == 'ayuntamientos') json_file = 'entidad.municipios.json';
        else if(selectedContienda == 'juntas') json_file = 'entidad.juntas.json';
        else if(selectedContienda == 'diputaciones') json_file = 'entidad.distritos.json';

        this._obtenerDatosService.loadJSON(selectedContienda, json_file).then(data => {
            this._globalService.bottomSheetTpl = 'comparador-contienda';
            this.comparativaService.stageStatus = false;
            this.comparativaService.pantalla = 'resumenDistrito';
            this.comparativaService.resetear();

            this.tipo_contienda = selectedContienda;
            this.data_mapa = data['mapa'].distritos? data['mapa'].distritos : data['mapa'].municipios;
            this.mapa_table = data['mapa'].contiendas;
            this.fichas = data['fichas'];
            this.resumen_table = data['resumen_table']? data['resumen_table'].columns : data['tabla'].columns;
            this.resumen = data['resumen'];
//            this.contiendas_total = data['mapa'].distritos? data['mapa'].distritos.length : data['mapa'].municipios.length;
            this.contiendas_total = data['mapa'].total;
            this.pintarMapa();
            // console.log(data['mapa']);

            this.comparativaService.votos_total = this.resumen['total'].total;
            this.comparativaService.votos_pctj = this.resumen['total'].prc;
            
            this.resumen_table.forEach(item => {
                item.data.pctj = item.data.r2.replace(/%/g, '');
            });

            console.log(this._obtenerDatosService?.config?.estado);

            this.resumen_votacion = {
                votos_acumulados : { votos: 0, pctj: 0 },
                votos_no_registrados : { votos: 0, pctj: 0 },
                votos_nulos : { votos: 0, pctj: 0 },
                votos_total : { votos: 0, pctj: 0 },
            };

            this.resumen_table.forEach(item => {
                if(item.header.text == 'Candidaturas no registradas'){
                    this.resumen_votacion.votos_no_registrados.votos += parseInt(item.data.r1.replace(/,/g, ''));
                }else if(item.header.text == 'Votos nulos'){
                    this.resumen_votacion.votos_nulos.votos += parseInt(item.data.r1.replace(/,/g, ''));
                }else if(item.header.text == 'Total'){
                    this.resumen_votacion.votos_total.votos += parseInt(item.data.r1.replace(/,/g, ''));
                }else{
                    this.resumen_votacion.votos_acumulados.votos += parseInt(item.data.r1.replace(/,/g, ''));
                }
            });
        });
      // }
    }

    pintarMapa(){ 
        var key = 'JUNTA';
        if(this.tipo_contienda != 'juntas'){
            key = (this.tipo_contienda == 'diputaciones'? 'DISTRITO_L' : 'MUNICIPIO');
        }
        var format = '';
        var _this = this;
        var map_file = 'juntas.geojson';
        if(this.tipo_contienda != 'juntas'){
            map_file = (this.tipo_contienda == 'diputaciones')? 'distritos.geojson' : 'municipio.geojson';
        }
        this._obtenerDatosService.loadMapa(map_file).then(data => {
            if(data){
                //Mapa en escritorio
                this.mapa = new MapChart({
                    title: {
                        text: ' '
                    },
                    chart: {
                        map: data as any,
                        backgroundColor: '#FFFFFF'
                    },
                    credits: {
                        enabled: false
                    },
                    exporting: {
                        enabled: false
                    },
                    legend: {
                        enabled: false
                    },
                    mapNavigation: {
                        enabled: true,
                        enableButtons: true,
                        enableMouseWheelZoom: true,
                        buttonOptions: {
                            align: 'right',
                            verticalAlign: 'top'
                        }
                    },
                    plotOptions: {
                        map: {
                            animation: false,
                            tooltip: {},
                            cursor: 'pointer',
                            states: {
                                hover: {
//                                    enabled: false,
                                    borderWidth: 2,
                                    borderColor: '#000000',
//                                    color: undefined
                                }
                            }
                        },
                    },
                    series: [{
                        type: undefined,
                        data: this.data_mapa,
                        keys: [key, 'value'],
                        joinBy: key,
                        point: {
                            events: {
                                mouseOver: function(){
                                    var series = this.series;
                                    for (var i=0; i < series.data.length; i++){//Oscurecer
                                        if(this.properties[key] != series.data[i][key]){
                                            var color;
                                            if(_this.data_mapa[i].color){
                                                if(_this.data_mapa[i].color.toLowerCase() == '#f7f7f7' || _this.data_mapa[i].color.toLowerCase() == '#ffffff' || _this.data_mapa[i].color.toLowerCase() == '#fff' || _this.data_mapa[i].color.toLowerCase() == 'white' ){
                                                    color = '#e8e8e8';
                                                }else{
                                                    color = _this.data_mapa[i].color+'80';
                                                }
                                            }else{
                                                color = '#e8e8e8';
                                            }
                                            
                                            series.data[i].color = color;
                                            
                                            if(series.data[i]['graphic']){
                                                series.data[i]['graphic'].element.setAttribute("fill", color);
                                            }
                                            
                                        }else{//Pintar color correspondiente
                                            series.data[i].color = _this.data_mapa[i].color;
                                            series.data[i]['graphic'].element.setAttribute("fill",  _this.data_mapa[i].color);
                                        }
                                    }
                                },
                                mouseOut: function(){
                                    var series = this.series;
                                    for (var i=0; i < series.data.length; i++){
                                        if(series.data[i]['graphic']){
                                            if(_this.data_mapa[i].color){
                                                series.data[i].color = _this.data_mapa[i].color;
                                                series.data[i]['graphic'].element.setAttribute("fill",  _this.data_mapa[i].color);
                                            }else{
                                                series.data[i].color = '#f7f7f7';
                                                series.data[i]['graphic'].element.setAttribute("fill",  '#f7f7f7');
                                            }
                                                
                                        }
                                    }
                                }
                            }
                        }
                    }],
                    tooltip: {
                        useHTML: true,
                        padding: 0,
                        borderRadius: 8,
                        shadow:null,
                        backgroundColor: '#EEEEEE',
                        borderColor: "#EEEEEE",
                        headerFormat: '',
                        followPointer: false,
//                        pointFormat: format,
                        formatter: function() {
                            var format = 
                            '<div style="background-color: '+this.point.options.color+'; min-width:300px; box-shadow: 0px 10px 20px -5px rgba(0,0,0,0.75); border-radius: 5px;">' +
                                '<div style="margin-left: 16px;">' +
                                    '<div style="background-color: #ffffff; padding: 10px;">' +
                                        '<img class="img-partido-grande" src="./assets/img/logos-partidos/'+this.point.options['img_partido']+'">' +
                                    '</div>' +
                                    '<div style="background-color: #e6e6e6; padding: 10px;">' +
                                    '<label style="margin: 0px; font-size: 11px; font-weight: 500;">';
                            
                            if(_this.tipo_contienda == 'juntas'){
                                format += 'Junta'
                            }else{
                                format += (_this.tipo_contienda == 'diputaciones')? 'Distrito' : 'Municipio';
                            }
                            
                            format += '</label> <p style="font-size: 13px; font-weight: 600; margin-bottom: 0px;">'+this.point.properties.NOMBRE+' </p>';
                            if(this.point.properties.ENLACE){
                                format += '<p style="text-align: right;"> <a href="';
                                if(_this.tipo_contienda == 'juntas'){
                                    format += '<p style="text-align: right;"> <a href="juntas/';
                                }else{
                                    format += (_this.tipo_contienda == 'diputaciones')? '<p style="text-align: right;"> <a href="diputaciones/' : '<p style="text-align: right;"> <a href="ayuntamientos/';
                                }
                                format += this.point.properties.ENLACE+'/votos-candidatura" style="font-size: 11px; font-weight: 400;color: #D5137F; text-decoration-line: underline;">'
                                
                                if(_this.tipo_contienda == 'juntas'){
                                    format += 'Ver detalle de la junta municipal'
                                }else{
                                    format += (_this.tipo_contienda == 'diputaciones')? 'Ver detalle del Distrito' : 'Ver detalle por municipio';
                                }
                                format += '</a></p>'
                            }
                            format += '</div> </div> </div>';
                            return format;
                        },
                        style: {
                            pointerEvents: 'auto'
                        } ,
                    }
                });
                
                //Mapa en la vista movil
                this.mapa_movil = new MapChart({
                    title: { text: '' },
                    chart: {
                        map: data as any,
                        height: 350,
                        width: 330,
                        backgroundColor: '#FFFFFF',
                        pinchType: 'xy',
                    },
                    credits: {
                        enabled: false
                    },
                    exporting: {
                        enabled: false
                    },
                    legend: {
                        enabled: false
                    },
                    mapNavigation: {
                        enabled: false
                    },
                    plotOptions: {
                        map: {
                            animation: false,
                            tooltip: {},
                            cursor: 'pointer'
                        },
                    },
                    series: [{
                        type: undefined,
                        data: this.data_mapa,
                        keys: [key, 'value'],
                        joinBy: key,
                    }],
                    tooltip: {
                        enabled: false,
                    }
                });
                
                //Mapa dentro del modal en la vista movíl
                this.mapa_modal = new MapChart({
                    title: { text: '' },
                    chart: {
                        map: data as any,
                        height: 350,
                        width: 330,
                        backgroundColor: '#FFFFFF',
                        events: {
                            render: function(){
    //                            this.resetZoomButton.hide();
                            },
                        },
                        pinchType: 'xy',
                    },
                    credits: {
                        enabled: false
                    },
                    exporting: {
                        enabled: false
                    },
                    legend: {
                        enabled: false
                    },
                    mapNavigation: {
                        enabled: true,
                        enableButtons: false,
                        enableTouchZoom: true,
                    },
                    plotOptions: {
                        map: {
                            animation: false,
                            cursor: 'pointer',
                            events: {
                                click: function(event) {
                                    //Simular doble tap
                                    setTimeout(function() {
                                        _this.mapSelection = null;
                                    }, 500);
                                    if(event.point == _this.mapSelection){
                                        $('#modalMovilMapa').modal('hide');
                                        _this._router.navigateByUrl(_this._globalService.getContienda()+'/'+_this.mapSelection.properties.ENLACE+'/votos-candidatura');
//                                        console.log(_this._globalService.getContienda()+'/'+_this.mapSelection.properties.ENLACE+'/votos-candidatura');
                                    }
                                    _this.mapSelection = event.point;
                                },
                            },
                            point: {
                                events: {
                                    select: function(event){
                                        console.log('select', event);
                                    }
                                }
                            }
                        },
                    },
                    series: [{
                        type: 'map',
                        data: this.data_mapa,
                        keys: [key, 'value'],
                        joinBy: key,
                    }],
                    tooltip: {
                        enabled: false,
                    }
                });
                
                this.mapa.destroy();
                this.mapa_movil.destroy();
                this.mapa_modal.destroy();
            }
        });
    }

    agregarElemento(el: any) {
      //Fix del contexto de la variable 
      let scope = (this instanceof GlobalesService)? this["bottomSheetContex"] : this;
//      console.log('arreglo elementos', scope.comparativaService.arregloElementos);
      if (scope.comparativaService.arregloElementos.length < 3) {
          scope.comparativaService.agregarElemento(el);
          el.seleccionado = true;
      }
      if(this instanceof GlobalesService && scope.comparativaService.arregloElementos.length >= 3){
          this.toggleBottomSheet(false);
      }
      if (!scope.comparativaService.stageStatus) {
          scope.comparativaService.stageStatus = true;
          scope.comparativaService.guardarStage(scope.fichas);
      }
  }

    eliminarElemento(el: any) {
        this.comparativaService.eliminarElemento(el);
        el.seleccionado = false;
    }

    selectedItemFun = (el: any): void => {
      this.agregarElemento(el);
    }
  
    diselectedItemFun = (el: any): void => {
      this.eliminarElemento(el);
    }


}
