<div class="distribucion-votos container-fluid pt-3">
    <div class="row">
        <div class="col-12">
            <div class="card p-2">
                <div class="container-fluid">
                    <div class="row pb-4">
                        <div class="col-12">
                            <p class="titulo pb-2">
                                <strong>
                                    Distribución de votos por Candidatura a nivel {{contienda}}
                                </strong>
                            </p>
                            <p class="em14">
                                La tabla muestra el desglose de votos que cada Candidatura presenta en {{contienda == 'Junta' ? ('la ' + contienda) : ('el ' + contienda)}}, indicando los votos recibidos de manera individual y, en su caso, los votos recibidos vía {{ _obtenerDatosService.config?.estado == 'Oaxaca' ? 'Candidatura Común' : 'Coalición' }}.
                            </p>
                            <p class="em14 underline">
                                <br>
                                Conoce cómo se suman los votos para Candidaturas de {{ _obtenerDatosService.config?.estado == 'Oaxaca' ? 'Candidatura Común' : 'Coalición' }} de acuerdo con la legislación vigente.
                            </p>
                        </div>
                    </div>

                    <!--Version escritorio-->
                    <div class="tabla pt-3 d-none d-xl-block">
                        <div class="titulos row borde-abajo">
                            <div class="col-4 col-lg-3 borde-derecha">
                                <p>
                                    <strong>
                                        Candidaturas
                                    </strong>
                                </p>
                            </div>
                            <div class="col-5 col-lg-7 borde-derecha text-center">
                                <p>
                                    <strong>
                                        Distribución de votos por Candidatura
                                    </strong>
                                </p>
                            </div>
                            <div class="col-3 col-lg-2 text-center">
                                <p>
                                    <strong>
                                        Total de votos por Candidatura
                                    </strong>
                                </p>
                            </div>
                        </div>
                        <div class="row datos" *ngFor="let fila of this._obtenerDatosService.desglose" [ngClass]="{cardGanador: fila?.winner}">
                            <div class="col-4 col-lg-3 borde-derecha py-3">
                                <p>
                                    <strong>
                                        {{ fila?.text }}
                                    </strong>
                                </p>
                                <img src="./assets/img/avatar-candidatos/{{(fila?.img_perfil ? fila?.img_perfil : 'avatar-persona.svg')}}">
                            </div>
                            <div class="col-5 col-lg-7 borde-derecha text-right padding-t-15">
                                <span class="" *ngFor="let pe of fila.candidaturas; let last = last">
                                    <ng-container *ngFor="let img of pe.imgs">
                                        <img class="img-partido" [src]="'./assets/img/logos-partidos/'+img?.toLowerCase()" />
                                    </ng-container>
                                    <strong>
                                        &nbsp;{{ pe.data }}
                                    </strong>
                                    <ng-container *ngIf="!last">
                                        &nbsp;<i class="fas fa-plus-circle"></i>&nbsp;
                                    </ng-container>

                                </span>
                            </div>
                            <div class="col-3 col-lg-2 dis-flex flex-col text-center">
                                <div class="conteo dis-flex justify-s w-100">
                                    <div class="igual dis-flex mx-sm-3">
                                        <i class="fas fa-equals" aria-hidden="true"></i>
                                    </div>
                                    <p class="total" [ngClass]="{textGanador: fila?.winner}">
                                        <strong>
                                            {{ fila.total }}
                                        </strong>
                                    </p>
                                </div>
                                <p class="em10">
                                    Total de votos por Candidatura
                                </p>
                            </div>
                        </div>
                    </div>

                    <!--Version movil-->
                    <div class="row d-block d-xl-none">
                        <div class="datos-movil" *ngFor="let fila of this._obtenerDatosService.desglose" [ngClass]="{cardGanador: fila?.winner}">
                            <div class="row-nombre">
                                <img class="img-candidato" src="./assets/img/avatar-candidatos/{{(fila?.img_perfil ? fila?.img_perfil : 'avatar-persona.svg')}}">
                                <p> <strong> {{ fila.text }} </strong> </p>
                            </div>
                            <div class="row candidatura" *ngFor="let pe of fila.candidaturas; let last = last">
                                <div>
                                    <ng-container *ngFor="let img of pe.imgs">
                                        <img class="img-partido" [src]="'./assets/img/logos-partidos/'+img?.toLowerCase()" />
                                    </ng-container>
                                </div>
                                <div>
                                    <p><strong> {{ pe.data }} </strong></p>
                                </div>
                            </div>
                            <div class="row total-row">
                                <div>
                                    <p class="txRosaIne"><strong>Total de votos por Candidatura</strong></p>
                                </div>
                                <div>
                                    <p class="txRosaIne"><strong> {{ fila.total }} </strong></p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
