<div class="card dis-flex flex-col space-a avance-nacional h-100">
    <div class="container-fluid bgPrototipo">
        <div class="row">
            <div class="dis-flex space-b col-9 pl-0">
                <h3 class="txt-rb px20">
                    <strong>Avance Entidad</strong>
                </h3>
            </div>
            <div class="col-3 dis-flex justify-e">
                <div
                (click)="actualizar()"
                class="actualizar dis-flex flex-col px-4 py-1 px14 cursorPointer">
                    <i class="fas fa-redo" aria-hidden="true"></i> <span class="updateTxt">Actualizar</span>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid px-0 py-2">
        <div class="row px-1 desktop">
            <div class="col-4 pd-c">
                <div class="card cards-avance p-1 brdr-card-avance" > <!-- [ngClass]="{'brdr-card-avance': !themeService.isDarkTheme()}" -->
                    <div class="titulo">
                        <p>
                            <strong>
                                Actas capturadas
                            </strong>
                        </p>
                    </div>
                    <div class="contenido capturadas">
                        <p>
                            <strong >
                                {{ datosContienda?.monto_actas }}
                            </strong> de {{ datosContienda?.total_actas }}
                        </p>
                        <p>
                            ({{ datosContienda?.porcentaje_actas }})
                        </p>
                        <div class="w-100 barra-container borde-barra"> <!-- [ngClass]="{'borde-barra': !themeService.isDarkTheme()}" !-->
                            <div class="barra-avance" [style.width]="datosContienda?.porcentaje_actas">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4 pd-c">
                <div class="card cards-avance p-1 brdr-card-avance" > <!-- [ngClass]="{'brdr-card-avance': !themeService.isDarkTheme()}" -->
                    <div class="titulo">
                        <p class="prueba">
                            <strong>
                                <i class="fas fa-question-circle cursor-hand" data-toggle="modal" data-target="#modalParticipacion"></i> Participación ciudadana
                            </strong>
                        </p>
                    </div>
                    <div class="contenido dis-flex flex-col pt-3">
                        <p class="participacion">
                            <strong>
                                {{ datosContienda?.participacion }}
                            </strong>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-4 pd-c">
                <div class="card cards-avance p-1 brdr-card-avance"> <!-- [ngClass]="{'brdr-card-avance': !themeService.isDarkTheme()}" -->
                    <div class="titulo">
                        <p>
                            <strong>
                                Último corte
                            </strong>
                        </p>
                    </div>
                    <div class="contenido dis-flex flex-col align-e pt-3">
                        <div class="dis-flex w-100">
                            <p class="fecha mr-1">
                                {{ fechaContienda | lowercase }}
                            </p>
                            <div class="dis-flex flex-col borde-tiempo">
                                <p class="tiempo-centro">
                                    Hora local
                                </p>
                                <p class="utc">
                                    {{ horaContienda }} h {{ utc }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row px-1 mobile">
            <div class="col-12 p-2">
                <div class="p-1 brdr-card-avance"> <!-- [ngClass]="{'brdr-card-avance': !themeService.isDarkTheme()}" -->
                    <div class="titulo">
                        <p>
                            <strong>
                                Actas capturadas
                            </strong>

                            <span class="mTotal">
                                ({{ datosContienda?.porcentaje_actas }})
                            </span>
                        </p>
                    </div>
					<div class="contenido capturadas d-flex flex-row">
						<div class="p-2 actaP">
							<p>
								<strong >
									{{ datosContienda?.monto_actas }}
								</strong> de {{ datosContienda?.total_actas }}
							</p>
						</div>
						<div class="p-2 actaP">
							<div class="w-100 barra-container borde-barra"> <!-- [ngClass]="{'borde-barra': !themeService.isDarkTheme()}" -->
								<div class="barra-avance" [style.width]="datosContienda?.porcentaje_actas">
								</div>
							</div>
						</div>
					</div>
					<div class="contenido d-flex flex-row">
						<div class="actaP separator titulo">
							<p class="subtitleP" data-toggle="modal" data-target="#modalParticipacion">
								Participación ciudadana
							</p>
							<p>
								<b>{{ datosContienda?.participacion }}</b>
							</p>
						</div>
						<div class="actaP titulo pl-2">
							<p class="subtitleP not-underline">
								Último corte
							</p>
							<div class="contenido dis-flex flex-col align-e">
								<div class="dis-flex w-100">
									<p class="fecha mr-1">
										{{ fechaContienda | lowercase }}
									</p>
									<div class="dis-flex flex-col borde-tiempo">
										<p class="tiempo-centro">
											Hora local
										</p>
										<p class="utc">
											{{ horaContienda }} h {{ utc }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
                    <!-- <div class="contenido capturadas">
                        <p>
                            <strong >
                                {{ datosContienda?.monto_actas }}
                            </strong> de {{ datosContienda?.total_actas }}
                        </p>
                        <p>
                            ({{ datosContienda?.porcentaje_actas }})
                        </p>
                        <div class="w-100 barra-container" [ngClass]="{'borde-barra': !themeService.isDarkTheme()}">
                            <div class="barra-avance" [style.width]="datosContienda?.porcentaje_actas">
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="card-nacional dis-flex space-b w-100">
        <div class="card dis-flex align-s p-2 bgGrisTenue">
            <p class="px18">
                <strong>Participación ciudadana</strong>
            </p>
            <p class="px24 font-medium">
                <strong>63.1162%</strong>
            </p>
        </div>
        <div class="card dis-flex align-s p-2 bgGrisTenue">
            <p class="px18">
                <strong>Último corte</strong>
            </p>
            <p class="px24 font-medium">
                <strong>03 julio 2021</strong>
            </p>
        </div>
        <div class="card dis-flex align-s p-2 bgGrisTenue">
            <p class="px18">
                <strong>Actas capturadas</strong>
            </p>
            <p class="px24 font-medium">
                <strong>145,327</strong>
            </p>
        </div>
    </div> -->
</div>

<!-- Button trigger modal -->
<!-- <div class="modal fade modal-paritcipacion" id="modalParticipacion" tabindex="-1" role="dialog" aria-labelledby="ModalParticipacion" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <div class="modal-title font-medium px16" id="exampleModalLabel">
            Conoce cómo calcular el porcentaje de Participación ciudadana
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="fas fa-times-circle"></i>
            </button>
        </div>
        <div class="modal-body px16 font-regular">
            <p class="padding-b-15">
                La Participación ciudadana es el porcentaje de ciudadanos que votaron, con base en la Lista Nominal de las Actas Contabilizadas.
            </p>
            <p class="padding-b-15">
                Por ejemplo, si en una casilla votan 500 electores y el número de personas inscritas en la Lista Nominal para esa casilla es de 750 ciudadanos, entonces:
            </p>
            <p class="padding-b-15">
                Multiplicamos 500 por 100 y el resultado lo dividimos entre 750.
            </p>
            <p class="padding-b-15">
                La votación emitida en las Casillas Especiales se tomará en cuenta para el cálculo de la Participación ciudadana, hasta el último corte de información que se publique en el PREP.
            </p>
        </div>
        </div>
    </div>
</div> -->
