import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';
import { Location } from '@angular/common';
import { ThemeService } from '../../../../../theme/theme.service';

@Component({
    selector: 'app-ppyci-representacion-proporcional',
    templateUrl: './ppyci-representacion-proporcional.component.html',
    styleUrls: ['./ppyci-representacion-proporcional.component.scss']
})
export class PpyciRepresentacionProporcionalComponent implements OnInit {

    base: string;
    estadisticasCasilla: any = [];
    resumen: any = {};
    data_selected: any = [];
    casilla_table: any = [];
    fichas: any = [];
    selectedContienda: any = '';
    selectedIndxCasilla: number = -1;
    selectedCasilla = 'Todas';
    isOpenCombo = false;
    firstLoad = true;
    total_num = 0;
    total_prc = "0";
    hideBtnActa = true;
    acta_url = "";
    casilla: any;
    resumenGral: any = {};
    showObs = false
    txtObs = ''
    showCATD = false
    showLabel = false

    constructor(
        public _globalService: GlobalesService,
        private readonly router: Router,
        private readonly activatedRouter: ActivatedRoute,
        public location: Location,
        public obtenerDatosService: ObtenerDatosService,
        public readonly rutas: RutasService,
        public readonly themeService: ThemeService,
    ) { }

    ngOnInit(): void {
        this.obtenerParametros();
        this.base = this.rutas.rutaActual.base;
        this._globalService.menuActual = 'RP';
        this.getScreenSize()
        this.obtenerDatosService.configSubject.subscribe(data =>{
            if(data == null) return;
            this.showLabel = data.estado == "Veracruz"
          });
        this.selectedContienda = this._globalService.getContienda();
        this.obtenerDatosService.mainSubject.subscribe(data => {
            if (data == null) return;
            this._globalService.returnURL = '/diputaciones/votos-ppyci/grafica'
            this.obtenerCasillas();
            this.showCATD = data?.show_catd ? data?.show_catd : false
        });
        this._globalService.callbackCasillaData.subscribe((parametros) => {
            this.itemSelected(parametros)
        });
        // window.scrollTo(0, 0);
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });
        setTimeout(function(){
            document.querySelector('#app-encabezado-anchor').scrollIntoView()
        }, 20);
    }

    // ngOnDestroy(): void {
    //   this.obtenerDatosService.mainSubject.unsubscribe();
    // }

    obtenerParametros() {
        this.activatedRouter.params.subscribe((parametros) => {
            const params = { parametros };
            this._globalService.detalles = params.parametros;
        });
        this._globalService.setearParametros();
    }

    obtenerCasillas() {
        this.obtenerDatosService.loadJSON(this.selectedContienda, 'casillas_especiales.json').then(data => {
            this.casilla_table = data['casilla_table'] ? data['casilla_table'] : data['seccion_casilla_table'];
            this.fichas = data['fichas'];
            this.resumen = data['resumen'];
            this.resumenGral = this._globalService.deepCopy(data['resumen']);
            this.total_num = this.resumen?.total?.total
            this.total_prc = this.resumen?.total?.prc
            this._globalService.bottomSheetTpl = 'simple';
            this._globalService.bottomSheeTitle = 'Casillas';
            this._globalService.bottomSheetData = data['combo_movil'];
            this.selectedIndxCasilla = -1;
        });
    }


    @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;

    public scrollRight(): void {
        this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
    }

    public scrollLeft(): void {
        this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
    }

    asIsOrder(a, b): number {
        const aStepId = parseInt(a.key, 10);
        const bStepId = parseInt(b.key, 10);
        return aStepId > bStepId ? 1 : (bStepId > aStepId ? -1 : 0);
    }

    itemSelected(item: any, closeSheet = true) {
        if (this.firstLoad) {
            this.firstLoad = false
            return;
        }
        
        const index = item.index;
        if(closeSheet)this.openBottomSheet()
        this.selectedIndxCasilla = +index;
        var posCombo = this.selectedIndxCasilla + 1;
        this.selectedCasilla = this._globalService.bottomSheetData[posCombo].text;
        if(this.selectedIndxCasilla > -1){
            this.hideBtnActa = false;
            const tmpCasilla = this.casilla_table?.rows[this.selectedIndxCasilla];
            if(tmpCasilla.status && tmpCasilla.status == 'proceso'){
                this.itemSelected({index: -1, text: "Todas"}, false)
            }else{
                this.casilla = tmpCasilla
                const resume = this.casilla?.resumen[2];

                this.acta_url = this.casilla?.status?.movil ? this.casilla?.status?.movil : this.casilla?.status?.scanner;
                //const prc = resume.prc.replace('%', '');
                this.resumen.no_registradas.total = this.casilla?.resumen[0].value;
                this.resumen.no_registradas.prc = this.casilla?.resumen[0].prc;
                this.resumen.nulos.total = this.casilla?.resumen[1].value;
                this.resumen.nulos.prc = this.casilla?.resumen[1].prc;

                this.total_num = resume.value;
                this.total_prc = resume.prc;
                this.showObs = true;
                this.txtObs = this.casilla?.resumen[3].value
            }
        }else{
            this.hideBtnActa = true;
            this.showObs = false;
            this.acta_url = "";
            this.total_num = this.resumen?.total?.total
            this.total_prc = this.resumen?.total?.prc

            this.resumen.no_registradas.total = this.resumenGral.no_registradas.total;
            this.resumen.no_registradas.prc = this.resumenGral.no_registradas.prc;
            this.resumen.nulos.total = this.resumenGral.nulos.total;
            this.resumen.nulos.prc = this.resumenGral.nulos.prc;
        }
    }

    openBottomSheet() {
        this.isOpenCombo = !this.isOpenCombo;
        this._globalService.toggleBottomSheet(this.isOpenCombo);
    }

    atras() {
        this.location.back();
        this._globalService.menuActual = 'Votos';
        window.scrollTo(0, 0);
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        const scrWidth = window.innerWidth;
        //console.log('WINDOW WIDTH:  ', scrWidth);
        this._globalService.menuActual = scrWidth <= 1200 ? 'vP' : 'RP';
    }

}
