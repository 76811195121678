import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ResumenPartidoComponent } from '../diputacion/votos-partido/componentes/resumen-partido/resumen-partido.component';
import { ResumenPartidoGraficaComponent } from '../diputacion/votos-partido/componentes/resumen-partido-grafica/resumen-partido-grafica.component';
import { PpyciDistritoComponent } from '../diputacion/votos-partido/componentes/ppyci-distrito/ppyci-distrito.component';

import { AuthGuard } from '../../auth/auth.guard';
import { ContiendasComponent } from '../contiendas.component';

const routes: Routes = [
  {
    path: 'juntas',
    canActivate: [AuthGuard],
    component: ContiendasComponent,
    children: [
      {
        path: 'votos-ppyci',
        children: [
          {
            path: '',
            redirectTo: 'grafica',
            pathMatch: 'full'
          },
          {
            path: 'grafica',
            component: ResumenPartidoGraficaComponent,
          },
          {
            path: 'tarjetas',
            component: ResumenPartidoComponent,
          },
        ]
      },
      {
        path: ':distrito/votos-ppyci',
        component: PpyciDistritoComponent,
        data : { contienda : 'Juntas', contienda_s : 'Junta' }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JuntaPartidoRoutingModule { }
