import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titulo'
})
export class TituloPipeDistrito implements PipeTransform {

  transform(value: string): string {
    return value? value.replace(/-/g, " ") : value;
  }

}
