<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 col-xl-10">
                        <app-breadcrumb
                            subtitle="Entidad"
                            subtitle_detalle="Votos por Candidatura"
                            [estado]="_obtenerDatosService?.config?.estado"
                        ></app-breadcrumb>
                        <div class="texto font-regular">
                            <p> El total de votos calculado y porcentaje que se muestran, se refiere a los votos asentados en las Actas PREP hasta el momento. </p>
                            <p> Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%.</p>
                        </div>
                    </div>
                    
                    <div class="col-2 d-none d-xl-block" *ngIf="_obtenerDatosService?.config?.estado == 'Veracruz'">
                        <p class="em12 text-left"><strong>Visualiza como:</strong></p>
                        <div class="visualizar dis-flex flex-col text-center">
                            <div class="selector dis-flex w-100 cursorPointer">
                                <div [routerLink]="['../grafica']" class="izq dis-flex flex-col w-50 p-2">
                                    <img *ngIf="!themeService.isDarkTheme()" class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Grafica-A.svg" style="opacity: 0.5;">
                                    <img *ngIf="themeService.isDarkTheme()" class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Grafica-A-dark.svg" style="opacity: 0.5;">
                                    <p>Gráfica</p>
                                </div>
                                <div class="activo dis-flex der flex-col w-50 p-2">
                                    <img *ngIf="!themeService.isDarkTheme()" class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Tarjetas-A.svg">
                                    <img *ngIf="themeService.isDarkTheme()" class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Tarjetas-B.svg">
                                    <p>Tarjetas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-resumen-votacion-mobile
                    [total_num]="resumen?.total?.total"
                    [total_prc]="resumen?.total?.prc"
                ></app-resumen-votacion-mobile>

                <div class="d-block d-xl-none" *ngIf="!omitirExtranjero">
                    <p class="movil-titulo-votos">Total de votos en:</p>
                    <div class="dis-flex ml-2">
                        <div class="w-50 votos-container ml-3 mr-1">
                            <p class="label-tipo ml-1"><strong>La Entidad</strong></p>
                            <p class="label-cantidad mr-2">{{resumen?.acumulados?.total}}</p>
                        </div>
                        <div class="w-50 votos-container mr-2">
                            <p class="label-tipo ml-1"><strong>El Extranjero</strong></p>
                            <p class="label-cantidad mr-2">{{resumen?.extranjero?.total}}</p>
                        </div>
                    </div>
                </div>

                <div class="row pb-3 mt-2">
                    <div class="col">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                    </div>
                </div>
                <!-- TARJETAS DE ESCRITORIO -->
                <div class=" d-none d-md-none d-xl-block">
                    <div class="row justify-content-center">
                        <div class="col-8 col-sm-4 col-md-4 col-lg-3 col-xl-2 w-tarjeta" *ngFor="let tarjeta of fichas; let indx = index;">
                            <div class="tarjeta card dis-flex flex-col mb-2" [ngClass]="{cardGanador: tarjeta?.winner}">
                                <div class="ficha-candidato" [ngClass]="{'h-no-extranjero': omitirExtranjero}">
                                    <div class="hover" [ngClass]="{'h-no-extranjero': omitirExtranjero}">
                                        <div class="datos-candidato">
<!--                                            <img class="avatar-hover" [src]="'./assets/img/avatar-candidatos/'+ tarjeta?.img_perfil">-->
                                            <p>{{ tarjeta?.text }}</p>
                                        </div>
                                        <div class="container-contiendas">
                                            <div *ngFor="let contienda of tarjeta.contiendas; let i = index;">
                                                <div class="contienda">
                                                    <div>
                                                        <img class="img-partido-small" src="./assets/img/logos-partidos/{{contienda?.img}}">
                                                    </div>
                                                    <div>
                                                        <p>{{contienda?.votos}}</p>
                                                        <p class="plus-sign black-text em12" *ngIf="i < tarjeta.contiendas.length-1">+</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container-total bgGrisTenue">
                                            <div>
<!--                                                <p><strong>Total de Votos</strong></p>-->
                                            </div>
                                            <div>
<!--                                                <p class="txRosaIne">{{tarjeta?.total}}</p>-->
<!--                                                <p>{{tarjeta?.porcentaje}}</p>-->
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="content-candidato">
                                        <img class="avatar" [src]="'./assets/img/avatar-candidatos/'+ tarjeta?.img_perfil">
                                        <div class="candidato">
                                            <p class="nombre text-center px-1"> {{ tarjeta?.text }} </p>
                                        </div>
                                        
                                        <div class="distritos w-100 dis-flex py-4 px-1">
                                            <ng-container *ngFor="let img of tarjeta?.partidos">
                                                <img class="logo" [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()" alt="">
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="container-totales">
                                        <p class="label-total-votos" *ngIf="omitirExtranjero"><strong>Total de Votos</strong></p>
                                        <p class="votos-totales" *ngIf="omitirExtranjero" [ngClass]="{textGanador: tarjeta?.winner}">{{ tarjeta?.total }}</p>
                                        <p class="porcentaje-totales" *ngIf="omitirExtranjero">{{ tarjeta?.porcentaje }}</p>
                                        <p class="label-total-votos-extranjero" *ngIf="!omitirExtranjero"><strong>Total de Votos</strong></p>
                                        <p class="votos-totales-extranjero" *ngIf="!omitirExtranjero" [ngClass]="{textGanador: tarjeta?.winner}">{{ tarjeta?.total }}</p>
                                        <p class="porcentaje-totales-extranjero" *ngIf="!omitirExtranjero">{{ tarjeta?.porcentaje }}</p>
                                    </div>
                                    <div class="cuerpo bgGrisTenue dis-flex w-100 p-2" *ngIf="!omitirExtranjero">
                                        <div class="w-100">
                                            <p><strong>Votos</strong><br>En la Entidad</p>
                                            <div class="container-votos">
                                                <p class="votos">{{ tarjeta?.votos_entidad }}</p>
                                                <p>{{tarjeta?.porcentaje_entidad}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cuerpo bgGrisTenue w-100 p-2 separador" *ngIf="!omitirExtranjero">
                                        <div class="w-100">
                                            <p>En el Extranjero</p>
                                            <div class="container-votos">
                                                <p class="votos">{{ tarjeta?.votos_extranjero }}</p>
                                                <p>{{tarjeta?.porcentaje_entidad}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!tarjeta.seleccionado" (click)="agregarElemento(tarjeta);" class="pie w-100 dis-flex bgMorado txBlanco p-2 cursorPointer">
                                    <p>Seleccionar</p>
                                </div>
                                <div *ngIf="tarjeta.seleccionado" (click)="eliminarElemento(tarjeta);" class="pie w-100 dis-flex bgMorado txBlanco p-2 cursorPointer seleccionado">
                                    <p>Quitar selección</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- TARJETAS MOVIL -->
                <movil-listado-content 
                    [selectedItemFunc]="selectedItemFun" 
                    [diselectedItemFunc]="diselectedItemFun" 
                    [infoList]="fichas"
                    [tipoTarjeta]="1"></movil-listado-content>
                <!--********************************************************************************************************************-->
                <div class="container-fluid">
                    <div class="row dis-flex">
                        <div class="col-md-12">
                            <div class="row dis-flex justify-s"></div>
                        </div>
                    </div>
                </div>
                <!-- <div class="container-fluid py-4 d-none d-xl-block">
                    <div class="row">
                        <div class="col-md-12 dis-flex justify-e">
                            <i class="fas fa-info-circle mr-2" aria-hidden="true"></i>
                            <p class="texto"> Da clic en <strong>Seleccionar</strong> para visualizar el detalle de la votación. Puedes seleccionar hasta tres opciones.</p>
                            <button [disabled]="_comparativaService?.arregloElementos.length < 1"
                                (click)="_comparativaService.reiniciarModal()"
                                class="boton-detalle btn bgMorado txBlanco btn-sm texto ml-2 px-4 cursorPointer" data-toggle="modal"
                                data-target="#modalComparativa">
                            Ver detalle
                            </button>
                        </div>
                    </div>
                </div> -->
                <app-resumen-votacion
                    extranjero="true"
                    
                    [acumulados_num]="resumen?.acumulados?.total"
                    [acumulados_prc]="resumen?.acumulados?.prc"
                    [extranjero_num]="resumen?.extranjero?.total"
                    [extranjero_prc]="resumen?.extranjero?.prc"
                    [noregistradas_num]="resumen?.no_registradas?.total"
                    [noregistradas_prc]="resumen?.no_registradas?.prc"
                    [nulos_num]="resumen?.nulos?.total"
                    [nulos_prc]="resumen?.nulos?.prc"
                    [total_num]="resumen?.total?.total"
                    [total_prc]="resumen?.total?.prc"
                ></app-resumen-votacion>

                <!-- <div class="container-fluid py-4 d-block d-md-block d-xl-none">
                    <div class="row">
                        <div class="col-md-12 dis-flex justify-e">
                            <button [disabled]="_comparativaService?.arregloElementos.length < 1"
                                (click)="_comparativaService.reiniciarModal()"
                                class="boton-detalle btn bgMorado txBlanco btn-sm texto ml-2 px-4 cursorPointer" data-toggle="modal"
                                data-target="#modalComparativa">
                            Ver detalle
                            </button>
                        </div>
                    </div>
                </div> -->

            </div>
            
            <div class="row" *ngIf="!omitirExtranjero">
                <div class="col-md-12">
                    <div class="card p-3 mt-3 mapa-puntos">
                        <div class="row justify-content-center">
                            <div class="col-12 col-xl-4 text-center">
                                <i class="fas fa-globe-americas"></i>
                                <h4 class="subtitulo-seccion py-3 text-center">Voto en el Extranjero</h4>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-6 col-xl-4 dis-flex">
                                <button
                                    [routerLink]="['/gubernatura/votos-ppyci/voto-extranjero']" 
                                    class="btn btn-sm btn-rosaFuerte texto cursorPointer my-3 px-4 py-1">
                                    Ver Resultados
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-estadistica-nacional [load_main]="true"></app-estadistica-nacional>
