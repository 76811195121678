<div id="observaciones-actas" class="anchor"></div>
<div class="container-fluid bgWhite p-4 p-lg-5">
    <div class="row">
        <div class="col-12 col-lg-10 p-0 p-lg-5">
            <h2 class="font-medium pb-4">- Observaciones en Actas -</h2>
            <div class="container">
                <div class="row">
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cuál es la información que se puede compartir y en dónde?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Se puede compartir la página del PREP en redes sociales.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cómo cambiar a versión escritorio o versión móvil?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Desde la computadora puedes seleccionar la opción minimizar, ubicada en la parte superior derecha de tu pantalla y podrás visualizar la versión móvil. Para regresar a versión escritorio, selecciona la opción maximizar. Desde tu móvil selecciona Versión
                        escritorio, ubicada en la parte final de la pantalla.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cómo poner modo nocturno?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Solo debes dar clic en Ajustes y seleccionar el tema Oscuro.' | highlightSearch: Search"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>