export interface Theme {
    name: string;
    properties: any;
  }

export const light: Theme = {
    name: 'light',
    properties: {
    '--bg-body': '#F4F4F4',
    '--bg-accesibilidad': '#34344E',
    '--bg-encabezado': '#F9F9F9',
    '--bg-rosa-ine': '#D1308A',
    '--bg-rosa-ine70': '#D979AF70',
    '--bg-diputaciones': '#FFFFFF',
    '--bg-avance': '#F9F9F9',
    '--bg-barra-avance': '#34344E',
    '--bg-input': '#FFFFFF',
    '--bg-compartir': '#23233E',
    '--bg-molecula': '#F7F6F6',
    '--bg-molecula-activo': '#181A2F',
    '--bg-resumen': '#f4f4f4',
    '--bg-estadistica': '#E3E3E3',
    '--bg-barra-estadistica': '#E6E6E6',
    '--bg-barra-esperadas': '#D2127E',
    '--bg-barra-capturadas': '#79144C',
    '--bg-barra-contabilizadas': '#EFB5D3',
    '--bg-estadistica-urbana': '#D2127E',
    '--bg-estadistica-nourbana': '#83044C',
    '--bg-estadistica-votos': '#F4F4F4',
    '--bg-estadistica-avance': '#2E2E2E',
    '--bg-tarjetas': '#FFFFFF',
    '--bg-comparador-ficha': '#FFFFFF',
    '--bg-barra-comparador': '#e2e2e2',
    '--bg-br': '#FFFFFF',
    '--bg-gray-dark': '#373737',
    '--bg-separator-menu': '#9697a3',
    '--bg-rg': '#D1308A',
    '--bg-mn': '#34344E',
    '--bg-table-odd': '#F1F1F1',
    '--bg-mov-res': '#D2D2EA',
    '--bg-comparador-cont': '#F9F9F9',
    '--bg-modal-header': '#FFFFFF',
    '--txt-bn': '#FFFFFF',
    '--txt-bnn': '#FFFFFF',
    '--txt-nb': '#212529',
    '--txt-rb': '#D1308A',
    '--txt-nr': '#212529',
    '--txt-ng': '#212529',
    '--txt-bg': '#FFFFFF',
    '--txt-consulta': '#000000',
    '--txt-rosa-ine': '#D1308A',
    '--txt-diputaciones': '#FFFFFF',
    '--txt-title-avance': '#34344E',
    '--txt-resumen': '#34344E',
    '--txt-title-barra': '#000000',
    '--txt-estadistica': '#000000',
    '--txt-selector': '#BBBBC0',
    '--txt-subtitle-seccion': '#333333',
    '--txt-icon': '#6F65A3',
    '--txt-mb': '#23233E',
    '--txt-ab': '#4199A0',
    '--txt-mmb': '#7A4493',
    '--txt-menu-mobile': '#F9F9F9',
    '--txt-acta-proceso': '#636669',
    '--btn-actualizar': '#D1308A',
    '--btn-update': '#FC4CAF',
    '--btn-consulta': '#E97EBD',
    '--btn-seccion': '#34344E',
    '--btn-morado-comparativa': '#23233E',
    '--shadow-avance': '#0000000F',
    '--brdr-compartir': '#D5007F',
    '--brdr-barra': '#CCCCCC',
    '--brdr-ficha-comparador': '#b2b2b2',
    '--brdr-comparador': '#00000033',
    '--icon-comparador': '#34344E',
    '--stripped-movil': '#F5F5F5',
    '--datos-movil': '#FFEDF7',
    '--url-back': "url('./assets/img/login/back-login.jpg')",
    '--url-mcad': "url('./assets/img/login/fondo-login-mcad.png')",
    '--url-mapa-puntos': "url('./assets/img/generales/mapa_puntos.png')",
    '--url-logo-whatsapp': "url('./assets/img/generales/logo_whatsapp.png')",
    '--url-logo-facebook': "url('./assets/img/generales/logo_facebook.png')",
    '--url-logo-twitter': "url('./assets/img/generales/logo_twitter.png')"
    }
  };

export const dark: Theme = {
    name: 'dark',
    properties: {
      '--bg-body': '#121212',
      '--bg-accesibilidad': '#1D1D1D',
      '--bg-encabezado': '#1D1D1D',
      '--bg-rosa-ine': '#D979AF',
      '--bg-rosa-ine70': '#D979AF70',
      '--bg-diputaciones': '#1D1D1D',
      '--bg-avance': '#2C2C2C',
      '--bg-barra-avance': '#F393C4',
      '--bg-input': '#121212',
      '--bg-compartir': '#212325',
      '--bg-molecula': '#D288B4',
      '--bg-molecula-activo': '#D288B4',
      '--bg-resumen': '#222222',
      '--bg-estadistica': '#2C2C2C',
      '--bg-barra-estadistica': '#2C2C2C',
      '--bg-barra-esperadas': '#FD2A71',
      '--bg-barra-capturadas': '#FE9DB0',
      '--bg-barra-contabilizadas': '#FEC5CD',
      '--bg-estadistica-urbana': '#F1B1D7',
      '--bg-estadistica-nourbana': '#E97EBD',
      '--bg-estadistica-votos': '#1D1D1D',
      '--bg-estadistica-avance': '#D979AF',
      '--bg-tarjetas': '#363636',
      '--bg-comparador-ficha': '#272727',
      '--bg-barra-comparador': '#363636',
      '--bg-br': '#D979AF',
      '--bg-gray-dark': '#373737',
      '--bg-separator-menu': '#FFFFFF',
      '--bg-rg': '#1D1D1D',
      '--bg-mn': '#121212',
      '--bg-table-odd': '#121212',
      '--bg-mov-res': '#363636',
      '--bg-comparador-cont': '#2C2C2C',
      '--bg-modal-header': '#1D1D1D',
      '--txt-bn': '#212529',
      '--txt-bnn': '#000000',
      '--txt-nb': '#FFFFFF',
      '--txt-rb': '#FFFFFF',
      '--txt-nr': '#ED98CB',
      '--txt-ng': '#B6B6B6',
      '--txt-bg': '#B6B6B6',
      '--txt-consulta': '#151515',
      '--txt-rosa-ine': '#ED98CB',
      '--txt-diputaciones': '#192734',
      '--txt-title-avance': '#FFFFFF',
      '--txt-selector': '#121212',
      '--txt-subtitle-seccion': '#FFFFFF',
      '--txt-resumen': '#B6B6B6',
      '--txt-title-barra': '#A5A5A5',
      '--txt-estadistica': '#A5A5A5',
      '--txt-icon': '#ED98CB',
      '--txt-mb': '#FFFFFF',
      '--txt-ab': '#FFFFFF',
      '--txt-mmb': '#FFFFFF',
      '--txt-menu-mobile': '#FFFFFF',
      '--txt-acta-proceso': '#B6B6B6',
      '--btn-actualizar': '#D288B4',
      '--btn-update': '#D288B4',
      '--btn-consulta': '#ED98CB',
      '--btn-seccion': '#D979AF',
      '--btn-morado-comparativa': '#D979AF',
      '--shadow-avance': '#00000029',
      '--brdr-compartir': '#E97EBD',
      '--brdr-barra': '#2C2C2C',
      '--brdr-ficha-comparador': '#363636',
      '--brdr-comparador': '#363636',
      '--icon-comparador': '#FFFFFF',
      '--stripped-movil': '#373737',
      '--datos-movil': '#373737',
      '--url-back': "url('./assets/img/login/back.jpg')",
      '--url-mcad': "url('./assets/img/login/login_1.png')",
      '--url-mapa-puntos': "url('./assets/img/mododark/mapa-puntos-dark.png')",
      '--url-logo-whatsapp': "url('./assets/img/generales/logo_whatsapp.png')",
      '--url-logo-facebook': "url('./assets/img/generales/logo_facebook.png')",
      '--url-logo-twitter': "url('./assets/img/generales/logo_twitter.png')"
    }
  };
