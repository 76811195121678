<div class="container-fluid bgWhite p-4 p-lg-5">
    <div class="row">
        <div class="col-12 col-lg-8 col-xl-8 p-0 p-lg-5">
            <h2 class="font-medium pb-4">- Resumen de la votación -</h2>
            <div class="container">
                <div class="row">
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cómo encuentro los resultados de mi casilla?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Ingresa tu Entidad, Sección y tu Clave de elector, que se encuentran en tu identificación oficial y realiza la consulta. En escritorio encuentras esta búsqueda en la parte superior de la derecha con el nombre de Conoce los resultados de tu casilla. En móvil, ingresa a Menú, selecciona la opción Mi Casilla y mete tus datos.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cuál es mi sección?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'La sección que te corresponde se encuentra especificada en el frente de tu credencial de elector, ejemplo: SECCIÓN 0610.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cuál es mi clave de elector?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Tu clave de elector está especificada en el frente de tu credencial de elector, ejemplo: CLAVE DE ELECTOR GMVLMR8007501M100.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Se guardará mi información en alguna base de datos?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Los datos que nos proporciones son exclusivos para visualizar el detalle de votación de tu casilla, no se guardarán en ninguna base de datos.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Si no quiero ingresar mis datos cómo puedo buscar los resultados de mi casilla?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Selecciona la Entidad, Municipio, Sección y Casilla desde las opciones de navegación.' | highlightSearch: Search"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>