<div class="distribucion-votos container-fluid pt-3">
    <div class="row">
        <div class="col-12">
            <div class="card p-2">
                <div class="container-fluid">
                    <div class="row pb-4">
                        <div class="col-12">
                            <p class="titulo pb-2">
                                <strong class="d-none d-xl-block">
                                    Distribución de votos por Partidos Políticos a nivel {{labels?.contienda_s}}
                                </strong>
                                <strong class="d-block d-xl-none">
                                    Distribución de votos por Partido{{addS}} Político{{addS}} a nivel {{labels?.contienda_s}}
                                </strong>
                            </p>
                            <p class="em14">
                                La tabla muestra el desglose de votos que cada Partido Político presenta en {{labels?.contienda_s == 'Junta' ? ('la ' + labels?.contienda_s) : ('el ' + labels?.contienda_s)}}, indicando los votos recibidos de manera individual y, en su caso, los votos recibidos vía {{ _obtenerDatosService.config?.estado == 'Oaxaca' ? 'Candidatura Común' : 'Coalición' }}.
                            </p>
                            <p class="em14 underline">
                                <br>
                                Conoce cómo se distribuyen los votos para Candidatura de {{ _obtenerDatosService.config?.estado == 'Oaxaca' ? 'Candidatura Común' : 'Coalición' }} de acuerdo con la legislación vigente.
                            </p>
                        </div>
                    </div>
                    <div class="d-none d-xl-block">
                        <div class="tabla pt-3">
                            <div class="titulos row pb-2 text-center">
                                <div class="col-3">
                                    <p>
                                        <strong>
                                            Partidos Políticos
                                        </strong>
                                    </p>
                                </div>
                                <div class="col-3 text-center">
                                    <p>
                                        <strong>
                                            Votos para Partidos Políticos
                                        </strong>
                                    </p>
                                </div>
                                <div class="col-4 text-center">
                                    <p>
                                        <strong>
                                            Votos obtenidos<br>vía {{ _obtenerDatosService.config?.estado == 'Oaxaca' ? 'Candidatura Común' : 'Coalición' }}
                                        </strong>
                                    </p>
                                </div>
                                <div class="col-2 text-center">
                                    <p>
                                        <strong>
                                            Total de votos
                                        </strong>
                                    </p>
                                </div>
                            </div>
                            <div class="row datos" *ngFor="let fila of this._obtenerDatosService.desglose">
                                <div class="col-3">
                                    <div class="distritos py-4 w-100 dis-flex">
                                            <!-- {{ _obtenerDatosService.diccionarioPartidos[fila?.partido].imgPartido}} -->
                                        <img class="img-partido" src="./assets/img/logos-partidos/{{fila?.img?.toLowerCase()}}">
<!--
                                        <p class="pe dis-flex p-2"
                                           [ngStyle]="{
                                                'background-image': 'url(./assets/img/logos-partidos/'+ fila?.img?.toLowerCase() +')',
                                                'background-size': 'auto 27px',
                                                'background-repeat': 'no-repeat',
                                                'background-position': 'left center'
                                            }">
                                        </p>
-->
                                    </div>
                                </div>
                                <div class="col-3 dis-flex">
                                    <p class="total dis-flex mx-3 my-2">
                                        {{ fila?.partido ? fila?.partido : fila?.text}}
                                    </p>
                                </div>
                                <div class="col-1 dis-flex flex-col text-center">
                                    <div class="conteo dis-flex justify-s w-100">
                                        <div class="igual dis-flex mx-sm-3">
                                            <i class="fas fa-plus" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 dis-flex">
                                    <p class="total dis-flex mx-3 my-2">
                                        {{ fila?.coalicion }}
                                    </p>
                                </div>
                                <div class="col-1 dis-flex flex-col text-center">
                                    <div class="conteo dis-flex justify-s w-100">
                                        <div class="igual dis-flex mx-sm-3">
                                            <i class="fas fa-equals" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 dis-flex">
                                    <p class="total dis-flex mx-3 my-2">
                                        <strong>
                                            {{ fila?.total }}
                                        </strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Versión Móvil-->
                    <div class="d-block d-xl-none">
                        <div class="row datos-movil" *ngFor="let fila of this._obtenerDatosService.desglose">
                            <div class="col-4">
                                <p>Votos obtenidos por Partido Político</p>
                                <p class="cantidad"><strong>{{ fila?.partido ? fila?.partido : fila?.text}}</strong></p>
                            </div>
                            <div class="col-1">
                                <div class="igual dis-flex mx-sm-3">
                                    <i class="fas fa-plus" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="col-3">
                                <img class="img-partido" src="./assets/img/logos-partidos/{{fila?.img?.toLowerCase()}}">
                                <p *ngIf="isInt(fila?.coalicion)">Votos obtenidos vía {{ _obtenerDatosService.config?.estado == 'Oaxaca' ? 'Candidatura Común' : 'Coalición' }}</p>
                                <p *ngIf="!isInt(fila?.coalicion)">{{ fila?.coalicion }}</p>

                                <p *ngIf="isInt(fila?.coalicion)" class="cantidad"><strong>{{ fila?.coalicion }}</strong></p>
                                <p *ngIf="!isInt(fila?.coalicion)" class="sincoalicion"><strong>0</strong></p>
                            </div>
                            <div class="col-1">
                                <div class="igual dis-flex mx-sm-3">
                                    <i class="fas fa-equals" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="col-3">
                                <p>Total de votos</p>
                                <p class="cantidad"><strong>{{fila?.total}}</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
