import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalesService } from '../../servicios/globales.service';
import { RutasService } from '../../servicios/rutas/rutas.service';
import { ObtenerDatosService } from '../../servicios/datos/obtener-datos/obtener-datos.service';
import { Title } from '@angular/platform-browser';
import { ThemeService } from 'src/app/theme/theme.service';


// declare var $;

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {
  config:any = {};
  main:any = {};
  title = "";

    constructor(
        public globalService: GlobalesService,
        private readonly router: Router,
        private readonly rutas: RutasService,
        private readonly _obtenerDatosService: ObtenerDatosService,
        private readonly route: ActivatedRoute,
        public themeService: ThemeService,
        private readonly titleService: Title
    ) {
      this._obtenerDatosService.loadConfig();
      this._obtenerDatosService.loadMain();
    }

    ngOnInit(): void {
      // this.themeService.setLightTheme();
      const _this = this;
      this._obtenerDatosService.configSubject.subscribe(config => {
        if (config != null) {
          this.config = config;
          this.title = this.config.header.title.replace("<span class='txRosaIne'>", "<span class='txRosaIne'><br>");
          this.titleService.setTitle(config['page_title']);
          this.globalService.toggleBottomSheet(false);
        }

        this._obtenerDatosService.mainSubject.subscribe(main => {
          if (main != null) this.main = main;
          // setTimeout(function(){
          //   _this.themeService.setDarkTheme();
          // }, 3000)
        });
      });
    }

    onConsultar() {
      localStorage.setItem('enable-contienda', 'true');

      let contienda = this.globalService.getContiendaDefault();
      if (contienda != '') this.router.navigate([('/' + contienda)], /*{ queryParams: { returnUrl: state.url } }*/);
      else {}
    }



}
