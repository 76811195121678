<div class="container-fluid">
    <!--  {{imprimir | json}} -->
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3" [ngClass]="{'smbr-card': themeService.isDarkTheme()}">
                <div class="row">
                    <div class="col-12 col-xl-10">
                        <app-breadcrumb
                            subtitle="Entidad"
                            subtitle_detalle="Votos por {{labels?.contienda}}"
                            [estado]="_obtenerDatosService?.config?.estado"
                        ></app-breadcrumb>
                        <div class="texto font-regular">
                            <p> El total de votos calculado y porcentaje que se muestran, se refiere a los votos asentados en las Actas PREP hasta el momento. </p>
                            <p><br class="d-block d-xl-none"> Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%. </p>
                            <p class="d-none d-xl-block pt-2">Se resalta el Partido Político o <span *ngIf="_obtenerDatosService?.config?.estado == 'Oaxaca'">Candidatura Común</span> <span *ngIf="_obtenerDatosService?.config?.estado != 'Oaxaca'">Coalición</span> que lleva ventaja hasta el momento con mayor número de Municipios obtenidos</p>
                            <!-- <p *ngIf="_obtenerDatosService?.config?.estado == 'Veracruz'" class="d-none d-xl-block pt-2">Se resalta el Partido Político o Coalición que lleva ventaja hasta el momento con mayor número de Municipios obtenidos</p> -->
                            <!-- <p *ngIf="_obtenerDatosService?.config?.estado != 'Veracruz'" class="d-none d-xl-block pt-2">Se resalta el Partido Político Coalición o Candidatura Independiente que lleva ventaja hasta el momento con mayor número de Municipios obtenidos.</p> -->
                        </div>
                    </div>
                    <div class="col-2 d-none d-xl-block" *ngIf="_obtenerDatosService?.config?.estado == 'Veracruz'">
                        <p class="em12 text-left"><strong>Visualiza como:</strong></p>
                        <div class="visualizar dis-flex flex-col text-center">
                            <div class="selector dis-flex w-100 cursorPointer" [ngClass]="{'brdr-selector': !themeService.isDarkTheme()}">
                                <div class="activo izq dis-flex flex-col w-50 p-2">
                                    <img class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Mapa-B.svg">
                                    <!-- <i class="ine ine-mapa" aria-hidden="true"></i> -->
                                    <p>Mapa</p>
                                </div>
                                <div [routerLink]="['../tarjetas']" class="dis-flex der flex-col w-50 p-2">
                                    <img class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Tarjetas-B.svg" style="opacity: 0.5;">
                                    <!-- <i class="ine ine-tarjetas" aria-hidden="true"></i> -->
                                    <p>Tarjetas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Mapa versión movil-->
                <div class="row d-block d-xl-none">
                    <div class="col-12 pt-4">
                        <span class="em18">
<!--                            <strong>Mapa de {{labels?.contienda == 'Distritos' ? 'Distritos Electorales' : labels?.contienda}}</strong>-->
                            <strong>Mapa de {{labels?.label_map}}</strong>
                        </span>
                        <p class="em12 padding-b-10">
                            El mapa resalta en {{ labels?.contienda != 'Juntas' ? 'los' : 'las' }} {{labels?.contienda == 'Distritos' ? 'Distritos Electorales' : labels?.contienda}} el Partido Político o {{ _obtenerDatosService.config?.estado == 'Oaxaca' ? 'Candidatura Común' : 'Coalición' }} que lleva ventaja hasta el momento.
                        </p>
                    </div>
                    <div class="h-100 map-container">
                        <div [chart]="mapa_movil"
                            data-toggle="modal"
                            data-target="#modalMovilMapa"
                        ></div>
                    </div>
                </div>
                <div class="row d-block d-xl-none">
                    <div class="col-12 pt-2">
                        <p class="em14 padding-b-10">
                            Se resalta el Partido Político o <span *ngIf="_obtenerDatosService?.config?.estado == 'Oaxaca'">Candidatura Común</span> <span *ngIf="_obtenerDatosService?.config?.estado != 'Oaxaca'">Coalición</span> que lleva ventaja hasta el momento con mayor número de Municipios obtenidos.
                        </p>
                    </div>
                </div>
                <app-resumen-votacion-mobile
                    [total_num]="resumen?.total?.total"
                    [total_prc]="resumen?.total?.prc"
                ></app-resumen-votacion-mobile>
                <div class="row py-4">
                    <div class="col-12">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                    </div>
                </div>
                <div class="contenedor-mapa">
                    <div class="row">
                        <div class="col-md-6">
                            <!-- LISTADO DE ESCRITORIO -->
                            <div class="col-md-8 tabla container-fluid d-none d-md-none d-xl-block" *ngIf="_obtenerDatosService?.config?.estado != 'Veracruz'">
                                <div class="row titulos no-border">
                                    <div class="col-12 px-0">
                                        <h4 class="subtitulo2-seccion mb-0 h-100 titulo-distritos">
                                            <strong>{{labels?.contienda}} obtenidos</strong>
                                        </h4>
                                    </div>
                                    
                                    <!-- <div class="col-6">
                                        <div class="row">
                                            <div class="col-5 pr-0">
                                                <h4 class="subtitulo2-seccion">
                                                    <strong>Votos</strong>
                                                </h4>
                                            </div>
                                            <div class="col-7">
                                                <h4 class="subtitulo2-seccion titulo-porcentaje font-medium">
                                                    <strong>Porcentaje de votos</strong>
                                                </h4>
                                            </div>
                                        </div>
                                    </div> -->
                                    
                                </div>
                                <div class="row fila col-12 px-0 mx-0" *ngFor="let fila of mapa_table; let i = index" [ngClass]="{filaGanador: fila?.winner}">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-8 dis-flex justify-s pl-0">
                                                <div>
                                                    <div class="color-partido mx-1" [ngStyle]="{'background': fila?.color}">
                                                        &nbsp;
                                                    </div>
                                                    <!-- {{ _obtenerDatosService?.diccionarioPartidos[partido].colorPartido | json }} -->
                                                </div>
                                                <img class="img-partido" *ngFor="let img of fila?.imgs" src="./assets/img/logos-partidos/{{img.toLowerCase()}}">
                                            </div>
                                            <div class="col-4 distrito-numero dis-flex justify-e no-border">
                                                <p>
                                                    <strong>{{ fila?.ganados }}</strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <!-- <div class="col-6 dis-flex">
                                        <div class="container-fluid px-0">
                                            <div class="row">
                                                <div class="col-6 votos-numero dis-flex justify-e">
                                                    <p>
                                                        <strong>{{ resumen_table[i]?.data?.r1 }}</strong>
                                                    </p>
                                                </div>
                                                <div class="col-6 votos-porcentaje dis-flex flex-col align-s px-0">
                                                    <p>
                                                        <strong>{{ resumen_table[i]?.data?.r2 }}</strong>
                                                    </p>
                                                    <div class="mini-grafica w-100">
                                                        <div [ngStyle]="{'background': fila?.color}" [style.width.%]="resumen_table[i]?.data?.pctj" class="avance">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    
                                </div>
                                <div class="row col-12 py-4">
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-8 dis-flex justify-s pl-0">
                                                <p>
                                                    <strong style="font-size: 2em;">Total</strong>
                                                </p>
                                            </div>
                                            <div class="col-4 distrito-numero dis-flex justify-e no-border">
                                                <p>
                                                    <strong>{{contiendas_total}}</strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Veracruz -->
                            <div class="tabla container-fluid d-none d-md-none d-xl-block" *ngIf="_obtenerDatosService?.config?.estado == 'Veracruz'">
                                <div class="row titulos">
                                    <div class="col-6 px-0">
                                        <h4 class="subtitulo2-seccion mb-0 h-100 titulo-distritos">
                                            <strong>{{labels?.contienda}} obtenidos</strong>
                                        </h4>
                                    </div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-5 pr-0">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row fila col-12 px-0 mx-0" *ngFor="let fila of mapa_table; let i = index" [ngClass]="{filaGanador: fila?.winner}">
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-8 dis-flex justify-s pl-0">
                                                <div class="color-partido mx-1" [ngStyle]="{'background': fila?.color}">
                                                    &nbsp;
                                                </div>
                                                <img class="img-partido" *ngFor="let img of fila?.imgs" src="./assets/img/logos-partidos/{{img.toLowerCase()}}">
                                            </div>
                                            <div class="col-4 distrito-numero dis-flex justify-e">
                                                <p>
                                                    <strong>{{ fila?.ganados }}</strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 dis-flex">
                                        <div class="container-fluid px-0">
                                            <div class="row">
                                                <div class="col-6 votos-numero dis-flex justify-e">
                                                    <p>
                                                        <strong>{{ resumen_table[i]?.data?.r1 }}</strong>
                                                    </p>
                                                </div>
                                                <div class="col-6 votos-porcentaje dis-flex flex-col align-s px-0">
                                                    <p>
                                                        <strong>{{ resumen_table[i]?.data?.r2 }}</strong>
                                                    </p>
                                                    <div class="mini-grafica w-100">
                                                        <div [ngStyle]="{'background': fila?.color}" [style.width.%]="resumen_table[i]?.data?.pctj" class="avance">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row col-12 py-4">
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-8 dis-flex justify-s pl-0">
                                                <p>
                                                    <strong style="font-size: 2em;">Total</strong>
                                                </p>
                                            </div>
                                            <div class="col-4 distrito-numero dis-flex justify-e no-border">
                                                <p>
                                                    <strong>{{contiendas_total}}</strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- TARJETAS MOVIL -->
                            <movil-listado-content 
                                [selectedItemFunc]="selectedItemFun" 
                                [diselectedItemFunc]="diselectedItemFun"
                                [infoList]="fichas" 
                                [title]="labels?.contienda"
                                [tipoTarjeta]="10"></movil-listado-content>
                            <!--********************************************************************************************************************-->
                        </div>
                        <div class="col-md-6 d-none d-xl-block">
                            <div>
                                <span class="em18">
                                    <strong>Mapa de {{labels?.label_map}}</strong>
<!--                                    <strong>Mapa de {{labels?.contienda == 'Distritos' ? 'Distritos Electorales' : labels?.contienda}}</strong>-->
                                </span>
                                <p class="em15 padding-b-10">
                                  El mapa resalta en {{ labels?.contienda != 'Juntas' ? 'los' : 'las' }} {{labels?.contienda == 'Distritos' ? 'Distritos Electorales' : labels?.contienda}} el Partido Político o {{ _obtenerDatosService.config?.estado == 'Oaxaca' ? 'Candidatura Común' : 'Coalición' }} que lleva ventaja hasta el momento.
                                </p>
                            </div>
                            <div class="h-100">
                                <div [chart]="mapa"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid" *ngIf="tipo_contienda == 'diputaciones'">
                    <div class="row pb-4">
                        <div class="col-10">
                            <p class="font-medium em14">
                                {{_obtenerDatosService?.main?.texto_porcentaje_estadistica ? 'Se incluye' : 'No se considera'}} la cantidad de votos asentada en las Actas PREP de Casilla Especial de Representación Proporcional.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- ****************************************************************************************************************** -->
                <!-- **************************************** TABLE DE TOTAL DE VOTOS EN MAPA *****************************************-->
                <div class="container-fluid mt-4 d-none d-md-none d-xl-block" *ngIf="_obtenerDatosService?.config?.estado != 'Veracruz'"> 
                    <div class="table-responsive tabla-seccion max-500">
                        <table class="table table-striped contenido table-bordered">
                            <thead class="titulos">
                                    <tr>
                                    <th class="sticky-cell table-header-background">
                                        <div class="dis-flex">
                                        <ng-container>
                                            <ng-container>
                                            </ng-container>
                                        </ng-container>
                                        </div>
                                    </th>
                                    <th *ngFor="let itemHeader of mapa_table; let i = index">
                                        <div class="dis-flex">
                                            <ng-container>
                                                <!-- <ng-container *ngIf="itemHeader?.text != ''">
                                                    <strong>{{itemHeader?.text}}</strong>
                                                </ng-container> -->
                                                <ng-container *ngFor="let img of itemHeader?.imgs">
                                                    <img class="img-partido" src="./assets/img/logos-partidos/{{img?.toLowerCase()}}">
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="sticky-cell">
                                        <ng-container>
                                            <span>Total de Votos</span>
                                        </ng-container>
                                    </td>
                                    <td *ngFor="let itemVoto of mapa_table; let i=index;">
                                        <span>{{ resumen_table[i]?.data?.r1 }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="sticky-cell">
                                        <ng-container>
                                            <span>Porcentaje</span>
                                        </ng-container>
                                    </td>
                                    <td *ngFor="let itemVoto of mapa_table; let i=index;">
                                        <span>{{ resumen_table[i]?.data?.r2 }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- ****************************************************************************************************************** -->
                <!-- ****************************************************************************************************************** -->
                
                <app-resumen-votacion
                    [acumulados_num]="resumen?.acumulados?.total"
                    [acumulados_prc]="resumen?.acumulados?.prc"
                    [noregistradas_num]="resumen?.no_registradas?.total"
                    [noregistradas_prc]="resumen?.no_registradas?.prc"
                    [nulos_num]="resumen?.nulos?.total"
                    [nulos_prc]="resumen?.nulos?.prc"
                    [total_num]="resumen?.total?.total"
                    [total_prc]="resumen?.total?.prc"
                ></app-resumen-votacion>

                <!-- <div class="container-fluid py-1 d-block d-md-block d-xl-none pt-4">
                    <div class="row">
                        <div class="col-md-12 dis-flex flex-col align-e">
                            <button
                                [disabled]="comparativaService?.arregloElementos.length < 1"
                                (click)="comparativaService.reiniciarModal()"
                                class="boton-detalle btn bgMorado txBlanco btn-sm texto ml-2 px-4 cursorPointer" data-toggle="modal" data-target="#modalComparativa">
                                Ver detalle
                            </button>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>
    </div>
</div>
<app-estadistica-nacional [load_main]="true"></app-estadistica-nacional>

<div class="modal fade modalMovilMapa" id="modalMovilMapa" tabindex="-1" role="dialog" aria-labelledby="ModalMovilMapa" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" [ngClass]="{'modal-dark': themeService.isDarkTheme()}" role="document">
        <div class="modal-content contendor-mapa-movil" >
            <p class="texto-salir" data-dismiss="modal" >Salir del mapa</p>
            <div class="mapa" [chart]="mapa_modal"></div>
            <img  class="icono-expandir" src="assets/img/iconos-visualiza/finger_expand.svg">
        </div>
    </div>
</div>
