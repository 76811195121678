import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';

// declare var $;
// import * as $ from 'jquery';

@Component({
  selector: 'app-diputacion-migrante',
  templateUrl: './diputacion-migrante.component.html',
  styleUrls: ['./diputacion-migrante.component.scss']
})

export class DiputacionMigranteComponent implements OnInit {
  resumen: any = [];
  casilla_table: any = [];
  estadistica_extranjero: any = [];

  base: string;


  constructor(
    public _globalService: GlobalesService,
    private readonly activatedRouter: ActivatedRoute,
    public readonly rutas: RutasService,
    public _obtenerDatosService: ObtenerDatosService
  ) { }

  ngOnInit(): void {
      this.obtenerParametros();
      this._globalService.menuActual = 'RP';
      this._obtenerDatosService.mainSubject.subscribe(data =>{
        if(data == null) return;
      this.loadData();
    });
  }

  // ngOnDestroy(): void {
  //   this._obtenerDatosService.mainSubject.unsubscribe();
  // }

  loadData() {
      let selectedContienda = this._globalService.getContienda();
      this._obtenerDatosService.loadJSON(selectedContienda, 'voto-extranjero.json').then(data => {
          this.resumen = data['resumen'];
          this.casilla_table = data['casilla_table'];
          this.estadistica_extranjero = data['estadistica_extranjero'];
          this._obtenerDatosService.desglose = data['casilla_table'];
          
      });
    // }
  }

  obtenerParametros() {
    this.activatedRouter.params.subscribe((parametros) => {
      const params = { parametros };
      this._globalService.detalles = params.parametros;
    });
    this._globalService.setearParametros();
  }

  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

}