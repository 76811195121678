import { Component, Inject, Input, OnInit } from '@angular/core';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { Router } from '@angular/router';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';
import { CombosService } from 'src/app/servicios/combos/combos.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ObtenerDatosService } from '../../../servicios/datos/obtener-datos/obtener-datos.service';


@Component({
	selector: 'app-bottom-sheet',
	templateUrl: './bottom-sheet.component.html',
	styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheetComponent implements OnInit {
	@Input() public tpl:any = 'default';
	@Input() public data:any = [];
	@Input() public callback:any = function() {};

	constructor(
		public rutasService: RutasService,
		public globalService: GlobalesService,
		public router: Router,
		public combosService: CombosService,
		public activatedRoute: ActivatedRoute,
		public location: Location,
		public datosService: ObtenerDatosService,
	) {
	
	}
	
	ngOnInit(): void {
	}

}
