<footer class="container-fluid bgMorado txBlanco pie-pagina">
    <div class="row">
        <div class="col-xl-10 offset-xl-2 dis-flex space-a px12 px-0 py-2 d-block d-md-block d-xl-none bgRg">
            <div class="d-flex flex-row justify-content-center">
                <img class="img-ine-mini img-footer" src="assets/img/ople/logo_ople_blanco.png" alt="Logo PREP">
                <!-- <img class="max-logo2 img-fluid" style="max-height: 60px;" src="assets/img/ople/logo_ople_prep_blanco.png" alt="Logo PREP"> -->


                <!-- <img class="max-logo img-fluid" style="max-height: 60px;" src="assets/img/generales/logo.png" alt="Logo OPLE" *ngIf="!themeService.isDarkTheme()">
                <img class="max-logo img-fluid" style="max-height: 60px;" src="assets/img/ople/logo_ople_blanco.png" alt="Logo OPLE" *ngIf="themeService.isDarkTheme()"> -->

                <img class="max-logo2 img-fluid img-footer" src="assets/img/ople/logo_ople_prep_blanco.png" alt="Logo PREP">
            </div>
        </div>
        <div class="col-xl-10 offset-xl-2 dis-flex space-a px12 px-0 py-2 txtBg d-none d-xl-block">
            <p class="m-0 align-center">
                Este sitio se visualiza mejor en las versiones más recientes de los navegadores web &#160;|&#160; <span [innerHTML]="config?.footer?.ople"></span> <span *ngIf="config?.estado == 'Veracruz'">&#160;|&#160; </span>
                <span *ngIf="config?.estado == 'Veracruz'" class="cursorPointer" (click)="openModal()">Aviso de privacidad</span>
            </p>
        </div>
        <div class="col-xl-10 offset-xl-2 dis-flex space-a px12 px-0 py-2 txtBg d-block d-xl-none aviso-cont">
            <p class="m-0 align-center">
                <span *ngIf="config?.estado == 'Veracruz'" class="cursorPointer" (click)="openModal()" style="text-decoration: underline;">Aviso de privacidad</span><br *ngIf="config?.estado == 'Veracruz'"><br>
                <span [innerHTML]="config?.footer?.ople"></span><br><br>
                <span [innerHTML]="config?.footer?.version"></span>
            </p>
        </div>
    </div>
</footer>