<div class="seccion-resumen container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 col-lg-10 padding-b-20">
                        <app-breadcrumb
                            subtitle="Representación proporcional - Nacional"
                        ></app-breadcrumb>
                        <div class="texto font-regular">
                            <p>
                                El total de votos calculado y porcentaje que se muestran, se refiere a los votos asentados en las Actas PREP hasta el momento.
                            </p>
                            <p>
                                Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%.
                            </p>
                        </div>
                    </div>
                </div>
                <app-resumen-votacion-mobile
                    [total_num]="_obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.resumenVotacion?.totalVotos?.total"
                    [total_prc]="_obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.resumenVotacion?.totalVotos?.porcentaje"
                ></app-resumen-votacion-mobile>
                <div class="row pb-4">
                    <div class="col">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="row dis-flex pb-1 mt-2">
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollLeft()">
                            <i class="fas fa-chevron-left" aria-hidden="true"></i>
                        </div>
                        <div class="col-11">
                            <div #widgetsContent class="row dis-flex justify-s scrollH">
                                <div *ngFor="let tarjeta of _obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.votos"
                                    class="col-6 col-sm-4 col-md-3 col-lg-2 px-1">
                                    <div class="card-resumen">
                                        <div class="encabezado dis-flex w-100 py-2">
                                            <img class="img-partido" [src]="_obtenerDatosService.pathImg+_obtenerDatosService.diccionarioPartidos[tarjeta.partido]?.imgPartido?.toLowerCase()" />
                                            <!-- <div class="dis-flex w-50">
                                                <div class="row w-80 dis-flex">
                                                    <p class="pe p-1 m-4">
                                                        {{ tarjeta.partido }}
                                                    </p>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="cuerpo dis-flex bgGrisTenue p-2">
                                            <div class="w-50">
                                                <p>Votos</p>
                                                <p class="votos">
                                                    <strong>{{ tarjeta?.votosPartido }}</strong>
                                                </p>
                                            </div>
                                            <div class="w-50 dis-flex flex-col align-e pr-3 pr-xl-0">
                                                <p>Porcentaje</p>
                                                <p>{{ tarjeta?.porcentaje }}%</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollRight()">
                            <i class="fas fa-chevron-right" aria-hidden="true"></i>
                        </div>
                    </div>
               </div>

               <app-resumen-votacion
                    [acumulados_num]="_obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.resumenVotacion?.acumulados?.total"
                    [acumulados_prc]="_obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.resumenVotacion?.acumulados?.porcentaje"
                    [noregistradas_num]="_obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.resumenVotacion?.noRegistradas?.total"
                    [noregistradas_prc]="_obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.resumenVotacion?.noRegistradas?.porcentaje"
                    [nulos_num]="_obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.resumenVotacion?.nulos?.total"
                    [nulos_prc]="_obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.resumenVotacion?.nulos?.porcentaje"
                    [total_num]="_obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.resumenVotacion?.totalVotos?.total"
                    [total_prc]="_obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.resumenVotacion?.totalVotos?.porcentaje"
                ></app-resumen-votacion>

               <div class="container-fluid resumen-votacion">
                <div class="row">
                    <div class="col-12 col-lg-8 col-xl-8 bgGris py-2 pxx">
                        <p class="titulo">
                            <strong>Resumen de la votación</strong>
                        </p>
                        <div class="container-fluid">
                            <div class="row dis-flex">
                                <div class="apartado dis-flex flex-col">
                                    <div class="dis-flex w-100 align-s">
                                        <p class="subtitulo">
                                            Votos acumulados &nbsp;
                                        </p>
                                        <span tooltip="Es la sumatoria de los votos obtenidos por Partido Político, {{ _obtenerDatosService.config?.estado == 'Oaxaca' ? 'Candidatura Común' : 'Coalición' }}." placement="top" show-delay="200">
                                            <i class="fas fa-info-circle" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                    <div class="row dis-flex w-100 space-b">
                                        <p class="col-12 cantidad">
                                            <strong>
                                                {{ _obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.resumenVotacion?.acumulados?.total }}
                                            </strong>
                                        </p>
                                        <p class="col-12 porcentaje">
                                            {{ _obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.resumenVotacion?.acumulados?.porcentaje }}%
                                        </p>
                                    </div>
                                </div>
                                <p class="separador">
                                    <strong>+</strong>
                                </p>
                                <div class="apartado dis-flex flex-col">
                                    <div class="dis-flex w-100">
                                        <p class="subtitulo">
                                            Candidaturas no registradas
                                        </p>
                                    </div>
                                    <div class="row dis-flex w-100 space-b">
                                        <p class="col-12 cantidad">
                                            <strong>
                                                {{ _obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.resumenVotacion?.noRegistradas?.total }}
                                            </strong>
                                        </p>
                                        <p class="col-12 porcentaje">
                                            {{ _obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.resumenVotacion?.noRegistradas?.porcentaje }}%
                                        </p>
                                    </div>
                                </div>
                                <p class="separador">
                                    <strong>+</strong>
                                </p>
                                <div class="apartado dis-flex flex-col">
                                    <div class="dis-flex w-100">
                                        <p class="subtitulo">
                                            Nulos
                                        </p>
                                    </div>
                                    <div class="row dis-flex w-100 space-b">
                                        <p class="col-12 cantidad">
                                            <strong>
                                                {{ _obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.resumenVotacion?.nulos?.total }}
                                            </strong>
                                        </p>
                                        <p class="col-12 porcentaje">
                                            {{ _obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.resumenVotacion?.nulos?.porcentaje }}%
                                        </p>
                                    </div>
                                </div>
                                <p class="d-none d-lg-block separador pr-3">
                                    <strong>=</strong>
                                </p>
                                <div class="d-none d-lg-block apartado dis-flex flex-col">
                                    <div class="dis-flex w-100 justify-e">
                                        <p class="px20">
                                            <strong>Total de votos</strong>
                                        </p>
                                    </div>
                                    <div class="dis-flex w-100 space-b justify-e">
                                        <p class="total">
                                            <strong class="txRosaIne">
                                                {{ _obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.resumenVotacion?.totalVotos?.total }}
                                            </strong>
                                        </p>
                                    </div>
                                    <div class="dis-flex w-100 justify-e">
                                        <p>
                                            <strong>
                                                <span class="txRosaIne">100.0000%</span>
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="container-fluid d-lg-none">
                                <div class="row">
                                    <div class="col-12 dis-flex flex-col mt-3">
                                        <div class="dis-flex w-100 justify-e">
                                            <p class="px20 mb-0">
                                                <strong>Total de votos</strong>
                                            </p>
                                        </div>
                                        <div class="dis-flex w-100 justify-e">
                                            <p class="total">
                                                <strong>
                                                    = <span class="txRosaIne">
                                                        {{ _obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.resumenVotacion?.totalVotos?.total }}
                                                    </span>
                                                </strong>
                                            </p>
                                        </div>
                                        <div class="dis-flex w-100 justify-e">
                                            <p>
                                                <strong>
                                                    <span class="txRosaIne">100.0000%</span>
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>

    <!-- <div class="row tabla-seccion tabla-scroll mt-3">
        <div class="col-12">
            <div class="tabla card pb-3">
                <div class="p-3 pb-0">
                    <p class="titulo">
                        <strong>
                            Detalle de votos por Casillas Especiales
                        </strong>
                    </p>
                    <div class="acta-digitalizada container-fluid">
                        <div class="row">
                            <div class="col-5 px12 pl-0">
                                <p class="font-medium text-left padding-b-15">
                                    Imagen procedente de:
                                </p>
                                <div class="container-fluid pl-0">
                                    <div class="row">
                                        <div class="col-3 dis-flex border-rightB">
                                            <div class="grupo gris dis-flex">
                                                <i class="far fa-file"></i>
                                                <i class="far fa-hourglass"></i>
                                                <p>
                                                    Acta en proceso
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-4 dis-flex border-rightB">
                                            <div class="grupo dis-flex cursor-hand" tooltip="Acta PREP digitalizada por medio de escáner." placement="top" max-width="500">
                                                <i class="far fa-file"></i>
                                                <i class="fas fa-print" aria-hidden="true"></i>
                                                <p class="color-gris">
                                                    Escáner
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-3 dis-flex">
                                            <div class="grupo dis-flex cursor-hand" tooltip="Acta PREP digitalizada por medio de dispositivo móvil." placement="top" max-width="500">
                                                <i class="far fa-file"></i>
                                                <i class="fas fa-mobile-alt"></i>
                                                <p class="color-gris">
                                                    Móvil
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="contenido">
                    <div class="container-fluid">
                        <div class="table-responsive max-500">
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th *ngFor="let itemHeader of _obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.listaCasillas?.header">

                                            <ng-container *ngFor="let itemPartido of itemHeader.partidos" class="dis-flex">
                                                <ng-container *ngIf="itemPartido == ''" class="dis-flex">
                                                    <strong class="txGris dis-flex text-center">
                                                        {{itemHeader.texto}}
                                                    </strong>
                                                </ng-container>
                                                <ng-container *ngIf="itemPartido != ''">
                                                    <img class="img-partido" [src]="_obtenerDatosService.pathImg + _obtenerDatosService?.diccionarioPartidos[itemPartido].imgPartido" alt="">
                                                </ng-container>
                                            </ng-container>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let itemKey of _obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.listaCasillas?.body | keyvalue : asIsOrder">
                                        <tr *ngFor="let itemBody of _obtenerDatosService.dataDiputacionesNacional?.representacionProporcional?.listaCasillas?.body[itemKey.key].tabla">

                                                <td >
                                                    {{itemBody.casilla}}
                                                </td>
                                                <td >
                                                    <a target="_blank" [href]="itemBody.url" *ngIf="itemBody.tipoDigitalizacion !== '-'">
                                                        <ng-container *ngIf="itemBody.tipoDigitalizacion === 'M'">
                                                            <i class="far fa-file" aria-hidden="true"></i>
                                                            <i class="fas fas fa-mobile-alt" aria-hidden="true"></i>
                                                        </ng-container>
                                                        <ng-container *ngIf="itemBody.tipoDigitalizacion === 'E'">
                                                            <i class="far fa-file" aria-hidden="true"></i>
                                                            <i class="fas fa-print" aria-hidden="true"></i>
                                                        </ng-container>
                                                    </a>
                                                    <ng-container *ngIf="itemBody.tipoDigitalizacion === '-'">
                                                        <i class="far fa-file"></i>
                                                        <i class="far fa-hourglass"></i>
                                                    </ng-container>
                                                </td>
                                                <td *ngFor="let itemVoto of itemBody.votosPartidos">
                                                    {{itemVoto}}
                                                </td>
                                                <td>
                                                    {{ itemBody?.candidaturas }}
                                                </td>
                                                <td>
                                                    {{ itemBody?.votosNulos }}
                                                </td>
                                                <td>
                                                    {{ itemBody?.totalVotos }}
                                                </td>
                                                <td>
                                                    {{ itemBody?.observaciones }}
                                                </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->



    <app-estadistica-nacional></app-estadistica-nacional>
</div>
