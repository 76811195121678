import { Component, OnInit } from '@angular/core';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { ObtenerDatosService } from '../../../../servicios/datos/obtener-datos/obtener-datos.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-base-datos-inicio',
  templateUrl: './base-datos-inicio.component.html',
  styleUrls: ['./base-datos-inicio.component.scss']
})
export class BaseDatosInicioComponent implements OnInit {
  config:any = {};
  safeURL:any = ""
  startVideo = false

  constructor(
    private readonly globalService: GlobalesService,
    public readonly _obtenerDatosService: ObtenerDatosService,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.globalService.menuActual = 'BaseDatos';
    this.globalService.unselectContiendas();

    this._obtenerDatosService.configSubject.subscribe(data =>{
      if (data != null){ 
        this.config = data;
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(data.url_video + "?autoplay=1");
        console.log("URL ", this.safeURL)
      }
    });
  }

  onStartViedeo(){
    this.startVideo = true;
  }

}
