import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';

// declare var $;
// import * as $ from 'jquery';

@Component({
  selector: 'app-distrito-secciones',
  templateUrl: './distrito-secciones.component.html',
  styleUrls: ['./distrito-secciones.component.scss']
})
export class DistritoSeccionesComponent implements OnInit {
  seccion_table: any = [];
  actas_participacion: any
  fichas: any = [];
  base: any;
  contiendaActual: string;
  labels: any = {};
  short_data: any = {};
  resumen: any = {};

  constructor(
    public readonly _globalService: GlobalesService,
    private readonly activatedRoute: ActivatedRoute,
    public _obtenerDatosService: ObtenerDatosService,
    public readonly rutas: RutasService,
    public readonly router: Router,

  ) { }

  ngOnInit(): void {
    this.obtenerParametros();
    this.base = this.rutas.rutaActual.base;
    this.rutas.migas.seccion = 'Todas';
    this.contiendaActual = this._globalService.getContienda();
      
    // $('[data-toggle="tooltip"]').tooltip();

    this.activatedRoute.data.subscribe(v => {
      this.labels.contienda = v.contienda || '[-]';
      this.labels.contienda_s = v.contienda_s || '[-]';
    });

    this._obtenerDatosService.mainSubject.subscribe(data =>{
      if(data == null) return;

      this.loadData();
    });
  }

  // ngOnDestroy(): void {
  //     this._obtenerDatosService.mainSubject.unsubscribe();
  // }

  loadData() {
    let selectedContienda = this._globalService.getContienda();

    this._obtenerDatosService.loadJSON(selectedContienda, 'seccion.' + this._globalService.param_distrito + '.json').then(data => {
		this._obtenerDatosService.seccionesSubject.next(data);
		this.seccion_table = data['seccion_table']? data['seccion_table'] : data['resumen_detalle_table'];
		this.fichas = data['fichas'];
		this.actas_participacion = data['actas_participacion'];
		this.short_data = this.actas_participacion.head_info;
    this._globalService.shortData.next(this.short_data);
		this.resumen = data['resumen'];

    });

	// this._obtenerDatosService.seccionesSubject.subscribe(data => {
	// 	console.log('entrosubscribe');
	// 	if (data != null) {
	// 		console.log('entroif');
	// 		this._obtenerDatosService.seccionesSubject.next(data);
	// 		this.seccion_table = data['seccion_table']? data['seccion_table'] : data['resumen_detalle_table'];
	// 		this.fichas = data['fichas'];
	// 		this.actas_participacion = data['actas_participacion'];
	// 		this.short_data = this.actas_participacion.head_info;
	// 		this.resumen = data['resumen'];
	// 	}
	// });
  }

  obtenerParametros() {
    this.activatedRoute.params.subscribe((parametros) => {
      const params = { parametros };
      this._globalService.detalles = params.parametros;
    });
    this._globalService.setearParametros();
  }

  onNavigate(seccion) {
			// 	delete this.rutas.migas.distrito;
			// 	delete this.rutas.migas.distrito_clave;
			// 	delete this.rutas.migas.seccion;
			// 	delete this.rutas.migas.seccion_clave;
			// 	delete this.rutas.migas.casilla; 
			// 	delete this.rutas.migas.casilla_clave;
			// 	break;
		
			// case 2:
			// 	delete this.rutas.migas.seccion;
			// 	delete this.rutas.migas.seccion_clave;
			// 	delete this.rutas.migas.casilla; 
			// 	delete this.rutas.migas.casilla_clave;
			// 	break;

			// case 3:
			// 	delete this.rutas.migas.casilla; 
			// 	delete this.rutas.migas.casilla_clave;
			// 	break;

			// default:
			// 	break;
		// }

    this.router.navigateByUrl(this.rutas.rutaActual.ruta + '/' + seccion);
    this.rutas.checkMigas(this.rutas.rutaActual.ruta);
  }


  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

  checkHeader(value?: String){
    if(value && value == "Candidatura no registrada"){
      return "Candidatura no <br> registrada";
    }
    return value
  }
}
