import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { HttpClientModule } from '@angular/common/http';
import { ImportingModule } from '../../importing.module';

import { GubernaturaCandidaturaRoutingModule } from './gubernatura-candidatura-routing.module';

import { VotosCandidaturaGraficaComponent } from './votos-candidatura/votos-candidatura-grafica.component';
import { VotosCandidaturaTarjetasComponent } from './votos-candidatura/votos-candidatura-tarjetas.component';

// import { VotosPpyciGraficaComponent } from './votos-ppyci/votos-ppyci-grafica.component';
// import { VotosPpyciTarjetasComponent } from './votos-ppyci/votos-ppyci-tarjetas.component';

// import { DistritoVotosCandidaturaComponent } from './distritos/distrito-votos-candidatura.component';
// import { DesglosetableComponent } from '../componentes/plantilla/desglosetable/desglosetable.component';

@NgModule({
  declarations: [
    VotosCandidaturaGraficaComponent,
    VotosCandidaturaTarjetasComponent,
    // VotosPpyciGraficaComponent,
    // VotosPpyciTarjetasComponent,
    // DistritoVotosCandidaturaComponent
    // DesglosetableComponent
  ],
  imports: [
    CommonModule,
    // HttpClientModule,
    ImportingModule,

    GubernaturaCandidaturaRoutingModule
  ]
})
export class GubernaturaCandidaturaModule { }
