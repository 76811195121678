<div class="preguntas-frecuentes">
    <div class="container-fluid">
        <div class="row bgBlanco">
            <div class="col-12 px-3 py-4">
                <h2 class="subtitulo-seccion ml-4 pl-2">
                    <strong>Preguntas frecuentes</strong>
                </h2>
            </div>
        </div>
    </div>
    <div class="container my-3">
        <div class="row">
            <div class="col-12">
                <app-pregunta-buscador></app-pregunta-buscador>
            </div>
        </div>
    </div>
    <!-- <div class="preguntas-container"> -->
    <div id="preguntas-container">
        <app-pregunta-avance-nacional></app-pregunta-avance-nacional>
        <app-pregunta-consulta-votacion></app-pregunta-consulta-votacion>
    
        <app-pregunta-resultados-casilla></app-pregunta-resultados-casilla>
        <app-pregunta-votos-actas></app-pregunta-votos-actas>
    
        <app-pregunta-visualizacion-tarjetas></app-pregunta-visualizacion-tarjetas>
        <app-pregunta-visualizacion-graficos></app-pregunta-visualizacion-graficos>
        
        <app-pregunta-ver-detalle></app-pregunta-ver-detalle>
        <app-pregunta-resumen-votacion></app-pregunta-resumen-votacion>
        
        <app-pregunta-estadistica></app-pregunta-estadistica>
        <app-pregunta-observaciones-actas></app-pregunta-observaciones-actas>
        
        <app-pregunta-elementos-apoyo></app-pregunta-elementos-apoyo>
    </div>
    <!-- </div> [innerHtml]="'' | highlightSearch: Search" -->
</div>