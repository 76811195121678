<div class="seccion-resumen container-fluid" id="maincontToScroll">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row d-block d-md-block d-xl-none" style="margin-bottom: 3em;">
                    <div class="row pb-4">
                        <div class="col">
                            <h4 class="subtitulo-seccion" style="margin-left: 1em;">
                                <strong>Consulta de votación</strong>
                            </h4>
                        </div>
                    </div>
                    <button (click)="openBottomSheet()" type="button" id="dDistrito" class="btn btn-combo dis-flex space-b">
                        <p class="casilla-label">
                            Casillas: <br> {{selectedCasilla}}
                        </p>
                        <span class="fa fa-sort-down ic-down"></span>
                    </button>

                </div>
                <div class="row">
                    <div class="col-12 col-lg-10 padding-b-20">
                        <h3 class="titulo-seccion d-none d-md-none d-xl-block">
                            <strong>{{ selectedContienda | titlecase }}</strong> - <span class="txRosaIne"><strong>Casillas Especiales</strong></span>
                        </h3>
                        <h3 class="titulo-seccion d-block d-md-block d-xl-none">
                            <strong>{{ selectedContienda | titlecase }}</strong> / <br>
                            <span class="txRosaIne"><strong>Representación Proporcional</strong></span>
                        </h3>

                        <p class="titulo-entidad d-block d-md-block d-xl-none">
                            <strong>{{obtenerDatosService?.config?.estado}}</strong>
                        </p>

                        <div class="texto font-medium">
                            <p>
                                El total de votos calculado y porcentaje que se muestran por Representación Proporcional de cada Casilla Especial
                                que conforman a la Entidad, se refieren a los votos asentados en las Actas PREP hasta el momento.
                            </p>
                            <p>
                                <br class="d-block d-md-block d-xl-none">
                                Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No
                                obstante, al considerar todos los decimales, suman 100%.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="container-fluid barra-lateral d-block d-md-block d-xl-none">
                    <div class="pt-2 dis-flex pb-2">
                        <button (click)="atras()" class="btn btn-sm btn-azulFuerte px14 btnBack">
                            Regresar
                        </button>
                    </div>
                </div>

                <app-resumen-votacion-mobile
                    [total_num]="total_num"
                    [total_prc]="total_prc"
                ></app-resumen-votacion-mobile>
                <div class="row pb-4">
                    <div class="col">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                    </div>
                </div>
                <!--************************ TARJETAS EN ESCRITORIO ***********************-->
                <!-- <div class="container-fluid d-none d-md-none d-xl-block">
                    <div class="row dis-flex pb-1 mt-2">
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollLeft()">
                            <i class="fas fa-chevron-left" aria-hidden="true"></i>
                        </div>
                        <div class="col-11">
                            <div #widgetsContent class="row dis-flex justify-s scrollH">
                                <div *ngFor="let tarjeta of fichas; let i=index;"
                                    class="col-6 col-sm-4 col-md-3 col-lg-2 px-1">
                                    <div class="card-resumen">
                                        <div class="encabezado dis-flex w-100 py-2">
                                            <div class="dis-flex w-100">
                                                <div class="row w-100 dis-flex">
                                                    <img class="img-partido" *ngFor="let img of tarjeta.imgs" src="./assets/img/logos-partidos/{{img?.toLowerCase()}}">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cuerpo dis-flex bgGrisTenue p-2">
                                            <div class="w-50">
                                                <p>Votos</p>
                                                <p class="votos">
                                                    <strong>{{ tarjeta?.total }}</strong>
                                                </p>
                                            </div>
                                            <div class="w-50 dis-flex flex-col align-e pr-3 pr-xl-0">
                                                <p>Porcentaje</p>
                                                <p>{{ tarjeta?.prc }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollRight()">
                            <i class="fas fa-chevron-right" aria-hidden="true"></i>
                        </div>
                    </div>
                </div> -->
                <!--***********************************************************************-->
                <!-- TARJETAS MOVIL -->
                <!-- <movil-listado-content
                [infoList]="fichas"
                [tipoTarjeta]="8"></movil-listado-content> -->
                <!--********************************************************************************************************************-->
                <!--************* VISATA ESCRITORIO *************-->
                <div class="row">
                    <div class="col">
                        <h4 class="subtitulo-seccion" style="margin-bottom: 0 !important;">
                            <strong class="d-none d-xl-block">
                                Detalle de votos por Casilla
                            </strong>
                        </h4>
                    </div>
                </div>
                <div class="col-6 border-rightB padding-r-0 my-3 py-1" *ngIf="!hideBtnActa">
                    <p class="movil-acta">Ver Acta Digitalizada en:</p>
                    <a target="_blank" [href]="acta_url">
                        <button
                            class="btn btn-sm btn-rosaFuerte texto cursorPointer px-4">
                            <ng-container *ngIf="casilla?.status?.movil">
                                <i class="far fa-file" aria-hidden="true" style="margin-right: 4px;"></i>
                                <i class="fas fas fa-mobile-alt" aria-hidden="true"></i>
                                Movil
                            </ng-container>
                            <ng-container *ngIf="casilla?.status?.scanner">
                                <i class="far fa-file" aria-hidden="true" style="margin-right: 4px;"></i>
                                <i class="fas fa-print" aria-hidden="true"></i>
                                Escáner
                            </ng-container>
                            <ng-container *ngIf="casilla?.actaDigitalizada === '-'">
                                <i class="fas fa-file" style="margin-right: 4px;"></i>
                                <i class="fas fa-hourglass"></i>
                                Acta
                            </ng-container>
                        </button>
                    </a>
                </div>
                <div class="row  d-none d-md-none d-xl-block">
                    <div class="col-12 px-0">
                        <div class="tabla pb-3">
                            <div class="p-3 pb-0">
                                <div class="acta-digitalizada container-fluid">
                                    <div class="row">
                                        <div class="col-8 px12 pl-0">
                                            <p class="font-medium text-left leyenda">
                                                Imagen procedente de:
                                            </p>
                                            <div class="container-fluid pl-0 my-2">
                                                <div class="row">
                                                    <div class="col-3 dis-flex">
                                                        <div class="grupo gris dis-flex">
                                                            <i class="fa fa-file" style="color: #929292;"></i>
                                                            <i class="fa fa-hourglass" style="color: #929292;"></i>
                                                            <p class="leyenda">
                                                                Acta en proceso
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 dis-flex bordes-laterales">
                                                        <div class="grupo dis-flex cursor-hand"
                                                            tooltip="Acta PREP digitalizada por medio de escáner."
                                                            placement="top" max-width="500">
                                                            <i class="far fa-file"></i>
                                                            <i class="fas fa-print" aria-hidden="true"></i>
                                                            <p class="leyenda">
                                                                Escáner
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 dis-flex">
                                                        <div class="grupo dis-flex cursor-hand"
                                                            tooltip="Acta PREP digitalizada por medio de dispositivo móvil."
                                                            placement="top" max-width="500">
                                                            <div class="grupo dis-flex">
                                                                <i class="far fa-file"></i>
                                                                <i class="fas fa-mobile-alt"></i>
                                                                <p class="leyenda">
                                                                    Móvil {{showLabel ? "Casilla" : ''}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 dis-flex borde-izq" *ngIf="showCATD">
                                                        <div class="grupo dis-flex cursor-hand"
                                                            tooltip="Acta PREP digitalizada por medio de dispositivo móvil."
                                                            placement="top" max-width="500">
                                                            <div class="grupo dis-flex">
                                                                <div *ngIf="!themeService.isDarkTheme()" style="width: 33px;height: 20px;background-image: url('./assets/img/generales/logo_movil_catd.png');background-repeat: no-repeat;image-rendering: -webkit-optimize-contrast;background-size:contain;"></div>
                                                                <div *ngIf="themeService.isDarkTheme()" style="width: 33px;height: 20px;background-image: url('./assets/img/generales/logo_movil_catd_rosa.png');background-repeat: no-repeat;image-rendering: -webkit-optimize-contrast;background-size:contain;"></div>
                                                                <p class="leyenda">
                                                                    Móvil CATD
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <div class="table-responsive tabla-seccion">
                                <table class="table table-striped contenido table-bordered">
                                    <thead class="titulos">
                                        <tr>
                                            <th class="sticky-cell">
                                                <div class="dis-flex" style="width: 140px;">
                                                    <ng-container>
                                                        <ng-container>
                                                            Casillas
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </th>

                                            <th class="sticky-cell2">
                                                <div class="dis-flex">
                                                    <ng-container>
                                                        <ng-container>
                                                            Corregida en cotejo
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </th>

                                            <th class="sticky-cell3">
                                                <div class="dis-flex">
                                                    <ng-container>
                                                        <ng-container>
                                                            Acta digitalizada
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </th>
                                            <th *ngFor="let itemHeader of casilla_table?.headers; let i = index">
                                                <div class="dis-flex">
                                                    <ng-container>
                                                        <ng-container *ngIf="itemHeader?.text != ''">

                                                            {{itemHeader?.text}}

                                                        </ng-container>
                                                        <ng-container *ngFor="let img of itemHeader?.imgs">
                                                            <img style="height: 30px;"
                                                                [src]="'./assets/img/logos-partidos/'+img" />
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let itemBody of casilla_table?.rows">
                                            <td class="sticky-cell">
                                                <strong>{{itemBody?.casilla.text}}</strong>
                                            </td>
                                            <td class="sticky-cell2">
                                                {{itemBody.casilla.corregida}}
                                            </td>
                                            <td class="sticky-cell3">
                                                <a target="_blank"
                                                    [href]="itemBody.status.movil? itemBody.status.movil : (itemBody.status.scanner ? itemBody.status.scanner : itemBody.status.catd)"
                                                    *ngIf="itemBody.status != 'proceso' && itemBody.status != 'sin_acta'">
                                                    <ng-container *ngIf="itemBody.status.movil">
                                                        <i class="far fa-file" aria-hidden="true"></i>
                                                        <i class="fas fas fa-mobile-alt" aria-hidden="true"></i>
                                                    </ng-container>
                                                    <ng-container *ngIf="itemBody.status.scanner">
                                                        <i class="far fa-file" aria-hidden="true"></i>
                                                        <i class="fas fa-print" aria-hidden="true"></i>
                                                    </ng-container>
                                                    <ng-container *ngIf="itemBody.status.catd">
                                                        <img *ngIf="!themeService.isDarkTheme()" height="15" [src]="'./assets/img/generales/logo_movil_catd.png'">
                                                        <img *ngIf="themeService.isDarkTheme()" height="15" [src]="'./assets/img/generales/logo_movil_catd_rosa.png'">
                                                    </ng-container>
                                                    <ng-container *ngIf="itemBody.status === 'proceso'">
                                                        <i class="fas fa-file"></i>
                                                        <i class="fas fa-hourglass"></i>
                                                    </ng-container>
                                                </a>
                                                <a target="_blank" *ngIf="itemBody.status == 'proceso' || itemBody.status == 'sin_acta'">
                                                    <div class="txGris">
                                                        <i class="fas fa-file txGris"></i>
                                                        <i class="fas fa-hourglass txGris"></i>
                                                    </div>
                                                </a>
                                            </td>
                                            <td *ngFor="let itemVoto of itemBody.columns">
                                                {{itemVoto?.value}}
                                            </td>
                                            <td *ngFor="let itemVoto of itemBody.resumen">
                                                {{itemVoto?.value}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!--*************************** VISTA MOVIL *************************-->
                <div class="row w-100 d-block d-md-block d-xl-none" style="margin: auto;">
                    <div class="w-100" *ngIf="selectedIndxCasilla == -1">
                        <div class="w-100 item-card" *ngFor="let item of fichas; let indx = index;" [ngClass]="{'stripped' : indx%2 == 0}">
                            <table class="w-100">
                                <tr>
                                    <td class="w-col">
                                        <img class="logo" height="30px" *ngIf="item.imgs?.length > 0"
                                            src="./assets/img/logos-partidos/{{item.imgs[0]?.toLowerCase()}}"/>
                                    </td>
                                    <td class="w-col">
                                        <p class="votos">Votos</p>
                                        <p class="label-votos"><strong>{{ item?.total }}</strong></p>
                                    </td>
                                    <td class="w-col">
                                        <p class="label-votos align-center"><br><strong>{{ item?.prc }}</strong></p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="w-100" *ngIf="selectedIndxCasilla > -1">
                        <div class="w-100 item-card" *ngFor="let item of casilla_table?.rows[selectedIndxCasilla]?.columns; let indx = index" [ngClass]="{'stripped' : indx%2 == 0}">
                            <div class="w-100">
                                <table class="w-100">
                                    <tr>
                                        <td class="w-col">
                                            <img class="logo" height="30px" *ngIf="casilla_table?.headers[indx]?.imgs?.length > 0"
                                                src="./assets/img/logos-partidos/{{casilla_table?.headers[indx]?.imgs[0]?.toLowerCase()}}"/>
                                        </td>
                                        <td class="w-col">
                                            <p class="votos">Votos</p>
                                            <p class="label-votos"><strong>{{ item?.value }}</strong></p>
                                        </td>
                                        <td class="w-col">
                                            <p class="label-votos align-center"><br><strong>{{ item?.prc }}</strong></p>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!--*****************************************************************-->

                <app-resumen-votacion [hide_acumulados]="true"
                    [noregistradas_num]="resumen?.no_registradas?.total"
                    [noregistradas_prc]="resumen?.no_registradas?.prc"
                    [nulos_num]="resumen?.nulos?.total"
                    [nulos_prc]="resumen?.nulos?.prc"
                    [total_num]="resumen?.total?.total"
                    [total_prc]="resumen?.total?.prc"
                    [observaciones]="txtObs"
                    [use_observaciones]="showObs">
                </app-resumen-votacion>

            </div>
        </div>
    </div>
    <div class="row observacionesActa">
        <div class="col-md-12">
            <div class="estadistica-casilla card mt-3 p-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <h4 class="subtitulo-seccion pleca-izquierda">
                                <strong>Observaciones en Acta</strong>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-4">
                    <div class="row justify-content-center">
                        <div class="col-11">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <h4 class="subtitulo-seccion pleca-izquierda">
                                            <strong class="txRosa2">Contabilizadas</strong>
                                        </h4>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Ilegible
                                                </strong>
                                            </p>
                                            <p>
                                                La cantidad de votos en algún campo del Acta PREP no puede leerse, tanto
                                                en letra como en número.
                                            </p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Sin Dato
                                                </strong>
                                            </p>
                                            <p>
                                                La cantidad de votos no fue asentada en el Acta PREP ni en letra ni en
                                                número en algún campo del Acta PREP.
                                            </p>
                                        </div>
                                        <div class="grupo py-3 mb-2">
                                            <p class="pb-2">
                                                <strong>
                                                    Identificación por Proceso Técnico Operativo
                                                </strong>
                                            </p>
                                            <p>
                                                Los datos del Acta PREP no se identificaron y sé solicitó verificarlos
                                                por algún procedimiento válido establecido en el Proceso Técnico
                                                Operativo.
                                            </p>
                                        </div>
                                        <div class="d-none d-xl-block">
                                            <h4 class="subtitulo-seccion pleca-izquierda pleca-morada mt-5">
                                                <strong><span class="txMorado">No identificadas -</span> Fuera del
                                                    catálogo</strong>
                                            </h4>
                                            <div class="grupo py-3">
                                                <p>
                                                    No es posible ubicar el Acta PREP dentro de la lista de Actas de
                                                    casillas aprobadas por el INE, al presentar alguna omisión, ilegibilidad
                                                    o error en sus datos de identificación. Para fines de publicación, el
                                                    Acta se muestra al final del listado de Actas
                                                    aprobadas en la Base de Datos.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <h4 class="subtitulo-seccion pleca-izquierda pleca-aqua">
                                            <strong class="txAqua">No Contabilizadas</strong>
                                        </h4>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Excede Lista Nominal
                                                </strong>
                                            </p>
                                            <p>
                                                La suma de todos los votos en el Acta PREP, excede el número de
                                                ciudadanas y ciudadanos en la Lista Nominal de Electores de la casilla,
                                                incluido el número de votos emitidos por los representantes de los
                                                Partidos Políticos.
                                            </p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Sin Acta
                                                </strong>
                                            </p>
                                            <p class="pb-2">
                                                El Acta PREP no llegó junto con el paquete electoral al Centro de Acopio
                                                y Transmisión de Datos (CATD), por algunas de las siguientes causas:
                                            </p>
                                            <p>
                                                1. Sin Acta por paquete no entregado.
                                            </p>
                                            <p>
                                                2. Sin Acta por casilla no instalada.
                                            </p>
                                            <p>
                                                3. Sin Acta por paquete entregado sin bolsa.
                                            </p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Todos los campos ilegibles
                                                </strong>
                                            </p>
                                            <p>
                                                Todos los campos del Acta PREP correspondientes al número de votos, son
                                                ilegibles tanto en letra como en número.
                                            </p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Todos los campos vacíos
                                                </strong>
                                            </p>
                                            <p>
                                                Todos los campos del Acta PREP correspondientes al número de votos, se
                                                encuentran vacíos tanto en letra como en número.
                                            </p>
                                        </div>
                                        <div class="d-block d-sm-none">
                                            <h4 class="subtitulo-seccion pleca-izquierda pleca-morada mt-5">
                                                <strong><span class="txMorado">No identificadas -</span> Fuera del catálogo</strong>
                                            </h4>
                                            <div class="grupo py-3">
                                                <p>
                                                    No es posible ubicar el Acta PREP dentro de la lista de Actas de casillas aprobadas por el INE, al presentar alguna omisión, ilegibilidad o error en sus datos de identificación. Para fines de publicación, el Acta se muestra al final del listado de Actas aprobadas en la  Base de Datos.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- <app-estadistica-nacional></app-estadistica-nacional> -->
</div>
