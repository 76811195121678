import { Injectable } from '@angular/core';
import { ObtenerDatosService } from '../datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from '../globales.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComparativaService {

    private componente = new Subject<any>();
    observable = this.componente.asObservable();
    
    arregloElementos: any[] = [];

    stageArray: any[] = [];
    stageStatus = false;

    pantalla: string;
    desplegable: string;    
    modalFull = false;

    votos_total:any;
    votos_pctj:any;

    constructor(
        public obtenerDatosService: ObtenerDatosService,
        public globalService: GlobalesService
    ) { }

    agregarElemento(elemento: any, nivel?: any) {
        this.arregloElementos.push(elemento);
        elemento.seleccionado = true;
        
        this.componente.next();
    }

    eliminarElemento(elemento: any, nivel?: any) {
        const id = this.arregloElementos.indexOf(elemento);
        elemento.seleccionado = false;
        this.arregloElementos.splice(id, 1);
        
        this.componente.next();
    }

    guardarStage(stage: any) {
        this.stageArray = stage;
        this.globalService.bottomSheetData = this.stageArray;
    }

    resetear() {
        this.arregloElementos.forEach(elemento => {
            elemento.seleccionado = false;
        });
        this.arregloElementos = [];
    }

    reiniciarModal() {
        this.componente.next();
        this.modalFull = false;
    }

    cerrar() {
        this.resetear();
    }
}
