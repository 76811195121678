import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from 'src/environments/environment';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { ComparativaService } from 'src/app/servicios/comparativa/comparativa.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Chart, MapChart} from 'angular-highcharts';

// import * as jQuery from 'jquery';
declare var $;

import { ThemeService } from '../../../../../theme/theme.service';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';
import { ConvertirMapaService } from '../../../../../servicios/convertir-mapa.service';

@Component({
  selector: 'app-resumen-distrito',
  templateUrl: './resumen-distrito.component.html',
  styleUrls: ['./resumen-distrito.component.scss']
})
export class ResumenDistritoComponent implements OnInit {
    tipo_contienda: any = '';
    mapa_table: any = [];
    data_mapa: any = [];
    resumen_table: any = [];
    resumen_votacion: any = [];
    contiendas_total: number = 0;

    labels: any = {};
    resumen: any = {};
    fichas: any = [];
    
    /* Variables Mapa */
    public mapa_movil: MapChart;
    public mapa_modal: MapChart;
    
    mapSelection: any = '';
  
  constructor(
    private http: HttpClient,
    public _obtenerDatosService: ObtenerDatosService,
    public comparativaService: ComparativaService,
    public _globalService: GlobalesService,
    private route: ActivatedRoute,
    public _router:Router,
    public themeService: ThemeService,
  ) { }

  ngOnInit(): void {
      // $('[data-toggle="tooltip"]').tooltip();
      this.comparativaService.stageStatus = false;
      this.comparativaService.pantalla = 'resumenDistrito';
      this.comparativaService.resetear();
      
      this._globalService.bottomSheetContex = this;
      this._globalService.bottomSheetCallback = this.agregarElemento;

      this.route.data.subscribe(v => {
        this.labels.contienda = v.contienda || '[-]';
      });

      this._obtenerDatosService.mainSubject.subscribe(data => {
          if (data == null) return;
          this.loadData();
      });
  }

  loadData() {
      let selectedContienda = this._globalService.getContienda();
      let json_file = '';
      if(selectedContienda == 'ayuntamientos') json_file = 'entidad.municipios.json';
      else if(selectedContienda == 'juntas') json_file = 'entidad.juntas.json';
      else if(selectedContienda == 'diputaciones') json_file = 'entidad.distritos.json';

      this._obtenerDatosService.loadJSON(selectedContienda, json_file).then(data => {
          this.tipo_contienda = selectedContienda;
          this.resumen_table = data['resumen_table']? data['resumen_table'].columns : data['tabla'].columns;
          this.data_mapa = data['mapa'].distritos? data['mapa'].distritos : data['mapa'].municipios;
          this._globalService.bottomSheetTpl = 'comparador-contienda';
          this.resumen = data['resumen'];
          this.fichas = data['fichas'];
          this.pintarMapa();
          
          this.comparativaService.votos_total = this.resumen['total'].total;
          this.comparativaService.votos_pctj = this.resumen['total'].prc;
      });
  }

    pintarMapa(){
        var key = 'JUNTA';
        if(this.tipo_contienda != 'juntas'){
            key = (this.tipo_contienda == 'diputaciones'? 'DISTRITO_L' : 'MUNICIPIO');
        }
        var format = '';
        var _this = this;
        switch(this.tipo_contienda){
            case 'diputaciones':{
                format = 
                '<div style="background-color: {point.options.color}; min-width:300px; box-shadow: 0px 10px 20px -5px rgba(0,0,0,0.75); border-radius: 5px;">' +
                    '<div style="margin-left: 16px;">' +
                        '<div style="background-color: #ffffff; padding: 10px;">' +
                            '<img class="img-partido" src="./assets/img/logos-partidos/{point.options.img_partido}">' +
                        '</div>' +
                        '<div style="background-color: #e6e6e6; padding: 10px;">' +
                            '<label style="margin: 0px; font-size: 11px; font-weight: 500;">Distrito</label>' +
                            '<p style="font-size: 13px; font-weight: 600;">{point.properties.NOMBRE} <a href="diputaciones/{point.properties.ENLACE}/votos-candidatura" style="font-size: 11px; font-weight: 400;color: #D5137F; text-decoration-line: underline;">Ver detalle por distrito</a> </p>' +
                        '</div>' +
                    '</div>' +
                '</div>';
                break;
            }
            case 'ayuntamientos':{
                format = 
                '<div style="background-color: {point.options.color}; min-width:300px; box-shadow: 0px 10px 20px -5px rgba(0,0,0,0.75); border-radius: 5px;">' +
                    '<div style="margin-left: 16px;">' +
                        '<div style="background-color: #ffffff; padding: 10px;">' +
                            '<img class="img-partido" src="./assets/img/logos-partidos/{point.options.img_partido}">' +
                        '</div>' +
                        '<div style="background-color: #e6e6e6; padding: 10px;">' +
                            '<label style="margin: 0px; font-size: 11px; font-weight: 500;">Municipio</label>' +
                            '<p style="font-size: 13px; font-weight: 600;">{point.properties.NOMBRE} <a href="ayuntamientos/{point.properties.ENLACE}/votos-candidatura" style="font-size: 11px; font-weight: 400;color: #D5137F; text-decoration-line: underline;">Ver detalle por municipio</a> </p>' +
                        '</div>' +
                    '</div>' +
                '</div>';
                break;
            }
            case 'juntas':{
                format = 
                '<div style="background-color: {point.options.color}; min-width:300px; box-shadow: 0px 10px 20px -5px rgba(0,0,0,0.75); border-radius: 5px;">' +
                    '<div style="margin-left: 16px;">' +
                        '<div style="background-color: #ffffff; padding: 10px;">' +
                            '<img class="img-partido" src="./assets/img/logos-partidos/{point.options.img_partido}">' +
                        '</div>' +
                        '<div style="background-color: #e6e6e6; padding: 10px;">' +
                            '<label style="margin: 0px; font-size: 11px; font-weight: 500;">Junta</label>' +
                            '<p style="font-size: 13px; font-weight: 600;">{point.properties.NOMBRE} <a href="juntas/{point.properties.ENLACE}/votos-candidatura" style="font-size: 11px; font-weight: 400;color: #D5137F; text-decoration-line: underline;">Ver detalle por junta municipal</a> </p>' +
                        '</div>' +
                    '</div>' +
                '</div>';
                break;
            }
        }
        
        var map_file = 'juntas.geojson';
        if(this.tipo_contienda != 'juntas'){
            map_file = (this.tipo_contienda == 'diputaciones')? 'distritos.geojson' : 'municipio.geojson';
        }
        this._obtenerDatosService.loadMapa(map_file).then(data => {
            if(data){
                //Mapa en la vista movil
                this.mapa_movil = new MapChart({
                    title: { text: '' },
                    chart: {
                        map: data as any,
                        height: 350,
                        width: 330,
                        backgroundColor: '#FFFFFF',
                        pinchType: 'xy',
                    },
                    credits: {
                        enabled: false
                    },
                    exporting: {
                        enabled: false
                    },
                    legend: {
                        enabled: false
                    },
                    mapNavigation: {
                        enabled: false
                    },
                    plotOptions: {
                        map: {
                            animation: false,
                            tooltip: {},
                            cursor: 'pointer'
                        },
                    },
                    series: [{
                        type: undefined,
                        data: this.data_mapa,
                        keys: [key, 'value'],
                        joinBy: key,
                    }],
                    tooltip: {
                        enabled: false,
                    }
                });
                
                //Mapa dentro del modal en la vista movíl
                this.mapa_modal = new MapChart({
                    title: { text: '' },
                    chart: {
                        map: data as any,
                        height: 350,
                        width: 330,
                        backgroundColor: '#FFFFFF',
                        events: {
                            render: function(){
    //                            this.resetZoomButton.hide();
                            },
                        },
                        pinchType: 'xy',
                    },
                    credits: {
                        enabled: false
                    },
                    exporting: {
                        enabled: false
                    },
                    legend: {
                        enabled: false
                    },
                    mapNavigation: {
                        enabled: true,
                        enableButtons: false,
                        enableTouchZoom: true,
                    },
                    plotOptions: {
                        map: {
                            animation: false,
                            cursor: 'pointer',
                            events: {
                                click: function(event) {
                                    //Simular doble tap
                                    setTimeout(function() {
                                        _this.mapSelection = null;
                                    }, 500);
                                    if(event.point == _this.mapSelection){
                                        $('#modalMovilMapa').modal('hide');
                                        _this._router.navigateByUrl(_this._globalService.getContienda()+'/'+_this.mapSelection.properties.ENLACE+'/votos-candidatura');
//                                        console.log(_this._globalService.getContienda()+'/'+_this.mapSelection.properties.ENLACE+'/votos-candidatura');
                                    }
                                    _this.mapSelection = event.point;
                                },
                            },
                            point: {
                                events: {
                                    select: function(event){
                                        console.log('select', event);
                                    }
                                }
                            }
                        },
                    },
                    series: [{
                        type: 'map',
                        data: this.data_mapa,
                        keys: [key, 'value'],
                        joinBy: key,
                    }],
                    tooltip: {
                        enabled: false,
                    }
                });
                
                this.mapa_movil.destroy();
                this.mapa_modal.destroy();
            }
        });
    }

  agregarElemento(el: any) {
      //Fix del contexto de la variable 
      let scope = (this instanceof GlobalesService)? this["bottomSheetContex"] : this;
      if (scope.comparativaService.arregloElementos.length < 3) {
          scope.comparativaService.agregarElemento(el);
          el.seleccionado = true;
      }
      if(this instanceof GlobalesService && scope.comparativaService.arregloElementos.length >= 3){
          this.toggleBottomSheet(false);
      }
      if (!scope.comparativaService.stageStatus) {
          scope.comparativaService.stageStatus = true;
          scope.comparativaService.guardarStage(scope.fichas);
      }
  }

    eliminarElemento(el: any) {
        this.comparativaService.eliminarElemento(el);
        el.seleccionado = false;
    }

    selectedItemFun = (el: any): void => {
      this.agregarElemento(el);
    }
  
    diselectedItemFun = (el: any): void => {
      this.eliminarElemento(el);
    }
}
