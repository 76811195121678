import { Component, OnInit, HostListener } from '@angular/core';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';

@Component({
  selector: 'app-boton-compartir',
  templateUrl: './boton-compartir.component.html',
  styleUrls: ['./boton-compartir.component.scss']
})
export class BotonCompartirComponent implements OnInit {

  _url: string = "";
  title: string = "";
  isOpen = -1
  isSimulacro = false;

  constructor(public _obtenerDatosService: ObtenerDatosService) { }

  ngOnInit(): void {
    this.getScreenSize()
    this._url = encodeURIComponent(window.location.href);
    this._obtenerDatosService.configSubject.subscribe(data => {
      if (data == null) return;
      this.isSimulacro = data.simulacro;
      this.title = data.header.title.replace(/<[^>]*>/g, '');
    });
    
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
      const scrWidth = window.innerWidth;
      //console.log('WINDOW WIDTH:  ', scrWidth);
      this.isOpen = scrWidth <= 1200 ? 0 : -1;
  }

  openShare(){
    if(this.isSimulacro){
      return;
    }
    if(this.isOpen < 0){
      return
    }
    this.isOpen = this.isOpen == 1 ? 0 : 1
  }

}
